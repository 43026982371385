import React from 'react';
import { useParams } from 'react-router-dom';
import Form from './Form';
import { Offer } from '../../interfaces/Offer';

interface FormProps {
    isTemplate: boolean;
    onSave: (id: string | undefined, isTemplate: boolean, status: string) => void;
    offer: Offer;
    setOffer: React.Dispatch<React.SetStateAction<Offer>>;
}

const ClientForm: React.FC<FormProps> = ({ onSave, offer, setOffer, isTemplate }) => {
    const { clientId } = useParams<{ clientId?: string }>();
    
    return (
        <Form
            onSave={onSave}
            offer={offer}
            setOffer={setOffer}
            isTemplate={isTemplate}
            clientId={parseInt(clientId!)}
        />
    );
};

export default ClientForm;
