import React, { useState, useCallback, memo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs from 'dayjs';

interface CustomTimePickerProps {
  selected: Date | null;
  onChange: (date: Date | null) => void;
  placeholderText: string;
  [key: string]: any;
}

const CustomTimePicker: React.FC<CustomTimePickerProps> = memo(({
  selected,
  onChange,
  placeholderText,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = useCallback(
    (newTime: dayjs.Dayjs | null) => {
      if (newTime && selected) {
        const updatedDate = new Date(selected);
        updatedDate.setHours(newTime.hour());
        updatedDate.setMinutes(newTime.minute());
        onChange(updatedDate);
      } else {
        onChange(null);
      }
    },
    [onChange, selected]
  );

  const handleFocus = useCallback(() => setIsFocused(true), []);
  const handleBlur = useCallback(() => setIsFocused(false), []);

  const isFilled = !!selected;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="floating-label-timepicker">
        <TimeField
          value={selected ? dayjs(selected) : null}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          format="HH:mm"
          {...rest}
        />
        <label className={`floating-label ${isFilled ? 'filled' : ''} ${isFocused ? 'focused' : ''}`}>
          {placeholderText}
        </label>
      </div>
    </LocalizationProvider>
  );
});

CustomTimePicker.displayName = 'CustomTimePicker';

export default CustomTimePicker;