export interface Contact {
    id: number | null;
    clientId: number | null;
    name: string | null;
    title: string | null;
    position: string | null;
    department: string | null;
    location: string | null;
    phone: string | null;
    email: string | null;
    dateOfBirth: Date | null;
};

export const defaultContact: Contact = {
    id: null,
    clientId: null,
    name: "",
    title: "",
    position: "",
    department: "",
    location: "",
    phone: "",
    email: "",
    dateOfBirth: null,
};