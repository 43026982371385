import React, {useMemo, useState} from 'react';

interface InputProps {
  id: string;
  label: string;
  borderColor?: string;
  errorColor?: string;
  placeholder?: string;
  type?: string;
  errorText?: string;
  value: string|undefined|null|number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  [x: string]: any; // Permite alte props
}

const Input: React.FC<InputProps> = ({
  id,
  label,
  borderColor = 'primary',
  errorColor = 'text-red-600 dark:text-red-400',
  placeholder = ' ',
  errorText = '',
  type='text',
  value,
  onChange,
  ...props
}) => {
  const isWhiteBackground = useMemo(() => {
    if (value === undefined || value === null || value === '') {
      return false;
    }
    return true;
  }, [value]);
  return (
    <div>
        <div className={`relative`}>
            <input 
                type={ type } 
                id={id}
                aria-describedby={`${id}_help`}
                className={`block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-${borderColor} focus:outline-none focus:ring-0 focus:border-${borderColor} peer`}
                placeholder={placeholder}
                value={value ? value : ''}
                onChange={onChange}
                {...props}
            />
            <label htmlFor={id} className={`absolute text-gray-500 dark:text-gray-400 text-sm duration-100 text-nowrap transform -translate-y-3 scale-75 top-1 z-10 origin-[0] ${isWhiteBackground ? 'bg-white' : 'bg-transparent'} dark:bg-gray-900 px-2 peer-focus:bg-white peer-focus:px-2 peer-focus:text-${borderColor} peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto`}>
                {label}
            </label>
        </div>
        <p id={`${id}_help`} className={`mt-1 text-xs ${errorColor}`}>{ errorText }</p>
    </div>
  );
};

export default Input;
