import React, { useState } from 'react';
import { Booking, BookingBill } from '../../../interfaces/Booking';
import { Button } from 'flowbite-react';
import BillsTable from './components/BillsTable';
import BillModal from '../components/BillModal';
import DeleteModal from '../../../components/DeleteModal';
import { BookingService } from '../../../services/BookingService';
import { successMessage } from '../../../functions/generalHelper';

interface BillsProps {
  booking: Booking;
  setBooking: React.Dispatch<React.SetStateAction<Booking>>;
}

const Bills: React.FC<BillsProps> = ({ booking, setBooking }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number>(0);
  const bookingService = new BookingService();

  const handleAddBill = () => {
    setIdToDelete(0);
    setShowModal(true);
  };
  
  const onSave = (bill : BookingBill, create : boolean) => {
    if(create){
      setBooking(prev => ({...prev, bookingBills: [...prev.bookingBills, bill]}));
    }else{
      setBooking(prev => ({...prev, bookingBills: prev.bookingBills.map(b => b.id === bill.id ? bill : b)}));
    }
  };
  
  const handleDelete = (id : number) => {
    setIdToDelete(id);
    setShowModalDelete(true);
  };
  
  const handleEdit = (id : number) => {
    setIdToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = () => {
    bookingService.removeBookingBill(idToDelete).then(() => {
      setBooking(prev => ({...prev, bookingBills: prev.bookingBills.filter(b => b.id !== idToDelete)}));
      setIdToDelete(0);
      successMessage('B/L sters cu succes!');
    });
    setShowModalDelete(false);
  }
  
  return (
    <div>
      <div className="flex justify-end space-x-4 mb-4">
        <Button size="sm" onClick={handleAddBill} >Adaugă Conosament</Button>
      </div>
        <BillsTable booking={booking} onDelete={handleDelete} onEdit={handleEdit}/>
        <BillModal id={idToDelete} bookingId={booking.id!} onSave={onSave} setShowModal={setShowModal} showModal={showModal} />
        <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={confirmDelete} deleteText='Sunteți sigur că doriți să eliminați acest B/L?' />
    </div>
  );
};

export default Bills;