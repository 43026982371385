import React, { useState, useEffect, useMemo } from 'react';
import { defaultMovement, getContainerInfo, Movement } from '../../interfaces/Movement';
import { errorMessage, handleOptions, isNullOrEmpty, successMessage } from '../../functions/generalHelper';
import { Button, Modal } from 'flowbite-react';
import Input from '../../components/Input';
import { handleInputChange, handleSelectChange } from '../../functions/selectHelper';
import CustomSelect from '../../components/CustomSelect';
import { MovementService } from '../../services/MovementService';
import { LabelValueInt, RecommendationResp } from '../../interfaces/Recommandation';
import CustomDatePicker from '../../components/CustomDatePicker';
import { getDateFormat } from '../../functions/dateHelper';
import CustomTimePicker from '../../components/CustomTimePicker';

interface ModalNextMovementProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: () => void;
    nextMovement: {next?: string, movement?: Movement};
    recommendations: RecommendationResp[];
}

const ModalNextMovement: React.FC<ModalNextMovementProps> = ({ showModal, setShowModal, onSave, nextMovement, recommendations }) => {
    const [movement, setMovement] = useState<Movement>(defaultMovement);
    const [bookingOptions, setBookingOptions] = useState<LabelValueInt[]>([]);
    const movementService = new MovementService();
    const terminalOptions = handleOptions(recommendations, 'booking-terminals');
    const loadingMovements = useMemo(() => ['DF', 'OF', 'OE', 'DE'], []);

    // ia movementul daca exista, altfel seteaza defaultMovement
    useEffect(() => {
      if(!showModal){
        setMovement(defaultMovement);
      }else{
        setMovement({
            ...defaultMovement,
            movement: nextMovement.next!,
            containerCode: nextMovement.movement!.containerCode,
            terminal: nextMovement.movement!.terminal,
            bookingId: (nextMovement.next == 'FC' || nextMovement.next == 'FL' || nextMovement.next == 'DF' || nextMovement.next == 'RE' || nextMovement.next == 'OF') ? nextMovement.movement?.bookingId : undefined
        });
      }
      movementService.getBookings().then(data => setBookingOptions(data.map((d) => ({ label: d.number!, value: d.id! }))));
    }, [showModal]);

    // functia care trimite datele catre server
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const newMovement = await movementService.createMovement(movement);
            if(newMovement.status == 'success'){
                if(nextMovement.movement){
                    await movementService.changeAction(nextMovement!.movement!.id!);
                }
                setShowModal(false);
                successMessage(newMovement.message);
            }else{
                errorMessage(newMovement.message);
            }
            onSave();
        } catch (error) {
            errorMessage('Denumirea este obligatorie!');
        }
    };
    
    // functia care modifica data catre YML daca nu exista si daca se modifica data miscarii
    useEffect(() => {
        if(movement.date && !movement.dateSystem){
            setMovement(prev => ({ ...prev, dateSystem: movement.date }));
        }
    }, [movement.date]);

    return (
        <>
            <Modal show={showModal} size="3xl" popup onClose={() => setShowModal(false)}>
                <Modal.Header>
                    Următoarea Mișcare
                </Modal.Header>
                <Modal.Body className="overflow-visible">
                    <div className="space-y-2 pt-5">
                        <form onSubmit={handleSubmit}>     
                            <div className="gap-4 grid grid-cols-2 mb-2">  
                                <div className="mb-1">
                                    <Input 
                                        id="containerCode" 
                                        label="Container"
                                        value={nextMovement.movement?.containerCode}
                                        onChange={() => {}}
                                        readOnly
                                    />
                                </div>
                                <div className="mb-1">
                                    <Input 
                                        id="containerCode" 
                                        label="Mișcare"
                                        value={nextMovement.next}
                                        onChange={() => {}}
                                        readOnly
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomSelect
                                        value={terminalOptions.find(option => option.value === movement.terminal)}
                                        onChange={(data) => handleSelectChange(movement, setMovement, 'terminal', data)}
                                        options={terminalOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Terminal"
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomSelect
                                        value={bookingOptions.find(option => option.value === movement.bookingId)}
                                        onChange={(data) => handleSelectChange(movement, setMovement, 'bookingId', data)}
                                        options={bookingOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Booking"
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomDatePicker
                                        selected={movement.date}
                                        onChange={(date) => setMovement({ ...movement, date: date ? date : new Date() })}
                                        dateFormat= {getDateFormat()}
                                        locale="ro"
                                        isClearable
                                        placeholderText="Dată"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomTimePicker 
                                        selected={movement.date}
                                        onChange={(date) => setMovement({ ...movement, date: date ? date : new Date() })}
                                        placeholderText="Oră"
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomDatePicker
                                        selected={movement.dateSystem}
                                        onChange={(date) => setMovement({ ...movement, dateSystem: date })}
                                        dateFormat= {getDateFormat()}
                                        locale="ro"
                                        isClearable
                                        placeholderText="Dată YML"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomTimePicker 
                                        selected={movement.dateSystem}
                                        onChange={(date) => setMovement({ ...movement, dateSystem: date })}
                                        placeholderText="Oră YML"
                                    />
                                </div>
                                <div className="mb-1">
                                    <Input 
                                        id="note" 
                                        label="Notă"
                                        value={movement.note}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movement, setMovement, 'note', e.target.value, defaultMovement)}
                                    />
                                </div>
                                {loadingMovements.includes(nextMovement.next!) && (
                                    <>
                                        <div className="mb-1">
                                            <Input 
                                                id="vesselCode" 
                                                label="Cod Vas"
                                                value={movement.vesselCode}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movement, setMovement, 'vesselCode', e.target.value, defaultMovement)}
                                            />
                                        </div>
                                        <div className="mb-1">
                                            <Input 
                                                id="vesselName" 
                                                label="Nume Vas"
                                                value={movement.vesselName}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movement, setMovement, 'vesselName', e.target.value, defaultMovement)}
                                            />
                                        </div>
                                        <div className="mb-1">
                                            <Input
                                                id="port"
                                                label="Port străin"
                                                value={movement.port}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movement, setMovement, 'port', e.target.value, defaultMovement)}
                                            />
                                        </div>
                                    </>  
                                )}
                            </div>
                            <div className="flex justify-end">
                                <Button size="sm" type="submit">Salvează</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalNextMovement;