import React from 'react';

interface RaportsProps {
}

const Raports: React.FC<RaportsProps> = () => {
  return (
    <>
    </>
  );
};

export default Raports;
