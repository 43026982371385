import React, { useRef, useEffect } from 'react';

interface TextAreaProps {
  id: string;
  label: string;
  borderColor?: string;
  errorColor?: string;
  placeholder?: string;
  errorText?: string;
  value: string|null|undefined;
  rows?: number;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  [x: string]: any; // Permite alte props
}

const TextArea: React.FC<TextAreaProps> = ({
  id,
  label,
  borderColor = 'primary',
  errorColor = 'text-red-600 dark:text-red-400',
  placeholder = ' ',
  errorText = '',
  rows = 3,
  value,
  onChange,
  ...props
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  // Ajustează înălțimea la prima randare și când se modifică valoarea
  useEffect(() => {
    adjustHeight();
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);
    adjustHeight();
  };

  return (
    <div>
      <div className="relative">
        <textarea
          ref={textareaRef}
          id={id}
          aria-describedby={`${id}_help`}
          className={`block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 
            bg-transparent rounded-lg border-1 border-gray-300 
            appearance-none dark:text-white dark:border-gray-600 
            dark:focus:border-${borderColor} focus:outline-none focus:ring-0 
            focus:border-${borderColor} peer min-h-[42px] overflow-hidden resize-none`}
          placeholder={placeholder}
          value={value ? value : ''}
          onChange={handleChange}
          rows={rows}
          {...props}
        />
        <label 
          htmlFor={id} 
          className={`absolute text-gray-500 dark:text-gray-400 text-sm 
            duration-100 transform -translate-y-3 scale-75 top-1 z-10 
            origin-[0] bg-white dark:bg-gray-900 px-2 
            peer-focus:px-2 peer-focus:text-${borderColor}
            peer-placeholder-shown:scale-100 
            peer-placeholder-shown:-translate-y-1/2 
            peer-placeholder-shown:top-1/2 peer-focus:top-1 
            peer-focus:scale-75 peer-focus:-translate-y-3 start-1 
            rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto`}
        >
          {label}
        </label>
      </div>
      <p id={`${id}_help`} className={`mt-1 text-xs ${errorColor}`}>
        {errorText}
      </p>
    </div>
  );
};

export default TextArea;