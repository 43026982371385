// @ts-nocheck
import React, { useState } from 'react';
import CustomEditor from 'quill-react-commercial';
import 'quill-react-commercial/lib/index.css';

interface BubbleRichTextEditorSnowProps {
  initialValue?: string;
  placeholder?: string;
  onChange: (content: string) => void;
}

const RichTextEditorSnow: React.FC<BubbleRichTextEditorSnowProps> = ({ initialValue = '', placeholder = '', onChange }) => {
  const [editorContent, setEditorContent] = useState(initialValue);

  const modules = {
    codeHighlight: true,
    table: {
      operationMenu: {
        insertColumnRight: {
            text: 'Insert Column Right',
        },
        insertColumnLeft: {
            text: 'Insert Column Left',
        },
        insertRowUp: {
            text: 'Insert Row Above',
        },
        insertRowDown: {
            text: 'Insert Row Below',
        },
        mergeCells: {
            text: 'Merge Selected Cells',
        },
        unmergeCells: {
            text: 'Unmerge Cells',
        },
        deleteColumn: {
            text: 'Delete Columns',
        },
        deleteRow: {
            text: 'Delete Rows',
        },
        deleteTable: {
            text: 'Delete Table',
        },
      }, // Generally not required 
      backgroundColors: {
        colors: ['#4a90e2', '#999'], // backgroundcolor of table cell, default: ['#dbc8ff', '#6918b4', '#4a90e2', '#999', '#fff']
        text: 'Background Colors', // default: 'Background Colors'
      },
      toolBarOptions: {
        dialogRows: 3, // default: 9
        dialogColumns: 4, // default: 9
        i18: 'en',
      }, // when click table in toorbar, the configs of the dialog
    }, // default: false
    link: true,
    toolbarOptions: [
        [{ font: ['songTi', 'serif', 'arial'] }, { size: ['8px', '10px', '12px', '14px', '16px', '20px', '24px', '28px', '32px', '36px', '42px'] }],
        [{ color: [] }, { background: [] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link', 'table', 'divider'],
        ['undo', 'redo', 'clean'],
    ]
  };

  return (
    <div className="bubble-rich-text-editor">
      <CustomEditor
        modules={modules}
        placeholder={placeholder}
        content={editorContent}
      />
    </div>
  );
};

export default RichTextEditorSnow;