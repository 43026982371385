import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon } from '@heroicons/react/24/outline'
import { FullClient } from '../../../interfaces/Client';

interface GeneralProps {
  onSave: () => void;
  client: FullClient;
}

const General: React.FC<GeneralProps> = ({ onSave, client }) => {
  // const { id } = useParams<{ id?: string }>();
  // const [component, setComponent] = useState<Component>(defaultComponent);
  // const componentService = new ComponentService();
  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ')
  }
  
  const stats = [
    { id: 1, name: 'Total bookinguri', stat: '102', icon: UsersIcon, change: '5%', changeType: 'increase' },
    { id: 2, name: 'Vânzări totale', stat: '75.609', icon: EnvelopeOpenIcon, change: '5.4%', changeType: 'increase' },
    { id: 3, name: 'Număr oferte', stat: client.offers?.length, icon: CursorArrowRaysIcon, change: '20%', changeType: 'decrease' },
    { id: 4, name: 'Scor client', stat: '96.89%', icon: CursorArrowRaysIcon, change: '1.3%', changeType: 'decrease' },
  ]

  return (
    <>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <item.icon aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
              <p
                className={classNames(
                  item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                  'ml-2 flex items-baseline text-sm font-semibold',
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowUpIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 self-center text-green-500" />
                ) : (
                  <ArrowDownIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 self-center text-red-500" />
                )}

                <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                {item.change}
              </p>
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Vezi mai mult<span className="sr-only"> {item.name} stats</span>
                  </a>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
      <div className="grid grid-cols-1 sm:grid-cols-4 mt-4 gap-5">
        <div className="col-span-3">
          <h3 className="font-bold">Contacte:</h3>
          <div className="mt-4 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Nume
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Email
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Telefon
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Alte Date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {client.contacts.map((contact) => (
                        <tr key={`contact${contact.id}`}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {contact.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><a className="link-primary" href={`mailTo:${contact.email}`}>{contact.email}</a></td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><a className="link-primary" href={`tel:${contact.phone}`}>{contact.phone}</a></td>
                          <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500" dangerouslySetInnerHTML={{__html: (`
                            ${contact.title ? `Titlu: ${contact.title}<br>` : ''}
                            ${contact.position ? `Poziție: ${contact.position}<br>` : ''}
                            ${contact.department ? `Departament: ${contact.department}<br>` : ''}
                            ${contact.location ? `Locație: ${contact.location}` : ''}
                          `)}}></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-5 pt-3 shadow">
          <h3 className="font-bold text-center pb-2 border-b">Date generale</h3>
          <div className="flex justify-between gap-x-4 py-1 pt-3">
              <div className="text-gray-500 text-sm">Denumire:</div>
              <div className="text-gray-700 text-sm">{ client.company }</div>
          </div>
          <div className="flex justify-between gap-x-4 py-1">
              <div className="text-gray-500 text-sm">Email:</div>
              <div className="text-gray-700 text-sm"><a className="link-primary" href={`mailTo: ${ client.email }`}>{ client.email }</a></div>
          </div>
          <div className="flex justify-between gap-x-4 py-1">
              <div className="text-gray-500 text-sm">Telefon:</div>
              <div className="text-gray-700 text-sm"><a className="link-primary" href={`tel:${client.phone}`}>{ client.phone }</a></div>
          </div>
          <div className="flex justify-between gap-x-4 py-1">
              <div className="text-gray-500 text-sm">CUI:</div>
              <div className="text-gray-700 text-sm">{ client.gstNumber }</div>
          </div>
          <div className="flex justify-between gap-x-4 py-1">
              <div className="text-gray-500 text-sm">Sales:</div>
              <div className="text-gray-700 text-sm">{ client.user?.name }</div>
          </div>
          <div className="flex justify-between gap-x-4 py-1">
              <div className="text-gray-500 text-sm">Nr. Reg. Com.:</div>
              <div className="text-gray-700 text-sm">{ client.vat }</div>
          </div>
          <div className="flex justify-between gap-x-4 py-1">
              <div className="text-gray-500 text-sm">Adresă:</div>
              <div className="text-gray-700 text-sm">{ `${client.street ? client.street : ', '} ${client.number ? client.number : ', '}` }</div>
          </div>
          <div className="flex justify-between gap-x-4 py-1">
              <div className="text-gray-500 text-sm">Județ/Sector:</div>
              <div className="text-gray-700 text-sm">{ client.state }</div>
          </div>
          <div className="flex justify-between gap-x-4 py-1">
              <div className="text-gray-500 text-sm">Oraș:</div>
              <div className="text-gray-700 text-sm">{ client.city }</div>
          </div>
          <div className="flex justify-between gap-x-4 py-1">
              <div className="text-gray-500 text-sm">Țară:</div>
              <div className="text-gray-700 text-sm">{ client.country }</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default General;