import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useUser } from '../../../components/UserContext';
import Breadcrumb from '../../../components/BreadCrumb';
import BookingsNavBar from '../../../components/BookingsNavBar';
import Index from './Index';
import View from './View';
import CheckPermission from '../../../components/CheckPermission';

const Manifests: React.FC = () => {
    return (
      <>
        <BookingsNavBar />
        <Breadcrumb />
        <CheckPermission permissions={['MANIFESTS_VIEW_ALL']}>
          <Routes>
            <Route path="index" element={<Index />} />
            <Route path="view/:id" element={<View />} />
            <Route path="/" element={<Navigate to="/bookings/manifests/index" replace />} />
          </Routes>
        </CheckPermission>
      </>
    );
};

export default Manifests;