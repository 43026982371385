import React, { useCallback, useMemo, useState } from 'react';
import { Activity } from '../../interfaces/Activity';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Actions from '../../components/Actions';
import { Badge, Button } from "flowbite-react";
import { AG_GRID_LOCALE_RO } from '../../functions/langHelper';
import { formatDate, getDateColorClass } from '../../functions/dateHelper';
import Tooltip from '../../components/Tooltip';
import { AiOutlineUserAdd } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { ColDef, GridReadyEvent, ColumnState, FilterModel, Params, RowDoubleClickedEvent, SideBarDef } from 'ag-grid-community';
import { useAuth } from '../../functions/useAuth';
import { useUser } from '../../components/UserContext';
import CheckPermission from '../../components/CheckPermission';
import ViewActivityModal from './ViewActivityModal';
import View from '../bookings/View';
import { IoMdRefresh } from "react-icons/io";
import useGridState from '../../functions/useGridState';

interface ActivityProps {
    clientId?: number | null | undefined;
    activities: Activity[];
    onDelete: (id: number) => void;
    handleEdit: (activity: Activity) => void;
    handleNewContactActivity: (activity: Activity) => void;
    handleCloseActivity: (activity: Activity) => void;
    refreshActivities: (type: number) => void;
}

const ActivityTable: React.FC<ActivityProps> = ({ clientId, activities, onDelete, handleEdit, handleNewContactActivity, handleCloseActivity, refreshActivities }) => {
    const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null);
    const [showModal, setShowModal] = useState(false);
    const { saveState, restoreState } = useGridState('activities-grid');
    const { user } = useUser();

    const pagination = true;
    const paginationPageSize = 100;
    const paginationPageSizeSelector = [20, 50, 100, 200, 1000];
    const gridOptions = { 
        rowHeight: 35,
        defaultColDef: {
            enableRowGroup: true,
        },
    };

    const RefreshToolPanel = (props : any) => {
        return (
          <div className="p-4 mx-auto w-full flex flex-col justify-center items-center">
            <div className="py-2">
                <Button size="sm" onClick={() => {refreshActivities(1)}}>
                    <IoMdRefresh className="w-5 h-5" />
                    Toate datele
                </Button>
            </div>
            <div className="py-2">
                <Button size="sm" onClick={() => {refreshActivities(0)}}>
                    <IoMdRefresh className="w-5 h-5" />
                    Datele mele
                </Button>
            </div>
        </div>
        );
    };

    const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
        return {
            toolPanels: [
                "columns",
                "filters",
                {
                    id: 'refreshPanel',
                    labelDefault: 'Refresh',
                    labelKey: 'refresh',
                    iconKey: 'refresh',
                    toolPanel: RefreshToolPanel,
                },
            ],
            // hiddenByDefault: true,
            defaultToolPanel: "filters",
        };
    }, []);

    const onGridReady = useCallback((event: GridReadyEvent) => {
        const api = event.api;
        event.api.closeToolPanel();
        
        // Restaurăm starea salvată
        const savedState = localStorage.getItem(`agGrid-activities-grid`);
        if (savedState) {
          // Dacă există state salvat, îl restaurăm
          restoreState(api);
        } else {
            // Configurarea implicita a gridului
            event.api.setFilterModel(null);
            event.api.setColumnFilterModel('status', { values: ['Deschis'] });
            event.api.onFilterChanged();
        
            const columnState: ColumnState[] = [
                { colId: 'scheduledDate', sort: 'asc', sortIndex: 0 },
                { colId: 'id', sort: 'asc', sortIndex: 1 }
            ];
            event.api.applyColumnState({ state: columnState, defaultState: { sort: null } });
        }
        // Adăugăm event listeners pentru salvarea stării
        const saveCurrentState = () => saveState(api);
        
        api.addEventListener('filterChanged', saveCurrentState);
        api.addEventListener('sortChanged', saveCurrentState);
        api.addEventListener('columnMoved', saveCurrentState);
        api.addEventListener('columnResized', saveCurrentState);
        
        // Optional: Cleanup function
        return () => {
          api.removeEventListener('filterChanged', saveCurrentState);
          api.removeEventListener('sortChanged', saveCurrentState);
          api.removeEventListener('columnMoved', saveCurrentState);
          api.removeEventListener('columnResized', saveCurrentState);
        };
    }, [user, saveState, restoreState]);

    const onEdit = (activity: Activity) => {
        handleEdit(activity);
    }

    const onRowDoubleClicked = useCallback((event: RowDoubleClickedEvent) => {
        setSelectedActivity(event.data);
        setShowModal(true);
    }, []);

    const columns: ColDef<Activity>[] = [
        { 
            headerName: 'Id', 
            field: 'id', 
            sortable: true, 
            filter: 'agNumberColumnFilter', 
            floatingFilter: true,
            minWidth: 80, 
            maxWidth: 80,
            hide: true,
            sort: 'desc',
            sortingOrder: ['desc', 'asc']
        },
        { headerName: 'Client', field: 'client.company', sortable: true, minWidth: 200, filter: 'agTextColumnFilter', floatingFilter: true },
        { headerName: 'Utilizator', field: 'user.name', sortable: true, minWidth: 200, filter: 'agSetColumnFilter', floatingFilter: true,
            enableRowGroup: true
         },
        {
            headerName: 'Tip',
            field: 'scheduledContact',
            sortable: true,
            filter: 'agSetColumnFilter', 
            floatingFilter: true,
            minWidth: 90, 
            maxWidth: 90, 
            cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            cellRenderer: (params: any) => (
                <Badge color={params.value === "Email" ? "purple" : params.value === "Telefon" ? "success" : "pink"}>
                    {params.value}
                </Badge>
            ),
            enableRowGroup: true
        },
        { 
            headerName: 'Contact', 
            field: 'contact.name',
            minWidth: 200,
            sortable: true,             
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            headerName: 'Stare',
            field: 'status',
            sortable: true,
            filter: 'agSetColumnFilter',
            floatingFilter: true,
            minWidth: 100, 
            maxWidth: 100, 
            cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            cellRenderer: (params: any) => (
                <Badge color={params.value === "Deschis" ? "success" : "failure"}>
                    {params.value}
                </Badge>
            ),
            enableRowGroup: true
        },
        {
            headerName: 'Prioritate',
            field: 'priority',
            sortable: true,
            filter: 'agSetColumnFilter', 
            floatingFilter: true,
            minWidth: 125, 
            maxWidth: 125, 
            cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            cellRenderer: (params: any) => (
                <Badge color={params.value === "Scăzut" ? "success" : params.value === "Mediu" ? "warning" : "failure"}>
                    {params.value}
                </Badge>
            ),
            enableRowGroup: true
        },
        {
            headerName: 'Dată',
            field: 'scheduledDate',
            sort: 'asc',
            sortable: true,
            filter: 'agDateColumnFilter',
            floatingFilter: true,
            minWidth: 150, 
            maxWidth: 150, 
            cellRenderer: (params : any) => params.value ? (<span className={`${getDateColorClass(new Date(params.value), params.data.status)}`}>{formatDate(new Date(params.value))}</span>) : '',
            comparator: (valueA, valueB) => {
                const dateA = new Date(valueA).getTime();
                const dateB = new Date(valueB).getTime();
                return dateA - dateB;
            },
            enableRowGroup: true
        },
        {
            headerName: 'Motiv încheiere',
            field: 'description',
            sortable: true,
            filter: 'agTextColumnFilter',
            hide: true,
            floatingFilter: true,
            minWidth: 200,
            maxWidth: 200,
            valueFormatter: (params) => params.value ? (params.value.length > 50 ? params.value.substring(0, 50) + '...' : params.value) : '-'
        },
        {
            headerName: 'Descriere',
            field: 'description',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            minWidth: 230,
            valueFormatter: (params) => params.value ? (params.value.length > 50 ? params.value.substring(0, 50) + '...' : params.value) : '-'
        },
        {
            headerName: 'Acțiuni',
            field: 'id',
            minWidth: 140, 
            maxWidth: 140, 
            cellRenderer: (params: any) => (
                <div>
                    <Actions 
                        id={params.data.id} 
                        onEdit={() => onEdit(params.data)} 
                        onDelete={() => onDelete(params.data.id)} 
                        moduleName="activitate"
                        deletePermissions={['ACTIVITY_DELETE']}
                        editPermissions={['ACTIVITY_UPDATE']}
                    >
                        { params.data.status=="Deschis" ? 
                        (
                            <>
                                <CheckPermission permissions={['ACTIVITY_CLOSE', 'ACTIVITY_CREATE']}>
                                    <Tooltip content="Rezolvă cu un nou contact">
                                        <button 
                                            onClick={() => handleNewContactActivity(params.data)}
                                            className="text-green-600 hover:text-green-800 h-[20px] mt-1 flex items-center mx-1"
                                            title="Rezolvă cu un nou contact">
                                            <AiOutlineUserAdd className="w-[17px] h-[17px] hover:scale-110 duration-75"/>
                                        </button>
                                    </Tooltip>
                                </CheckPermission>
                                <CheckPermission permissions={['ACTIVITY_CLOSE']}>
                                    <Tooltip content="Închide activitatea">
                                        <button
                                            onClick={() => handleCloseActivity(params.data)}
                                            className="text-red-600 hover:text-red-800 h-[20px] mt-1 flex items-center mx-1"
                                            title="Închide activitatea">
                                            <IoClose className="w-[17px] h-[17px] hover:scale-110 duration-75"/>
                                        </button>
                                    </Tooltip>
                                </CheckPermission>
                            </>
                        ) : `` }
                    </Actions>
                </div>
            )
        }
    ];

    return (
        user ? <>
            <AgGridReact
                localeText={AG_GRID_LOCALE_RO}
                columnDefs={columns}
                rowData={activities}
                defaultColDef={{ flex: 1, minWidth: 20 }}
                domLayout='autoHeight'
                pagination={pagination}
                paginationPageSize={paginationPageSize}
                paginationPageSizeSelector={paginationPageSizeSelector}
                gridOptions={gridOptions}
                rowGroupPanelShow="always"
                onGridReady={onGridReady}
                enableRangeSelection={true}
                suppressMultiRangeSelection={true}
                onRowDoubleClicked={onRowDoubleClicked}
                sideBar={sideBar}
                groupDisplayType="groupRows"
            />
            <ViewActivityModal 
                activity={selectedActivity}
                show={showModal}
                onClose={() => setShowModal(false)}
            />
        </> : <></>
    );
};

export default ActivityTable;
