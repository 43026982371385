import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Modal, Button, Label, FileInput } from 'flowbite-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CreatableSelect from 'react-select/creatable';
import { defaultEmail, Email } from '../interfaces/Email';
import { handleInputChange, handleMultipleCreatableChange } from '../functions/selectHelper';
import Input from './Input';
import RichTextEditorSnow from './RichTextEditorSnow';
import DragDropFileUpload from './DragDropFileUpload';

interface EmailModalProps {
  clientId: number;
  templateSlug: string;
  setShowModal: (open: boolean) => void;
  showModal: boolean;
}

interface EmailOption {
  value: string;
  label: string;
}

const EmailModal: React.FC<EmailModalProps> = ({
  clientId,
  templateSlug,
  setShowModal,
  showModal
}) => {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [email, setEmail] = useState<Email>(defaultEmail);
  const [emailOptions, setEmailOptions] = useState<EmailOption[]>([{label: 'serban2702@gmail.com', value: 'serban2702@gmail.com'}, {label: 'dan@gmail.com', value: 'dan@gmail.com'}]);
  const [selectedEmails, setSelectedEmails] = useState<EmailOption[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);

  // Ia sabonul de fiecare data cand se modifica deschide modalul showModal == true

  useEffect(() => {
    if(showModal){
   
    }
  }, [showModal]);

//   useEffect(() => {
//     const options: EmailOption[] = [
//       { value: client.email, label: client.email },
//       ...client.contacts.map(contact => ({ value: contact.email, label: contact.email }))
//     ];
//     setEmailOptions(options);
//   }, [client]);

  const handleEmailChange = (newValue: any) => {
    setSelectedEmails(newValue);
  };

  const handleFileUpload = (files: File) => {
    // setAttachments(prevAttachments => [...prevAttachments, ...files]);
  };

  const handleAttachmentChange = () => {
    console.log(attachments)
  };

  const handleSubmit = () => {
    // Here you would typically call a mutation to save the email data
    console.log({
      to: selectedEmails.map(email => email.value),
      subject,
      content,
      attachments,
    //   clientId: client.id
    });
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onClose={() => setShowModal(false)} size={`6xl`}>
      <Modal.Header>Trimite E-mail</Modal.Header>
      <Modal.Body className="mailModal">
        <div className="grid sm:grid-cols-2 gap-3">
          <div>
            <CreatableSelect 
                isMulti 
                value={email.to.map((option: string) => ({ value: option, label: option }))}
                onChange={(data) => { handleMultipleCreatableChange(email, setEmail, 'to', data) }}
                options={emailOptions} 
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Către"
            />
          </div>
          <div>
            <Input 
                id="subject" 
                label="Subiect"
                value={email.subject}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(email, setEmail, 'subject', e.target.value, defaultEmail)}
            />
          </div>
          <div className="col-span-2">
            <RichTextEditorSnow
                placeholder='test'
                initialValue='test'
                // key={editorKey}
                // initialValue={editorContent}
                onChange={() => {}}
                // onFocus={() => { setEditorFocus(true) }}
                // onBlur={() => { setEditorFocus(false) }}
            />
          </div>
          <div className="col-span-2">
            <DragDropFileUpload onFileUpload={handleFileUpload} />
          </div>
          <div className="col-span-2">
            {/* <button onClick={handleAttachmentChange}>ssss</button> */}
              <div>
                <h3 className="mb-4 text-lg font-semibold text-dark dark:text-white">
                  Fișiere încărcate:
                </h3>
                <div className="space-y-3">
                  <div className="flex items-center justify-between overflow-hidden rounded-lg border border-stroke p-3 pr-5 dark:border-dark-3">
                    <div className="flex items-center gap-3">
                      <div className="flex h-[52px] w-[50px] items-center justify-center rounded-md bg-primary text-white">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.5624 0.674988H5.4374C4.1624 0.674988 3.1499 1.68749 3.1499 2.96249V15C3.1499 15.5625 3.3749 16.0875 3.7874 16.4625L10.5374 22.8375C10.9124 23.2125 11.3999 23.4 11.9249 23.4H18.5249C19.7999 23.4 20.8124 22.3875 20.8124 21.1125V2.96249C20.8499 1.68749 19.7999 0.674988 18.5624 0.674988ZM6.0749 16.275H10.4624C10.6499 16.275 10.7999 16.425 10.7999 16.575V20.7L6.0749 16.275ZM19.1624 21.0375C19.1624 21.375 18.8999 21.6375 18.5624 21.6375H12.4874V16.575C12.4874 15.4875 11.5874 14.5875 10.4624 14.5875H4.8374V2.96249C4.8374 2.62499 5.0999 2.36249 5.4374 2.36249H18.5249C18.8624 2.36249 19.1249 2.62499 19.1249 2.96249V21.0375H19.1624Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div>
                        <p className="text-base text-dark dark:text-white">
                          randomfile.pdf
                        </p>
                        <p className="text-base text-body-color dark:text-dark-6">
                          20.5 KB
                        </p>
                      </div>
                    </div>
                    <div>
                      <button className="flex items-center justify-center gap-2 rounded-lg border border-stroke px-3 py-2 text-sm font-medium text-dark-4 duration-200 hover:bg-dark hover:text-red-600 hover:border-red-600 dark:border-dark-3 dark:text-dark-6">
                        Șterge fișier
                        <span>
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_2330_10311)">
                              <path
                                d="M6.5998 5.99999L11.1748 1.42499C11.3436 1.25624 11.3436 0.993738 11.1748 0.824988C11.0061 0.656238 10.7436 0.656238 10.5748 0.824988L5.9998 5.39999L1.4248 0.824988C1.25605 0.656238 0.993555 0.656238 0.824805 0.824988C0.656055 0.993738 0.656055 1.25624 0.824805 1.42499L5.3998 5.99999L0.824805 10.575C0.656055 10.7437 0.656055 11.0062 0.824805 11.175C0.899805 11.25 1.0123 11.3062 1.1248 11.3062C1.2373 11.3062 1.3498 11.2687 1.4248 11.175L5.9998 6.59999L10.5748 11.175C10.6498 11.25 10.7623 11.3062 10.8748 11.3062C10.9873 11.3062 11.0998 11.2687 11.1748 11.175C11.3436 11.0062 11.3436 10.7437 11.1748 10.575L6.5998 5.99999Z"
                                fill="currentColor"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2330_10311">
                                <rect width="12" height="12" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="relative flex items-center justify-between overflow-hidden rounded-lg border border-stroke p-3 pr-5 dark:border-dark-3">
                    <div className="flex items-center gap-3">
                      <div className="flex h-[52px] w-[50px] items-center justify-center rounded-md bg-primary text-white">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.5624 0.674988H5.4374C4.1624 0.674988 3.1499 1.68749 3.1499 2.96249V15C3.1499 15.5625 3.3749 16.0875 3.7874 16.4625L10.5374 22.8375C10.9124 23.2125 11.3999 23.4 11.9249 23.4H18.5249C19.7999 23.4 20.8124 22.3875 20.8124 21.1125V2.96249C20.8499 1.68749 19.7999 0.674988 18.5624 0.674988ZM6.0749 16.275H10.4624C10.6499 16.275 10.7999 16.425 10.7999 16.575V20.7L6.0749 16.275ZM19.1624 21.0375C19.1624 21.375 18.8999 21.6375 18.5624 21.6375H12.4874V16.575C12.4874 15.4875 11.5874 14.5875 10.4624 14.5875H4.8374V2.96249C4.8374 2.62499 5.0999 2.36249 5.4374 2.36249H18.5249C18.8624 2.36249 19.1249 2.62499 19.1249 2.96249V21.0375H19.1624Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div>
                        <p className="text-base text-dark dark:text-white">
                          screenshort.jpg
                        </p>
                        <p className="text-base text-body-color dark:text-dark-6">
                          uploading...
                        </p>
                      </div>
                    </div>
                    <div>
                    <button className="flex items-center justify-center gap-2 rounded-lg border border-stroke px-3 py-2 text-sm font-medium text-dark-4 duration-200 hover:bg-dark hover:text-red-600 hover:border-red-600 dark:border-dark-3 dark:text-dark-6">
                        Șterge fișier
                        <span>
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_2330_10311)">
                              <path
                                d="M6.5998 5.99999L11.1748 1.42499C11.3436 1.25624 11.3436 0.993738 11.1748 0.824988C11.0061 0.656238 10.7436 0.656238 10.5748 0.824988L5.9998 5.39999L1.4248 0.824988C1.25605 0.656238 0.993555 0.656238 0.824805 0.824988C0.656055 0.993738 0.656055 1.25624 0.824805 1.42499L5.3998 5.99999L0.824805 10.575C0.656055 10.7437 0.656055 11.0062 0.824805 11.175C0.899805 11.25 1.0123 11.3062 1.1248 11.3062C1.2373 11.3062 1.3498 11.2687 1.4248 11.175L5.9998 6.59999L10.5748 11.175C10.6498 11.25 10.7623 11.3062 10.8748 11.3062C10.9873 11.3062 11.0998 11.2687 11.1748 11.175C11.3436 11.0062 11.3436 10.7437 11.1748 10.575L6.5998 5.99999Z"
                                fill="currentColor"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2330_10311">
                                <rect width="12" height="12" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div className="absolute bottom-0 left-0 h-1 w-1/3 bg-primary"></div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit}>Trimite</Button>
        <Button color="gray" onClick={() => setShowModal(false)}>
          Anulează
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailModal;