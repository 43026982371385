import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import renderSkeleton from '../../../components/SkeletonLoader';
import { Button, Tooltip } from 'flowbite-react';
import { defaultManifest, Manifest } from '../../../interfaces/Booking';
import { ManifestService } from '../../../services/ManifestService';
import TranslateTable from './TranslateTable';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import Loader from '../../../components/CustomLoader';
import ModalDetails from './ModalDetails';

const View: React.FC = () => {
    const { id } = useParams<{ id?: string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
    const [manifest, setManifest] = useState<Partial<Manifest>>(defaultManifest);
    const manifestService = new ManifestService();

    useEffect(() => {
        getManifest();
    }, [id]);
    
    const getManifest = () => {
        manifestService.getManifest(parseInt(id!)).then((data) => {setManifest(data)}).finally(() => {setLoading(false)})
    };

    const translateManifest = useCallback(() => {
        setLoading(true);
        manifestService.translateManifest(parseInt(id!)).then((resp : {status: string; message: string;}) => { 
            if(resp.status == 'success'){ successMessage(resp.message); }
            if(resp.status == 'error'){ errorMessage(resp.message); }
            setLoading(false);
            getManifest();
        });
    }, [id])

    const shortManifest = useCallback(() => {
        setLoading(true);
        manifestService.shortManifest(parseInt(id!)).then((resp : {status: string; message: string;}) => { 
            if(resp.status == 'success'){ successMessage(resp.message); }
            if(resp.status == 'error'){ errorMessage(resp.message); }
            setLoading(false);
            getManifest();
        });
    }, [id])

    const generateTranslatedPdf = useCallback(() => {
        setLoading(true);
        manifestService.generateTranslatedPdf(parseInt(id!)).then((resp : {status: string; message: string;}) => { 
            if(resp.status == 'success'){ successMessage(resp.message); }
            if(resp.status == 'error'){ errorMessage(resp.message); }
            setLoading(false);
            getManifest();
        });
    }, [id])

    const generateExcel = useCallback(() => {
        setLoading(true);
        manifestService.generateExcel(parseInt(id!)).then((resp : {status: string; message: string;}) => { 
            if(resp.status == 'success'){ 
                successMessage(resp.message); 
                window.open(`${process.env.REACT_APP_BACKEND_URI}/file/${resp.message}`, '_blank');
            }
            if(resp.status == 'error'){ errorMessage(resp.message); }
            setLoading(false);
        });
    }, [id])

    const importData = useCallback(() => {
        setLoading(true);
        manifestService.importManifestData(parseInt(id!)).then((resp : {status: string; message: string;}) => { 
            if(resp.status == 'success'){ successMessage(resp.message); }
            if(resp.status == 'error'){ errorMessage(resp.message); }
            setLoading(false);
            getManifest();
        });
    }, [id])

    return (
        <div className="ag-theme-quartz px-4">
            <div className="flex justify-between items-center py-2">
                <div className="text-lg font-bold">
                    Manifest
                </div>
                <div className="flex gap-2">
                    <Tooltip content="Detalii manifest">
                        <Button size="sm" onClick={() => {setShowDetailsModal(true)}}>Detalii manifest</Button>
                    </Tooltip>
                    <Tooltip content="Manifest inițial">
                        <Link to={`${process.env.REACT_APP_BACKEND_URI}/file/${manifest.filepath}`} target="_blank" rel="noopener noreferrer">
                            <Button size="sm" onClick={() => {}}>Manifest inițial</Button>
                        </Link>
                    </Tooltip>
                    <Tooltip content="Tradu manifest">
                        <Button size="sm" onClick={translateManifest}>Tradu manifest</Button>
                    </Tooltip>
                    <Tooltip content="Tradu manifest">
                        <Button size="sm" onClick={shortManifest}>Scurtează marfă</Button>
                    </Tooltip>
                    <Tooltip content="Generează PDF tradus">
                        <Button size="sm" onClick={generateTranslatedPdf}>Generează PDF</Button>
                    </Tooltip>
                    {
                        manifest.translatedFilepath ? (
                            <>
                                <Tooltip content="Descarcă Excel">
                                    <Button size="sm" onClick={generateExcel}>Excel</Button>
                                </Tooltip>
                                <Tooltip content="Descarcă PDF">
                                    <Link to={`${process.env.REACT_APP_BACKEND_URI}/file/${manifest.translatedFilepath}`} target="_blank" rel="noopener noreferrer">
                                        <Button size="sm" onClick={() => {}}>PDF</Button>
                                    </Link>
                                </Tooltip>
                                <Tooltip content="Importă în program">
                                    <Button size="sm" onClick={importData}>Importă date</Button>
                                </Tooltip>
                            </>
                        ) : ``
                    }
                </div>
            </div>
            <Loader isLoading={loading} />
            <TranslateTable manifest={manifest} setManifest={setManifest} />
            <ModalDetails manifest={manifest ? manifest : null} showModal={showDetailsModal} setShowModal={setShowDetailsModal} />
        </div>
    );
};

export default View;