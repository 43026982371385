import React, { useState, useEffect, useCallback } from 'react';
import { Manifest } from '../../../interfaces/Booking';
import { ManifestService } from '../../../services/ManifestService';
import { Button, Tooltip } from 'flowbite-react';
import renderSkeleton from '../../../components/SkeletonLoader';
import Table from './Table';
import ModalCreate from './ModalCreate';

const Index: React.FC = () => {
    const [manifests, setManifests] = useState<Partial<Manifest>[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [id, setId] = useState<number|null>(null);
    const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
    const manifestService = new ManifestService();

    useEffect(() => {
        fetchManifests();
    }, []);
    
      const fetchManifests = useCallback(
        async () => {
            setLoading(true);
            manifestService.getManifests().then((data) => {setManifests(data)}).finally(() => {setLoading(false)});
    },[])

    return (
        <div className="ag-theme-quartz px-4">
            <div className="flex justify-between items-center py-2">
                <div className="text-lg font-bold">
                    Manifeste
                </div>
                <div className="flex gap-2">
                    <Tooltip content="Adaugă manifest">
                        <Button size="sm" onClick={() => { setShowModalCreate(true); }}>Adaugă manifest</Button>
                    </Tooltip>
                </div>
            </div>
            {loading ? ( renderSkeleton() ) : (
                <>
                    <Table manifests={manifests} />
                </>
            )}
            <ModalCreate showModal={showModalCreate} setShowModal={() => {setShowModalCreate(false)}} id={id} onSave={() => {fetchManifests()}} />
        </div>
    );
};

export default Index;