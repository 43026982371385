import React from 'react';

const Invoices: React.FC = () => {
  return (
    <div>
      <h3>Facturi</h3>
      <p>Momentan sectiunea este indisponibila.</p>
    </div>
  );
};

export default Invoices;