import React, { lazy, useCallback } from 'react';
import { SectionSuggestion, Offer, OfferDetail } from '../../../interfaces/Offer';

interface DetaliiExportSectionProps {
    offer: Offer;
    setOffer: React.Dispatch<React.SetStateAction<Offer>>;
    allOfferDetails: OfferDetail[];
}

const DetaliiExportSection: React.FC<DetaliiExportSectionProps> = ({ offer, setOffer, allOfferDetails }) => {

    const handleOfferDetailToggle = useCallback((detail: OfferDetail) => {
        setOffer(prev => {
            const isDetailIncluded = prev.offerDetails.some(d => d.id === detail.id);
            if (isDetailIncluded) {
                return {
                ...prev,
                offerDetails: prev.offerDetails.filter(d => d.id !== detail.id)
                };
            } else {
                if (!prev.offerDetails.some(d => d.id === detail.id)) {
                    return {
                        ...prev,
                        offerDetails: [...prev.offerDetails, detail]
                    };
                }
                return prev;
            }
        });
    }, []);

    return (
        <>
            <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
                <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Secțiuni ofertă</h4>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
                    {allOfferDetails.map((detail) => (
                        detail.category === 'cantar_export' ? (
                            <div key={detail.id} className={`cursor-pointer flex items-center space-x-3 py-1 border px-2 border-gray-200 rounded-lg hover:bg-gray-50 hover:border-primary ${offer.offerDetails.some(d => d.id === detail.id) ? 'border-primary' : ''}`} onClick={() => handleOfferDetailToggle(detail)}>
                                <input
                                    type="checkbox"
                                    id={`detail-${detail.id}`}
                                    checked={offer.offerDetails.some(d => d.id === detail.id)}
                                    onChange={() => {}}
                                    className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary dark:focus:ring-primary dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                />
                                <label className="flex-grow cursor-pointer">
                                    <span className="text-sm font-medium text-gray-900 offerDetailElement" dangerouslySetInnerHTML={{__html: detail.name}}></span>
                                </label>
                            </div>
                        ) : ""
                    ))}                            
                    {allOfferDetails.map((detail) => (
                        detail.category === 'penalitati_export' ? (
                            <div key={detail.id} className={`cursor-pointer flex items-center space-x-3 py-1 border px-2 border-gray-200 rounded-lg hover:bg-gray-50 hover:border-primary ${offer.offerDetails.some(d => d.id === detail.id) ? 'border-primary' : ''}`} onClick={() => handleOfferDetailToggle(detail)}>
                                <input
                                    type="checkbox"
                                    id={`detail-${detail.id}`}
                                    checked={offer.offerDetails.some(d => d.id === detail.id)}
                                    onChange={() => {}}
                                    className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary dark:focus:ring-primary dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                />
                                <label className="flex-grow cursor-pointer">
                                    <span className="text-sm font-medium text-gray-900 offerDetailElement" dangerouslySetInnerHTML={{__html: detail.name}}></span>
                                </label>
                            </div>
                        ) : ""
                    ))}                            
                </div>  
            </div>
            <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
                <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Condiții Suplimentare</h4>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-3">
                    {allOfferDetails.map((detail) => (
                        detail.category === 'conditii_suplimentare_export' ? (
                            <div key={detail.id} className={`cursor-pointer flex items-center space-x-3 py-1 border px-2 border-gray-200 rounded-lg hover:bg-gray-50 hover:border-primary ${offer.offerDetails.some(d => d.id === detail.id) ? 'border-primary' : ''}`} onClick={() => handleOfferDetailToggle(detail)}>
                                <input
                                    type="checkbox"
                                    id={`detail-${detail.id}`}
                                    checked={offer.offerDetails.some(d => d.id === detail.id)}
                                    onChange={() => {}}
                                    className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary dark:focus:ring-primary dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                />
                                <label className="flex-grow cursor-pointer">
                                    <span className="text-sm font-medium text-gray-900 offerDetailElement" dangerouslySetInnerHTML={{__html: detail.name}}></span>
                                </label>
                            </div>
                        ) : ""
                    ))}
                </div>  
            </div>
            <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
                <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Note Importante</h4>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-3">
                    {allOfferDetails.map((detail) => (
                        detail.category === 'note_importante_export' ? (
                            <div key={detail.id} className={`cursor-pointer flex items-center space-x-3 py-1 border px-2 border-gray-200 rounded-lg hover:bg-gray-50 hover:border-primary ${offer.offerDetails.some(d => d.id === detail.id) ? 'border-primary' : ''}`} onClick={() => handleOfferDetailToggle(detail)}>
                                <input
                                    type="checkbox"
                                    id={`detail-${detail.id}`}
                                    checked={offer.offerDetails.some(d => d.id === detail.id)}
                                    onChange={() => {}}
                                    className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary dark:focus:ring-primary dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                />
                                <label className="flex-grow cursor-pointer">
                                    <span className="text-sm font-medium text-gray-900 offerDetailElement" dangerouslySetInnerHTML={{__html: detail.name}}></span>
                                </label>
                            </div>
                        ) : ""
                    ))}
                </div>  
            </div>
        </>
    );
};
export default DetaliiExportSection;