export interface RecommendationCategory {
    categorySlug: string;
    categoryName: string;
};

export interface Recommendation {
    id: number | null;
    categorySlug: string;
    categoryName: string;
    label: string;
    value: string;
    type: string;
}

export interface RecommendationResp {
    categorySlug: string;
    label: string;
    value: string;
    type: string;
}

export interface LabelValueString {
    label: string;
    value: string;
}

export interface LabelValueInt {
    label: string;
    value: number;
}

export interface LabelValueDate {
    label: string;
    value: Date;
}

export const defaultRecommendation : Recommendation = {
    id: null, 
    categorySlug: '',
    categoryName: '',
    label: '',
    value: '',
    type: 'string'
}

export const typeOptions : {value: string, label: string}[] = [
    {
        value: 'string',
        label: 'Text'
    },
    {
        label: 'Număr',
        value: 'number'
    },
    {
        label: 'Dată',
        value: 'data'
    },
];

export const replaceType = (type : string) : string => {
    if(type==='string'){
        return 'Text';
    }
    if(type==='number'){
        return 'Număr';
    }
    if(type==='data'){
        return 'Dată';
    }
    return '';
}