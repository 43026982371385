import React, { useState, useEffect } from 'react';
import { Client, defaultClient } from '../../interfaces/Client';
import { ClientService } from '../../services/ClientService';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Button, Tabs } from "flowbite-react";
import { HiClipboardList, HiUserCircle } from "react-icons/hi";
import { MdDashboard } from "react-icons/md";
import General from './formTabs/General';
import Comercial from './formTabs/Comercial';
import Contacts from './formTabs/Contacts';
import Financials from './formTabs/Financials';
import { errorMessage, successMessage } from '../../functions/generalHelper';
import { RecommendationResp } from '../../interfaces/Recommandation';
import { RecommandationService } from '../../services/RecommandationService';

const Form: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [client, setClient] = useState<Client>(defaultClient);
  const [recommendations, setRecommendations] = useState<RecommendationResp[]>([]);
  const clientService = new ClientService();
  const recommendationsService = new RecommandationService();
  const navigate = useNavigate();

  // GET AND SET CLIENT IF ID PARAM IS SEND
  useEffect(() => {
    if (id) {
      clientService.getClientById(parseInt(id)).then((data) => setClient(data));
    }
  }, [id]);

  // GET ALL OPTION VALUES AND FIELD RECOMMENDATIONS
  useEffect(() => {
    recommendationsService.getRecommendations().then(data => setRecommendations(data));
  }, []);

  // HANDLTE THE FORM SUBMIT BACKEND
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (id) {
      try {
        await clientService.updateClient(client);
        successMessage('Client editat cu succes!');
        navigate('/crm/clients/index');
      } catch (error) {
        errorMessage('Vă rugăm să completați toate câmpurile obligatorii(denumire)!');
      }
    } else {
      try {
        await clientService.createClient(client);
        successMessage('Client adăugat cu succes!');
        navigate('/crm/clients/index');
      } catch (error) {
        errorMessage('Vă rugăm să completați toate câmpurile obligatorii(denumire)!');
      }
    }
  };

  return (
    <div className='px-3'>
      <form onSubmit={handleSubmit}>
        <Tabs aria-label="Tabs with icons" className="pt-2">
          <Tabs.Item active title="Date generale" icon={HiUserCircle}>
            <General client={client} setClient={setClient} recommendations={recommendations} id={id} />
          </Tabs.Item>
          <Tabs.Item title="Comercial" icon={MdDashboard}>
            <Comercial client={client} setClient={setClient} recommendations={recommendations} />
          </Tabs.Item>
          <Tabs.Item title="Contacte" icon={HiClipboardList}>
            <Contacts client={client} setClient={setClient} />
          </Tabs.Item>
          <Tabs.Item title="Financiar" icon={HiClipboardList}>
            <Financials client={client} setClient={setClient} />
          </Tabs.Item>
        </Tabs>
        
        <div className="flex justify-end space-x-4 mt-3">
            <NavLink to="/crm/clients/index">
              <Button size="sm" color="gray">Înapoi</Button>
            </NavLink>
            <Button size="sm" type="submit">Salvează</Button>
        </div>
      </form>
    </div>
  );
};

export default Form;
