import { Currency } from "./Currency";

export interface Financial {
    id: number | null;
    clientId: number | null;
    bankName: string | null;
    iban: string | null;
    currencyId: number | null;
    branch: string | null;
    bankAddress: string | null;
    currency: Currency | null;
};

export const defaultFinancial: Financial = {
    id: null,
    clientId: null,
    bankName: "",
    iban: "",
    currencyId: null,
    branch: "",
    bankAddress: "",
    currency: null,
};

