import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Index from './Index';
import Breadcrumb from '../../components/BreadCrumb';
import SettingsNavBar from '../../components/SettingsNavBar';
import RecommandationTable from './RecommandationTable';
import { useUser } from '../../components/UserContext';
import { hasPermission } from '../../interfaces/Permissions';

const Recommandations: React.FC = () => {
  const { user } = useUser();

  return (
    <>
      {
        hasPermission(user, 'SETTING_VIEW_ALL') ?
          <SettingsNavBar />
        : <></>
      } 
      <Breadcrumb />
      {
        hasPermission(user, 'SETTING_VIEW_ALL') ?
          <Routes>
            <Route path="index" element={<Index />} />
            <Route path="table/:slug" element={<RecommandationTable />} />
            <Route path="/table" element={<Navigate to="/settings/recommandations/index" replace />} />
            <Route path="/" element={<Navigate to="/settings/recommandations/index" replace />} />
          </Routes>
        : <></>
      }
    </>
  );
};

export default Recommandations;
