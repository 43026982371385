import axios from 'axios';
import { PermissionInRole, Permissions, Role, RoleOptions } from '../interfaces/Role';

const API_URL = process.env.REACT_APP_GRAPHQL_URI!;

export class RoleService {
  async getRoles(): Promise<Role[]> {
    const response = await axios.post(API_URL, {
      query: `
        query {
          roles {
            id
            name
          }
        }
      `,
    });
    return response.data.data.roles;
  }

  async getRolesForSelect(): Promise<RoleOptions[]> {
    const response = await axios.post(API_URL, {
      query: `
        query {
          roles {
            id
            name
          }
        }
      `,
    });
    return response.data.data.roles.map((role : Role) => ({value: role.id, label: role.name}));
  }

  async deleteRole(id: number): Promise<boolean> {
    const response = await axios.post(API_URL, {
      query: `
        mutation {
          deleteRole(id: ${id})
        }
      `,
    });
    return response.data.data.deleteRole;
  }


  async getRoleById(id: number): Promise<Role> {
    const response = await axios.post(API_URL, {
      query: `
        query {
          role(id: ${id}) {
            id
            name
            permissions{
              id
            }
          }
        }
      `,
    });
    return {...response.data.data.role, permissions: response.data.data.role.permissions.map((permission : PermissionInRole) => permission.id)};
  }

  async getPermissions(): Promise<Permissions[]> {
    const response = await axios.post(API_URL, {
      query: `
        query {
          permissions {
            id
            beautifulName
            name
            description
            category
          }
        }
      `,
    });
    return response.data.data.permissions;
  }

  async create(role: Role): Promise<Role> {
    const response = await axios.post(API_URL, {
      query: `
        mutation {
            createRole(input: {
                name: ${role.name ? `"${role.name}"` : null},
                permissionIds: [${role.permissions.map(permission => `${permission}`).join(', ')}]
            }) 
            {
                id
            }
        }
      `,
    });
    return response.data.data.createRole;
  }

  async update(role: Role): Promise<Role> {
    const response = await axios.post(API_URL, {
      query: `
        mutation {
            updateRole(input: {
                id: ${role.id},
                name: ${role.name ? `"${role.name}"` : null},
                permissionIds: [${role.permissions.map(permission => `${permission}`).join(', ')}]
            })
            {
                id
                name
                permissions{
                  id
                }
            }
        }
      `,
    });
    return {...response.data.data.updateRole, permissions: response.data.data.updateRole.permissions.map((permission : PermissionInRole) => permission.id)};
  }
}
