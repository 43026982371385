import React, { useState, useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import renderSkeleton from '../../../components/SkeletonLoader';
import { defaultOffer, Offer } from '../../../interfaces/Offer';
import { OfferService } from '../../../services/OfferService';
import ImportView from './ImportView';
import ExportView from './ExportView';

interface ComponentProps {
    id: number;
}

const View: React.FC<ComponentProps> = ({id}) => {
    const [offer, setOffer] = useState<(Offer)>(defaultOffer);
    const [loading, setLoading] = useState<boolean>(true);
    const [offerContent, setOfferContent] = useState<string>('');
    const offerService = new OfferService();

    useEffect(() => {
        // offerService.getOfferById(id).then((data) => {
        //     setOffer(data);
        // }).then(() => { setLoading(false) });
        offerService.getOfferHTML(id).then((data) => {
            setOfferContent(data);
        }).then(() => { setLoading(false) });
    }, [id]);

  return (
    <div className="pt-4">
        {/* {loading ? ( renderSkeleton() ) : (
            offer.type == 'Import' ? 
            <>
                <ImportView id={id} offer={offer} />
            </> : 
            <>
                <ExportView id={id} offer={offer} />
            </>
        )}  */}
        {loading ? ( renderSkeleton() ) : (
            <div dangerouslySetInnerHTML={{ __html: offerContent }} >
            </div>
        )} 
    </div>
  );
};

export default View;
