import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'flowbite-react';
import { defaultServiceLoop, VesselServiceLoop } from '../../../interfaces/Vessel';
import { VesselService } from '../../../services/VesselService';
import { handleInputChange } from '../../../functions/selectHelper';
import DynamicPortSelect from '../../../components/DynamicPortSelect';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import Input from '../../../components/Input';
import { ManifestService } from '../../../services/ManifestService';
import { defaultManifest, Manifest } from '../../../interfaces/Booking';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { getDateFormat } from '../../../functions/dateHelper';
import DragDropFileUpload from '../../../components/DragDropFileUpload';
import UploadedFiles from '../../../components/UploadedFiles';
import { FileService } from '../../../services/FileService';
import Loader from '../../../components/CustomLoader';

interface ModalCreateProps {
    id: number|null;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: () => void;
}

const ModalCreate: React.FC<ModalCreateProps> = ({ id, showModal, setShowModal, onSave }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [manifest, setManifest] = useState<Manifest>(defaultManifest);
    const [file, setFile] = useState<File | null>(null);
    const manifestService = new ManifestService();
    const fileService = new FileService();

    useEffect(() => {
        if (!showModal) {
            if(id){
                // manifestService.getManifest(id!).then((data) => { setManifest(data) });
                // setFile(null);
            }else{
                setManifest(defaultManifest);
                setFile(null);
            }
        }
    }, [showModal]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (id) {
                // const newServiceLoop = await vesselService.updateServiceLoop(serviceLoop);
                // onSave(newServiceLoop, 'update');
                // successMessage('Serviciul a fost modificat cu succes.');
            } else {
                if(file){
                    const fileResponse = await fileService.uploadFile(file);
                    if(fileResponse.status === 'success'){
                        const response = await manifestService.createManifest(manifest, fileResponse.filename);
                        if(response.status === 'success'){
                            successMessage(response.message);
                        }else{
                            errorMessage(response.message);
                        }
                        onSave();
                        setShowModal(false);
                        setLoading(false);
                    }else{
                        setLoading(false);
                        errorMessage('Din pacate am intampinat o eroare la incarcarea fisierului.');
                    }
                }else{
                    errorMessage('Fisierul este obligatoriu.')
                    setLoading(false);
                }
            }
        } catch (error) {
            errorMessage('An error occurred while saving the Manifest.');
            setLoading(false);

        }
    };

    const handleFileChange = (file : File) => {
        setFile(file);
    };

    return (
        <>
            <Loader isLoading={loading} />
            <Modal show={showModal} size="2xl" popup onClose={() => setShowModal(false)}>
                <Modal.Header>
                    {id ? 'Editează Manifest' : 'Adaugă Manifest'}
                </Modal.Header>
                <Modal.Body className="overflow-auto">
                    <div className="space-y-2 pt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="gap-4 grid grid-cols-1 mb-2 md:grid-cols-2">
                                <div className="mb-1">
                                    <Input 
                                        id="vesselName" 
                                        label="Nume navă"
                                        value={manifest.vesselName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(manifest, setManifest, 'vesselName', e.target.value, defaultManifest)}
                                    />
                                </div>
                                <div className="mb-1">
                                    <Input 
                                        id="vesselCode" 
                                        label="Cod voiaj"
                                        value={manifest.vesselCode}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(manifest, setManifest, 'vesselCode', e.target.value, defaultManifest)}
                                    />
                                </div>
                                <div className="mb-1">
                                    <Input 
                                        id="terminal" 
                                        label="Terminal"
                                        value={manifest.terminal}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(manifest, setManifest, 'terminal', e.target.value, defaultManifest)}
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomDatePicker
                                        selected={manifest.date}
                                        onChange={(date) => setManifest((prev) => ({ ...prev, date: date ? date : new Date() }))}
                                        dateFormat= {getDateFormat()}
                                        locale="ro"
                                        isClearable
                                        placeholderText="Dată"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div className="mb-1">
                                    <DynamicPortSelect
                                        placeholder="PORT PLECARE NAVĂ"
                                        value={manifest.pol ? manifest.pol : ''}
                                        onChange={(newValue) => setManifest(prev => ({...prev, pol: newValue.value}))}
                                    />
                                </div>
                                {
                                    !id ? (
                                        <div className="col-span-2 mb-2">
                                            <DragDropFileUpload onFileUpload={handleFileChange} />
                                            <UploadedFiles file={file} setFile={setFile} />
                                        </div>
                                    ) : <></>
                                }
                            </div>
                            <div className="flex justify-end">
                                <Button size="sm" type="submit">Salvează</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalCreate;