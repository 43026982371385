import React from 'react';
import { Button } from 'flowbite-react';
import { FaPlus } from 'react-icons/fa6';
import Input from "../../../components/Input";
import { defaultOfferElement, Offer } from '../../../interfaces/Offer';
import Tooltip from '../../../components/Tooltip';
import { MdDeleteOutline } from 'react-icons/md';

interface LocaleSectionProps {
    offer: Offer;
    setOffer: React.Dispatch<React.SetStateAction<Offer>>;
}

const LocaleSection: React.FC<LocaleSectionProps> = ({ offer, setOffer }) => {

    const handleShippingChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        rowIndex: number,
        field : string,
    ) => {
        setOffer(prevOffer => ({
            ...prevOffer,
            offerElements: prevOffer.offerElements.map((element, elementIndex) => {
                if (elementIndex === rowIndex) {
                    if(field == 'shippingFrom'){
                        return { ...element, shippingFrom: e.target.value };
                    }if(field == 'shippingTo'){
                        return { ...element, shippingTo: e.target.value };
                    }else{
                        return { ...element, name: e.target.value };
                    }
                }
                return element;
            })
        }));
    };

    const handleCurrentInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        rowIndex: number,
        colIndex: number
    ) => {
        const { name, value } = e.target;
        setOffer(prevOffer => ({
            ...prevOffer,
            offerElements: prevOffer.offerElements.map((element, elementIndex) => {
                if (elementIndex === rowIndex) {
                    const newPrices = [...element.prices];
                    newPrices[colIndex] = parseFloat(value);
                    return { ...element, prices: newPrices };
                }
                return element;
            })
        }));
    };

    const addRow = (type : string) => {
        setOffer((prevOffer) => ({
            ...prevOffer,
            offerElements: [...prevOffer.offerElements, defaultOfferElement(type, prevOffer.containers.length)]
        }));
    };

    const deleteRow = (rowIndex : number) => {
        setOffer((prevOffer) => ({
            ...prevOffer,
            offerElements: prevOffer.offerElements.filter((_, i) => i !== rowIndex)
        }));
    };

    return (
        <div className="rounded-lg border border-gray-200 bg-white md:px-5 py-5 relative my-2 md:my-6 px-1">
            <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Locale</h4>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded-lg border-collapse md:table block">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 font-bold md:table-header-group hidden">
                    <tr>
                        <th scope="col" className="w-auto md:border border-gray-300 text-center py-2 font-bold">
                            Denumire
                        </th>
                        {offer.containers.map((name, colIndex) => (
                            <th key={`containerLocale${colIndex}`} scope="col" className="w-auto md:border border-gray-300 text-center py-2 font-bold">{name}</th>
                        ))}
                        <th className="w-auto md:border border-gray-300 text-center py-2 font-bold min-w-[55px]">Șterge</th>
                    </tr>
                </thead>
                <tbody className="md:w-full block md:table-row-group">
                    {offer.offerElements.map((element, rowIndex) => (
                        element.type === 'locale' ? (
                            <tr key={`rowLocale${rowIndex}`} className="bg-white md:border border-0 border-b md:border-t border-gray-300 dark:bg-gray-800 dark:border-gray-700 md:table-row flex flex-col mb-4">
                                <td className="p-1 md:p-2 md:table-cell block">
                                    <Input
                                        id={`name-${rowIndex}`}
                                        label="Denumire"
                                        name="name"
                                        value={element.name}
                                        onChange={(e) => handleShippingChange(e, rowIndex, 'name')}
                                    />
                                </td>
                                {element.prices.map((price, colIndex) => (
                                    <td key={`pretLocale${colIndex}`} className="border-gray-300 p-1 md:p-2 md:border border-0 md:table-cell block">
                                        <Input
                                            type="number"
                                            id={`price-${colIndex}-${rowIndex}`} 
                                            name={`price-${colIndex}`} 
                                            label={`Preț ${offer.containers[colIndex] ? ' - ' : ''}${offer.containers[colIndex] || ''}`}
                                            step="0.01"
                                            value={price}
                                            onChange={(e) => handleCurrentInputChange(e, rowIndex, colIndex)}
                                        />
                                    </td>
                                ))}
                                <td className="align-middle justify-center items-center text-center md:border border-0 border-gray-300 md:table-cell block">
                                    <Tooltip content={`Șterge serviciu`}>
                                        <button type="button" onClick={() => {deleteRow(rowIndex)}} className="text-red-500 hover:text-red-700 h-[20px] mt-1 flex items-center mx-1" aria-label={`Șterge tip serviciu`}>
                                            <MdDeleteOutline className='w-[20px] h-[20px] hover:scale-110 duration-75'/>
                                        </button>
                                    </Tooltip>
                                </td>
                            </tr>
                        ) : ''
                    ))}
                    <tr className="bg-white dark:bg-gray-800 dark:border-gray-700">
                        <td className="text-center align-middle flex justify-center items-center h-[50px]">
                            <Button onClick={() => {addRow('locale')}}>   
                                <FaPlus size={12}/>
                            </Button>
                        </td>
                        <td className={`col-span-${offer.containers.length + 1}`}></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
export default LocaleSection;