import React, { useState, useEffect } from 'react';
import { Modal, Button, TextInput } from 'flowbite-react';
import { errorMessage, isNullOrEmpty, successMessage } from '../../../functions/generalHelper';
import { BookingService } from '../../../services/BookingService';

interface SendEmailsModalProps {
    response: { errors: string[]; emails: { id: number; email: string | null; name: string | null; booking: string | null; }[]; };
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    onSave: () => void;
}

const SendEmailsModal: React.FC<SendEmailsModalProps> = ({ response, showModal, setShowModal, setLoading, title, onSave }) => {
    const [formData, setFormData] = useState<{ id: number; email: string | null; name: string | null; booking: string | null; }[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const bookingService = new BookingService();

    useEffect(() => {
        setFormData(response.emails);
        setErrors(response.errors);
    }, [response]);
    

    const handleSubmit = async (e: React.FormEvent) => {
        setLoading(true);
        e.preventDefault();
        try {
            if (formData.filter(item => isNullOrEmpty(item.email)).length > 0) {
                errorMessage('Toate adresele de email sunt obligatorii');
                return;
            }
            const resp : { status: string; message: string; } = await bookingService.sendArrivalNoticeEmail(formData);
            setShowModal(false);
            successMessage(resp.message);
            onSave();
            setFormData([]);
        } catch (error) {
            errorMessage('A aparut o eroare la adaugarea containerului!');
        }
        setLoading(false);
    };

    const changeEmail = (index: number, value: string) => {
        setFormData(prev => ([...prev.map((item, i) => {
                if(i == index){
                    return {...item, email: value}
                }
                return item;
            }
        )]));
    }

    return (
        <Modal show={showModal} size="3xl" popup onClose={() => setShowModal(false)}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Body className="overflow-visible">
                <div className="space-y-2">
                    <form onSubmit={handleSubmit}>
                        <div className="overflow-auto max-h-[700px]">
                            <div className="flex items-center py-2 mt-2 bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-700">
                                <div className="flex-1">Detalii B/L</div>
                                <div className="flex-1">Email</div>
                            </div>
                            <div className="max-h-[70vh] overflow-y-auto">
                                {formData.map((item, index) => (
                                    <div 
                                        key={item.booking}
                                        className={`flex items-center py-1.5 px-3 hover:bg-gray-50 ${index !== formData.length - 1 ? 'border-b border-gray-100' : ''}`}>
                                        <div className="flex-1 min-w-0 pr-2">
                                            <div className="text-xs font-medium text-gray-900 truncate">
                                            {item.booking}
                                            </div>
                                            <div className="text-xs text-gray-500 truncate">
                                            {item.name}
                                            </div>
                                        </div>

                                        <div className="flex-1">
                                            <TextInput 
                                                id="small" 
                                                type="text" 
                                                sizing="sm" 
                                                value={item.email ? item.email : ''}
                                                onChange={(e) => changeEmail(index, e.target.value)}
                                                placeholder="Adresa de email"
                                            />
                                        </div>
                                    </div>
                                ))}
                                {errors.map((error, index) => (
                                    <div key={index} className="text-red-600 text-center py-1 font-medium">
                                    {error}
                                  </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex justify-end mt-4">
                            <Button size="sm" type="submit">Salvează</Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SendEmailsModal;
