import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/client';
import { GET_USER_PROFILE } from '../pages/auth/graphql/queries';

interface User {
  id: number;
  name: string;
  email: string;
  permissions: string[];
}

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const client = useApolloClient();

  const { data: userData, refetch } = useQuery(GET_USER_PROFILE, {
    skip: !isAuthenticated,
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);

  useEffect(() => {
    if (isAuthenticated && userData?.me) {
      setUser(userData.me);
    }
  }, [isAuthenticated, userData]);

  const login = (token: string, remember: boolean) => {
    localStorage.setItem('token', token);
    if (remember) {
      localStorage.setItem('rememberMe', 'true');
    } else {
      localStorage.removeItem('rememberMe');
    }
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('rememberMe');
    setIsAuthenticated(false);
    // client.resetStore();
    navigate('/login');
  };

  return useMemo(() => ({
    isAuthenticated,
    user,
    login,
    logout
  }), [isAuthenticated, user]);
};
