import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'flowbite-react';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import Input from '../../../components/Input';
import { BookingBill, defaultBookingBill } from '../../../interfaces/Booking';
import { handleInputChange } from '../../../functions/selectHelper';
import { BookingService } from '../../../services/BookingService';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { formatDate, getDateFormat } from '../../../functions/dateHelper';
import TextArea from '../../../components/Textarea';
import Loader from '../../../components/CustomLoader';
import ArrivalNoticeModal from './ArrivalNoticeModal';

interface ViewBillProps {
    id: number;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewBill: React.FC<ViewBillProps> = ({ id, showModal, setShowModal }) => {
    const [bill, setBill] = useState<BookingBill>(defaultBookingBill);
    const [loading, setLoading] = useState<boolean>(false);
    const bookingService = new BookingService();
    const [showArrivalNoticeModal, setShowArrivalNoticeModal] = useState<boolean>(false);
    const [documentType, setDocumentType] = useState<string>('');
  
    const InfoGroup = ({ label, value }: { label: string; value?: string | null }) => (
        <div className="mb-2">
            <p className="text-sm font-semibold text-gray-600">{label}</p>
            <p className="text-sm">{value || '-'}</p>
        </div>
    );

    useEffect(() => {
        if (showModal) {
            setLoading(true);
            bookingService.getBookingBillById(id).then((data) => {setBill(data); setLoading(false)});
        }
    }, [showModal, id]);

    return (
        <>
            <ArrivalNoticeModal setDocumentType={setDocumentType} documentType={documentType} type="bill" ids={id ? [id] : undefined} showModal={showArrivalNoticeModal} setShowModal={setShowArrivalNoticeModal} />
            <Modal show={showModal} size="5xl" popup onClose={() => setShowModal(false)}>
                <Modal.Header>Detalii B/L - {bill.code}</Modal.Header>
                <Modal.Body className="overflow-auto">
                    <div className="space-y-4 p-2">
                        {/* Header Information */}
                        <div className="grid grid-cols-3 gap-4 p-4 bg-gray-50 rounded-lg">
                            <InfoGroup label="Număr" value={bill.code} />
                            <InfoGroup label="Terminal" value={bill.terminal} />
                            <InfoGroup label="Data" value={bill.date ? formatDate(new Date(bill.date), getDateFormat()) : '-'} />
                            <div className="flex justify-center items-center gap-4 w-full p-4">
                                <Button size="sm" onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('arrivalNotice')}}>
                                    Preavizare
                                </Button>
                                <Button size="sm" onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('arrivalUpdate')}}>
                                    Avizare
                                </Button>
                                <Button size="sm" onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('deliveryOrder')}}>
                                    Liber
                                </Button>
                            </div>
                        </div>

                        {/* Shipper Information */}
                        <div className="p-4 border rounded-lg">
                            <h3 className="text-lg font-semibold mb-3 text-blue-600">Informații Shipper</h3>
                            <div className="grid grid-cols-2 gap-4">
                                <InfoGroup label="Nume" value={bill.shipperName} />
                                <InfoGroup label="Adresă" value={bill.shipperAddress} />
                                <InfoGroup label="Telefon" value={bill.shipperPhone} />
                                <InfoGroup label="Email" value={bill.shipperEmail} />
                            </div>
                        </div>

                        {/* Consignee Information */}
                        <div className="p-4 border rounded-lg">
                            <h3 className="text-lg font-semibold mb-3 text-blue-600">Informații Consignee</h3>
                            <div className="grid grid-cols-2 gap-4">
                                <InfoGroup label="Nume" value={bill.consigneeName} />
                                <InfoGroup label="Adresă" value={bill.consigneeAddress} />
                                <InfoGroup label="Telefon" value={bill.consigneePhone} />
                                <InfoGroup label="Email" value={bill.consigneeEmail} />
                            </div>
                        </div>

                        {/* Notify Information */}
                        <div className="p-4 border rounded-lg">
                            <h3 className="text-lg font-semibold mb-3 text-blue-600">Informații Notify</h3>
                            <div className="grid grid-cols-2 gap-4">
                                <InfoGroup label="Nume" value={bill.notifyName} />
                                <InfoGroup label="Adresă" value={bill.notifyAddress} />
                                <InfoGroup label="Telefon" value={bill.notifyPhone} />
                                <InfoGroup label="Email" value={bill.notifyEmail} />
                            </div>
                        </div>

                        {/* Commodity Information */}
                        <div className="p-4 border rounded-lg">
                            <h3 className="text-lg font-semibold mb-3 text-blue-600">Informații Marfă</h3>
                            <div className="grid grid-cols-1 gap-4">
                                <InfoGroup label="Marfă B/L" value={bill.commodity} />
                                <InfoGroup label="Marfă prescurtată" value={bill.shortCommodity} />
                                <InfoGroup label="Marfă tradusă" value={bill.translatedCommodity} />
                            </div>
                        </div>

                        {/* Containers Table */}
                        <div className="p-4 border rounded-lg">
                            <h3 className="text-lg font-semibold mb-3 text-blue-600">Containere</h3>
                            <div className="overflow-x-auto">
                                <table className="w-full text-sm text-left text-gray-500">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr>
                                            <th className="px-4 py-2">Container</th>
                                            <th className="px-4 py-2">Size</th>
                                            <th className="px-4 py-2">Type</th>
                                            <th className="px-4 py-2">Seal</th>
                                            <th className="px-4 py-2">Weight</th>
                                            <th className="px-4 py-2">Volume</th>
                                            <th className="px-4 py-2">Free Days</th>
                                            <th className="px-4 py-2">Danger Cargo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bill.containers?.map((container, index) => (
                                            <tr key={index} className="bg-white border-b hover:bg-gray-50">
                                                <td className="px-4 py-2">{container.containerNumber}</td>
                                                <td className="px-4 py-2">{container.size}</td>
                                                <td className="px-4 py-2">{container.type}</td>
                                                <td className="px-4 py-2">{container.sealNumber}</td>
                                                <td className="px-4 py-2">{container.weight}</td>
                                                <td className="px-4 py-2">{container.volume}</td>
                                                <td className="px-4 py-2">{container.freeDays}</td>
                                                <td className="px-4 py-2">
                                                    <span className={`px-2 py-1 rounded text-xs font-medium ${container.dangerCargo ? 'bg-red-100 text-red-600' : 'bg-green-100 text-green-600'}`}>
                                                        {container.dangerCargo ? 'Da' : 'Nu'}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ViewBill;
