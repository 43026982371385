import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Raports from './Raports';
import Form from './Form';
import Index from './Index';
import Activities from '../activities/Activities';
import Breadcrumb from '../../components/BreadCrumb';
import ClientsNavBar from '../../components/ClientsNavBar';
import { hasAnyPermission, hasPermission } from '../../interfaces/Permissions';
import { useUser } from '../../components/UserContext';

const Clients: React.FC = () => {
  const { user } = useUser();

  return (
    <>
      <ClientsNavBar />
      <Breadcrumb />
      <Routes>
        {
          hasAnyPermission(user, ['CLIENT_VIEW_ALL', 'CLIENT_VIEW']) ?
            <Route path="index" element={<Index />} /> 
          : <></>
        }
        {
          hasAnyPermission(user, ['ACTIVITY_VIEW_ALL', 'ACTIVITY_VIEW']) ?
            <Route path="activities" element={<Activities />} /> 
          : <></>
        }
        <Route path="raports" element={<Raports />} />
        {
          hasPermission(user, 'CLIENT_CREATE') ?
            <Route path="form" element={<Form />} />
          : <></>
        }
        {
          hasPermission(user, 'CLIENT_UPDATE') ?
            <Route path="form/:id" element={<Form />} />
          : <></>
        }
        <Route path="/" element={<Navigate to="/crm/clients/index" replace />} />
      </Routes>
    </>
  );
};

export default Clients;
