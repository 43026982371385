export interface Activity {
    id: number | null;
    client: {
        company: string;
    };
    user: {
        id?: number|null;
        name: string;
    };
    clientId: number;
    userId: number;
    createdBy: number;
    priority: string | null;
    scheduledDate: Date | null;
    scheduledContact: string | null;
    contact: {
        name: string;
    };
    contactId: number | null;
    description: string | null;
    closureReason: string | null;
    status: string | null;
    ordered: boolean | null;
    createdFrom: number | null;
}

export interface CreateFromExistingProps{
    existingActivity: Activity;
    newActivity: Activity;
}

export const defaultActivity: Activity = {
    id: 0,
    client: {
        company: '',
    },
    user: {
        name: '',
    },
    clientId: 0,
    userId: 0,
    createdBy: 0,
    priority: 'Mediu',
    scheduledDate: null,
    scheduledContact: 'Telefon',
    contact: {
        name: '',
    },
    contactId: null,
    description: '',
    closureReason: null,
    status: null,
    ordered: null,
    createdFrom: 0,
};

export const priorityOptions = ['Urgent', 'Medie', 'Scăzut'].map(option => ({ value: option, label: option }));
export const scheduledContactOptions = ['Telefon', 'Email', 'Intrevedere', 'Fax'].map(option => ({ value: option, label: option }));
