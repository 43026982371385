import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Breadcrumb from '../../components/BreadCrumb';
import BookingsNavBar from '../../components/BookingsNavBar';
import Index from './Index';
import MovementErrors from './file/MovementErrors';
import IndexReports from './reports/Index';
import CheckPermission from '../../components/CheckPermission';

const Movements: React.FC = () => {
    return (
      <>
        <BookingsNavBar />
        <Breadcrumb />
        <CheckPermission permissions={['MOVEMENTS_VIEW_ALL']}>
          <Routes>
            <Route path="index" element={<Index />} />
            <Route path="errors" element={<MovementErrors />} />
            <Route path="reports" element={<IndexReports/>} />
            <Route path="/" element={<Navigate to="/bookings/movements/index" replace />} />
          </Routes>
        </CheckPermission>
      </>
    );
};

export default Movements;