import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import renderSkeleton from '../../components/SkeletonLoader';
import { Recommendation, replaceType } from '../../interfaces/Recommandation';
import { RecommandationService } from '../../services/RecommandationService';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import { ColDef } from 'ag-grid-community';
import { AG_GRID_LOCALE_RO } from '../../functions/langHelper';
import Actions from '../../components/Actions';
import DeleteModal from '../../components/DeleteModal';
import ModalForm from './ModalForm';
import { Button } from 'flowbite-react';

const RecommandationTable: React.FC = () => {
    const {slug} = useParams<{ slug: string }>();
    const [name, setName] = useState<string>('');
    const [recommandations, setRecommandations] = useState<Recommendation[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const recommandationService = new RecommandationService();
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
    const [recommendationToDelete, setRecommendationToDelete] = useState<number | null>(null);
    const [recommendationToEdit, setRecommendationToEdit] = useState<number | null>(null);

    const pagination = true;
    const paginationPageSize = 250;
    const paginationPageSizeSelector = [50, 100, 250, 1000];
    const gridOptions = {rowHeight: 35};

    const columns: ColDef<Recommendation>[] = [
        { 
            sortable: true, 
            filter: true,
            headerName: 'Id',
            valueGetter: 'node.rowIndex + 1',
            minWidth: 110, 
            maxWidth: 110,
        },
        { headerName: 'Titlu', field: 'label', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true },
        { headerName: 'Valoare', field: 'value', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true },
        { 
            headerName: 'Tip',
            field: 'type',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            cellRenderer: (params: any) => (<>{replaceType(params.value)}</>) 
        },
        {
          headerName: 'Acțiuni',
          field: 'id',
          cellRenderer: (params: any) => (
            <div>
              <Actions 
                id={params.data.id} 
                onEdit={() => onEdit(params.data.id)} 
                onDelete={() => onDelete(params.data.id)} 
                moduleName="recomandare"
                deletePermissions={[]}
              />
            </div>
          )
        }
    ];

    const onEdit = (id: number) => {
        setRecommendationToEdit(id);
        setShowModalEdit(true);
    };

    const onCreate = () => {
        setRecommendationToEdit(null);
        setShowModalEdit(true);
    };

    const onDelete = (id: number) => {
        setRecommendationToDelete(id);
        setShowModalDelete(true);
    };

    const handleDelete = async () => {
        if (recommendationToDelete !== null) {
            await recommandationService.deleteRecommendation(recommendationToDelete);
            setShowModalDelete(false);
            setRecommandations((prev) => prev.filter(rec => rec.id !== recommendationToDelete));
            setRecommendationToDelete(0);
        }
    };

    useEffect(() => {
        fetchRecommandations();
    }, [slug]);

    const fetchRecommandations = async () => {
        setLoading(true);
        if(slug){
            const data = await recommandationService.recommendationsByCategorySlug(slug);
            setName(data[0].categoryName);
            setRecommandations(data);
            setLoading(false);
        }
    };

    const handleSubmit = (recommendationResponse : Recommendation, create : boolean) => {
        if(create){
            setRecommandations((prev) => [...prev, recommendationResponse]);
        }else{
            setRecommandations((prev) => [...prev.filter(rec => rec.id !== recommendationResponse.id), recommendationResponse]);
        }
    }

  return (
    <>
        <div className="ag-theme-quartz px-4">
            <div className="flex justify-between items-center py-2">
                <div className="text-lg font-bold">
                    Recomandări - {recommandations[0] ? recommandations[0].categoryName : ''}
                </div>
                <Button size="sm" onClick={onCreate}>Adaugă recomandare</Button>
            </div>
            {loading ? ( renderSkeleton() ) : (
                <>
                    <AgGridReact 
                        localeText={AG_GRID_LOCALE_RO}
                        columnDefs={columns} 
                        rowData={recommandations}
                        defaultColDef={{ flex: 1, minWidth: 20 }}
                        domLayout='autoHeight'
                        pagination={pagination}
                        paginationPageSize={paginationPageSize}
                        paginationPageSizeSelector={paginationPageSizeSelector}
                        gridOptions={gridOptions}
                        enableRangeSelection={true}
                    />
                    <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={handleDelete} deleteText={'Sunteți sigur că doriți să ștergeți această recomandare?'} />
                    { slug ? (
                        <>
                            <ModalForm openModal={showModalEdit} setOpenModal={setShowModalEdit} id={recommendationToEdit} onSubmit={handleSubmit} slug={slug} name={name} />
                        </>
                    ) : ''}
                </>
            )}
        </div>
    </>
  );
};

export default RecommandationTable;