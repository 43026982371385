import React, { useState, useEffect } from 'react';
import { Modal, Button, Badge } from 'flowbite-react';
import { defaultManifest, Manifest } from '../../../interfaces/Booking';
import { formatDate, getDateFormat } from '../../../functions/dateHelper';

interface ModalDetailsProps {
    manifest: Partial<Manifest>|null;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalDetails: React.FC<ModalDetailsProps> = ({ manifest, showModal, setShowModal }) => {
    const getBadgeColor = (status: string|null|undefined) : string => {
        if(!status){
            return '';
        }
        switch (status) {
          case 'Incarcare esuata':
            return 'failure';
          case 'Incarcat':
            return 'warning';
          case 'Tradus':
            return 'indigo';
          case 'Generat':
            return 'info';
          case 'Importat':
            return 'green';
          default:
            return 'failure';
        }
    };

    const countTEUs = (manif : Partial<Manifest>): number => {
        if(!manif || !manif.content){
            return 0;
        }

        return manif.content!.reduce((total, bill) => {
            return total + bill.containers.reduce((billTEUs, container) => {
                const size = container.size.trim();
                
                // Pentru containere de 20 feet
                if (size.includes('20')) {
                    return billTEUs + 1;
                }
                
                // Pentru containere de 40, 42 sau 45 feet
                if (size.includes('40') || size.includes('42') || size.includes('45')) {
                    return billTEUs + 2;
                }
                
                return billTEUs;
            }, 0);
        }, 0);
    }

    const getTotalContainerTare = (manif: Partial<Manifest>): number => {
        if(!manif || !manif.content){
            return 0;
        }
        const total = manif.content!.reduce((total, bill) => {
            return total + bill.containers.reduce((billWeight, container) => {
                // Convertim string-ul în float și fixăm la 3 zecimale
                const weight = parseFloat(container.tare.toString()).toFixed(3);
                // Convertim înapoi în număr pentru calcul
                return billWeight + parseFloat(weight);
            }, 0);
        }, 0);

        return Math.round(total * 1000) / 1000;
    }

    const getTotalContainerVolume = (manif: Partial<Manifest>): number => {
        if(!manif || !manif.content){
            return 0;
        }
        const total = manif.content!.reduce((total, bill) => {
            return total + bill.containers.reduce((billVol, container) => {
                // Convertim string-ul în float și fixăm la 3 zecimale
                const vol = parseFloat(container.vol.toString()).toFixed(3);
                // Convertim înapoi în număr pentru calcul
                return billVol + parseFloat(vol);
            }, 0);
        }, 0);

        return Math.round(total * 1000) / 1000;
    }

    const getTotalCommodityWeight = (manif: Partial<Manifest>): number => {
        if(!manif || !manif.content){
            return 0;
        }
        const total = manif.content!.reduce((total, bill) => {
            return total + bill.commodityItems.reduce((billWeight, commodityItem) => {
                // Convertim string-ul în float și fixăm la 3 zecimale
                const weight = parseFloat(commodityItem.weight.toString()).toFixed(3);
                // Convertim înapoi în număr pentru calcul
                return billWeight + parseFloat(weight);
            }, 0);
        }, 0);
        return Math.round(total * 1000) / 1000;
    }

    const getCargoPackNumber = (manif: Partial<Manifest>): number => {
        if(!manif || !manif.content){
            return 0;
        }
        const total = manif.content!.reduce((total, bill) => {
            return total + bill.commodityItems.reduce((billPackNumber, commodityItem) => {
                // Convertim string-ul în float și fixăm la 3 zecimale
                const packNumber = parseInt(commodityItem.packNumber.toString()).toFixed(3);
                // Convertim înapoi în număr pentru calcul
                return billPackNumber + parseInt(packNumber);
            }, 0);
        }, 0);
        return Math.round(total * 1000) / 1000;
    }

    const getContainerPackNumber = (manif: Partial<Manifest>): number => {
        if(!manif || !manif.content){
            return 0;
        }
        const total = manif.content!.reduce((total, bill) => {
            return total + bill.containers.reduce((billPackNumber, container) => {
                // Convertim string-ul în float și fixăm la 3 zecimale
                const packNumber = parseInt(container.packNumber.toString()).toFixed(3);
                // Convertim înapoi în număr pentru calcul
                return billPackNumber + parseInt(packNumber);
            }, 0);
        }, 0);

        return Math.round(total * 1000) / 1000;
    }

    const totalWeight = Math.round((getTotalContainerTare(manifest!) + getTotalCommodityWeight(manifest!)) * 1000) / 1000;

    return (
        <>
            <Modal show={showModal} size="lg" popup onClose={() => setShowModal(false)}>
                <Modal.Header>
                    Detalii Manifest #{manifest?.id}
                </Modal.Header>
                <Modal.Body className="overflow-visible">
                    {
                        manifest ? (
                            <div className="bg-white py-6 px-2 rounded-lg grid grid-cols-2">
                                {/* Data Manifest */}
                                <div className="mb-3">
                                    <label className="text-sm font-medium text-gray-500">Data Manifest</label>
                                    <p className="text-base font-medium text-gray-900">
                                        {formatDate(manifest?.date ? manifest.date : null)}
                                    </p>
                                </div>

                                {/* Status */}
                                <div className="mb-3">
                                    <label className="text-sm font-medium text-gray-500">Status</label><br></br>
                                    <div className="flex items-center">
                                        <Badge color={getBadgeColor(manifest?.status)}>{manifest?.status}</Badge>
                                    </div>
                                </div>

                                {/* Vessel Info */}
                                <div className="mb-3">
                                    <label className="text-sm font-medium text-gray-500">Navă</label>
                                    <p className="text-base font-medium text-gray-900">
                                        {manifest.vesselCode}<br/>
                                        <span className="text-sm text-gray-500">
                                            {manifest.vesselName}
                                        </span>
                                    </p>
                                </div>

                                {/* Bills Count */}
                                <div className="mb-3">
                                    <label className="text-sm font-medium text-gray-500">Număr B/L-uri</label>
                                    <p className="text-base font-medium text-gray-900">
                                        { manifest && manifest.content ? manifest.content?.length : `` }
                                    </p>
                                </div>

                                {/* Terminal */}
                                <div className="mb-3">
                                    <label className="text-sm font-medium text-gray-500">Terminal</label>
                                    <p className="text-base font-medium text-gray-900">
                                    {manifest.terminal}
                                    </p>
                                </div>

                                {/* Containers Count */}
                                <div className="mb-3">
                                    <label className="text-sm font-medium text-gray-500">Număr Containere</label>
                                    <p className="text-base font-medium text-gray-900">
                                        {
                                            manifest && manifest.content ? 
                                                manifest!.content!.reduce((total, bill) => {
                                                    return total + bill.containers.length;
                                                }, 0)
                                            : ``
                                        }
                                    </p>
                                </div>

                                {/* Tare */}
                                <div className="mb-3">
                                    <label className="text-sm font-medium text-gray-500">Tara</label>
                                    <p className="text-base font-medium text-gray-900">
                                        {
                                            getTotalContainerTare(manifest!)
                                        }
                                    </p>
                                </div>

                                {/* Teu Count */}
                                <div className="mb-3">
                                    <label className="text-sm font-medium text-gray-500">Număr teuri</label>
                                    <p className="text-base font-medium text-gray-900">
                                        {
                                            countTEUs(manifest!)
                                        }
                                    </p>
                                </div>

                                {/* Weight */}
                                <div className="mb-3">
                                    <label className="text-sm font-medium text-gray-500">Greutate marfă</label>
                                    <p className="text-base font-medium text-gray-900">
                                        {
                                            getTotalCommodityWeight(manifest!)
                                        }
                                    </p>
                                </div>

                                {/* Volum */}
                                <div className="mb-3">
                                    <label className="text-sm font-medium text-gray-500">Volum total</label>
                                    <p className="text-base font-medium text-gray-900">
                                        {
                                            getTotalContainerVolume(manifest!)
                                        }
                                    </p>
                                </div>

                                {/* Total Weight */}
                                <div className="mb-3">
                                    <label className="text-sm font-medium text-gray-500">Greutate totală</label>
                                    <p className="text-base font-medium text-gray-900">
                                        {
                                            totalWeight
                                        }
                                    </p>
                                </div>

                                {/* Total Weight */}
                                <div className="mb-3">
                                    <label className="text-sm font-medium text-gray-500">Număr pachete(container)</label>
                                    <p className="text-base font-medium text-gray-900">
                                        {
                                            (getContainerPackNumber(manifest!))
                                        }
                                    </p>
                                </div>

                                {/* Total Weight */}
                                <div className="mb-3">
                                    <label className="text-sm font-medium text-gray-500">Număr pachete(cargo)</label>
                                    <p className="text-base font-medium text-gray-900">
                                        {
                                            (getCargoPackNumber(manifest!))
                                        }
                                    </p>
                                </div>
                            </div>
                        ) : <></>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalDetails;