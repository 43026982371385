import Input from "../../../components/Input";
import InputSug from "../../../components/InputSug";
import { handleInputChange, handleMultipleSelectChange, handleSelectChange } from "../../../functions/selectHelper";
import { Client, defaultClient } from "../../../interfaces/Client";
import { useEffect, useState } from "react";
import { User } from "../../../interfaces/User";
import { UserService } from '../../../services/UserService';
import { Button, ToggleSwitch } from 'flowbite-react';
import { MdChecklist } from "react-icons/md";
import { ClientService } from '../../../services/ClientService';
import CustomSelect from '../../../components/CustomSelect';
import { LabelValueString, RecommendationResp } from '../../../interfaces/Recommandation';
import { handleOptions, handleRecommendations } from '../../../functions/generalHelper';
import { useUser } from "../../../components/UserContext";

interface TabProps {
  client: Client;
  setClient: React.Dispatch<React.SetStateAction<Client>>;
  recommendations: RecommendationResp[];
  id?: string;
}

const General: React.FC<TabProps> = ({ client, setClient, recommendations, id }) => {
  const [users, setUsers] = useState<User[]>([]);
  const userService = new UserService();
  const clientService = new ClientService();
  const { user } = useUser();

  const activityTypesOptions      = handleOptions(recommendations, 'client-activityType');
  const countryOptions            = handleOptions(recommendations, 'client-country');
  const levelOptions              = handleOptions(recommendations, 'client-level');
  const locationOptions           = handleOptions(recommendations, 'client-location');
  const potentialOptions          = handleOptions(recommendations, 'client-potential');
  const priorityOptions           = handleOptions(recommendations, 'client-priority');
  const relationStatusOptions     = handleOptions(recommendations, 'client-status');
  const typeOptions               = handleOptions(recommendations, 'client-type');
  const citySugestions            = handleRecommendations(recommendations, 'client-city');
  const stateSugestions           = handleRecommendations(recommendations, 'client-state');
  const regionSugestions          = handleRecommendations(recommendations, 'client-region');

  useEffect(() => {
    userService.getUsers().then((data) => {
      setUsers(data);
      if (!id) {
        setClient((prev) => ({...prev, userId: user!.id}));
      }
    });
  }, []);

  const checkAnafData = () => {
    if(client.gstNumber){
      clientService.getAnafData(client.gstNumber).then((anafData) =>
        setClient((prevClient: Client) => ({
          ...prevClient,
          ...anafData
        }))
      );
    }
  }

  const userOptions = users.map(user => ({ value: user.id, label: user.name }));

  return (
    <div>
      {/* Date generale */}
      <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Date generale</h4>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
          <Input 
            id="company" 
            label="Denumire"
            value={client.company}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'company', e.target.value, defaultClient)}
          />
          <Input 
            id="email" 
            label="Email"
            value={client.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'email', e.target.value, defaultClient)}
          />
          <Input 
            id="phone" 
            label="Telefon"
            value={client.phone}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'phone', e.target.value, defaultClient)}
          />
          <div className="grid grid-cols-[1fr,auto]">
            <Input 
              id="gstNumber" 
              label="CUI/CIF"
              value={client.gstNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'gstNumber', e.target.value, defaultClient)}
            />
            <div className="ps-3">
              <Button size="sm" onClick={() => checkAnafData()}><MdChecklist fontSize={'20px'}/></Button>
            </div>
          </div>

          <Input 
            id="vat" 
            label="Nr. Reg. Com."
            value={client.vat}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'vat', e.target.value, defaultClient)}
          />
          <CustomSelect
            value={userOptions.find(user => user.value == client.userId)}
            onChange={(data) => { handleSelectChange(client, setClient, 'userId', data) }}
            options={userOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Sales/Responsabil"
          />
          <div className="hidden">
            <CustomSelect
              value={typeOptions.find(option => option.value == client.type)}
              onChange={(data) => { handleSelectChange(client, setClient, 'type', data) }}
              options={typeOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Tipul clientului"
            />
          </div>

          <Input 
            id="tradeId" 
            label="Trade ID"
            value={client.tradeId}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'tradeId', e.target.value, defaultClient)}
          />
        </div>
      </div>
      {/* /Date generale */}
      {/* Clasificare client */}
      <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Clasificare client</h4>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
          <CustomSelect
            value={locationOptions.find(option => option.value == client.location)}
            onChange={(data) => { handleSelectChange(client, setClient, 'location', data) }}
            options={locationOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Locație"
          />

          <CustomSelect
            isMulti
            value={activityTypesOptions.filter((option : any) => client.activityType.includes(option.value))}
            onChange={(data) => { handleMultipleSelectChange(client, setClient, 'activityType', data) }}
            options={activityTypesOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Tip activitate"
          />
          
          <CustomSelect
            value={relationStatusOptions.find(option => option.value == client.relationStatus)}
            onChange={(data) => { handleSelectChange(client, setClient, 'relationStatus', data) }}
            options={relationStatusOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Status relatie"
          />

          <CustomSelect
            value={priorityOptions.find(option => option.value == client.priority)}
            onChange={(data) => { handleSelectChange(client, setClient, 'priority', data) }}
            options={priorityOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Prioritate"
          />

          <CustomSelect
            isMulti
            value={levelOptions.filter((option : any) => client.level.includes(option.value))}
            onChange={(data) => { handleMultipleSelectChange(client, setClient, 'level', data) }}
            options={levelOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="TLS Level"
          />

          <CustomSelect
            isMulti
            value={potentialOptions.filter((option : any) => client.potential.includes(option.value))}
            onChange={(data) => { handleMultipleSelectChange(client, setClient, 'potential', data) }}
            options={potentialOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Potențial"
          />

          <div className="flex flex-row items-center">
            <ToggleSwitch color="primary" checked={client.isTransporting} label="Transportă?" onChange={() => {setClient({ ...client, isTransporting: !client.isTransporting })}} />
          </div>
        </div>
      </div>
      {/* /Clasificare client */}
      {/* Adresă */}
      <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Adresă</h4>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
          <Input 
              id="street" 
              label="Stradă"
              value={client.street}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'street', e.target.value, defaultClient)}
          />
          <Input 
              id="number" 
              label="Număr"
              value={client.number}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'number', e.target.value, defaultClient)}
          />
          <InputSug      
            id="city" 
            label="Oraș"
            value={client.city}
            suggestions={citySugestions}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'city', e.target.value, defaultClient)}
          />
          <InputSug      
            id="state" 
            label="Județ/Sector"
            value={client.state}
            suggestions={stateSugestions}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'state', e.target.value, defaultClient)}
          />
          <InputSug      
            id="region" 
            label="Regiune"
            value={client.region}
            suggestions={regionSugestions}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'region', e.target.value, defaultClient)}
          />
          <CustomSelect
            value={countryOptions.find(option => option.value == client.country)}
            onChange={(data) => { handleSelectChange(client, setClient, 'country', data) }}
            options={countryOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Țară"
          />
          <Input 
              id="building" 
              label="Bloc"
              value={client.building}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'building', e.target.value, defaultClient)}
          />
          <Input 
              id="staircase" 
              label="Scară"
              value={client.staircase}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'staircase', e.target.value, defaultClient)}
          />
          <Input 
              id="floor" 
              label="Etaj"
              value={client.floor}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'floor', e.target.value, defaultClient)}
          />
          <Input 
              id="apartment" 
              label="Apartament"
              value={client.apartment}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'apartment', e.target.value, defaultClient)}
          />
          <Input 
              id="postalCode" 
              label="Cod poștal"
              value={client.postalCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'postalCode', e.target.value, defaultClient)}
          />
          <Input 
              id="website" 
              label="Adresă web"
              value={client.website}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(client, setClient, 'website', e.target.value, defaultClient)}
          />
        </div>
      </div>
      {/* /Adresă */}
    </div>
  );
};
export default General;
