import { Role } from "./Role";

export interface User {
    id: number | null;
    name: string;
    email: string;
    function?: string;
    department?: string;
    password?: string;
    roles?: Role[];
}

export const defaultUser : User = {
    id: null, 
    name: '',
    email: '',
    function: '',
    department: '',
    password: '',
    roles: []
}