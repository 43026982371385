import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { MdOutlineEdit } from "react-icons/md";

export class CellRenderer implements ICellRendererComp {
    eGui: any;
    eButton: any;
    params!: ICellRendererParams;
    buttonClickListener!: () => void;

    createGui() {
        const template = 
        `<span id="theValue" style="padding-left: 4px;"></span> 
        <button id="theButton" type="button" class="absolute right-2 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 w-[22px] h-[22px] border rounded bg-white text-center text-gray-600">
            <svg class="feather feather-edit block mx-auto" fill="none" height="14" width="14" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg>
        </button>`;
        const span = document.createElement('span');
        span.innerHTML = template;
        this.eGui = span;
    }

    init(params: ICellRendererParams) {
        // create the gui
        this.createGui();
        // keep params, we use it in onButtonClicked
        this.params = params;

        // attach the value to the value span
        const eValue = this.eGui.querySelector('#theValue');

        eValue.textContent = params.value;
        // setup the button, first get reference to it
        this.eButton = this.eGui.querySelector('#theButton');

        this.buttonClickListener = () => this.onButtonClicked();
        this.eButton.addEventListener('click', this.buttonClickListener);
    }
    onButtonClicked() {
        // start editing this cell. see the docs on the params that this method takes
        const startEditingParams = {
            rowIndex: this.params.node.rowIndex!,
            colKey: this.params.column!.getId(),
        };
        this.params.api.startEditingCell(startEditingParams);
    }
    getGui() {
        // returns our gui to the grid for this cell
        return this.eGui;
    }
    refresh() {
        return false;
    }
    destroy() {
        // be good, clean up the listener
        this.eButton.removeEventListener('click', this.buttonClickListener);
    }
}