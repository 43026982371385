
import { handleMultipleCreatableChange, handleMultipleSelectChange } from "../../../functions/selectHelper";
import CreatableSelect from 'react-select/creatable';
import CustomSelect from '../../../components/CustomSelect';
import { RecommendationResp } from "../../../interfaces/Recommandation";
import { handleOptions, handleRecommendations } from '../../../functions/generalHelper';

interface TabProps {
  client: any;
  setClient: React.Dispatch<React.SetStateAction<any>>;
  recommendations: RecommendationResp[];
}

const Comercial: React.FC<TabProps> = ({ client, setClient, recommendations }) => {
    
    const cargoCategoryOptions          = handleOptions(recommendations, 'client-cargoCategory');
    const cargoPriceOptions             = handleOptions(recommendations, 'client-cargoPrice');
    const cargoWeightOptions            = handleOptions(recommendations, 'client-cargoWeight');
    const dryTransportModeOptions       = handleOptions(recommendations, 'client-dryTransportMode');
    const equipmentUsedOptions          = handleOptions(recommendations, 'client-equipmentUsed');
    const loadingCountrySugestions      = handleOptions(recommendations, 'client-loadingCountry');
    const loadingLocationSugestions     = handleOptions(recommendations, 'client-loadingLocation');
    const loadingPortSugestions         = handleOptions(recommendations, 'client-loadingPort');
    const loadingRegionSugestions       = handleOptions(recommendations, 'client-loadingRegion');
    const loadingStateSugestions        = handleOptions(recommendations, 'client-loadingState');
    const tradeOptions                  = handleOptions(recommendations, 'client-trade');
    const transportDirectionOptions     = handleOptions(recommendations, 'client-transportDirection');
    const transportUsedOptions          = handleOptions(recommendations, 'client-transportUsed');

  return (
    <div>
      {/* Transport */}
      <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Transport și Marfă</h4>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
            <CustomSelect
                isMulti
                value={transportUsedOptions.filter(option => client.transportUsed.includes(option.value))}
                onChange={(data) => { handleMultipleSelectChange(client, setClient, 'transportUsed', data) }}
                options={transportUsedOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Transport utilizat"
            />

            <CustomSelect
                isMulti
                value={transportDirectionOptions.filter(option => client.transportDirection.includes(option.value))}
                onChange={(data) => { handleMultipleSelectChange(client, setClient, 'transportDirection', data) }}
                options={transportDirectionOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Sens transport"
            />
            
            <CustomSelect
                isMulti
                value={tradeOptions.filter(option => client.trade.includes(option.value))}
                onChange={(data) => { handleMultipleSelectChange(client, setClient, 'trade', data) }}
                options={tradeOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Trade"
            />

            <CustomSelect
                isMulti
                value={equipmentUsedOptions.filter(option => client.equipmentUsed.includes(option.value))}
                onChange={(data) => { handleMultipleSelectChange(client, setClient, 'equipmentUsed', data) }}
                options={equipmentUsedOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Echipament utilizat"
            />
            <CustomSelect
                isMulti
                value={dryTransportModeOptions.filter(option => client.dryTransportMode.includes(option.value))}
                onChange={(data) => { handleMultipleSelectChange(client, setClient, 'dryTransportMode', data) }}
                options={dryTransportModeOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Mod de transport uscat"
            />
            <CustomSelect
                isMulti
                value={cargoCategoryOptions.filter(option => client.cargoCategory.includes(option.value))}
                onChange={(data) => { handleMultipleSelectChange(client, setClient, 'cargoCategory', data) }}
                options={cargoCategoryOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Categorie marfă"
            />
            <CustomSelect
                isMulti
                value={cargoPriceOptions.filter(option => client.cargoPrice.includes(option.value))}
                onChange={(data) => { handleMultipleSelectChange(client, setClient, 'cargoPrice', data) }}
                options={cargoPriceOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Preț marfă"
            />
            <CustomSelect
                isMulti
                value={cargoWeightOptions.filter(option => client.cargoWeight.includes(option.value))}
                onChange={(data) => { handleMultipleSelectChange(client, setClient, 'cargoWeight', data) }}
                options={cargoWeightOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Greutate marfă"
            />
        </div>
      </div>
      {/* /Transport */}
      {/* Încărcare */}
      <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Încărcare</h4>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
            <CreatableSelect 
                isMulti 
                value={client.loadingCountry.map((option: string) => ({ value: option, label: option }))}
                onChange={(data) => { handleMultipleCreatableChange(client, setClient, 'loadingCountry', data) }}
                options={loadingCountrySugestions} 
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Țară încărcare"
            />
            <CreatableSelect 
                isMulti 
                value={client.loadingRegion.map((option: string) => ({ value: option, label: option }))}
                onChange={(data) => { handleMultipleCreatableChange(client, setClient, 'loadingRegion', data) }}
                options={loadingRegionSugestions} 
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Regiune încărcare"
            />
            <CreatableSelect 
                isMulti 
                value={client.loadingState.map((option: string) => ({ value: option, label: option }))}
                onChange={(data) => { handleMultipleCreatableChange(client, setClient, 'loadingState', data) }}
                options={loadingStateSugestions} 
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Județ/Stat încărcare"
            />
            <CreatableSelect 
                isMulti 
                value={client.loadingPort.map((option: string) => ({ value: option, label: option }))}
                onChange={(data) => { handleMultipleCreatableChange(client, setClient, 'loadingPort', data) }}
                options={loadingPortSugestions} 
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Port încărcare"
            />
            <CreatableSelect 
                isMulti 
                value={client.loadingLocation.map((option: string) => ({ value: option, label: option }))}
                onChange={(data) => { handleMultipleCreatableChange(client, setClient, 'loadingLocation', data) }}
                options={loadingLocationSugestions} 
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Loc încărcare"
            />
        </div>
      </div>
      {/* /Încărcare */}
    </div>
  );
};

export default Comercial;