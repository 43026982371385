import React, { useRef } from 'react';
import { Modal } from "flowbite-react";
import useClickOutside from '../../functions/useClickOutside';
import View from './components/View';

interface ActivityModalProps {
    id: number;
    openModal: boolean,
    setOpenModal: (openModal: boolean) => void;
}

const ModalView: React.FC<ActivityModalProps> = ({ id, openModal, setOpenModal }) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    useClickOutside(dropdownRef, () => {
        setOpenModal(false);
    });

    return (
        <div>
            <Modal show={openModal} size="6xl" popup onClose={() => setOpenModal(false)} ref={dropdownRef}>
                <Modal.Header>
                    Previzualizare ofertă
                </Modal.Header>
                <Modal.Body>
                    <View id={id} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ModalView;
