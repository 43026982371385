import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const renderSkeleton = () => (
  <div className="grid grid-cols-7 gap-2 py-2">
    {Array.from({ length: 7 }).map((_, rowIndex) => (
      <React.Fragment key={rowIndex}>
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
      </React.Fragment>
    ))}
  </div>
);

export default renderSkeleton;
