import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Raports from './Raports';
import Form from './Form';
import Index from './Index';
import Breadcrumb from '../../components/BreadCrumb';
import UsersNavBar from '../../components/UsersNavBar';
import { useUser } from '../../components/UserContext';
import { hasAnyPermission } from '../../interfaces/Permissions';

const Users: React.FC = () => {
  const { user } = useUser();

  return (
    <>
      <UsersNavBar />
      <Breadcrumb />
      <Routes>
        {
          hasAnyPermission(user, ['USER_VIEW_ALL']) ?
            <Route path="index" element={<Index />} />
          : <></>
        }
        {
          hasAnyPermission(user, ['USER_VIEW_ALL']) ?
          <Route path="raports" element={<Raports />} />
          : <></>
        }
        <Route path="/" element={<Navigate to="/hr/users/index" replace />} />
      </Routes>
    </>
  );
};

export default Users;
