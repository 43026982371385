import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookingBillTable } from '../../../interfaces/Booking';
import { BookingService } from '../../../services/BookingService';
import renderSkeleton from '../../../components/SkeletonLoader';
import Table from './Table';
import ModalCreate from './ModalCreate';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import { Button, Tooltip } from "flowbite-react";

const Index: React.FC = () => {
  const [bills, setBills] = useState<Partial<BookingBillTable>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const bookingService = new BookingService();
  const navigate = useNavigate();
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);

  useEffect(() => {
    fetchBillChecks();
  }, []);

  const fetchBillChecks = useCallback(
    async () => {
        setLoading(true);
        bookingService.getBillChecks().then((data) => { setBills(data); setLoading(false)}).catch(() => {setLoading(false)});
  },[])

    const onCreate = () => {
        setShowModalCreate(true);
    };

  return (
    <div className="ag-theme-quartz px-4">
        <div className="flex justify-between items-center py-2">
        <div className="text-lg font-bold">
            Verificari B/L-uri
        </div>
        <Tooltip content="Adaugă  B/L">
            <Button size="sm" onClick={onCreate}>Adaugă B/L</Button>
        </Tooltip>
    </div>
      {loading ? ( renderSkeleton() ) : (
        <>
          <Table bills={bills} refresh={fetchBillChecks} />
          <ModalCreate showModal={showModalCreate} setShowModal={setShowModalCreate} onSave={fetchBillChecks} />
        </>
      )}
    </div>
  );
};

export default Index;