import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Badge, Button } from 'flowbite-react';
import { AgGridReact } from 'ag-grid-react';
import { CellEditRequestEvent, CellValueChangedEvent, ColDef, ColumnState, GetContextMenuItemsParams, GridReadyEvent, MenuItemDef, ProcessCellForExportParams, TextMatcherParams } from 'ag-grid-community';
import { Movement } from '../../interfaces/Movement';
import { MovementService } from '../../services/MovementService';
import Tooltip from '../../components/Tooltip';
import renderSkeleton from '../../components/SkeletonLoader';
import { AG_GRID_LOCALE_RO } from '../../functions/langHelper';
import { RecommandationService } from '../../services/RecommandationService';
import { RecommendationResp } from '../../interfaces/Recommandation';
import ModalCreate from './ModalCreate';
import ModalReport from './reports/ModalCreate';
import { createDateFromString, formatDate, formatDateTime, stringToDate } from '../../functions/dateHelper';
import { errorMessage, successMessage } from '../../functions/generalHelper';
import ModalImport from './ModalImport';
import ModalNextMovement from './ModalNextMovement';
import { stopCoverage } from 'v8';
import { MovementReportService } from '../../services/MovementReportService';
import DateTimeEditor from '../../components/AgGrid/DateTimeEditor';
import DateTimeEditorNotNull from '../../components/AgGrid/DateTimeEditorNotNull';
import Actions from '../../components/Actions';
import { MdDeleteOutline } from "react-icons/md";
import DeleteModal from '../../components/DeleteModal';
import useGridState from '../../functions/useGridState';


const Index: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [movementToEdit, setMovementToEdit] = useState<number>(0);
    const [movementToDelete, setMovementToDelete] = useState<number>(0);
    const [movements, setMovements] = useState<Movement[]>([]);
    const [movementsForModal, setMovementsForModal] = useState<number[]>([]);
    const [showModalReport, setShowModalReport] = useState<boolean>(false);
    const [showContextItems, setShowContextItems] = useState<boolean>(false);
    const [showModalImport, setShowModalImport] = useState<boolean>(false);
    const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
    const [showModalNextMovement, setShowModalNextMovement] = useState<boolean>(false);
    const [nextMovement, setNextMovement] = useState<{next?: string, movement?: Movement}>({});
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const movementService = new MovementService();
    const movementReportService = new MovementReportService();
    const [recommendations, setRecommendations] = useState<RecommendationResp[]>([]);
    const recommendationsService = new RecommandationService();
    const { saveState, restoreState } = useGridState('movements-grid');

    // GET ALL OPTION VALUES AND FIELD RECOMMENDATIONS
    useEffect(() => {
      recommendationsService.getRecommendations().then(data => setRecommendations(data));
      fetchMovements();
    }, []);

    const fetchMovements = async () => {
        setLoading(true);
        const data = await movementService.getMovements();
        setMovements(data ? data : []);
        setLoading(false);
    };

    const onCreate = useCallback(
        () => {
            setMovementToEdit(0);
            setShowModalCreate(true);
        },
      [movementToEdit, showModalCreate],
    )

    const onEdit = useCallback(
        (id: number) => {
            setMovementToEdit(id);
            setShowModalCreate(true);
        },
      [movementToEdit, showModalCreate],
    )

    const onDelete = useCallback(
        (id: number) => {
            setMovementToDelete(id);
            setShowModalDelete(true);
        },
      [movementToEdit, showModalDelete],
    )

    const onSave = useCallback(
        () => {
            fetchMovements();
        },
      [],
    )

    const confirmDelete = async () => {
        if (movementToDelete !== null) {
            await movementService.deleteMovement(movementToDelete);
            setShowModalDelete(false);
            setMovements(movements.filter(movement => movement.id !== movementToDelete));
            setMovementToDelete(0);
            successMessage('Mișcare ștearsă cu succes');
        }
    };

    const createNextMovement = async (movement: Movement, movementName: string) => {
        setShowModalNextMovement(true);
        setNextMovement({next: movementName, movement: movement});
    }

    const formatNextMovement = (movement: Movement) => {
        if(!movement.isLastMovement){
            return <></>
        }
        switch (movement.movement) {
            case 'DF':
                return <button className="customMovementButton" onClick={() => createNextMovement(movement, 'FC')} >FC</button>;
            case 'FC':
                return <>
                    <button className="customMovementButton" onClick={() => createNextMovement(movement, 'RE')} >RE</button>
                    <button className="customMovementButton ms-2" onClick={() => createNextMovement(movement, 'IE')} >IE</button>
                </>;
            case 'RE':
                return <button className="customMovementButton" onClick={() => createNextMovement(movement, 'ES')} >ES</button>;
            case 'IE':
                return <button className="customMovementButton" onClick={() => createNextMovement(movement, 'FL')} >FL</button>;
            case 'ES':
                return <button className="customMovementButton" onClick={() => createNextMovement(movement, 'FL')} >FL</button>;
            case 'FL':
                return <button className="customMovementButton" onClick={() => createNextMovement(movement, 'OF')} >OF</button>;
            case 'DE':
                return <button className="customMovementButton" onClick={() => createNextMovement(movement, 'ES')} >ES</button>;
            default:
                return <></>;
        }
    }

    const onGridReady = useCallback((event: GridReadyEvent) => {
        const api = event.api;
        event.api.closeToolPanel();
        
        // Restaurăm starea salvată
        const savedState = localStorage.getItem(`agGrid-movements-grid`);
        if (savedState) {
          // Dacă există state salvat, îl restaurăm
          restoreState(api);
        } else {
            // Configurarea implicita a gridului
            const columnState: ColumnState[] = [
                { colId: 'id', sort: 'desc', sortIndex: 0 }
            ];
            event.api.applyColumnState({ state: columnState, defaultState: { sort: null } });
        }
        // Adăugăm event listeners pentru salvarea stării
        const saveCurrentState = () => saveState(api);
        
        api.addEventListener('filterChanged', saveCurrentState);
        api.addEventListener('sortChanged', saveCurrentState);
        api.addEventListener('columnMoved', saveCurrentState);
        api.addEventListener('columnResized', saveCurrentState);
        
        // Optional: Cleanup function
        return () => {
          api.removeEventListener('filterChanged', saveCurrentState);
          api.removeEventListener('sortChanged', saveCurrentState);
          api.removeEventListener('columnMoved', saveCurrentState);
          api.removeEventListener('columnResized', saveCurrentState);
        };
    }, [saveState, restoreState]);

    const columns: ColDef<Movement>[] = useMemo(() => [
        { headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 40 },
        { headerName: 'Id', field: 'id', sortable: true, filter: 'agNumberColumnFilter', floatingFilter: true, width: 110, maxWidth: 110, initialHide: true },
        { headerName: 'Container', field: 'containerCode', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, enableRowGroup: true },
        { headerName: 'Mișcare', field: 'movement', sortable: true, filter: 'agSetColumnFilter', floatingFilter: true, enableRowGroup: true },
        { headerName: 'Terminal', field: 'terminal', sortable: true, filter: 'agSetColumnFilter', floatingFilter: true },
        {
            headerName: 'Dată',
            field: 'date',
            sort: 'asc',
            sortable: true,
            filter: 'agDateColumnFilter',
            floatingFilter: true,
            filterParams: {
                inRangeInclusive: true,
                defaultOption: 'inRange'
            },
            valueFormatter: (params : any) => formatDateTime(params.data.date!)!,
            // cellRenderer: (params : any) => formatDateTime(params.data.date!)!,
            editable: true,
            cellEditor: DateTimeEditorNotNull,
            minWidth: 170,
            
        },
        {
            headerName: 'Dată YML',
            field: 'dateSystem',
            sort: 'asc',
            sortable: true,
            filter: 'agDateColumnFilter',
            floatingFilter: true,
            filterParams: {
                inRangeInclusive: true,
                defaultOption: 'inRange'
            },
            valueFormatter: (params : any) => formatDateTime(params.data.dateSystem!)!,
            editable: true,
            cellEditor: DateTimeEditor,
            minWidth: 170,
            
        },
        { headerName: 'Booking', field: 'bookingNumber', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true },
        { headerName: 'Dimensiune', field: 'size', sortable: true, filter: 'agSetColumnFilter', floatingFilter: true,
            valueGetter: (params : any) => params.data.size + params.data.type,
            valueFormatter: (params : any) => params.data.size + params.data.type,
            cellRenderer: (params : any) => <>{params.data.size}{params.data.type}</>,
         },
        { headerName: 'Vas', field: 'vesselName', editable: true, sortable: true, filter: 'agTextColumnFilter', floatingFilter: true },
        { headerName: 'Voiaj', field: 'vesselCode', editable: true, sortable: true, filter: 'agTextColumnFilter', floatingFilter: true },
        { headerName: 'Acțiuni', field: 'movement', sortable: true, filter: 'agSetColumnFilter', floatingFilter: true,
            cellRenderer: (params : any) => <>{formatNextMovement(params.data)}</>,
        },

        { headerName: 'Status', field: 'createdAtSystem', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true,
            cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            cellRenderer: (params : any) => params.value ? 
            <Badge color="green">Raportat</Badge> : 
            <Badge color="red">Neraportat</Badge>,
            filterValueGetter: (params: any) => (params.data.createdAtSystem ? 'Raportat' : 'Neraportat'),
            getQuickFilterText: (params: any) => (params.data.createdAtSystem ? 'Raportat' : 'Neraportat'),
        },
        { headerName: 'In 12H', field: 'isReportedWithin12Hours', sortable: true, filter: 'agSetColumnFilter', floatingFilter: true,
            cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            cellRenderer: (params : any) => params.value ? <Badge color="green">Da</Badge> : <Badge color="red">Nu</Badge>,
            initialHide: true
        },
        { headerName: 'Notă', field: 'note', editable: true, sortable: true, filter: 'agSetColumnFilter', floatingFilter: true, enableRowGroup: true, initialHide: true },
        { headerName: 'Port', field: 'port', editable: true, sortable: true, filter: 'agSetColumnFilter', floatingFilter: true, enableRowGroup: true, initialHide: true },
        { headerName: 'Ultima mișcare', field: 'isLastMovement', sortable: true, filter: 'agSetColumnFilter', floatingFilter: true, enableRowGroup: true, initialHide: true,
            cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            cellRenderer: (params : any) => params.value ? <Badge color="green">Da</Badge> : <Badge color="red">Nu</Badge>,
         },
        {
        headerName: 'Actiuni',
        field: 'id',
        minWidth: 130,
        cellRenderer: (params: any) => (
            <div>
                <Tooltip content={`Șterge miscare`}>
                    <button type="button" onClick={() => onDelete(params.data.id)} className="text-red-500 hover:text-red-700 h-[20px] mt-1 flex items-center mx-1" aria-label={`Șterge miscare`}>
                        <MdDeleteOutline className='w-[20px] h-[20px] hover:scale-110 duration-75' />
                    </button>
                </Tooltip>
            </div>
        )
      } 
    ], []);

    const cancelRows = async (rows: Movement[]) => {
        const ids = rows.map((row) => row.id!);
        const response = await movementReportService.cancelMovementsReports(ids);
        if(response.status === 'success'){
            successMessage(response.message);
        }else{
            errorMessage(response.message);
        }
        fetchMovements();
    }

    const reportRows = (rows: Movement[]) => {
        setMovementsForModal(rows.map((row) => row.id!));
        setShowModalReport(true);
    }

    const deleteRows = async (rows: Movement[]) => {
        const ids = rows.map((row) => row.id!);
        const response = await movementService.removeMovements(ids);
        if(response.status === 'success'){
            successMessage(response.message);
        }else{
            errorMessage(response.message);
        }
        fetchMovements();
    }

    const getContextMenuItems = (params: GetContextMenuItemsParams) : (string | MenuItemDef)[] => {
        var result: (string | MenuItemDef)[] = 
        showContextItems ? 
        [
            {
                name: "Anulează raportare",
                action: (event) => {
                    cancelRows(event.api.getSelectedRows());
                },
            },
            {
                name: "Raportează",
                action: (event) => {
                    reportRows(event.api.getSelectedRows());
                },
            },
            {
                name: "Șterge",
                action: (event) => {
                    deleteRows(event.api.getSelectedRows());
                },
            },
            "separator",
            "cut",
            "copy",
            "copyWithHeaders",
            "copyWithGroupHeaders",
            "paste",
            "separator",
            "chartRange",
            "export",
        ] : [
            "cut",
            "copy",
            "copyWithHeaders",
            "copyWithGroupHeaders",
            "paste",
            "separator",
            "chartRange",
            "export",
        ];
        return result;
    }

    const onCellValueChanged = useCallback((event: CellValueChangedEvent) => {
        // console.log(event.colDef.field)
        // if(event.colDef.field == 'date'){ dateSystem
        //     console.log(event.data.id)
        //     console.log(event.rowIndex)
        //     console.log(event.newValue)
        //     console.log(event.oldValue)
        // }
    }, []);

    const processCellFromClipboard = (params : ProcessCellForExportParams) => {
        if(params.column.getColDef().field == 'date' || params.column.getColDef().field == 'dateSystem'){
            try {
                return stringToDate(params.value);
            } catch (error) {
                errorMessage('Data nu este în formatul corect');
                const field = params.column.getColDef().field as keyof Movement;
                return movements[params.node!.childIndex][field];
            }
        }else{
            return params.value;
        }
    };

    return (
        <div className="ag-theme-quartz px-4">
            <div className="flex justify-between items-center py-2">
                <div className="text-lg font-bold">
                    Mișcări
                </div>
                <div className="flex gap-2">
                    <NavLink to="/bookings/movements/errors">
                        <Button size="sm" >Erori de procesare</Button>
                    </NavLink>
                    <NavLink to="/bookings/movements/reports">
                        <Button size="sm" >Raportări YML</Button>
                    </NavLink>
                    <Tooltip content="Importă mișcări">
                        <Button size="sm" onClick={() => { setShowModalImport(true); }}>Importă mișcări</Button>
                    </Tooltip>
                    <Tooltip content="Adaugă fișier">
                        <Button size="sm" onClick={onCreate}>Adaugă mișcări</Button>
                    </Tooltip>
                </div>
            </div>
            <ModalNextMovement showModal={showModalNextMovement} setShowModal={setShowModalNextMovement} nextMovement={nextMovement} onSave={onSave} recommendations={recommendations} />
            {loading ? ( renderSkeleton() ) : (
                <>
                    <AgGridReact 
                        rowSelection={'multiple'}
                        onSelectionChanged={(event) => {setShowContextItems(event.api.getSelectedRows().length > 0)}}
                        localeText={AG_GRID_LOCALE_RO}
                        columnDefs={columns} 
                        rowData={movements}
                        defaultColDef={{ flex: 1, minWidth: 100 }}
                        domLayout='autoHeight'
                        pagination={true}
                        paginationPageSize={100}
                        paginationPageSizeSelector={[50, 100, 1000, 10000]}
                        onGridReady = {onGridReady}
                        gridOptions = {{rowHeight: 35, }}
                        groupDisplayType="groupRows"
                        rowGroupPanelShow="always"
                        animateRows={true}
                        sideBar={true}
                        enableCharts={true}
                        enableRangeSelection={true}
                        getContextMenuItems={getContextMenuItems}
                        onCellValueChanged={onCellValueChanged}
                        processCellFromClipboard={processCellFromClipboard}
                        stopEditingWhenCellsLoseFocus={true}
                    />
                    {/* <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={confirmDelete} deleteText='Sunteți sigur că doriți să eliminați acest fisier?' /> */}
                    <DeleteModal isOpen={showModalDelete} onClose={() => { setShowModalDelete(false) }} onConfirm={confirmDelete} deleteText='Sunteți sigur că doriți să eliminați acest fisier?' />
                    <ModalCreate showModal={showModalCreate} setShowModal={setShowModalCreate} id={movementToEdit} onSave={onSave} recommendations={recommendations} />
                    <ModalReport showModal={showModalReport} setShowModal={setShowModalReport} onSave={onSave} movements={movementsForModal} />
                    <ModalImport showModal={showModalImport} setShowModal={setShowModalImport} onSuccess={fetchMovements}/>
                </>
            )}
        </div>
    );
};

export default Index;