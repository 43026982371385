import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAuth } from '../functions/useAuth';

interface User {
    id: number;
    name: string;
    email: string;
    permissions: string[];
}

interface UserContextType {
  user: User | null;
  logout: () => void;
  isLoading: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { user, logout } = useAuth();
  useEffect(() => {
    if(user){
        setIsLoading(false);
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, isLoading, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }
  return context;
};