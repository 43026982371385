import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Table from './Table';
import { Button, Tooltip } from "flowbite-react";
import 'react-loading-skeleton/dist/skeleton.css';
import renderSkeleton from '../../components/SkeletonLoader';
import { Offer } from '../../interfaces/Offer';
import { OfferService } from '../../services/OfferService';
import ModalView from './ModalView';
import DeleteModal from '../../components/DeleteModal';
import { errorMessage, successMessage } from '../../functions/generalHelper';
import CheckPermission from '../../components/CheckPermission';

interface OfferIndexProps {
  isTemplate: boolean;
  clientId?: number | null | undefined;
}

const Index: React.FC<OfferIndexProps> = ({ isTemplate, clientId }) => {
  const [offers, setOffers] = useState<Offer[]>([]);
  const [viewOfferId, setViewOfferId] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalViewOpen, setIsModalViewOpen] = useState(false);
  const [offerToDelete, setOfferToDelete] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const offerService = new OfferService();
  const navigate = useNavigate();

  useEffect(() => {
    fetchOffers();
  }, [isTemplate]);

  const fetchOffers = async () => {
    setLoading(true);
    const data = await offerService.getOffers(isTemplate, clientId);
    setOffers(data);
    setLoading(false);
  };

  const handleView = (id: number) => {
    setViewOfferId(id);
    setIsModalViewOpen(true);
  }

  const handleEdit = (id: number) => {
    if(isTemplate){
      navigate('/crm/offers/templates/form/' + id);
    }else{
      navigate('/crm/offers/form/' + id);
    }
  };

  const handleDelete = (id: number) => {
    setOfferToDelete(id);
    setIsModalOpen(true);
  };

  const confirmDelete = async () => {
    if (offerToDelete !== null) {
      await offerService.deleteOffer(offerToDelete);
      setIsModalOpen(false);
      setOffers(offers.filter(offer => offer.id !== offerToDelete));
      setOfferToDelete(null);
    }
  };

  const handleSend = (partialOffer: Partial<Offer>) => {
    if(partialOffer){
        setOffers(prevOffers =>
          prevOffers.map(offer => offer.id === partialOffer.id ? { ...offer, status: partialOffer.status ?? offer.status } : offer
        )
      );
    }
  }

  const handleClone = (id: number) => {
    offerService.cloneOffer(id).then((result) => {
      setOffers(prevOffers =>
        [...prevOffers, result]
      );
      successMessage('Oferta a fost duplicată cu succes!');
      navigate('/crm/offers/form/' + result.id);
    }).catch(error => {
      errorMessage(error.message);
    });
  }

  return (
    <div className="ag-theme-quartz px-4">
      <div className="flex justify-between items-center py-2">
        <div className="text-lg font-bold">
          Oferte
        </div>
        <CheckPermission permissions={isTemplate ? ['OFFER_CREATE'] : ['OFFER_CREATE']}>
          <NavLink to={ isTemplate ? '/crm/offers/templates/form' : '/crm/offers/form'}>
            <Button size="sm">{ isTemplate ? 'Adaugă șablon' : 'Adaugă ofertă'}</Button>
          </NavLink>
        </CheckPermission>
      </div>
      { loading ? ( renderSkeleton() ) : (
        <>
          <Table onClone={handleClone} onSend={handleSend} isTemplate={isTemplate} offers={offers} onEdit={handleEdit} onDelete={handleDelete} onView={handleView}/>
          <ModalView id={viewOfferId} openModal={isModalViewOpen} setOpenModal={setIsModalViewOpen} />
          <CheckPermission permissions={isTemplate ? ['OFFER_DELETE'] : ['OFFER_DELETE']}>
            <DeleteModal isOpen={isModalOpen} onClose={() => {setIsModalOpen(false)}} onConfirm={() => {confirmDelete()}} deleteText={isTemplate ? 'Sunteți sigur că doriți să ștergeți aceast șablon?' : 'Sunteți sigur că doriți să ștergeți această ofertă?'} />
          </CheckPermission>
        </>
      )}
    </div>
  );
};

export default Index;
