import React, { useState, useCallback, memo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface CustomDatePickerProps {
  selected: Date | null;
  onChange: (date: Date | null) => void;
  placeholderText: string;
  [key: string]: any;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = memo(({
  selected,
  onChange,
  placeholderText,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = useCallback(
    (newDate: Date | null) => {
      if (newDate && selected) {
        newDate.setHours(selected.getHours());
        newDate.setMinutes(selected.getMinutes());
        newDate.setSeconds(selected.getSeconds());
      }
      onChange(newDate);
    },
    [onChange, selected]
  );

  const handleFocus = useCallback(() => setIsFocused(true), []);
  const handleBlur = useCallback(() => setIsFocused(false), []);

  const isFilled = !!selected;
  return (
    <div className="floating-label-datepicker">
      <DatePicker
        selected={selected}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholderText={placeholderText}
        {...rest}
      />
      <label className={`floating-label ${isFilled ? 'filled' : ''} ${isFocused ? 'focused' : ''}`}>
        {placeholderText}
      </label>
    </div>
  );
});

CustomDatePicker.displayName = 'CustomDatePicker';

export default CustomDatePicker;