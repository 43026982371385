import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.png';
import UserMenu from '../pages/auth/components/UserMenu';
import { useUser } from './UserContext';
import { hasAnyPermission } from '../interfaces/Permissions';
import CheckPermission from './CheckPermission';

const MainNavBar: React.FC = () => {
  const { user } = useUser();

  return (
    <div className="bg-gray-50 h-9 hidden md:flex items-center justify-between px-4">
      <div className="items-center h-full flex">
        <img src={logo} alt="Logo" className="h-full object-contain me-2" />
        <CheckPermission permissions={['CLIENT_VIEW_ALL', 'CLIENT_VIEW', 'ACTIVITY_VIEW_ALL', 'ACTIVITY_VIEW']}>
          <NavLink to={hasAnyPermission(user, ['CLIENT_VIEW_ALL', 'CLIENT_VIEW']) ? '/crm/clients' : '/crm/clients/activities'} className={({ isActive }) => (isActive ? 'active_menu_element' : 'menu_element')}><span>CRM</span></NavLink>
        </CheckPermission>

        <CheckPermission permissions={['OFFER_VIEW_ALL', 'OFFER_VIEW']}>
          <NavLink to="/crm/offers" className={({ isActive }) => (isActive ? 'active_menu_element' : 'menu_element')}><span>Oferte</span></NavLink>
        </CheckPermission>

        <CheckPermission permissions={['BOOKINGS_VIEW_ALL']}>
          <NavLink to="/bookings" className={({ isActive }) => (isActive ? 'active_menu_element' : 'menu_element')}><span>Bookinguri</span></NavLink>
        </CheckPermission>

        <CheckPermission permissions={['USER_VIEW_ALL', 'ROLE_VIEW_ALL']}>
          <NavLink to={hasAnyPermission(user, ['USER_VIEW_ALL']) ? '/hr' : '/hr/roles/index'} className={({ isActive }) => (isActive ? 'active_menu_element' : 'menu_element')}><span>Utilizatori</span></NavLink>
        </CheckPermission>

        <CheckPermission permissions={['SETTING_VIEW_ALL']}>
          <NavLink to="/settings" className={({ isActive }) => (isActive ? 'active_menu_element' : 'menu_element')}><span>Setări</span></NavLink>
        </CheckPermission>
      </div>
      <div className="flex items-center">
        <UserMenu />
      </div>
    </div>
  );
};

export default MainNavBar;
