import client from '../apollo-client';
import { gql } from '@apollo/client';
import { Recommendation, RecommendationCategory, RecommendationResp } from '../interfaces/Recommandation';

export class RecommandationService {
  async getCategories(): Promise<RecommendationCategory[]> {
    const response = await client.query({
      query: gql`
        query {
            recommendationCategories {
              categorySlug
              categoryName
            }
        }  
      `,
    });
    return response.data.recommendationCategories;
  }

  async recommendationsByCategorySlug(categorySlug : string): Promise<Recommendation[]> {
    const response = await client.query({
      query: gql`
        query {
          recommendationsByCategorySlug(categorySlug: "${categorySlug}") {
            id
            categorySlug
            categoryName
            label
            value
            type
          }
        }
      `,
    });
    return response.data.recommendationsByCategorySlug;
  }

  async createRecommendation(recommendation: Recommendation): Promise<Recommendation> {
    console.log(recommendation);
    const response = await client.mutate({
      mutation: gql`
        mutation {
          createRecommendation(
            input: { 
                categorySlug: "${recommendation.categorySlug}",
                categoryName: "${recommendation.categoryName}",
                label: "${recommendation.label}",
                value: "${recommendation.value}",
                type: "${recommendation.type}",
              }
          ){
            id
            categorySlug
            categoryName
            label
            value
            type
          }
        }
      `,
    });
    return response.data.createRecommendation;
  }

  async updateRecommendation(recommendation: Recommendation): Promise<Recommendation> {
    const response = await client.mutate({
      mutation: gql`
        mutation {
            updateRecommendation(
              input: { 
                  id: ${recommendation.id},
                  categorySlug: "${recommendation.categorySlug}",
                  categoryName: "${recommendation.categoryName}",
                  label: "${recommendation.label}",
                  value: "${recommendation.value}",
                  type: "${recommendation.type}",
                }
            ){
              id
              categorySlug
              categoryName
              label
              value
              type
            }
        }
      `,
    });
    return response.data.updateRecommendation;
  }

  async getRecommendationById(id: number): Promise<Recommendation> {
    const response = await client.query({
      query: gql`
        query {
          recommendation(id: ${id}) {
            id
            categorySlug
            categoryName
            label
            value
            type
          }
        }
      `,
    });
    return response.data.recommendation;
  }

  async deleteRecommendation(id: number) {
    const response = await client.mutate({
      mutation: gql`
        mutation {
          removeRecommendation(id: ${id})
        }
      `,
    });
    return response.data.removeRecommendation;
  }



  async getRecommendations(): Promise<RecommendationResp[]> {
    const response = await client.query({
      query: gql`
        query {
          recommendations{
            categorySlug
            label
            value
            type
          }
        }
      `,
    });
    return response.data.recommendations
  }

}
