import React, { FormEvent, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import Form from './Form';
import Index from './Index';
import Breadcrumb from '../../components/BreadCrumb';
import OffersNavBar from '../../components/OffersNavBar';
import { defaultOffer, Offer } from '../../interfaces/Offer';
import { useNavigate } from 'react-router-dom';
import { errorMessage, successMessage } from '../../functions/generalHelper';
import { OfferService } from '../../services/OfferService';
import TemplatesModal from './TemplatesModal';
import { Button } from 'flowbite-react';
import ClientForm from './ClientForm';
import CheckPermission from '../../components/CheckPermission';
import { hasAnyPermission } from '../../interfaces/Permissions';
import { useUser } from '../../components/UserContext';
import Loader from '../../components/CustomLoader';

const Offers: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const offerService = new OfferService();
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [offer, setOffer] = useState<Offer>(defaultOffer);
  const [formKey, setFormKey] = useState<number>(0);
  const { user } = useUser();

  useEffect(() => {
    setOffer(defaultOffer);
    setOpenModal(false);
    setFormKey(prev => prev + 1);
  }, [location.pathname]);

  const handleSubmit = async (id: string | undefined, isTemplate: boolean, status: string) => {
    if(!offer.email || offer.email.trim() == '' || (!offer.clientId && !isTemplate)){
      if(isTemplate){
        errorMessage('Denumirea este obligatorie.');
      }else{
        errorMessage('Clientul și adresa de email sunt obligatorii pentru a salva oferta.');
      }
      return;
    }
    try {
      setLoading(true);
      if (id) {
        await offerService.update({...offer, status: status}, isTemplate);
        isTemplate ? successMessage('Șablon editat cu success!') : successMessage('Ofertă editată cu success!');
        isTemplate ? navigate('/crm/offers/templates') : navigate('/crm/offers/index');
      } else {
        await offerService.create({...offer, status: status}, isTemplate);
        isTemplate ? successMessage('Șablon adăugat cu success!') : successMessage('Ofertă adăugată cu success!');
        isTemplate ? navigate('/crm/offers/templates') : navigate('/crm/offers/index');
      }
      setLoading(false);
    } catch (error) {
      errorMessage('Ceva nu a functionat. Va rugam sa incercati mai tarziu.');
      console.log(error);
      setLoading(false);
    }
  };

  const isFormPage = location.pathname.includes('/offers/form');
  const actionButton = isFormPage ? (
    <CheckPermission permissions={['OFFER_VIEW_ALL', 'OFFER_VIEW']}>
      <Button size="sm" onClick={() => {setOpenModal(true)}}>Importă șablon</Button>
    </CheckPermission>
  ) : undefined;

  return (
    <>
      <OffersNavBar />
      <Breadcrumb actionButton={actionButton}/>
      <TemplatesModal offer={offer} openModal={openModal} setOffer={setOffer} setOpenModal={setOpenModal} />
      <div className={loading ? `h-full fixed left-0 top-0 bottom-0 right-0 z-[100]` : ``}>
        <Loader isLoading={loading} />
      </div>
      <Routes>
        {
          hasAnyPermission(user, ['OFFER_VIEW', 'OFFER_VIEW_ALL']) ?
            <Route path="index" element={<Index isTemplate={false} />} /> 
          : <></>
        }
        {
          hasAnyPermission(user, ['OFFER_CREATE']) ?
            <Route path="form/client/:clientId" element={<ClientForm key={`${formKey}-${location.pathname}`} onSave={handleSubmit} offer={offer} setOffer={setOffer} isTemplate={false} />} />
          : <></>
        }
        {
          hasAnyPermission(user, ['OFFER_CREATE']) ?
            <Route path="form" element={<Form key={`${formKey}-${location.pathname}`} onSave={handleSubmit} offer={offer} setOffer={setOffer} isTemplate={false} />} />
          : <></>
        }
        {
          hasAnyPermission(user, ['OFFER_UPDATE']) ?
            <Route path="form/:id" element={<Form key={`${formKey}-${location.pathname}`} onSave={handleSubmit} offer={offer} setOffer={setOffer} isTemplate={false} />} />
          : <></>
        }
        {
          hasAnyPermission(user, ['OFFER_UPDATE']) ?
            <Route path="form/:id" element={<Form key={`${formKey}-${location.pathname}`} onSave={handleSubmit} offer={offer} setOffer={setOffer} isTemplate={false} />} />
          : <></>
        }
        {
          hasAnyPermission(user, ['OFFER_VIEW', 'OFFER_VIEW_ALL']) ?
            <Route path="templates" element={<Index isTemplate={true} />} />
          : <></>
        }
        {
          hasAnyPermission(user, ['OFFER_CREATE']) ?
            <Route path="templates/form" element={<Form key={`${formKey}-${location.pathname}`} onSave={handleSubmit} offer={offer} setOffer={setOffer} isTemplate={true} />} />
          : <></>
        }
        {
          hasAnyPermission(user, ['OFFER_UPDATE']) ?
            <Route path="templates/form/:id" element={<Form  key={`${formKey}-${location.pathname}`}  onSave={handleSubmit} offer={offer} setOffer={setOffer} isTemplate={true} />} />
          : <></>
        }

        <Route path="/" element={<Navigate to="/crm/offers/index" replace />} />
        {/* <Route path="*" element={<Index isTemplate={false} />} />/ */}
      </Routes>
    </>
  );
};

export default Offers;
