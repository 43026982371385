import React, { useState, useCallback } from 'react';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import { Button, Modal } from 'flowbite-react';
import CustomSelect from '../../../components/CustomSelect';
import { MovementReportService } from '../../../services/MovementReportService';

interface ModalCreateProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: () => void;
    movements?: number[];
}

const ModalCreate: React.FC<ModalCreateProps> = ({ showModal, setShowModal, onSave, movements}) => {
    const [type, setType] = useState<number>(0);
    const movementReportService = new MovementReportService();

    const typeOptions = [
        {label: 'Toate cu data reală', value: 0},
        {label: 'Toate în 12H', value: 1},
        {label: 'FC în data exactă, restul în 12H', value: 2},
        {label: 'FC și RE în data exactă, restul în 12H', value: 3},
        {label: 'FC și RE întârziate în data exactă, restul în 12H', value: 4},
    ];

    const handleSubmit = useCallback(async (send : boolean) => {
        const response = await movementReportService.generateReportCSV(movements ? movements : [], type, send);
        if (response.status === 'success') {
            onSave();
            successMessage(response.message);
        } else {
            errorMessage(response.message);
        }
        onSave();
        setShowModal(false);
    }, [type]);

    return (
        <>
            <Modal show={showModal} size="3xl" popup onClose={() => setShowModal(false)}>
                <Modal.Header>
                    Raportează mișcări
                </Modal.Header>
                <Modal.Body className="overflow-visible">
                    <div className="space-y-2 pt-5">
                        <div className="gap-4 grid mb-2">  
                            <div className="mb-1">
                                <CustomSelect
                                    value={typeOptions.find(option => option.value === type)}
                                    onChange={(data) => {setType(data.value)}}
                                    options={typeOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Tip"
                                />
                            </div>
                        </div>
                        <div className="flex justify-end gap-4">
                            <Button size="sm" onClick={() => { handleSubmit(false) }}>Generează</Button>
                            <Button size="sm" onClick={() => { handleSubmit(true) }}>Generează și trimite</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalCreate;