import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from './Table';
import 'react-loading-skeleton/dist/skeleton.css';
import renderSkeleton from '../../components/SkeletonLoader';
import { RecommendationCategory } from '../../interfaces/Recommandation';
import { RecommandationService } from '../../services/RecommandationService';

const Index: React.FC = () => {
  const [recommandations, setRecommandations] = useState<RecommendationCategory[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const recommandationService = new RecommandationService();
  const navigate = useNavigate();

  useEffect(() => {
    fetchRecommandations();
  }, []);

  const fetchRecommandations = async () => {
    setLoading(true);
    const data = await recommandationService.getCategories();
    setRecommandations(data);
    setLoading(false);
  };

  const handleEdit = (slug: string) => {
    navigate('/settings/recommandations/table/' + slug);
  };
  
  return (
    <div className="ag-theme-quartz px-4">
        <div className="flex justify-between items-center py-2">
            <div className="text-lg font-bold">
                Recomandări
            </div>
        </div>
      {loading ? ( renderSkeleton() ) : (
        <>
          <Table recommandations={recommandations} onEdit={handleEdit}/>
        </>
      )}
    </div>
  );
};

export default Index;
