import { Button, Modal } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { HiPlus, HiX } from "react-icons/hi";
import Input from '../../components/Input';
import { defaultUser, User } from '../../interfaces/User';
import { handleInputChange } from '../../functions/selectHelper';
import { UserService } from '../../services/UserService';
import { errorMessage, successMessage } from '../../functions/generalHelper';

interface FormProps {
  userId: number | null;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (user: User, type: string) => void;
}

const Form: React.FC<FormProps> = ({userId, showModal, setShowModal, onSave}) => {
  const [user, setUser] = useState<User>(defaultUser);
  const userService = new UserService();
  
  useEffect(() => {
    if (userId) {
      userService.getUserById(userId).then((data) => setUser({...defaultUser, ...data}));
    }else{
      setUser(defaultUser);
    }
  }, [showModal]);

  const handleSubmit = async () => {
    try {
      if (userId) {
        const returnUser = await userService.update(user);
        successMessage('Utilizatorul a fost editat cu success!');
        setShowModal(false);
        onSave(returnUser, 'update');
      } else {
        const returnUser = await userService.create(user);
        successMessage('Utilizatorul a fost adăugat cu success!');
        setShowModal(false);
        onSave(returnUser, 'create');
      }
    } catch (error) {
      if (userId) {
        errorMessage('Numele si adresa de email sunt obligatorii');
      } else {
        errorMessage('Numele, emailul si parola sunt obligatorii');
      }
    }
  };

  return (
    <>
      <Modal onClose={() => setShowModal(false)} show={showModal}>
        <Modal.Body className="relative rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-5">
          <div className="mb-4 flex items-center justify-between rounded-t border-b pb-4 dark:border-gray-600 sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              { userId ? 'Editează utilizator' : 'Adaugă utilizator'}
            </h3>
            <button
              onClick={() => setShowModal(false)}
              className="absolute right-5 top-[18px] ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <HiX className="h-5 w-5" />
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form action="#">
            <div className="mb-4 grid gap-4 sm:grid-cols-2">
              <div>
                <Input 
                  id="name" 
                  label="Nume complet"
                  value={user.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(user, setUser, 'name', e.target.value, defaultUser)}
                />
              </div>
              <div>
                <Input 
                  id="email" 
                  label="Email"
                  type="email"
                  value={user.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(user, setUser, 'email', e.target.value, defaultUser)}
                />
              </div>
              <div>
                <Input 
                  id="function" 
                  label="Funcție"
                  value={user.function}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(user, setUser, 'function', e.target.value, defaultUser)}
                />
              </div>
              <div>
                <Input 
                  id="department" 
                  label="Departament"
                  value={user.department}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(user, setUser, 'department', e.target.value, defaultUser)}
                />
              </div>
              <div>
                <Input 
                  id="password" 
                  label="Parola"
                  value={user.password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(user, setUser, 'password', e.target.value, defaultUser)}
                />
              </div>
            </div>
            <div className="items-center space-y-4 sm:flex sm:space-x-4 sm:space-y-0">
              <Button size="sm" color="gray" onClick={() => setShowModal(false)}>Înapoi</Button>
              <Button size="sm" type="button" onClick={() => { handleSubmit() }}><HiPlus className="-ml-1 mr-2 h-4 w-4" />Salvează</Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Form;
