import React from 'react';
import { handleInputChange, handleSelectChange } from '../../../functions/selectHelper';
import Input from "../../../components/Input";
import { Client, defaultBeginningText, defaultCustomSubject, defaultOffer, Offer, OfferTypeOptions } from '../../../interfaces/Offer';
import { getDateFormat } from '../../../functions/dateHelper';
import CustomSelect from '../../../components/CustomSelect';
import { errorMessage } from '../../../functions/generalHelper';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { Currency } from '../../../interfaces/Currency';
import { ToggleSwitch } from 'flowbite-react';

interface GeneralDataSectionProps {
    offer: Offer;
    setOffer: React.Dispatch<React.SetStateAction<Offer>>;
    isTemplate: boolean;
    clients: Client[];
    currencies: Currency[];
}

const GeneralDataSection: React.FC<GeneralDataSectionProps> = ({ offer, setOffer, isTemplate, clients, currencies }) => {
    const clientsOptions = clients.map(client => ({ value: client.id, label: client.company }));

    const handleChangeClient = (selectedOption: any) => {
        const currentClient = clients.find((client) => client.id == selectedOption.value);
        if(currentClient){
            setOffer({
                ...offer,
                clientId: selectedOption.value,
                email: currentClient.email!,
                tradeId: currentClient?.tradeId!,
                client: currentClient,
            });
        }else{
            errorMessage('Clientul nu a fost găsit!');
        }
    };

    const handleCurrencyChange = (selectedOption: {value: number, label: string}, field : string) => {
        setOffer({
            ...offer, 
            [field]: selectedOption.value,
        });
    };

    return (
        <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
            <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Date generale</h4>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 ">
                <Input 
                    id="email" 
                    label={isTemplate ? 'Denumire' : 'Email'}
                    value={offer.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(offer, setOffer, 'email', e.target.value, defaultOffer)}
                />
                <CustomSelect
                    value={OfferTypeOptions.find(type => type.value == offer.type)}
                    onChange={(data) => { handleSelectChange(offer, setOffer, 'type', data) }}
                    options={OfferTypeOptions}
                    placeholder="Tip ofertă"
                />
                {
                    !isTemplate ? 
                    (
                        <>
                            <CustomSelect
                                value={clientsOptions.find(client => client.value == offer.clientId)}
                                onChange={(data) => { handleChangeClient(data) }}
                                options={clientsOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Client"
                            />
                            <div>
                                <CustomDatePicker
                                    selected={offer.validityStartDate}
                                    onChange={(date) => setOffer({...offer, validityStartDate: date})} 
                                    dateFormat={getDateFormat()}
                                    locale="ro"
                                    isClearable
                                    placeholderText="Data ofertei"
                                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <CustomDatePicker
                                    selected={offer.validityEndDate}
                                    onChange={(date) => setOffer({...offer, validityEndDate: date})} 
                                    dateFormat={getDateFormat()}
                                    locale="ro"
                                    isClearable
                                    placeholderText="Data expirării"
                                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <Input 
                                    id="tradeId" 
                                    label="Trade ID"
                                    value={offer.tradeId}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(offer, setOffer, 'tradeId', e.target.value, defaultOffer)}
                                />
                            </div>
                        </>
                    )
                    : ''
                }
                <div>
                    <CustomSelect
                        value={
                            currencies
                            .map(currency => ({ value: currency.id, label: currency.name }))
                            .find(currency => currency.value == offer.shippingCurrencyId)
                        }
                        onChange={(data) => { handleCurrencyChange(data, 'shippingCurrencyId') }}
                        options={currencies.map(currency => ({ value: currency.id, label: currency.name }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={offer.type == 'Export' && offer.compressed ? "Monedă" : "Monedă navlu"}
                    />
                </div>
                { 
                    offer.type == 'Export' && offer.compressed ? <></> : 
                    <>
                        <div>
                            <CustomSelect
                                value={
                                    currencies
                                    .map(currency => ({ value: currency.id, label: currency.name }))
                                    .find(currency => currency.value == offer.localCurrencyId)
                                }
                                onChange={(data) => { handleCurrencyChange(data, 'localCurrencyId') }}
                                options={currencies.map(currency => ({ value: currency.id, label: currency.name }))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Monedă locale"
                            />
                        </div>
                        <div>
                            <CustomSelect
                                value={
                                    currencies
                                    .map(currency => ({ value: currency.id, label: currency.name }))
                                    .find(currency => currency.value == offer.autoCurrencyId)
                                }
                                onChange={(data) => { handleCurrencyChange(data, 'autoCurrencyId') }}
                                options={currencies.map(currency => ({ value: currency.id, label: currency.name }))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Monedă auto"
                            />
                        </div>
                    </>
                }
                
                <div>
                    <Input 
                        id="terminal" 
                        label="Terminal"
                        value={offer.terminal}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(offer, setOffer, 'terminal', e.target.value, defaultOffer)}
                    />
                </div>
                <div>
                    <Input 
                        id="cc" 
                        label="CC"
                        value={offer.cc}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(offer, setOffer, 'cc', e.target.value, defaultOffer)}
                    />
                </div>
                {/* <div>
                    <Input 
                        id="bcc" 
                        label="BCC"
                        value={offer.bcc}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(offer, setOffer, 'bcc', e.target.value, defaultOffer)}
                    />
                </div> */}
                <div className="flex flex-row items-center">
                    <ToggleSwitch color="primary" checked={offer.beginningText != null} label="Formulă personalizată?" onChange={() => {setOffer(prev => ({ ...prev, beginningText: prev.beginningText === null ? defaultBeginningText(offer.type) : null }))}} />
                </div>
                <div className="flex flex-row items-center">
                    <ToggleSwitch color="primary" checked={offer.customSubject != null} label="Subiect personalizat?" onChange={() => {setOffer(prev => ({ ...prev, customSubject: prev.customSubject === null ? defaultCustomSubject : null }))}} />
                </div>
                <div className="flex flex-row items-center">
                    <ToggleSwitch color="primary" checked={offer.navluVat} label={offer.type == 'Export' && offer.compressed ? '+TVA?' : '+TVA navlu?'} onChange={() => {setOffer(prev => ({ ...prev, navluVat: !prev.navluVat }))}} />
                </div>
                {
                    offer.type == 'Export' && offer.compressed ? <></> : 
                    <>
                        <div className="flex flex-row items-center">
                            <ToggleSwitch color="primary" checked={offer.localeVat} label="+TVA locale?" onChange={() => {setOffer(prev => ({ ...prev, localeVat: !prev.localeVat }))}} />
                        </div>
                    </>
                }
                {
                    offer.customSubject!=null ? 
                        <div>
                            <Input 
                                id="customSubject" 
                                label="Subiect mail"
                                value={offer.customSubject}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(offer, setOffer, 'customSubject', e.target.value, defaultOffer)}
                            />
                        </div>
                    : <></>
                }
                {
                    offer.type == 'Export' ? 
                        <div className="flex flex-row items-center">
                            <ToggleSwitch color="primary" checked={offer.compressed} label="Tabel comun?" onChange={() => {setOffer(prev => ({ ...prev, compressed: !prev.compressed }))}} />
                        </div>
                    : <></>
                }
                {
                    offer.type == 'Import' ? 
                        <div className="flex flex-row items-center">
                            <ToggleSwitch color="primary" checked={offer.displayDespatch} label="Tabel contrastalii?" onChange={() => {setOffer(prev => ({ ...prev, displayDespatch: !prev.displayDespatch }))}} />
                        </div>
                    : <></>
                }
            </div>
        </div>
    );
};
export default GeneralDataSection;