import React, { useState, useEffect, useMemo } from 'react';
import { BillCheck, defaultBillCheck } from '../../../interfaces/Booking';
import { BookingService } from '../../../services/BookingService';
import { Button, Modal } from 'flowbite-react';
import Input from '../../../components/Input';
import { handleInputChange } from '../../../functions/selectHelper';
import { errorMessage, successMessage } from '../../../functions/generalHelper';

interface ModalCreateProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: () => void;
}

const ModalCreate: React.FC<ModalCreateProps> = ({ showModal, setShowModal, onSave }) => {
    const [billCheck, setBillCheck] = useState<BillCheck>(defaultBillCheck);
    const bookingService = new BookingService();

    // ia movementul daca exista, altfel seteaza defaultMovement
    useEffect(() => {
        setBillCheck(defaultBillCheck);
    }, [showModal])

    // functia care trimite datele catre server
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const newMovement = await bookingService.createBillCheck(billCheck);
            if(newMovement.status == 'success'){
                setShowModal(false);
                successMessage(newMovement.message);
            }else{
                errorMessage(newMovement.message);
            }
            onSave();
        } catch (error) {
            errorMessage('Bill-ul este obligatoriu!');
        }
    };

    return (
        <>
            <Modal show={showModal} size="lg" popup onClose={() => setShowModal(false)}>
                <Modal.Header>Adaugă verificare</Modal.Header>
                <Modal.Body className="overflow-visible">
                    <div className="space-y-2 pt-5">
                        <form onSubmit={handleSubmit}>     
                            <div className="gap-4 grid grid-cols-1 mb-2">  
                                <div className="mb-1">
                                    <Input 
                                        id="billNumber" 
                                        label="B\L Number"
                                        value={billCheck.billNumber}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(billCheck, setBillCheck, 'billNumber', e.target.value, defaultBillCheck)}
                                    />
                                </div>   
                            </div>
                            <div className="flex justify-end">
                                <Button size="sm" type="submit">Salvează</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalCreate;