import React, { useMemo, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_RO } from '../../../functions/langHelper';
import { ColDef } from 'ag-grid-community';
import { formatDate, formatDateTime } from '../../../functions/dateHelper';
import { Badge } from 'flowbite-react';
import { Manifest } from '../../../interfaces/Booking';

interface TableProps {
  manifests: Partial<Manifest>[];
}

const Table: React.FC<TableProps> = ({ manifests }) => {
  const pagination = true;
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const gridOptions = { rowHeight: 35 };

  const getBadgeColor = useCallback((status: string|null|undefined) : string => {
      if(!status){
          return '';
      }
      switch (status) {
        case 'Incarcare esuata':
          return 'failure';
        case 'Incarcat':
          return 'warning';
        case 'Tradus':
          return 'indigo';
        case 'Generat':
          return 'info';
        case 'Importat':
          return 'green';
        default:
          return 'failure';
      }
    }, []);

  const columns = useMemo<ColDef<Partial<Manifest>>[]>(() => [
    { 
        headerName: 'Id',
        field: 'id', 
        sortable: true, 
        filter: 'agNumberColumnFilter', 
        floatingFilter: true, 
        minWidth: 100,
        hide: true
    },
    { 
      headerName: 'Cod navă', 
      field: 'vesselCode', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
      cellRenderer: (params : any) => (
        <NavLink to={`/bookings/manifests/view/${params.data.id}`} className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline">{params.value}</NavLink>
      ),
    },
    { 
      headerName: 'Terminal', 
      field: 'terminal', 
      sortable: true, 
      filter: 'agSetColumnFilter', 
      floatingFilter: true,
    },
    {
      headerName: 'Dată',
      field: 'date',
      sort: 'asc',
      sortable: true,
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      filterParams: {
          inRangeInclusive: true,
          defaultOption: 'inRange'
      },
      valueFormatter: (params : any) => formatDate(params.data.date!)!,
      minWidth: 170,
      
    },
    { 
      headerName: 'Navă', 
      field: 'vesselName', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
    },
    { 
      headerName: 'Status', 
      field: 'status', 
      sortable: true, 
      filter: 'agSetColumnFilter', 
      floatingFilter: true,
      minWidth: 170,
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      cellRenderer: (params: any) => (
        <Badge color={getBadgeColor(params.value)}>
          {params.value}
        </Badge>
      )
    },
  ], []);
  
  return (
    <>
      <AgGridReact 
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={manifests}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
        gridOptions={gridOptions}
        enableCharts={true}
        enableRangeSelection={true}
        sideBar={true}
      />
    </>
  );
};

export default Table;