import axios from 'axios';
import { Client } from '../interfaces/Client';
import { Bounce, toast } from 'react-toastify';
import { formatDateForBackend } from '../functions/dateHelper';
import client from '../apollo-client';
import { gql } from '@apollo/client';

const API_URL = process.env.REACT_APP_GRAPHQL_URI!;

export class ClientService {
  async getClients(): Promise<Client[]> {
    const response = await client.query({
        query: gql`
          query {
            clients {
              id
              company
              email
              phone
              gstNumber
              tradeId
              priority
              isTransporting
              transportDirection
              equipmentUsed
              loadingCountry
              loadingRegion
              loadingState
              loadingPort
              loadingLocation
              city
              state
              street
              actualizat
              user{
                name
              }
            }
          }
        `,
    });
    return response.data.clients;
  }

  async getClientById(id: number): Promise<Client> {
    const response = await client.query({
        query: gql`
          query {
            client(id: ${id}) {
              id
              company
              email
              phone
              tradeId
              gstNumber
              vat
              userId
              priority
              type
              location
              activityType
              relationStatus
              level
              potential
              isTransporting
              street
              building
              staircase
              city
              floor
              number
              apartment
              postalCode
              state
              region
              country
              website
              transportUsed
              transportDirection
              trade
              equipmentUsed
              dryTransportMode
              cargoCategory
              cargoPrice
              cargoWeight
              loadingCountry
              loadingRegion
              loadingState
              loadingPort
              loadingLocation
              contacts{
                id
                name
                title
                position
                department
                location
                phone
                email
                dateOfBirth
              }
              financials{
                id
                bankName
                iban
                currencyId
                branch
                bankAddress
              }
            }
          }
        `,
    });
    const contacts = response.data.client.contacts.map((contact : any) => ({
      ...contact,
      dateOfBirth: contact.dateOfBirth ? new Date(contact.dateOfBirth) : null
    }));
    return {
      ...response.data.client, 
      contacts: contacts,
      activityType: response.data.client.activityType ? response.data.client.activityType : [],
      level: response.data.client.level ? response.data.client.level : [],
      potential: response.data.client.potential ? response.data.client.potential : [],
      transportUsed: response.data.client.transportUsed ? response.data.client.transportUsed : [],
      transportDirection: response.data.client.transportDirection ? response.data.client.transportDirection : [],
      trade: response.data.client.trade ? response.data.client.trade : [],
      equipmentUsed: response.data.client.equipmentUsed ? response.data.client.equipmentUsed : [],
      dryTransportMode: response.data.client.dryTransportMode ? response.data.client.dryTransportMode : [],
      cargoCategory: response.data.client.cargoCategory ? response.data.client.cargoCategory : [],
      cargoPrice: response.data.client.cargoPrice ? response.data.client.cargoPrice : [],
      cargoWeight: response.data.client.cargoWeight ? response.data.client.cargoWeight : [],
      loadingCountry: response.data.client.loadingCountry ? response.data.client.loadingCountry : [],
      loadingRegion: response.data.client.loadingRegion ? response.data.client.loadingRegion : [],
      loadingState: response.data.client.loadingState ? response.data.client.loadingState : [],
      loadingPort: response.data.client.loadingPort ? response.data.client.loadingPort : [],
      loadingLocation: response.data.client.loadingLocation ? response.data.client.loadingLocation : [],
    };
  }

  async createClient(currentClient: Client): Promise<Client> {
    const response = await client.mutate({
      mutation: gql`
        mutation createClient($input: CreateClientWithRelationsDto!){
            createClient(input: $input){
              id
            }
        }
      `,
      variables: {
        input: {
          company: currentClient.company,
          email: currentClient.email ? currentClient.email : null,
          phone: currentClient.phone ? currentClient.phone : null,
          gstNumber: currentClient.gstNumber ? currentClient.gstNumber : null,
          tradeId: currentClient.tradeId ? currentClient.tradeId : null,
          vat: currentClient.vat ? currentClient.vat : null,
          userId: currentClient.userId !== undefined ? currentClient.userId : null,
          priority: currentClient.priority ? currentClient.priority : null,
          type: currentClient.type ? currentClient.type : null,
          location: currentClient.location ? currentClient.location : null,
          activityType: currentClient.activityType,
          relationStatus: currentClient.relationStatus ? currentClient.relationStatus : null,
          level: currentClient.level,
          potential: currentClient.potential,
          isTransporting: currentClient.isTransporting,
          street: currentClient.street ? currentClient.street : null,
          building: currentClient.building ? currentClient.building : null,
          staircase: currentClient.staircase ? currentClient.staircase : null,
          city: currentClient.city ? currentClient.city : null,
          floor: currentClient.floor ? currentClient.floor : null,
          number: currentClient.number ? currentClient.number : null,
          apartment: currentClient.apartment ? currentClient.apartment : null,
          postalCode: currentClient.postalCode ? currentClient.postalCode : null,
          state: currentClient.state ? currentClient.state : null,
          region: currentClient.region ? currentClient.region : null,
          country: currentClient.country ? currentClient.country : null,
          website: currentClient.website ? currentClient.website : null,
          transportUsed: currentClient.transportUsed,
          transportDirection: currentClient.transportDirection,
          trade: currentClient.trade,
          equipmentUsed: currentClient.equipmentUsed,
          dryTransportMode: currentClient.dryTransportMode,
          cargoCategory: currentClient.cargoCategory,
          cargoPrice: currentClient.cargoPrice,
          cargoWeight: currentClient.cargoWeight,
          loadingCountry: currentClient.loadingCountry,
          loadingRegion: currentClient.loadingRegion,
          loadingState: currentClient.loadingState,
          loadingPort: currentClient.loadingPort,
          loadingLocation: currentClient.loadingLocation,
          contacts: currentClient.contacts.map(contact => ({
            name: contact.name ? contact.name : null,
            title: contact.title ? contact.title : null,
            position: contact.position ? contact.position : null,
            department: contact.department ? contact.department : null,
            location: contact.location ? contact.location : null,
            phone: contact.phone ? contact.phone : null,
            email: contact.email ? contact.email : null,
            dateOfBirth: contact.dateOfBirth ? formatDateForBackend(contact.dateOfBirth) : null,
          })),
          financials: currentClient.financials.map(financial => ({
            bankName: financial.bankName ? financial.bankName : null,
            iban: financial.iban ? financial.iban : null,
            currencyId: financial.currencyId !== undefined ? financial.currencyId : null,
            branch: financial.branch ? financial.branch : null,
            bankAddress: financial.bankAddress ? financial.bankAddress : null,
          }))
        }
      }
    });
    return response.data.createClient;
  }

  async updateClient(currentClient: Client): Promise<Client> {
    const response = await client.mutate({
      mutation: gql`
        mutation updateClient($input: UpdateClientWithRelationsDto!){
            updateClient(input: $input){
              id
            }
        }
      `,
      variables: {
        input: {
          id: currentClient.id,
          company: currentClient.company,
          email: currentClient.email ? currentClient.email : null,
          phone: currentClient.phone ? currentClient.phone : null,
          gstNumber: currentClient.gstNumber ? currentClient.gstNumber : null,
          tradeId: currentClient.tradeId ? currentClient.tradeId : null,
          vat: currentClient.vat ? currentClient.vat : null,
          userId: currentClient.userId !== undefined ? currentClient.userId : null,
          priority: currentClient.priority ? currentClient.priority : null,
          type: currentClient.type ? currentClient.type : null,
          location: currentClient.location ? currentClient.location : null,
          activityType: currentClient.activityType,
          relationStatus: currentClient.relationStatus ? currentClient.relationStatus : null,
          level: currentClient.level,
          potential: currentClient.potential,
          isTransporting: currentClient.isTransporting,
          street: currentClient.street ? currentClient.street : null,
          building: currentClient.building ? currentClient.building : null,
          staircase: currentClient.staircase ? currentClient.staircase : null,
          city: currentClient.city ? currentClient.city : null,
          floor: currentClient.floor ? currentClient.floor : null,
          number: currentClient.number ? currentClient.number : null,
          apartment: currentClient.apartment ? currentClient.apartment : null,
          postalCode: currentClient.postalCode ? currentClient.postalCode : null,
          state: currentClient.state ? currentClient.state : null,
          region: currentClient.region ? currentClient.region : null,
          country: currentClient.country ? currentClient.country : null,
          website: currentClient.website ? currentClient.website : null,
          transportUsed: currentClient.transportUsed,
          transportDirection: currentClient.transportDirection,
          trade: currentClient.trade,
          equipmentUsed: currentClient.equipmentUsed,
          dryTransportMode: currentClient.dryTransportMode,
          cargoCategory: currentClient.cargoCategory,
          cargoPrice: currentClient.cargoPrice,
          cargoWeight: currentClient.cargoWeight,
          loadingCountry: currentClient.loadingCountry,
          loadingRegion: currentClient.loadingRegion,
          loadingState: currentClient.loadingState,
          loadingPort: currentClient.loadingPort,
          loadingLocation: currentClient.loadingLocation,
          contacts: currentClient.contacts.map(contact => ({
            id: contact.id ? contact.id : null,
            name: contact.name ? contact.name : null,
            title: contact.title ? contact.title : null,
            position: contact.position ? contact.position : null,
            department: contact.department ? contact.department : null,
            location: contact.location ? contact.location : null,
            phone: contact.phone ? contact.phone : null,
            email: contact.email ? contact.email : null,
            dateOfBirth: contact.dateOfBirth ? formatDateForBackend(contact.dateOfBirth) : null,
          })),
          financials: currentClient.financials.map(financial => ({
            id: financial.id ? financial.id : null,
            bankName: financial.bankName ? financial.bankName : null,
            iban: financial.iban ? financial.iban : null,
            currencyId: financial.currencyId !== undefined ? financial.currencyId : null,
            branch: financial.branch ? financial.branch : null,
            bankAddress: financial.bankAddress ? financial.bankAddress : null,
          }))
        }
      }
    });
    return response.data.updateClient;
  }

  async deleteClient(id: number): Promise<boolean> {
    const response = await client.mutate({
      mutation: gql`
        mutation {
          deleteClient(id: ${id})
        }
      `,
    });
    return response.data.deleteClient;
  }

  async getAnafData(gstNumber: string): Promise<Partial<Client>> {
    try {
        const response = await client.query({
          query: gql`
            query {
              getAnafData(input: {gstNumber: "${gstNumber}"}) {
                company
                vat
                phone
                street
                number
                city
                state
                country
              }
            }
          `,
      });
      if(response.data){
        return response.data.getAnafData;
      }
      throw response.errors![0].message;
    } catch (error) {
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 1500,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
      return {};
    }
  }

  async getClientWithContacts(id: number): Promise<Client> {
    const response = await client.query({
      query: gql`
        query {
          getClientWithContacts(id: ${id}) {
            id
            company
            contacts {
              id
              name
              email
              phone
            }
          }
        }
      `,
    });
    return response.data.getClientWithContacts;
  }
  
  async getFullClient(id: number): Promise<Client> {
    const response = await client.query({
      query: gql`
        query {
            client(id: ${id}) {
            id
            company
            email
            phone
            gstNumber
            tradeId
            vat
            userId
            priority
            type
            location
            activityType
            relationStatus
            level
            potential
            isTransporting
            street
            building
            staircase
            city
            floor
            number
            apartment
            postalCode
            state
            region
            country
            website
            transportUsed
            transportDirection
            trade
            equipmentUsed
            dryTransportMode
            cargoCategory
            cargoPrice
            cargoWeight
            loadingCountry
            loadingRegion
            loadingState
            loadingPort
            loadingLocation
            contacts{
              id
              name
              title
              position
              department
              location
              phone
              email
              dateOfBirth
            }
            financials{
              id
              bankName
              iban
              currencyId
              branch
              bankAddress
            }
            offers{
              id
              email
              type
              status
              validityStartDate
              validityEndDate
            }
            user{
              id
              name
              email
            }
          }
        }
      `,
    });
    const contacts = response.data.client.contacts.map((contact : any) => ({
      ...contact,
      dateOfBirth: contact.dateOfBirth ? new Date(contact.dateOfBirth) : null
    }));
    return {...response.data.client, contacts: contacts};
  }
}
