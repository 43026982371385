import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaRegChartBar } from "react-icons/fa";
import { LuActivitySquare } from "react-icons/lu";
import { TbUsers } from "react-icons/tb";
import CheckPermission from './CheckPermission';

const ClientsNavBar: React.FC = () => {
  return (
    <div className="hidden bg-softprimary md:flex items-center px-5 text-black text-[0.95rem] font-medium h-9 ps-[155px]">
      <CheckPermission permissions={['CLIENT_VIEW_ALL', 'CLIENT_VIEW']}>
        <NavLink to="/crm/clients/index" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
          <TbUsers className='h-4 w-4'/>
          <span className="mr-2">Clienți</span>
        </NavLink>
      </CheckPermission>
      <CheckPermission permissions={['ACTIVITY_VIEW_ALL', 'ACTIVITY_VIEW']}>
        <NavLink to="/crm/clients/activities" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
          <LuActivitySquare className='h-4 w-4'/>
          <span className="mr-2">Activitate</span>
        </NavLink>
      </CheckPermission>
      {/* <NavLink to="/crm/clients/raports" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
        <FaRegChartBar className='h-4 w-4'/>
        <span className="mr-2">Rapoarte</span>
      </NavLink> */}
    </div>
  );
};

export default ClientsNavBar;