import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IconType } from 'react-icons';
import CheckPermission from './CheckPermission';
import { LuActivitySquare, LuTable } from "react-icons/lu";
import { TbUsers } from "react-icons/tb";
import { IoMenu, IoClose } from "react-icons/io5";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { HiTemplate } from "react-icons/hi";
import { PiShippingContainerBold } from "react-icons/pi";
import { IoDocumentOutline } from 'react-icons/io5';
import { RiBillLine } from "react-icons/ri";
import { FaRegChartBar } from "react-icons/fa";
import logo from '../assets/logo.png';
import { IoIosOptions } from "react-icons/io";

interface MenuItemProps {
    id: string;
    title: string;
    to?: string;
    icon?: IconType;  // Acum folosim tipul corect pentru icon
    children?: React.ReactNode;
}

export default function MobileMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSubmenus, setOpenSubmenus] = useState<Set<string>>(new Set());

  const toggleSubmenu = (id: string) => {
    setOpenSubmenus(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const MenuItem = ({ 
    title, 
    to = "", 
    icon: Icon,
    children = null,
    id
  } : MenuItemProps) => {
    const hasSubmenu = Boolean(children);
    const isSubmenuOpen = openSubmenus.has(id);

    if (hasSubmenu) {
      return (
        <div className="border-b border-gray-200">
          <div 
            className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-50"
            onClick={() => toggleSubmenu(id)}
          >
            <div className="flex items-center gap-3">
              {Icon && <Icon className="w-5 h-5" />}
              <span className="font-medium">{title}</span>
            </div>
            {isSubmenuOpen ? 
              <FaChevronUp className="w-5 h-5 text-gray-500" /> : 
              <FaChevronDown className="w-5 h-5 text-gray-500" />
            }
          </div>
          
          {isSubmenuOpen && (
            <div className="bg-gray-50 pl-8">
              {children}
            </div>
          )}
        </div>
      );
    }

    return (
        <NavLink
            to={to}
            className={({ isActive }) => `
            flex items-center gap-3 p-4 hover:bg-gray-50 border-b border-gray-200
            ${isActive ? 'text-darkprimary font-medium' : 'text-gray-800'}
            `}
            onClick={() => setIsMenuOpen(false)}
        >
        {Icon && <Icon className="w-5 h-5" />}
        {title}
      </NavLink>
    );
  };

  const menuItems = () => (
    <>
        <CheckPermission permissions={['CLIENT_VIEW_ALL', 'CLIENT_VIEW', 'ACTIVITY_VIEW_ALL', 'ACTIVITY_VIEW']}>
            <MenuItem id="crm" title="CRM" >
                <CheckPermission permissions={['CLIENT_VIEW_ALL', 'CLIENT_VIEW']}>
                    <MenuItem
                        id="clienti"
                        title="Clienți"
                        to="/crm/clients/index"
                        icon={TbUsers}
                    />
                </CheckPermission>
                <CheckPermission permissions={['ACTIVITY_VIEW_ALL', 'ACTIVITY_VIEW']}>
                    <MenuItem
                        id="activitate"
                        title="Activitate"
                        to="/crm/clients/activities"
                        icon={LuActivitySquare}
                    />
                </CheckPermission>
            </MenuItem>
        </CheckPermission>
        <CheckPermission permissions={['OFFER_VIEW_ALL', 'OFFER_VIEW']}>
            <MenuItem id="offers" title="Oferte" >
                <CheckPermission permissions={['OFFER_VIEW', 'OFFER_VIEW_ALL']}>
                    <MenuItem
                        id="oferte"
                        title="Oferte"
                        to="/crm/offers/index"
                        icon={LuTable}
                    />
                </CheckPermission>
                <CheckPermission permissions={['OFFER_CREATE']}>
                    <MenuItem
                        id="adaugaOferta"
                        title="Adaugă ofertă"
                        to="/crm/offers/form"
                        icon={IoMdAddCircleOutline}
                    />
                </CheckPermission>
                <CheckPermission permissions={['OFFER_VIEW', 'OFFER_VIEW_ALL']}>
                    <MenuItem
                        id="sabloane"
                        title="Șabloane"
                        to="/crm/offers/templates"
                        icon={HiTemplate}
                    />
                </CheckPermission>
            </MenuItem>
        </CheckPermission>
        <CheckPermission permissions={['BOOKINGS_VIEW_ALL']}>
            <MenuItem id="bookings" title="Bookinguri" >
                <CheckPermission permissions={['BOOKINGS_VIEW_ALL']}>
                    <MenuItem
                        id="bookinguri"
                        title="Bookinguri"
                        to="/bookings/index"
                        icon={LuTable}
                    />
                </CheckPermission>
                <CheckPermission permissions={['MOVEMENTS_VIEW_ALL']}>
                    <MenuItem
                        id="movements"
                        title="Mișcări"
                        to="/bookings/movements"
                        icon={PiShippingContainerBold}
                    />
                </CheckPermission>
                <CheckPermission permissions={['BOOKINGS_VIEW_ALL']}>
                    <MenuItem
                        id="billChecks"
                        title="Verificări"
                        to="/bookings/billChecks"
                        icon={FaRegChartBar}
                    />
                </CheckPermission>
                <CheckPermission permissions={['MOVEMENT_FILES']}>
                    <MenuItem
                        id="movementsConfiguration"
                        title="Configurări mișcări"
                        to="/bookings/movementsConfiguration"
                        icon={PiShippingContainerBold}
                    />
                </CheckPermission>
                <CheckPermission permissions={['MANIFESTS_VIEW_ALL']}>
                    <MenuItem
                        id="manifests"
                        title="Manifeste"
                        to="/bookings/manifests"
                        icon={IoDocumentOutline}
                    />
                </CheckPermission>
                <CheckPermission permissions={['BOOKINGS_VIEW_ALL']}>
                    <MenuItem
                        id="bills"
                        title="B/L's"
                        to="/bookings/bills/index"
                        icon={RiBillLine}
                    />
                </CheckPermission>
            </MenuItem>
        </CheckPermission>
        <CheckPermission permissions={['SETTING_VIEW_ALL']}>
            <MenuItem
                id="recommandations"
                title="Recomandări"
                to="/settings/recommandations"
                icon={IoIosOptions}
            />
        </CheckPermission>
        <CheckPermission permissions={['USER_VIEW_ALL']}>
            <MenuItem
                id="users"
                title="Utilizatori"
                to="/hr/users/index"
                icon={TbUsers}
            />
        </CheckPermission>
        <CheckPermission permissions={['ROLE_VIEW_ALL']}>
            <MenuItem
                id="roluri"
                title="Roluri"
                to="/hr/roles/index"
                icon={LuTable}
            />
        </CheckPermission>
    </>
  );

  return (
    <>
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="md:hidden fixed top-2 right-4 z-50 p-2 rounded-lg bg-white shadow-md hover:bg-gray-50"
      >
        {isMenuOpen ? 
            <IoClose className="w-6 h-6" /> : 
            <IoMenu className="w-6 h-6" />
        }
      </button>

      <div
        className={`
          fixed top-0 left-0 w-full h-full bg-white shadow-xl transition-transform duration-300 ease-in-out transform md:hidden
          ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}
          z-40
        `}
      >
        <img src={logo} alt="Logo" className="absolute top-1 left-3 max-h-[50px]" />
        <div className="pt-16 pb-4 overflow-y-auto h-full">
          {menuItems()}
        </div>
      </div>

      <div 
        className={`
          fixed inset-0 bg-gray-800 bg-opacity-50 transition-opacity duration-300 md:hidden
          ${isMenuOpen ? 'opacity-100 z-30' : 'opacity-0 pointer-events-none'}
        `}
        onClick={() => setIsMenuOpen(false)}
      />
    </>
  );
}