import React, { lazy, Suspense, useCallback } from 'react';
import { Offer, OfferDetail } from '../../../interfaces/Offer';

interface SuprataxeSectionProps {
    offer: Offer;
    setOffer: React.Dispatch<React.SetStateAction<Offer>>;
    allOfferDetails: OfferDetail[];
}

const SuprataxeSection: React.FC<SuprataxeSectionProps> = ({ offer, setOffer, allOfferDetails }) => {
    const handleOfferDetailToggle = useCallback((detail: OfferDetail) => {
        setOffer(prev => {
            const isDetailIncluded = prev.offerDetails.some(d => d.id === detail.id);
            if (isDetailIncluded) {
                return {
                ...prev,
                offerDetails: prev.offerDetails.filter(d => d.id !== detail.id)
                };
            } else {
                if (!prev.offerDetails.some(d => d.id === detail.id)) {
                return {
                    ...prev,
                    offerDetails: [...prev.offerDetails, detail]
                };
                }
                return prev;
            }
        });
    }, []);

    return (
        <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
            <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Suprataxe incluse</h4>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 offer_quill">
                {allOfferDetails.map((detail) => (
                    detail.category === 'suprataxe_incluse' ? (
                        <div key={detail.id} className={`cursor-pointer flex items-center space-x-3 py-1 border px-2 border-gray-200 rounded-lg hover:bg-gray-50 hover:border-primary ${offer.offerDetails.some(d => d.id === detail.id) ? 'border-primary' : ''}`} onClick={() => handleOfferDetailToggle(detail)}>
                            <input
                                type="checkbox"
                                id={`detail-${detail.id}`}
                                checked={offer.offerDetails.some(d => d.id === detail.id)}
                                onChange={() => {}}
                                className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary dark:focus:ring-primary dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label className="flex-grow cursor-pointer">
                                <span className="text-sm font-medium text-gray-900 offerDetailElement" dangerouslySetInnerHTML={{__html: detail.name}}></span>
                            </label>
                        </div>
                    ) : ""
                ))}
            </div>  
        </div>
    );
};
export default SuprataxeSection;