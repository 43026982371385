import axios from 'axios';
import { User } from '../interfaces/User';

const API_URL = process.env.REACT_APP_GRAPHQL_URI!;

export class UserService {
  async getUsers(): Promise<User[]> {
    const response = await axios.post(API_URL, {
      query: `
        query {
          users {
            id
            name
            email
            function
            department
            roles{
              id
              name
            }
          }
        }
      `,
    });
    return response.data.data.users;
  }

  async deleteUser(id: number): Promise<boolean> {
    const response = await axios.post(API_URL, {
      query: `
        mutation {
          deleteUser(id: ${id})
        }
      `,
    });
    return response.data.data.deleteUser;
  }

  async create(user: User): Promise<User> {
    const response = await axios.post(API_URL, {
      query: `
        mutation {
            createUser(input: {
                name: "${user.name}",
                email: "${user.email}",
                function: ${user.function ? `"${user.function}"` : null},
                department: ${user.department ? `"${user.department}"` : null},
                password: "${user.password}"
            }) 
            {
              id
              name
              email
              function
              department
              roles{
                id
                name
              }
            }
        }
      `,
    });
    return response.data.data.createUser;
  }

  async update(user: User): Promise<User> {
    const response = await axios.post(API_URL, {
      query: `
        mutation {
            updateUser(input: {
                id: ${user.id},
                name: "${user.name}",
                email: "${user.email}",
                function: ${user.function ? `"${user.function}"` : null},
                department: ${user.department ? `"${user.department}"` : null},
                password: ${user.password ? `"${user.password}"` : null}
            })
            {
              id
              name
              email
              function
              department
              roles{
                id
                name
              }
            }
        }
      `,
    });
    return response.data.data.updateUser;
  }

  async getUserById(id: number): Promise<User> {
    const response = await axios.post(API_URL, {
      query: `
        query {
          user(id: ${id}) {
            id
            name
            email
            function
            department
          }
        }
      `,
    });
    return response.data.data.user;
  }
  
  async addRoleToUser(userId: number, roleId: number[]): Promise<User> {
    const response = await axios.post(API_URL, {
      query: `
        mutation {
            addRoleToUser(
                userId: ${userId},
                roleIds: [${roleId.map(tag => `${tag}`).join(', ')}]
            )
            {
              id
              name
              email
              function
              department
              roles{
                id
                name
              }
            }
        }
      `,
    });
    return response.data.data.addRoleToUser;
  }

}
