import React, { useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Booking, Container } from '../../../../interfaces/Booking';
import { CellRenderer } from '../../../../functions/agGridHelper';
import { AG_GRID_LOCALE_RO } from '../../../../functions/langHelper';
import Actions from '../../../../components/Actions';
import { BookingService } from '../../../../services/BookingService';
import { successMessage } from '../../../../functions/generalHelper';

interface ContainersTableProps {
  booking: Booking;
  setBooking: React.Dispatch<React.SetStateAction<Booking>>;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

const ContainersTable: React.FC<ContainersTableProps> = ({ booking, onEdit, onDelete, setBooking }) => {
    const bookingService = new BookingService();

    const modificaCelula = async (params : any, coloana : string) => {
      const updatedContainer = {
        ...booking.containers.find(cont => cont.id! === params.data.id!)!,
        [coloana!]: params.newValue
      };
    
      // Actualizăm containerul în backend
      const savedContainer = await bookingService.createBookingContainer(updatedContainer);
    
      // Actualizăm state-ul local cu noul container
      setBooking(prev => ({
        ...prev,
        containers: prev.containers.map(cont => 
          cont.id === savedContainer.id ? savedContainer : cont
        )
      }));
    
      successMessage('Container editat cu succes!');
    }

    const columns: ColDef<Container>[] = [
    { 
      headerName: 'Număr unic', 
      field: 'containerNumber', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      minWidth: 160,
      valueSetter: (params) => {
        modificaCelula(params, 'containerNumber');
        return params.newValue;
      }
    },
    { 
      headerName: 'Dimensiune',
      field: 'size',
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'size');
        return params.newValue;
      }
    },
    { 
      headerName: 'Tip', 
      field: 'type', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'type');
        return params.newValue;
      }
    },
    { 
      headerName: 'Număr sigiliu', 
      field: 'sealNumber', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'sealNumber');
        return params.newValue;
      }
    },
    { 
      headerName: 'B/L', 
      field: 'bookingBillId', 
      minWidth: 160,
      valueFormatter: (params) => booking.bookingBills?.find(bill => bill.id === params.value)?.code || '',
    },
    { 
      headerName: 'Tara', 
      field: 'tare', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'tare');
        return params.newValue;
      }
    },
    { 
      headerName: 'Zile libere', 
      field: 'freeDays', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'freeDays');
        return params.newValue;
      }
    },
    { 
      headerName: 'Greutate', 
      field: 'weight', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'weight');
        return params.newValue;
      }
    },
    { 
      headerName: 'Pachete', 
      field: 'packageNumber', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'packageNumber');
        return params.newValue;
      }
    },
    { 
      headerName: 'Volum', 
      field: 'volume', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'volume');
        return params.newValue;
      }
    },
    { 
      headerName: 'IMO', 
      field: 'dangerCargo', 
      editable: true,
      valueSetter: (params) => {
        modificaCelula(params, 'dangerCargo');
        return params.newValue;
      }
    },
    {
      headerName: 'Acțiuni',
      width: 150, 
      maxWidth: 150,
      field: 'id',
      cellRenderer: (params: any) => (
        <div>
          <Actions 
            id={params.data.id} 
            onEdit={() => onEdit(params.data.id)} 
            onDelete={() => onDelete(params.data.id)} 
            moduleName="container"
          />
        </div>
      )
    }
  ];

  return (
    <div className="ag-theme-quartz">
      <AgGridReact 
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={booking.containers}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={false}
        gridOptions={{rowHeight: 35}}
        enableRangeSelection={true}
        suppressClickEdit={true}
        stopEditingWhenCellsLoseFocus={true}
      />
    </div>
  );
};

export default ContainersTable;