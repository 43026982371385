import client from '../apollo-client';
import { gql } from '@apollo/client';
import { formatDateForBackend } from '../functions/dateHelper';

export class MovementReportService {
    async getAllRows(): Promise<any[]> {
        const response = await client.query({
            query: gql`
                query {
                    movementReports {
                        id
                        date
                        sentDate
                        filename
                    }
                }
            `,
        });
        return response.data.movementReports;
    }

    async generateReportCSV(movements : number[], type: number, send: boolean): Promise<{ status: string; message: string }> {
        const response = await client.mutate({
            mutation: gql`
                mutation createMovementReport($input: CreateMovementReportDto!) {
                    createMovementReport(input: $input) {
                        status
                        message
                    }
                }
            `,
            variables: {
                input: {
                    movements,
                    type,
                    send
                }
            }
        });
        return response.data.createMovementReport;
    }

    async sendReportCSV(reportId: number): Promise<{ status: string; message: string }> {
        try {
            const response = await client.mutate({
                mutation: gql`
                    mutation {
                        sendReportCSV(id: ${reportId}) {
                            status
                            message
                        }
                    }
                `,
            });
            return response.data.sendReportCSV;
        } catch (error) {
            return { status: 'error', message: 'An error occurred while sending the report.' };
        }
    }

    async cancelMovementsReports(ids : number[]): Promise<{ status: string; message: string }> {
        const response = await client.mutate({
            mutation: gql`
                mutation cancelMovementsReports($ids: [Int!]!) {
                    cancelMovementsReports(ids: $ids) {
                        status
                        message
                    }
                }
            `,
            variables: {
                ids
            }
        });
        return response.data.cancelMovementsReports;
    }
}