import React, { useState, useEffect } from 'react';
import { RecommandationService } from '../../services/RecommandationService';
import { defaultRecommendation, Recommendation, typeOptions } from '../../interfaces/Recommandation';
import { successMessage } from '../../functions/generalHelper';
import { Button, Modal } from 'flowbite-react';
import Input from '../../components/Input';
import CustomSelect from '../../components/CustomSelect';
import { handleInputChange, handleSelectChange } from '../../functions/selectHelper';

interface ModalFormProps { 
  id: number|null,
  slug: string,
  name: string,
  onSubmit: (recommendation : Recommendation, create : boolean) => void,
  openModal: boolean,
  setOpenModal: (openModal: boolean) => void;
}
const ModalForm: React.FC<ModalFormProps> = ({id, slug, name, onSubmit, openModal, setOpenModal}) => {
  const [recommendation, setRecommendation] = useState<Recommendation>(defaultRecommendation);
  const recommandationService = new RecommandationService();

  useEffect(() => {
    if (id) {
      recommandationService.getRecommendationById(id).then((data) => setRecommendation(data));
    }else{
      setRecommendation((prev) => ({...defaultRecommendation, categorySlug: slug, categoryName: name}));
    }
  }, [id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (id) {
      const recommendationResponse = await recommandationService.updateRecommendation(recommendation);
      setOpenModal(false);
      successMessage('Recomandare editată cu success!');
      onSubmit(recommendationResponse, false)
    } else {
      const recommendationResponse = await recommandationService.createRecommendation(recommendation);
      setOpenModal(false);
      successMessage('Recomandare creată cu success!');
      onSubmit(recommendationResponse, true)
    }
  };

  return (
    <>
      <Modal show={openModal} size="lg" popup onClose={() => setOpenModal(false)}>
        <Modal.Header>
            {id ? 'Editează recomandare' : 'Adaugă recomandare'} - {recommendation.categoryName}
        </Modal.Header>
        <Modal.Body className="overflow-visible">
            <div className="space-y-2 pt-5">
                <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
                    <div className="mb-1">
                      <Input 
                          id="label" 
                          label="Titlu"
                          value={recommendation.label}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(recommendation, setRecommendation, 'label', e.target.value, defaultRecommendation)}
                      />
                    </div>
                    <div className="mb-1">
                      <Input 
                          id="value" 
                          label="Valoare"
                          value={recommendation.value}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(recommendation, setRecommendation, 'value', e.target.value, defaultRecommendation)}
                      />
                    </div>
                    <div className="mb-1">
                        <CustomSelect
                            value={typeOptions.find(type => type.value === recommendation.type)}
                            onChange={(data) => handleSelectChange(recommendation, setRecommendation, 'type', data)}
                            options={typeOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Tip"
                        />
                    </div>
                    <div className="flex justify-end">
                        <Button size="sm" type="submit">Salvează</Button>
                    </div>
                </form>
            </div>
        </Modal.Body>
    </Modal>
    </>
  );
};

export default ModalForm;