import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { createRoot } from 'react-dom/client';
import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

interface DateTimeEditorProps extends ICellEditorParams {
  value: Date | null;
  onValueChange: (value: Date | null) => void;
}

const DateTimeEditorComponent = forwardRef((props: DateTimeEditorProps, ref) => {
  const [value, setValue] = useState<Dayjs | null>(props.value ? dayjs(props.value) : null);
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useImperativeHandle(ref, () => ({
    getValue() {
      return value ? value.toDate() : null;
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },
    focusIn() {
      inputRef.current?.focus();
    },
    focusOut() {
      props.stopEditing();
    },
  }));

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      props.stopEditing();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div ref={inputRef} onKeyDown={onKeyDown} className='agGrid-timepicker'>
        <DateTimeField
          value={value}
          onChange={handleChange}
          format="DD.MM.YYYY HH:mm"
          onBlur={() => props.stopEditing()}
          autoFocus={true}
        />
      </div>
    </LocalizationProvider>
  );
});

interface EditorState {
  isEditing: boolean;
  params: ICellEditorParams | null;
}

export default class DateTimeEditor implements ICellEditorComp {
  private container: HTMLDivElement | null = null;
  private root: any = null;
  private state: EditorState = { isEditing: false, params: null };
  private editorRef = React.createRef<any>();

  init(params: ICellEditorParams) {
    this.state = { isEditing: true, params };
    this.render();
  }

  getGui() {
    if (!this.container) {
      this.container = document.createElement('div');
      this.container.className = 'agGrid-timepicker';
      this.root = createRoot(this.container);
      this.render();
    }
    return this.container;
  }

  getValue() {
    return this.editorRef.current?.getValue();
  }

  afterGuiAttached() {
    this.editorRef.current?.focusIn();
  }

  isPopup() {
    return false;
  }

  destroy() {
    this.state = { ...this.state, isEditing: false };
    this.render();
  }

  private render() {
    if (!this.root) return;

    this.root.render(
      this.state.isEditing && this.state.params ? (
        <DateTimeEditorComponent
          {...this.state.params}
          ref={this.editorRef}
          onValueChange={(value) => {
            if (this.state.params?.node && this.state.params?.column) {
              this.state.params.node.setDataValue(this.state.params.column.getColId(), value);
            }
          }}
        />
      ) : null
    );

    if (!this.state.isEditing) {
      setTimeout(() => {
        if (this.root) {
          this.root.unmount();
          this.root = null;
        }
        if (this.container) {
          this.container.remove();
          this.container = null;
        }
      }, 0);
    }
  }
}