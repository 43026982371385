import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs } from 'flowbite-react';
import { HiClipboardList, HiUserCircle, HiTruck, HiDocumentText, HiCube, HiCurrencyDollar } from 'react-icons/hi';
import { BookingService } from '../../services/BookingService';
import { Booking, defaultBooking } from '../../interfaces/Booking';
import General from './viewTabs/General';
import Movements from './viewTabs/Movements';
import Bills from './viewTabs/Bills';
import Manifest from './viewTabs/Manifest';
import Containers from './viewTabs/Containers';
import Invoices from './viewTabs/Invoices';
import Costs from './viewTabs/Costs';
import BookingProgress from './viewTabs/components/BookingProgress';
import renderSkeleton from '../../components/SkeletonLoader';

const View: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [booking, setBooking] = useState<Booking>(defaultBooking);
  const bookingService = new BookingService();

  useEffect(() => {
    fetchBooking();
  }, [id]);

  const fetchBooking = () => {
    if (id) {
      bookingService.getBookingById(parseInt(id)).then((data) => {setBooking(data); setLoading(false);});
    }
  }

  return loading ? renderSkeleton() : (
    <div className="px-3">
      <h2 className="text-xl font-bold mb-1 mt-1">Detalii Booking - {booking.number}</h2>
      <Tabs aria-label="Booking tabs">
        <Tabs.Item active title="General" icon={HiClipboardList}>
          <General booking={booking} onSave={fetchBooking} />
        </Tabs.Item>
        <Tabs.Item title="Mișcări" icon={HiTruck}>
          <Movements booking={booking} />
        </Tabs.Item>
        <Tabs.Item title="B/L's" icon={HiUserCircle}>
          <Bills booking={booking} setBooking={setBooking}/>
        </Tabs.Item>
        <Tabs.Item title="Marfa" icon={HiDocumentText}>
          <Manifest booking={booking} setBooking={setBooking} />
        </Tabs.Item>
        <Tabs.Item title="Containere" icon={HiCube}>
          <Containers booking={booking} setBooking={setBooking} />
        </Tabs.Item>
        <Tabs.Item title="Facturi" icon={HiCurrencyDollar}>
          <Invoices />
        </Tabs.Item>
        <Tabs.Item title="Costuri" icon={HiCurrencyDollar}>
          <Costs />
        </Tabs.Item>
      </Tabs>
    </div>
  );
};

export default View;