import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { BuildRule, filterOptions, MovementLogic } from '../../../interfaces/Movement';
import { MovementFileService } from '../../../services/MovementFileService';
import { ColDef } from 'ag-grid-community';
import Actions from '../../../components/Actions';
import Tooltip from '../../../components/Tooltip';
import { Button } from 'flowbite-react';
import renderSkeleton from '../../../components/SkeletonLoader';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_RO } from '../../../functions/langHelper';
import DeleteModal from '../../../components/DeleteModal';
import ModalLogic from './ModalLogic';
import { isNullOrEmpty } from '../../../functions/generalHelper';

const View: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [logicToEdit, setLogicToEdit] = useState<number>(0);
  const [movementLogics, setMovementLogics] = useState<MovementLogic[]>([]);
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const movementFileService = new MovementFileService();
  
  useEffect(() => {
    if(id){
        fetchMovementLogics();
    }
  }, []);

  const fetchMovementLogics = useCallback(
      async () => {
          setLoading(true);
          const data = await movementFileService.getMovementLogic(parseInt(id!));
          setMovementLogics(data);
          setLoading(false);
      },
    [],
  )
  

  const onCreate = useCallback(
      () => {
          setLogicToEdit(0);
          setShowModalCreate(true);
      },
    [logicToEdit, showModalCreate],
  )

  const onEdit = useCallback(
      (id: number) => {
          setLogicToEdit(id);
          setShowModalCreate(true);
      },
    [logicToEdit, showModalCreate],
  )

  const onDelete = useCallback(
      (id: number) => {
          setLogicToEdit(id);
          setShowModalDelete(true);
      },
    [logicToEdit, showModalDelete],
  )

  const onSave = useCallback(
      () => {
          if(id){
            fetchMovementLogics();
          }
      },
    [logicToEdit, showModalDelete],
  )

  const confirmDelete = useCallback(
      () => {
          // delete din service logicToEdit
          setLogicToEdit(0);
          setShowModalDelete(false);
          // successMessage
      },
    [logicToEdit],
  )

  const columns: ColDef<MovementLogic>[] = useMemo(() => [
      { headerName: 'Id', field: 'id', sortable: true, filter: 'agNumberColumnFilter', floatingFilter: true, width: 110, maxWidth: 110},
      { headerName: 'Denumire', field: 'name', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true },
      { headerName: 'Detalii', field: 'description', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true },
      { 
        headerName: 'Logică', 
        field: 'build', 
        sortable: true, 
        filter: 'agTextColumnFilter', 
        floatingFilter: true,
        cellRenderer: (params: any) => {
          return <div>
            {
              params.value.map((build : BuildRule) => (
                <div className='h-[15px]'>
                  {build.header} - {filterOptions.find(obj => obj.value == build.filter)!.label} {isNullOrEmpty(build.text) ? `` : ` - ${build.text}`}
                </div>
              ))
            }
          </div>;
        }
      },
      {
          headerName: 'Acțiuni',
          width: 150, 
          maxWidth: 150,
          field: 'id',
          cellRenderer: (params: any) => (
            <div>
              <Actions 
                id={params.data.id} 
                onEdit={() => onEdit(params.data.id)} 
                onDelete={() => onDelete(params.data.id)} 
                moduleName="mișcare"
              />
            </div>
          )
        }
  ], []);

  return (
    <div className="ag-theme-quartz px-4">
        <div className="flex justify-between items-center py-2">
            <div className="text-lg font-bold">
                Fișiere mișcări
            </div>
            
            <Tooltip content="Adaugă fișier">
                <Button size="sm" onClick={onCreate}>Adaugă fișier</Button>
            </Tooltip>

        </div>
        {loading ? ( renderSkeleton() ) : (
            <>
                <AgGridReact 
                    localeText={AG_GRID_LOCALE_RO}
                    columnDefs={columns} 
                    rowData={movementLogics}
                    defaultColDef={{ flex: 1, minWidth: 100 }}
                    domLayout='autoHeight'
                    pagination={false}
                    gridOptions={{rowHeight: 70}}
                />
                <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={confirmDelete} deleteText='Sunteți sigur că doriți să eliminați aceasta miscare?' />
                <ModalLogic movementFileId={id ? parseInt(id) : 0} showModal={showModalCreate} setShowModal={setShowModalCreate} id={logicToEdit} onSave={onSave} />
            </>
        )}
    </div>
  );
};

export default View;