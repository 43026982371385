import React, { useEffect, useState } from 'react';
import { Button, Modal, TextInput } from "flowbite-react";
import { ActivityService } from '../../services/ActivityService';
import { errorMessage, successMessage } from '../../functions/generalHelper';
import { HiOutlineExclamationCircle, HiX } from 'react-icons/hi';
import { Activity } from '../../interfaces/Activity';
import Input from '../../components/Input';

interface CloseTaskModalProps {
    activity: Activity | null;
    openModal: boolean;
    setOpenModal: (openModal: boolean) => void;
    onClosed: (task: Activity) => void;
}

const CloseTaskModal: React.FC<CloseTaskModalProps> = ({ activity, openModal, setOpenModal, onClosed }) => {
    const [closureReason, setClosureReason] = useState('');
    const activityService = new ActivityService();

    useEffect(() => {setClosureReason('')}, [openModal]);

    const handleCloseTask = async () => {
        try {
            if(activity){
                const updatedActivity = await activityService.closeActivityWithReason(activity.id!, closureReason);
                setOpenModal(false);
                successMessage('Activitate închisă cu succes!');
                onClosed(updatedActivity);
            }
        } catch (error) {
            errorMessage('Eroare la închiderea activității:');
        }
    };

    return (
        <Modal
            show={openModal}
            size="md"
            popup
            onClose={() => setOpenModal(false)} >
            <Modal.Body>
                <div className="relative">
                    <button
                        onClick={() => setOpenModal(false)}
                        className="absolute top-2 right-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    >
                        <HiX className="w-5 h-5" />
                    </button>
                    <div className="text-center pt-5">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Vrei să închizi această activitate?
                        </h3>
                        <div className="mb-4">
                            <Input
                                id="closureReason" 
                                type="text"
                                label="Motivul închiderii"
                                value={closureReason}
                                onChange={(e) => setClosureReason(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={handleCloseTask}>
                                Da, închide activitatea
                            </Button>
                            <Button color="gray" onClick={() => setOpenModal(false)}>
                                Nu, anulează
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CloseTaskModal;