import React, { useState, useEffect, useCallback } from 'react';
import { Button, Tabs } from "flowbite-react";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Booking, defaultBooking } from '../../interfaces/Booking';
import { BookingService } from '../../services/BookingService';
import { RecommendationResp } from '../../interfaces/Recommandation';
import { errorMessage, isNullOrEmpty, successMessage } from '../../functions/generalHelper';
import { HiUserCircle, HiClipboardList } from "react-icons/hi";
import { MdDashboard } from "react-icons/md";
import GeneralTab from './formTabs/GeneralTab';
import RouteTab from './formTabs/RouteTab';
import ClientTab from './formTabs/ClientTab';
import ContainersTab from './formTabs/ContainersTab';
import CargoManifestTab from './formTabs/CargoManifestTab';
import { RecommandationService } from '../../services/RecommandationService';
import renderSkeleton from '../../components/SkeletonLoader';
import { useUser } from '../../components/UserContext';

const Form: React.FC = () => {
    const { id } = useParams<{ id?: string }>();
    const [loading, setLoading] = useState<boolean>(true);
    const [booking, setBooking] = useState<Booking>(defaultBooking);
    const bookingService = new BookingService();
    const navigate = useNavigate();
    const [recommendations, setRecommendations] = useState<RecommendationResp[]>([]);
    const recommendationsService = new RecommandationService();
    const [tableKey, setTableKey] = useState(0);
    const { user } = useUser();
    
    const refreshTable = useCallback(
      () => {
        setTableKey(prevKey => prevKey + 1);
      },[]
    );

    // GET ALL OPTION VALUES AND FIELD RECOMMENDATIONS
    useEffect(() => {
      recommendationsService.getRecommendations().then(data => setRecommendations(data));
    }, []);
  
    useEffect(() => {
      if (id) {
        bookingService.getBookingById(parseInt(id)).then((data) => {setBooking(data); setLoading(false)});
      }else{
        setBooking({...defaultBooking, containers: [], cargoManifests: [], routes: [], createdBy: user ? user.id : defaultBooking.createdBy});
        setLoading(false);
      }
    }, [id]);

    const validateBooking = useCallback(
      () => {
          // const validNames = booking.containers
          // .map(c => c.containerNumber?.trim())
          // .filter(Boolean);
      
          // if (new Set(validNames).size !== validNames.length) {
          //   throw new Error('Numele containerelor trebuie să fie unice!');
          // }
          
          if(isNullOrEmpty(booking.number)){
            throw new Error('Numărul bookingului este obligatoriu!');
          }

          if(isNullOrEmpty(booking.status)){
            throw new Error('Statusul bookingului este obligatoriu!');
          }
          
          if(!booking.creationDate){
            throw new Error('Data bookingului este obligatorie!');
          }
      },
      [booking]
    );
    
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        validateBooking();

        if (id) {
          await bookingService.update(booking);
          successMessage('Booking editat cu succes!');
        } else {
          await bookingService.create(booking);
          successMessage('Booking adăugat cu succes!');
        }
        navigate('/bookings/index');
      } catch (error) {
        errorMessage(error instanceof Error ? error.message : 'Vă rugăm să completați toate câmpurile obligatorii!');
      }
    }

    return loading ? renderSkeleton() : (
        <>
            <div className='px-3'>
                <form onSubmit={handleSubmit}>
                    {
                      id ? <>
                        <Tabs aria-label="Tabs with icons" className="pt-2">
                          <Tabs.Item active title="Date generale" icon={HiUserCircle}>
                            <GeneralTab booking={booking} setBooking={setBooking} recommendations={recommendations} />
                          </Tabs.Item>
                          {/* <Tabs.Item title="Client" icon={HiClipboardList}>
                            <ClientTab booking={booking} setBooking={setBooking} recommendations={recommendations} />
                          </Tabs.Item> */}
                        </Tabs>
                      </> : <>
                        <Tabs aria-label="Tabs with icons" className="pt-2">
                          <Tabs.Item active title="Date generale" icon={HiUserCircle}>
                            <GeneralTab booking={booking} setBooking={setBooking} recommendations={recommendations} />
                          </Tabs.Item>
                          <Tabs.Item title="Ruta" icon={MdDashboard}>
                            <RouteTab booking={booking} setBooking={setBooking} recommendations={recommendations} />
                          </Tabs.Item>
                          {/* <Tabs.Item title="Client" icon={HiClipboardList}>
                            <ClientTab booking={booking} setBooking={setBooking} recommendations={recommendations} />
                          </Tabs.Item>
                          <Tabs.Item title="Containere" icon={HiClipboardList}>
                            <ContainersTab booking={booking} setBooking={setBooking} recommendations={recommendations} tableKey={tableKey} refreshTable={refreshTable} />
                          </Tabs.Item>
                          <Tabs.Item title="Cargo Manifest" icon={HiClipboardList}>
                            <CargoManifestTab booking={booking} setBooking={setBooking} recommendations={recommendations} />
                          </Tabs.Item> */}
                        </Tabs>
                      </>
                    }

                    <div className="flex justify-end space-x-4 mt-3">
                      <NavLink to="/bookings/index">
                        <Button size="sm" color="gray">Înapoi</Button>
                      </NavLink>
                      <Button size="sm" type="submit">Salvează</Button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Form;