import React, { useCallback, useEffect, useState } from 'react';
import Input from '../../components/Input';
import { handleInputChange } from '../../functions/selectHelper';
import { defaultRole, Permissions, Role } from '../../interfaces/Role';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { RoleService } from '../../services/RoleService';
import { Button } from 'flowbite-react';
import { successMessage } from '../../functions/generalHelper';

interface FormProps {
}

const Form: React.FC<FormProps> = () => {
  const { id } = useParams<{ id?: string }>();
  const [role, setRole] = useState<Role>(defaultRole);
  const [permissions, setPermissions] = useState<Permissions[]>([]);
  const roleService = new RoleService();
  const navigate = useNavigate();

  useEffect(() => {
      roleService.getPermissions().then((data) => {
        setPermissions(data)
        setRole(prev => ({...prev, permissions: data.map(permission => permission.id)}))
      });
  }, []);

  useEffect(() => {
    if (id) {
      roleService.getRoleById(parseInt(id)).then((data) => setRole(data));
    }
  }, [id]);

  const handlePermissionChange = useCallback(
    (id : number) => {
      if(role.permissions.includes(id)){
        setRole(prev => ({...prev, permissions: prev.permissions.filter((permission) => permission != id)}))
      }else{
        setRole(prev => ({...prev, permissions: [...prev.permissions, id]}))
      }
    }, [role.permissions]);

  const handleSubmit = async () => {
    if (id) {
      await roleService.update(role);
      successMessage('Rolul a fost editat cu success!');
    } else {
      await roleService.create(role);
      navigate('/hr/roles/index');
    }
  }

  return (
    <div className='p-4'>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
        <div className="sm:col-span-1 md:col-span-3 lg:col-span-3">
          <Input 
            id="name" 
            label="Denumire"
            value={role.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(role, setRole, 'name', e.target.value, defaultRole)}
          />
        </div>
        
        {
          Array.from(new Set(permissions.map(item => item.category))).map(category => 
          (
            <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
              <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-1/2 transform -translate-x-1/2">{ category }</h4>
              <div className="space-y-5">
                { permissions.filter(permission => permission.category == category).map(permission => (
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id={`permissions${permission.id}`}
                        key={`permissions${permission.id}`}
                        onChange={() => { handlePermissionChange(permission.id)}}
                        type="checkbox"
                        checked={role.permissions.includes(permission.id)}
                        aria-describedby="comments-description"
                        className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label htmlFor={`permissions${permission.id}`} className="font-medium text-gray-900 cursor-pointer">
                        {permission.beautifulName}
                      </label><br />
                      <label htmlFor={`permissions${permission.id}`} className="text-gray-500 cursor-pointer">
                        {permission.description}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        }
      </div>
      <div className="flex justify-end space-x-4 mt-3 mb-5">
          <NavLink to="/hr/roles/index">
              <Button size="sm" color="gray">Înapoi</Button>
          </NavLink>
          <Button size="sm" type="button" onClick={() => {handleSubmit()}}>Salvează</Button>
      </div>
    </div>
  );
};

export default Form;
