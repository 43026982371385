import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Client } from '../../../interfaces/Client';
import Table from '../../offers/Table';
import Index from '../../offers/Index';

interface OffersProps {
  client: Client | null;
}

const Offers: React.FC<OffersProps> = ({ client }) => {
//   const { id } = useParams<{ id?: string }>();
//   const [component, setComponent] = useState<Component>(defaultComponent);
//   const componentService = new ComponentService();
    if(client){
        if(client.offers)
        {
            return (
                <>
                    <Index isTemplate={false} clientId={client.id}/>
                </>
            );
        }
    }
    return (<></>);

};

export default Offers;