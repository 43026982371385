import React, { ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';
import 'tailwindcss/tailwind.css';

interface TooltipProps {
  content: string;
  children: ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
  const [visible, setVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const showTooltip = (e: React.MouseEvent) => {
    const rect = (e.target as HTMLElement).getBoundingClientRect();
    setTooltipPosition({
      top: rect.top + window.scrollY - 50,
      left: rect.left + rect.width / 2 - 50
    });
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {visible && createPortal(
        <div className="absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-sm transition-opacity duration-300 bg-gray-900 text-white dark:bg-gray-700" style={{ position: 'absolute', top: `${tooltipPosition.top-10}px`, left: `${tooltipPosition.left}px` }}><div className="relative z-20">{content}</div><div className="absolute hidden z-10 h-2 w-2 rotate-45 bg-gray-900 dark:bg-gray-700 left-1/2 -translate-x-1/2 bottom-[-4px]">&nbsp;</div></div>,
        document.body
      )}
    </div>
  );
};

export default Tooltip;
