import Spinner from './Spinner';

interface LoadingOverlayProps {
  isLoading: boolean;
  zIndex?: number;
  bgColor?: string;
}

export default function Loader({ 
  isLoading, 
  zIndex = 10000,
  bgColor = 'rgba(0,0,0,0.4)'
}: LoadingOverlayProps) {
  if (!isLoading) return null;

  return (
    <div 
      className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center h-full"
      style={{
        zIndex,
        backgroundColor: bgColor
      }}
    >
      <Spinner />
    </div>
  );
}