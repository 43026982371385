import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button } from 'flowbite-react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ColumnState, GridReadyEvent, TextMatcherParams } from 'ag-grid-community';
import { Movement } from '../../../interfaces/Movement';
import { MovementService } from '../../../services/MovementService';
import Tooltip from '../../../components/Tooltip';
import { AG_GRID_LOCALE_RO } from '../../../functions/langHelper';
import { createDateFromString, formatDate } from '../../../functions/dateHelper';
import { successMessage } from '../../../functions/generalHelper';

interface MovementsProps {
  booking: any;  // Replace 'any' with your Booking interface
}

const Movements: React.FC<MovementsProps> = ({ booking }) => {
  const [movements, setMovements] = useState<Movement[]>([]);
  const movementService = new MovementService();

  useEffect(() => {
    if (booking && booking.movements) {
      setMovements(booking.movements);
    }
  }, [booking]);

  const createNextMovement = async (movement: Movement, movementName: string) => {
    await movementService.createMovement({...movement, movement: movementName, date: new Date()});
    await movementService.changeAction(movement.id!);
    successMessage('Mișcare adaugata cu succes!');
    // You might want to refresh the booking data here
  }

  const formatNextMovement = (movement: Movement) => {
    if(movement.action){
      return <></>;
    }
    switch (movement.movement) {
      case 'DF':
        return <button className="customMovementButton" onClick={() => createNextMovement(movement, 'FC')} >FC</button>;
      case 'FC':
        return <>
          <button className="customMovementButton" onClick={() => createNextMovement(movement, 'RE')} >RE</button>
          <button className="customMovementButton ms-2" onClick={() => createNextMovement(movement, 'IE')} >IE</button>
        </>;
      case 'RE':
        return <button className="customMovementButton" onClick={() => createNextMovement(movement, 'ES')} >ES</button>;
      case 'IE':
        return <button className="customMovementButton" onClick={() => createNextMovement(movement, 'FL')} >FL</button>;
      case 'ES':
        return <button className="customMovementButton" onClick={() => createNextMovement(movement, 'FL')} >FL</button>;
      case 'FL':
        return <button className="customMovementButton" onClick={() => createNextMovement(movement, 'OF')} >OF</button>;
      case 'DE':
        return <button className="customMovementButton" onClick={() => createNextMovement(movement, 'ES')} >ES</button>;
      default:
        return <></>;
    }
  }

  const onGridReady = useCallback((event: GridReadyEvent) => {
    const columnState: ColumnState[] = [
      { colId: 'id', sort: 'desc', sortIndex: 0 }
    ];
    event.api.applyColumnState({ state: columnState, defaultState: { sort: null } });
  },[]);

  const columns: ColDef<Movement>[] = useMemo(() => [
    { headerName: 'Id', field: 'id', sortable: true, filter: 'agNumberColumnFilter', floatingFilter: true, width: 110, maxWidth: 110},
    { headerName: 'Container', field: 'containerCode', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true },
    { headerName: 'Mișcare', field: 'movement', sortable: true, filter: 'agSetColumnFilter', floatingFilter: true },
    { headerName: 'Terminal', field: 'terminal', sortable: true, filter: 'agSetColumnFilter', floatingFilter: true },
    {
      headerName: 'Dată',
      field: 'date',
      sort: 'asc',
      sortable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
        textMatcher: (params: TextMatcherParams) => {
          if(params.filterText === null || params.filterText.trim() === ''){
            return false;
          }
          const dateString = formatDate(createDateFromString(params.value));
          if(dateString==null){
            return false;
          }
          switch (params.filterOption) {
            case 'contains':
              return dateString.indexOf(params.filterText) >= 0;
            case 'notContains':
              return dateString.indexOf(params.filterText) < 0;
            case 'equals':
              return dateString === params.filterText;
            case 'notEqual':
              return dateString != params.filterText;
            case 'startsWith':
              return dateString.indexOf(params.filterText) === 0;
            case 'endsWith':
              const index = dateString.lastIndexOf(params.filterText);
              return index >= 0 && index === (dateString.length - params.filterText.length);
            default:
              return false;
          }
        }
      },
      minWidth: 170,
      cellRenderer: (params : any) => <>{formatDate(createDateFromString(params.value))}</>,
      comparator: (valueA, valueB) => {
        const dateA = createDateFromString(valueA)?.getTime();
        const dateB = createDateFromString(valueB)?.getTime();
        return dateA && dateB ? dateA - dateB : 0;
      },
      
    },
    { headerName: 'Booking', field: 'booking', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true },
    { headerName: 'Client', field: 'client', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true },
    { headerName: 'Dimensiune', field: 'size', sortable: true, filter: 'agSetColumnFilter', floatingFilter: true },
    { headerName: 'Tip', field: 'type', sortable: true, filter: 'agSetColumnFilter', floatingFilter: true },
    { headerName: 'Vas', field: 'vesselName', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true },
    { 
      headerName: 'Următoarea mișcare', 
      field: 'movement', 
      sortable: true, 
      filter: 'agSetColumnFilter', 
      floatingFilter: true,
      cellRenderer: (params : any) => <>{formatNextMovement(params.data)}</>,
    },
  ], []);

  return (
    <div className="ag-theme-quartz px-4">
      <div className="flex justify-between items-center py-2">
        <div className="text-lg font-bold">
          Mișcări
        </div>
        
        <Tooltip content="Adaugă mișcare">
          <Button size="sm" onClick={() => {/* Implement add movement logic */}}>Adaugă mișcare</Button>
        </Tooltip>
      </div>
      
      <AgGridReact 
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={movements}
        defaultColDef={{ flex: 1, minWidth: 100 }}
        domLayout='autoHeight'
        pagination={false}
        onGridReady={onGridReady}
        gridOptions={{rowHeight: 35}}
      />
    </div>
  );
};

export default Movements;