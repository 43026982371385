import React, { useState, useEffect } from 'react';
import { Button, Modal } from "flowbite-react";
import { Offer, OfferTemplate } from '../../interfaces/Offer';
import { OfferService } from '../../services/OfferService';
import { errorMessage } from '../../functions/generalHelper';
import CustomSelect from '../../components/CustomSelect';
import { useUser } from '../../components/UserContext';

interface ModalProps {
    offer: Offer;
    setOffer: React.Dispatch<React.SetStateAction<Offer>>;
    openModal: boolean,
    setOpenModal: (openModal: boolean) => void;
}

const TemplatesModal: React.FC<ModalProps> = ({ offer, setOffer, openModal, setOpenModal }) => {
    const [templates, setTemplates] = useState<OfferTemplate[]>([]);
    const [templateId, setTemplateId] = useState<number | undefined>(undefined);
    const [templateType, setTemplateType] = useState<string>('personal'); // personal / all
    const templateOptions = templates.map(template => ({value: template.id, label: template.email}));
    const offerService = new OfferService();
    const { user } = useUser();

    useEffect(() => {
        setTemplateId(undefined);
    }, [openModal]);

    useEffect(() => {
        if(user && openModal){
            offerService.getOfferTemplates(templateType, user!.id!).then((data) => {
                setTemplates(data);
            });
        }else{
            setTemplateType('personal');
        }
    }, [openModal, user]);

    useEffect(() => {
        if(user && openModal){
            offerService.getOfferTemplates(templateType, user!.id!).then((data) => {
                setTemplates(data);
            });
        }
    }, [templateType]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if(!templateId){
            errorMessage('Șablonul nu a fost găsit.');
        }else{
            offerService.getOfferTemplate(templateId).then((data) => {
                setOffer((prevData) => (
                    {
                        ...data, 
                        shippingCurrencyId: data.shippingCurrencyId ?? prevData.shippingCurrencyId,
                        localCurrencyId: data.localCurrencyId ?? prevData.localCurrencyId,
                        autoCurrencyId: data.autoCurrencyId ?? prevData.autoCurrencyId,
                        clientId: prevData.clientId, 
                        client: prevData.client, 
                        email: prevData.email, 
                        tradeId: prevData.tradeId, 
                        validityStartDate: prevData.validityStartDate, 
                        validityEndDate: prevData.validityEndDate,
                    }));
            });
            setOpenModal(false);
        }
    };

    return (
        <div>
            <Modal show={openModal} size="lg" popup onClose={() => setOpenModal(false)}>
                <Modal.Header>
                    Încarcă șablon
                </Modal.Header>
                <Modal.Body className="overflow-visible">
                    <div className="space-y-2 pt-5">
                        <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
                            <div className="mb-1 col-span-1">
                                <CustomSelect
                                    value={templateOptions.find(template => template.value == templateId)}
                                    onChange={(data) => setTemplateId(data?.value)}
                                    options={templateOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Denumire șablon"
                                />
                            </div>
                            <div className="col-span-2 flex justify-between">
                                <Button size="sm" onClick={() => {setTemplateType('all')}}>Toate șabl.</Button>
                                <Button size="sm" type="submit">Adaugă</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default TemplatesModal;
