import React, { useState, useEffect } from 'react';
import { MovementFileService } from '../../../services/MovementFileService';
import { defaultBuildRule, defaultMovementLogic, existaText, filterOptions, MovementLogic } from '../../../interfaces/Movement';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import { Button, Modal } from 'flowbite-react';
import Input from '../../../components/Input';
import { handleInputChange } from '../../../functions/selectHelper';
import { IoMdAdd } from 'react-icons/io';
import CustomSelect from '../../../components/CustomSelect';

interface ModalLogicProps {
    movementFileId: number;
    id: number;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: () => void;
}

const ModalLogic: React.FC<ModalLogicProps> = ({ movementFileId, id, showModal, setShowModal, onSave }) => {
    const [movementLogic, setMovementLogic] = useState<MovementLogic>(defaultMovementLogic(movementFileId));
    const movementFileService = new MovementFileService();

    useEffect(() => {
      if(!showModal){
        setMovementLogic(defaultMovementLogic(movementFileId));
      }
    }, [showModal])
    

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if(id != 0){
                // const ser = await movementFileService.update(activity);
                // setOpenModal(false);
                // successMessage('Activitate actualizată cu success!');
                // onEdit!(act);
            }else{
                const newId = await movementFileService.createMovementLogic(movementLogic);
                setShowModal(false);
                successMessage('Miscare adaugata cu success!');
                onSave();
            }
        } catch (error) {
            errorMessage('Denumirea este obligatorie!');
        }
    };
    
    const handleIndexChange = (index : number, value : string|number, field : string) => {
        setMovementLogic(prev => {
            const updatedBuilds = prev.build;
            updatedBuilds[index] = {...updatedBuilds[index], [field]: value};
            return { ...prev, build: updatedBuilds };
        });
    }
    
    return (
        <>
            <Modal show={showModal} size="3xl" popup onClose={() => setShowModal(false)}>
                <Modal.Header>
                    {id ? 'Editează mișcare' : 'Adaugă mișcare'}
                </Modal.Header>
                <Modal.Body className="overflow-visible">
                    <div className="space-y-2 pt-5">
                        <form onSubmit={handleSubmit}>     
                            <div className="gap-4 grid grid-cols-3 mb-2">       
                                <div className="col-span-1 mb-1">
                                    <Input 
                                        id="name" 
                                        label="Denumire"
                                        value={movementLogic.name}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movementLogic, setMovementLogic, 'name', e.target.value, defaultMovementLogic(movementFileId))}
                                    />
                                </div>
                                <div className="col-span-2 mb-1">
                                    <Input 
                                        id="description" 
                                        label="Detalii"
                                        value={movementLogic.description}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movementLogic, setMovementLogic, 'description', e.target.value, defaultMovementLogic(movementFileId))}
                                    />
                                </div>
                                {movementLogic.build.map((field, index) => (
                                    <>
                                        <div>
                                            <Input 
                                                key={`header-${index}`}
                                                id={`header-${index}`}
                                                label="Titlu Coloană"
                                                value={field.header}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleIndexChange(index, e.target.value.trim(), 'header')}
                                            />
                                        </div>
                                        <div className="">
                                            <CustomSelect
                                                key={`filter-${index}`}
                                                value={filterOptions.find(option => option.value === field.filter)}
                                                onChange={(data) => handleIndexChange(index, data.value, 'filter')}
                                                options={filterOptions}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Filtru"
                                            />
                                        </div>
                                        <div>
                                            {
                                                existaText(field.filter) ? 
                                                <>
                                                    <Input 
                                                        key={`text-${index}`}
                                                        id="text" 
                                                        label="Textul"
                                                        value={field.text}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {handleIndexChange(index, e.target.value, 'text')}}
                                                    />
                                                </>
                                                : <></>
                                            }
                                        </div>
                                    </>
                                ))}
                            </div>
                            <Button size="sm" onClick={() => {setMovementLogic(prev => ({...prev, build: [...prev.build, defaultBuildRule]}))}} ><IoMdAdd /></Button>
                            <div className="flex justify-end">
                                <Button size="sm" type="submit">Salvează</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalLogic;