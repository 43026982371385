import { Button, Modal } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { HiPlus, HiX } from "react-icons/hi";
import { User } from '../../interfaces/User';
import { UserService } from '../../services/UserService';
import { successMessage } from '../../functions/generalHelper';
import { Role, RoleOptions } from '../../interfaces/Role';
import { RoleService } from '../../services/RoleService';
import CustomSelect from '../../components/CustomSelect';

interface ModalRolesProps {
  user: User | null;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (user: User, type : string) => void;
}

const ModalRoles: React.FC<ModalRolesProps> = ({user, showModal, setShowModal, onSave}) => {
  const [roles, setRoles] = useState<RoleOptions[]>([]);
  const [role, setRole] = useState<number[]>([]);
  const userService = new UserService();
  const roleService = new RoleService();
  
  useEffect(() => {
    if(showModal){
      roleService.getRolesForSelect().then((data) => setRoles(data));
    }
    setRole(user ? (user.roles ? user.roles.map((role : Role) => role.id!) : []) : []);
  }, [showModal]);

  const handleSubmit = async () => {
    if(user){
      const returnUser = await userService.addRoleToUser(user.id!, role);
      successMessage('Rolul a fost atribuit cu succes!');
      setShowModal(false);
      onSave(returnUser, 'update');
    }
  };

  return (
    <>
      <Modal onClose={() => setShowModal(false)} show={showModal} size={`md`}>
        <Modal.Body className="relative rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-5">
          <div className="mb-4 flex items-center justify-between rounded-t border-b pb-4 dark:border-gray-600 sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Atribuie rol({ user?.name })
            </h3>
            <button
              onClick={() => setShowModal(false)}
              className="absolute right-5 top-[18px] ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white">
              <HiX className="h-5 w-5" />
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form action="#">
            <div className="mb-4">
              <div>
                <CustomSelect
                    // value={roles.find(item => item.value == role) ? roles.find(item => item.value == role) : null}
                    onChange={(data : RoleOptions[]) => { setRole(data ? data.map((option: RoleOptions) => option.value) : []) }}
                    value={roles.filter(option => role.includes(option.value))}
                    options={roles}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Rol"
                    isMulti
                />
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <Button size="sm" color="gray" onClick={() => setShowModal(false)}>Înapoi</Button>
              <Button size="sm" type="button" onClick={() => { handleSubmit() }}><HiPlus className="-ml-1 mr-2 h-4 w-4" />Atribuie</Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalRoles;
