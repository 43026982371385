export interface Email {
    clientId: null|number;
    templateId: null|number;
    subject: string;
    to: string[];
    cc: string[];
    bcc: string[];
    content: string;
    attachments: string[]
}

export const defaultEmail : Email = {
    clientId: null,
    templateId: null,
    subject: '',
    to: [],
    cc: [],
    bcc: [],
    content: '',
    attachments: [],
}