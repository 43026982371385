import { Movement } from "./Movement";
import { User } from "./User";
import { 
  HiDocumentText, 
  HiUpload, 
  HiCheck, 
  HiTruck, 
  HiLocationMarker, 
  HiClipboardCheck, 
  HiX, 
  HiArchive
} from 'react-icons/hi';
import { MdCancelPresentation } from "react-icons/md";
import { RiShip2Fill } from "react-icons/ri";

export interface Booking {
  // General Tab
  id?: number|null;
  number: string;
  status: string;
  shippingType: string|null;
  loadType: string|null;
  bookingType: string|null;
  tradeId: string|null;
  shippingLineName: string|null; // doar daca tip == Forwarding
  commodity: string|null; // 
  svcTermOrigin: string|null;
  svcTermDestination: string|null;
  creationDate: Date | null;
  createdBy: number|null;
  blockStatus: string|null; // select
  waiting: string|null; // de adaugat recomandarile in backend
  cancelReason: string|null; // doar daca status == Cancelled
  cancelledDate: Date|null; // doar daca status == Cancelled
  blockStowage: string|null; // Blocarea incarcarii -> Detalii blocare + Select
  terminal?: string|null;
  pol?: string|null;
  pod?: string|null;
  lastVesselCode?: string|null;
  motherVesselCode?: string|null;
  bls: string[];
  bookingBills: BookingBill[];

  // office: string; // de ascuns
  // chinaAgent: string; // de ascuns
  // via: string; // de ascuns
  // trade: string; // de ascuns
  // rated: boolean; // de ascuns

  // Client Tab
  clientId: number|null;
  offerId?: number|null;
  // contractId: number;

  // Forwarder
  forwarderName: string|null;
  forwarderTaxId: string|null;
  forwarderCountry: string|null;
  forwarderState: string|null;
  forwarderCity: string|null;
  forwarderStreet: string|null;
  forwarderAddress: string|null;
  forwarderZip: string|null;
  forwarderPhone: string|null;
  forwarderEmail: string|null;

  // Shipper
  shipperName: string|null;
  shipperMdmName: string|null;
  shipperBillName: string|null;
  shipperTaxId: string|null;
  shipperCountry: string|null;
  shipperState: string|null;
  shipperCity: string|null;
  shipperStreet: string|null;
  shipperAddress: string|null;
  shipperZip: string|null;
  shipperPhone: string|null;
  shipperEmail: string|null;

  // Consignee
  consigneeName: string|null;
  consigneeTaxId: string|null;
  consigneeCountry: string|null;
  consigneeState: string|null;
  consigneeCity: string|null;
  consigneeStreet: string|null;
  consigneeAddress: string|null;
  consigneeZip: string|null;
  consigneePhone: string|null;
  consigneeEmail: string|null;

  // Notify
  notifyName?: string|null;
  notifyAddress?: string|null;
  notifyPhone?: string|null;
  notifyEmail?: string|null;

  createdAt?: Date;
  updatedAt?: Date;
  teuCount?: number;

  // Route Tab
  routes: Route[];
  // Containers Tab
  containers: Container[];
  // Cargo Manifest Tab
  cargoManifests: CargoManifest[];
  // User
  user?: Partial<User>|null;
  movements?: Movement[];
  client?: {id: number; company: string;};
}

export const defaultBooking: Booking = {
  // General Tab
  number: '',
  status: 'Draft',
  shippingType: 'Import FOB',
  loadType: 'FCL',
  bookingType: 'Linie',
  shippingLineName: 'Yang Ming Line',
  blockStatus: '',
  waiting: '',
  // office: '',
  // chinaAgent: '',
  // via: '',
  svcTermOrigin: 'CY(Container Yard)',
  svcTermDestination: 'CY(Container Yard)',
  cancelReason: '',
  tradeId: '',
  commodity: '',
  creationDate: null,
  cancelledDate: null,
  blockStowage: '',
  pol: '',
  bls: [],
  bookingBills: [],
  // rated: false,
  createdBy: 0,
  // Client Tab
  clientId: 0, // de adaugat
  // contractId: 0, // de adaugat

  // Forwarder
  forwarderName: '',
  forwarderTaxId: '',
  forwarderCountry: '',
  forwarderState: '',
  forwarderCity: '',
  forwarderStreet: '',
  forwarderAddress: '',
  forwarderZip: '',
  forwarderPhone: '',
  forwarderEmail: '',

  // Shipper
  shipperName: '',
  shipperMdmName: '',
  shipperBillName: '',
  shipperTaxId: '',
  shipperCountry: '',
  shipperState: '',
  shipperCity: '',
  shipperStreet: '',
  shipperAddress: '',
  shipperZip: '',
  shipperPhone: '',
  shipperEmail: '',

  // Consignee
  consigneeName: '',
  consigneeTaxId: '',
  consigneeCountry: '',
  consigneeState: '',
  consigneeCity: '',
  consigneeStreet: '',
  consigneeAddress: '',
  consigneeZip: '',
  consigneePhone: '',
  consigneeEmail: '',

  // Notify
  notifyName: '',
  notifyAddress: '',
  notifyPhone: '',
  notifyEmail: '',

  // Route Tab
  routes: [],

  // Containers Tab
  containers: [],

  // Cargo Manifest Tab
  cargoManifests: []
};

export interface Route { 
  id: number | null;
  from: string | null;
  to: string | null;
  vesselName: string | null;
  vesselCode: string | null;
  loop: string | null;
  rocndVessel: boolean;
  etd: Date | null;
  atd: Date | null;
  eta: Date | null;
  ata: Date | null;
}

export const defaultRoute : Route = {
  id: null,
  from: '',
  to: '',
  loop: '',
  vesselName: '',
  rocndVessel: false,
  vesselCode: '',
  etd: null,
  atd: null,
  eta: null,
  ata: null
}

export interface Container {
  id?: number;
  containerNumber?: string|null;
  sealNumber?: string|null;
  bookingId?: number;
  tare: number;
  weight: number;
  volume: number;
  type: string|null;
  size: string|null;
  iso?: string|null;
  bookingBillId?: number|null;
  bl?: string|null;
  bookingBill?: BookingBill;
  freeDays?: number;
  dangerCargo?: boolean;
  packageNumber?: number;
}

export interface BookingBill {
    id: number;
    code: string;
    bookingId?: number;
    // Shipper
    shipperName?: string|null;
    shipperAddress?: string|null;
    shipperPhone?: string|null;
    shipperEmail?: string|null;
  
    // Consignee
    consigneeName?: string|null;
    consigneeAddress?: string|null;
    consigneePhone?: string|null;
    consigneeEmail?: string|null;
  
    // Notify
    notifyName?: string|null;
    notifyAddress?: string|null;
    notifyPhone?: string|null;
    notifyEmail?: string|null;

    // Alte date:
    date: Date|null;
    terminal?: string|null;
    commodity?: string|null;
    translatedCommodity?: string|null;
    shortCommodity?: string|null;
    containers?: Partial<Container>[];
}

export interface BookingBillTable extends BookingBill {
    // relatii
    bookingContainers: Partial<Container>[];
    cargoManifests: Partial<CargoManifest>[];
    booking: Partial<Booking>;
}

export const defaultBookingBill: BookingBill = {
  id: 0,
  code: '',
  shipperName: '',
  shipperAddress: '',
  shipperPhone: '',
  shipperEmail: '',
  consigneeName: '',
  consigneeAddress: '',
  consigneePhone: '',
  consigneeEmail: '',
  notifyName: '',
  notifyAddress: '',
  notifyPhone: '',
  notifyEmail: '',
  date: new Date()
};
  
export const defaultContainer: Container = {
  id: 0,
  containerNumber: '',
  sealNumber: '',
  tare: 0,
  weight: 0,
  volume: 0,
  type: 'DC',
  size: '40',
  iso: '',
  bl: '',
  freeDays: 14,
  dangerCargo: false,
  packageNumber: 0
};

export interface CargoManifest {
  id: number;
  bookingContainerId?: number|null;
  bookingBillId: number|null;
  hsCode: string|null;
  packageNumber: number;
  packageType: string|null;
  weight: number;
  volume: number;
}

export const defaultCargoManifest: CargoManifest = {
  id: 0,
  bookingContainerId: null,
  bookingBillId: null,
  hsCode: '',
  packageNumber: 0,
  packageType: '',
  weight: 0,
  volume: 0,
};

export interface ArrivalNotice {
  type: string;
  documentType: string;
  vesselCode?: string|null;
  ids?: number[];
  ata: Date;
}
  
export const defaultArrivalNotice = (type : string, documentType: string) : ArrivalNotice => ({
  type,
  documentType,
  vesselCode: null,
  ids: [],
  ata: new Date()
});

export interface Manifest {
  id?: number;
  date: Date|null;
  status?: string;
  vesselName?: string;
  vesselCode?: string;
  terminal?: string;
  filepath?: string;
  pol: string;
  translatedFilepath?: string;
  content?: BillInterface[]; 
}
  
export const defaultManifest : Manifest = {
  date: new Date(),
  pol: 'TRIST',
  vesselName: '',
  vesselCode: '',
  terminal: 'SOCEP',
}

export const bookingStatuses = [
  { name: 'Anulat', Icon: HiX },
  { name: 'Confirmat', Icon: HiCheck },
  { name: 'În tranzit(mother vessel)', Icon: RiShip2Fill },
  { name: 'În tranzit(marea neagră)', Icon: RiShip2Fill },
  { name: 'Preavizat', Icon: HiDocumentText },
  { name: 'Avizat', Icon: HiDocumentText },
  { name: 'Descărcat', Icon: HiClipboardCheck },
  { name: 'Eliberat de vamă', Icon: HiCheck },
  { name: 'În livrare', Icon: HiTruck },
  { name: 'Finalizat', Icon: HiClipboardCheck },
];

export const getNextStatusName = ( currentStatusName : string ) => {
  const index = bookingStatuses.findIndex(status => status.name === currentStatusName);
  
  if (index === -1 || index === bookingStatuses.length - 1) {
    return null;
  }
  
  return bookingStatuses[index + 1].name;
}

export interface ContainerInterface {
  packNumber : string;
  containerNumber : string;
  seal : string;
  size : string;
  tare : number;
  weight : number;
  vol : number; 
}

export interface CommodityItemInterface {
  hsCode : string;
  packNumber : number;
  packType : string;
  weight : number;
  vol : number;
}

export interface BillInterface {
  id: number;
  containers: ContainerInterface[];
  commodityItems: CommodityItemInterface[];
  pages: number[];
  commodity: string;
  marfa: string;
  shortCommodity: string;
  billNumber: string;
  bookingNumber: string;
  pol: string;
  ata: string;
  atd: string;
  vesselName: string;
  vesselCode: string;
  shipperName: string;
  shipperAddress: string;
  consigneeName: string;
  consigneeAddress: string;
  notifyName: string;
  notifyAddress: string;
}

export interface BillCheck {
  id?: number;
  billNumber: string;
  vesselName?: string;
  vesselCode?: string;
  initialVesselCode?: string;
  status?: string;
  info?: string;
}

export const defaultBillCheck : BillCheck = {
  billNumber: '',
}