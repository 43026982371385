import axios from 'axios';
import { Currency } from '../interfaces/Currency';

const API_URL = process.env.REACT_APP_GRAPHQL_URI!;

export class CurrencyService {
  async getCurrencies(): Promise<Currency[]> {
    const response = await axios.post(API_URL, {
      query: `
        query {
          currencies {
            id
            name
            symbol
          }
        }
      `,
    });
    return response.data.data.currencies;
  }
}
