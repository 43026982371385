import React, { useState, useCallback } from 'react';
import { BillInterface, Manifest } from '../../../interfaces/Booking';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import { ManifestService } from '../../../services/ManifestService';

interface TranslateTableProps {
  manifest: Partial<Manifest>;
  setManifest: React.Dispatch<React.SetStateAction<Partial<Manifest>>>;
}

const TranslateTable: React.FC<TranslateTableProps> = ({ manifest, setManifest }) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof BillInterface;
    direction: 'ascending' | 'descending';
  } | null>(null);
  const manifestService = new ManifestService();
  
  const [filters, setFilters] = useState({
    billNumber: '',
    commodity: '',
    marfa: '',
    shortCommodity: '',
    id: null
  });

  const saveTranslation = useCallback((id: number, value: string) => {
    if (value.trim() !== '') {
        manifestService.updateTranslation(manifest.id!, id, value).then((data : {status: string; message: string;}) => {
            if(data.status == 'success'){ successMessage(data.message); }
            if(data.status == 'error'){ errorMessage(data.message); }
        });
    }
  }, [manifest]);

  const saveShortManifest = useCallback((id: number, value: string) => {
    if (value.trim() !== '') {
        manifestService.updateShortManifest(manifest.id!, id, value).then((data : {status: string; message: string;}) => {
            if(data.status == 'success'){ successMessage(data.message); }
            if(data.status == 'error'){ errorMessage(data.message); }
        });
    }
  }, [manifest]);

  const handleSort = (key: keyof BillInterface) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig?.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleFilterChange = (key: string, value: string|number) => {
    setFilters(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const filteredAndSortedData = React.useMemo(() => {
    let result = manifest.content || [];

    // Aplicare filtre
    result = result.filter(item => 
      item.billNumber?.toLowerCase().includes(filters.billNumber.toLowerCase()) &&
      item.commodity?.toLowerCase().includes(filters.commodity.toLowerCase()) &&
      item.marfa?.toLowerCase().includes(filters.marfa.toLowerCase()) && 
      item.shortCommodity?.toLowerCase().includes(filters.shortCommodity.toLowerCase())
    );

    // Sortare
    if (sortConfig) {
      result = [...result].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return result;
  }, [manifest.content, sortConfig, filters]);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {/* Rând filtre */}
            <th className="px-2 py-1">

            </th>
            <th className="px-2 py-1">
              <input
                type="text"
                placeholder="Filtru B/L"
                className="block w-full rounded-lg border text-sm border-gray-300 bg-gray-50"
                value={filters.billNumber}
                onChange={(e) => handleFilterChange('billNumber', e.target.value)}
              />
            </th>
            <th className="px-2 py-1">
              <input
                type="text"
                placeholder="Filtru Engleza"
                className="block w-full rounded-lg border text-sm border-gray-300 bg-gray-50"
                value={filters.commodity}
                onChange={(e) => handleFilterChange('commodity', e.target.value)}
              />
            </th>
            <th className="px-2 py-1">
              <input
                type="text"
                placeholder="Filtru Romana"
                className="block w-full rounded-lg border text-sm border-gray-300 bg-gray-50"
                value={filters.marfa}
                onChange={(e) => handleFilterChange('marfa', e.target.value)}
              />
            </th>
            <th className="px-2 py-1">
              <input
                type="text"
                placeholder="Filtru Scurtare"
                className="block w-full rounded-lg border text-sm border-gray-300 bg-gray-50"
                value={filters.shortCommodity}
                onChange={(e) => handleFilterChange('shortCommodity', e.target.value)}
              />
            </th>
          </tr>
          <tr>
            {/* Headere sortabile */}
            <th 
              className="px-1 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer w-[80px]"
              onClick={() => handleSort('id')}
            >
              Număr {sortConfig?.key === 'id' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
            </th>
            <th 
              className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer w-[120px]"
              onClick={() => handleSort('billNumber')}
            >
              B/L {sortConfig?.key === 'billNumber' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
            </th>
            <th 
              className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort('commodity')}
            >
              Engleza {sortConfig?.key === 'commodity' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
            </th>
            <th 
              className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort('marfa')}
            >
              Romana {sortConfig?.key === 'marfa' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
            </th>
            <th 
              className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort('shortCommodity')}
            >
              Marfă scurtată {sortConfig?.key === 'shortCommodity' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredAndSortedData.map((row) => (
            <tr key={row.id} className="h-full">
              <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500 text-center w-[80px]">
                {row.id}
              </td>
              <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500 w-[120px]">
                {row.billNumber}
              </td>
              <td className="px-4 py-1 text-sm text-gray-500 whitespace-pre leading-none">
                {row.commodity}
              </td>
              <td className="px-4 py-1 min-w-[400px] align-top h-full">
                <div className="h-full w-full">
                  <textarea
                    className="h-full resize-none min-h-[2.5rem] w-full rounded-lg border text-sm border-gray-300 bg-gray-50 focus:border-primary focus:ring-primary"
                    value={row.marfa || ''}
                    onChange={(e) => {
                      setManifest(prev => ({
                        ...prev,
                        content: prev.content?.map(item =>
                          item.id === row.id ? { ...item, marfa: e.target.value } : item
                        )
                      }));
                    }}
                    onBlur={(e) => saveTranslation(row.id!, e.target.value)}
                  />
                </div>
              </td>
              <td className="px-4 py-1 min-w-[300px] align-top h-full">
                <div className="h-full w-full">
                  <textarea
                    className="h-full resize-none min-h-[2.5rem] w-full rounded-lg border text-sm border-gray-300 bg-gray-50 focus:border-primary focus:ring-primary"
                    value={row.shortCommodity || ''}
                    onChange={(e) => {
                      setManifest(prev => ({
                        ...prev,
                        content: prev.content?.map(item =>
                          item.id === row.id ? { ...item, shortCommodity: e.target.value } : item
                        )
                      }));
                    }}
                    onBlur={(e) => saveShortManifest(row.id!, e.target.value)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TranslateTable;