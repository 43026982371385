import React, { useState, useEffect } from 'react';
import { Button, Modal } from "flowbite-react";
import { Activity, defaultActivity, scheduledContactOptions } from '../../interfaces/Activity';
import { Client } from '../../interfaces/Client';
import { User } from '../../interfaces/User';
import { ClientService } from '../../services/ClientService';
import { UserService } from '../../services/UserService';
import { handleInputChange, handleSelectChange } from '../../functions/selectHelper';
import { priorityOptions } from '../../interfaces/Activity';
import DatePicker from 'react-datepicker';
import { getDateFormat } from '../../functions/dateHelper';
import Input from '../../components/Input';
import { ActivityService } from '../../services/ActivityService';
import { errorMessage, successMessage } from '../../functions/generalHelper';
import CustomSelect from '../../components/CustomSelect';
import CustomDatePicker from '../../components/CustomDatePicker';
import { useAuth } from '../../functions/useAuth';
import { useUser } from '../../components/UserContext';
import { OfferService } from '../../services/OfferService';
import Loader from '../../components/CustomLoader';

interface ActivityModalProps {
    clientId: number;
    openModal: boolean,
    setOpenModal: (openModal: boolean) => void;
    id?: number | null;
    onEdit?: (activity : Activity) => void;
}

const ActivityModal: React.FC<ActivityModalProps> = ({ clientId, openModal, setOpenModal, id=null, onEdit }) => {
    const [activity, setActivity] = useState<Activity>(defaultActivity);
    const [client, setClient] = useState<Client | null>(null);
    const [users, setUsers] = useState<User[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const clientService = new ClientService();
    const activityService = new ActivityService();
    const userService = new UserService();
    const offerService = new OfferService();
    const { user } = useUser();

    useEffect(() => {
        modificaClient();
        if(openModal && clientId === 0){
            fetchClients();
        }
        if(openModal){
            userService.getUsers().then((data) => setUsers(data));
        }
    }, [openModal, user]);

    const fetchClients = async () => {
        setLoading(true);
        offerService.getClients().then(data => {
            setClients(data); setActivity(prev => ({...prev, clientId: 0}));
        }).finally(() => setLoading(false));
    };

    useEffect(() => {
        modificaClient();
    }, [activity.clientId]);

    const modificaClient = () => {
        if(clientId !== 0 || activity.clientId !== 0){
            clientService.getClientWithContacts(clientId!=0 ? clientId : activity.clientId).then((data) => {
                if(!id){
                    setActivity((prevActivity) => ({
                        ...defaultActivity, 
                        clientId: clientId!=0 ? clientId : activity.clientId, 
                        client: {company: data.company},
                        contactId: data.contacts.length > 0 ? data.contacts[0].id : null,
                        userId: user ? user?.id : prevActivity.userId,
                        user: {
                            name: user ? user?.name : prevActivity.user.name,
                        }
                    }));
                }
                setClient(data)
            });
        }
    }

    useEffect(() => {
        if(id){
            activityService.find(id).then((data) => setActivity(data));
        }
    }, [openModal]);

    const userOptions = users.map(user => ({ value: user.id, label: user.name }));
    const contactOptions = client?.contacts.map(contact => ({ value: contact.id, label: contact.name })) || [];

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if(id){
                const act = await activityService.update(activity);
                setOpenModal(false);
                successMessage('Activitate actualizată cu success!');
                onEdit!(act);
            }else{
                await activityService.create(activity);
                setOpenModal(false);
                successMessage('Activitate creată cu success!');
            }
        } catch (error) {
            errorMessage('Data planificată este obligatorie!');
        }
    };

    return (
        <div>
            <Loader isLoading={loading} />
            <Modal show={openModal} size="3xl" popup onClose={() => setOpenModal(false)}>
                <Modal.Header>
                    {id ? 'Editează activitate' : 'Adaugă activitate'}
                </Modal.Header>
                <Modal.Body className="overflow-visible">
                    <div className="space-y-2 pt-5">
                        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                            <div className="mb-1 col-span-1">
                                { 
                                    clientId === 0 ? 
                                    <>
                                        <CustomSelect
                                            value={clients.map(item => ({value: item.id, label: item.company})).find(client => client.value == activity.clientId)}
                                            onChange={(data) => { handleSelectChange(activity, setActivity, 'clientId', data)}}
                                            options={clients.map(item => ({value: item.id, label: item.company}))}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Client"
                                        />
                                    </> : 
                                    <>
                                        <Input 
                                            id="client" 
                                            readOnly
                                            label="Client"
                                            value={activity.client.company}
                                            onChange={()=>{}}
                                        />
                                    </>
                                }
                            </div>
                            <div className="mb-1 col-span-1">
                                <CustomSelect
                                    value={userOptions.find(user => user.value === activity.userId)}
                                    onChange={(data) => handleSelectChange(activity, setActivity, 'userId', data)}
                                    options={userOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Utilizator"
                                />
                            </div>
                            <div className="mb-1 col-span-1">
                                <CustomSelect
                                    value={priorityOptions.find(option => option.value === activity.priority)}
                                    onChange={(data) => handleSelectChange(activity, setActivity, 'priority', data)}
                                    options={priorityOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Prioritate"
                                />
                            </div>
                            <div className="mb-1 col-span-1">
                                <CustomDatePicker
                                    selected={activity.scheduledDate}
                                    onChange={(date) => setActivity({ ...activity, scheduledDate: date })}
                                    dateFormat= {getDateFormat()}
                                    locale="ro"
                                    isClearable
                                    placeholderText="Dată planificată"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div className="mb-1 col-span-1">
                                <CustomSelect
                                    value={contactOptions.find(contact => contact.value === activity.contactId)}
                                    onChange={(data) => handleSelectChange(activity, setActivity, 'contactId', data)}
                                    options={contactOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Contact"
                                />
                            </div>
                            <div className="mb-1 col-span-1">
                                <CustomSelect
                                    value={scheduledContactOptions.find(option => option.value === activity.scheduledContact)}
                                    onChange={(data) => handleSelectChange(activity, setActivity, 'scheduledContact', data)}
                                    options={scheduledContactOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Contact planificat"
                                />
                            </div>
                            <div className="mb-1 col-span-2">
                                <label htmlFor="description" className="block text-sm font-medium text-gray-700">Descriere</label>
                                <textarea
                                    id="description"
                                    name="description"
                                    value={activity.description || ''}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(activity, setActivity, 'description', e.target.value, defaultActivity)}
                                    rows={3}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-0 focus:border-primary sm:text-sm"
                                    placeholder="Introduce-ți descriere."
                                ></textarea>
                            </div>
                            <div className="col-span-2 flex justify-end">
                                <Button size="sm" type="submit">Salvează</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ActivityModal;
