import axios from 'axios';
import { Option } from '../interfaces/Option';

const API_URL = process.env.REACT_APP_GRAPHQL_URI!;

export class OptionService {
    async getOption(slug: string): Promise<Option> {
        const response = await axios.post(API_URL, {
          query: `
            query {
              option(slug: "${slug}") {
                id
                title
                slug
                value
              }
            }  
          `,
        });
        return response.data.data.option;
    }
}
