import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { MovementReport } from '../../../interfaces/Movement';
import renderSkeleton from '../../../components/SkeletonLoader';
import Tooltip from '../../../components/Tooltip';
import { Badge, Button } from 'flowbite-react';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_RO } from '../../../functions/langHelper';
import { ColDef } from 'ag-grid-community';
import { MovementReportService } from '../../../services/MovementReportService';
import ModalCreate from './ModalCreate';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import { createDateFromString, formatDate, formatDateTime } from '../../../functions/dateHelper';
import { NavLink } from 'react-router-dom';

const Index: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [movementReports, setMovementReports] = useState<MovementReport[]>([]);
    const movementReportService = new MovementReportService();
    const [showModalCreate, setShowModalCreate] = useState<boolean>(false);

    useEffect(() => {
        fetchReports();
    }, []);

    //fetch the reports trough the service
    const fetchReports = useCallback(async () => {
        setLoading(true);
        const data = await movementReportService.getAllRows();
        setMovementReports(data);
        setLoading(false);
    }, []);

    const sendReport = (id : number) => {
        movementReportService.sendReportCSV(id).then((response) => {
            if (response.status === 'success') {
                successMessage(response.message);
                fetchReports();
            }else{
                errorMessage(response.message);
            }
        });
    }

    const columns: ColDef<MovementReport>[] = useMemo(() => [
        { headerName: 'ID', field: 'id', sortable: true, filter: 'agNumberColumnFilter', floatingFilter: true, width: 110, maxWidth: 110 },
        { headerName: 'Fisier', field: 'filename', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true,
            cellRenderer : (params: any) => {
              return <>
                <a href={`${process.env.REACT_APP_BACKEND_URI}/file/movementReports/${params.value}`} target="_blank" rel="noopener noreferrer" className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline">
                  {params.value}
                </a>
              </>
            }
           },
        { headerName: 'Date', field: 'date', sortable: true, filter: 'agDateColumnFilter', floatingFilter: true, cellRenderer: (params: any) => formatDateTime(createDateFromString(params.value)) },
        { 
            headerName: 'Sent Date', 
            field: 'sentDate', 
            sortable: true, 
            filter: 'agDateColumnFilter', 
            floatingFilter: true, 
            cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            cellRenderer: (params: any) => (
                <div>
                    {params.value ? <Badge color="green">Trimis - {formatDateTime(createDateFromString(params.value))}</Badge> : <Button size="sm" onClick={() => {sendReport(params.data.id)}}>Trimite</Button>}
                </div>
            )
        }
    ], []);

    return (
        <div className="ag-theme-quartz px-4">
            <div className="flex justify-between items-center py-2">
                <div className="text-lg font-bold">
                    Miscari raportate
                </div>
                <div className="flex gap-2">
                    <Tooltip content="Raportează toate mișcările">
                        <Button size="sm" onClick={() => {setShowModalCreate(true)}}>Raportează toate mișcările</Button>
                    </Tooltip>
                    <NavLink to="/bookings/movements/index">
                        <Button size="sm">Înapoi la Mișcări</Button>
                    </NavLink>
                </div>
            </div>
            {loading ? (renderSkeleton()) : (
                <>
                    <AgGridReact
                        localeText={AG_GRID_LOCALE_RO}
                        columnDefs={columns}
                        rowData={movementReports}
                        defaultColDef={{ flex: 1, minWidth: 100 }}
                        domLayout='autoHeight'
                        pagination={false}
                        gridOptions={{ rowHeight: 35 }}
                    />
                </>
            )}
            <ModalCreate showModal={showModalCreate} setShowModal={setShowModalCreate} onSave={fetchReports} />
        </div>
    );
};

export default Index;