import axios from "axios";

const API_URL = `${process.env.REACT_APP_BACKEND_URI}/file`;

export class FileService { 
    async uploadFile(file: File): Promise<{status: string, originalname: string, filename: string}> {
        try {
            const formData = new FormData();
            formData.append('file', file);
            
            const response = await axios.post(`${API_URL}/uploadFile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return {
                status: 'success',
                originalname: response.data.originalname,
                filename: response.data.filename
            };
        } catch (error) {
            console.error('Eroare la încărcarea fișierului:', error);
            return {
                status: 'error',
                originalname: '',
                filename: ''
            };
        }
    }

}