import React, { useCallback, useEffect, useState } from 'react';
import { defaultRole, Permissions, Role } from '../../interfaces/Role';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { RoleService } from '../../services/RoleService';
import { Button, Modal } from 'flowbite-react';
import ListItem from './components/ListItem';

interface FormProps {
    id: number;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewModal: React.FC<FormProps> = ({id, showModal, setShowModal}) => {
  const [role, setRole] = useState<Role>(defaultRole);
  const [permissions, setPermissions] = useState<Permissions[]>([]);
  const roleService = new RoleService();

  useEffect(() => {
      roleService.getPermissions().then((data) => setPermissions(data));
  }, []);

  useEffect(() => {
    if (id) {
      roleService.getRoleById(id).then((data) => setRole(data));
    }
  }, [id]);

  return (
    <>
        <Modal show={showModal} onClose={() => setShowModal(false)} size="7xl">
            <Modal.Header>Vizualizare rol</Modal.Header>
            <Modal.Body>
                <div className="space-x-2 space-y-2 grid sm:grid-cols-2 grid-cols-1">
                    {
                        Array.from(new Set(permissions.map(item => item.category))).map(category => 
                        (
                            <div className="rounded-lg border border-gray-200 bg-white p-5 relative">
                                <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-1/2 transform -translate-x-1/2">{ category }</h4>
                                <div className="space-y-3">
                                    { permissions.filter(permission => permission.category == category).map(permission => (
                                        <div className="relative flex items-start">
                                            <div className="space-y-3">
                                                <ListItem title={permission.beautifulName} text={permission.description ? permission.description : ''} type={role.permissions.includes(permission.id) ? `success` : `error`}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex justify-end">
                    <Button color="gray" onClick={() => setShowModal(false)}>
                        Închide
                    </Button>
                </div>
            </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewModal;
