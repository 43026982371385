import React, { useCallback, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css'; // Import bubble theme CSS

interface BubbleRichTextEditorProps { 
  initialValue: string|null|undefined;
  title: string;
  onChange: (content: string) => void;
}

const RichTextareaSection: React.FC<BubbleRichTextEditorProps> = ({ initialValue, title, onChange }) => {
  const [focus, setFocus] = useState(false);

  const onFocus = useCallback(() => {setFocus(true)}, []);
  const onBlur = useCallback(() => {setFocus(false)},[]);
  
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      ['clean'],
    ],
  };

  const formats = [
    'bold', 'italic', 'underline',
    'color', 'background',
    'list', 'bullet', 'indent'
  ];

  return (
    <div className={`rounded-lg border bg-white p-5 relative my-6 ${focus ? 'border-primary' : 'border-gray-200'}`}>
      <h4 className={`absolute text-base font-semibold bg-white -top-3 px-3 left-10 ${focus ? 'text-darkprimary' : 'text-main'}`}>{title}</h4>
      <div>
        <div className="bubble-rich-text-editor">
          <ReactQuill
            theme="bubble"
            value={initialValue ? initialValue : ''}
            onChange={onChange}
            modules={modules}
            formats={formats}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
      </div>
    </div>
  );
};

export default RichTextareaSection;