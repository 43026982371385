import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Form from './Form';
import Index from './Index';
import Breadcrumb from '../../components/BreadCrumb';
import UsersNavBar from '../../components/UsersNavBar';
import { useUser } from '../../components/UserContext';
import { hasPermission } from '../../interfaces/Permissions';

const Roles: React.FC = () => {
  const { user } = useUser();
  
  return (
    <>
      <UsersNavBar />
      <Breadcrumb />
        {
          hasPermission(user, 'ROLE_VIEW_ALL') ?
            <Routes>
              <Route path="index" element={<Index />} />
              <Route path="form" element={<Form />} />
              <Route path="form/:id" element={<Form />} />
              <Route path="/" element={<Navigate to="/hr/roles/index" replace />} />
            </Routes>
          : <></>
        }
    </>
  );
};

export default Roles;
