// Breadcrumb.js
import { NavLink, useLocation } from 'react-router-dom';
import { Breadcrumb as BreadcrumbFlowbite } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { capitalize } from "../functions/stringHelper";
import { ReactNode } from 'react';

interface BreadcrumbProps {
  actionButton?: ReactNode;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ actionButton }) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <>
      <BreadcrumbFlowbite aria-label="Solid background breadcrumb example" className={`bg-gray-50 px-5 ${ actionButton ? 'py-1' : 'py-3'} dark:bg-main-800 customBreadCrumb`}>
          <BreadcrumbFlowbite.Item href="#" icon={HiHome}>
              Acasă
          </BreadcrumbFlowbite.Item>
          {pathnames.map((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            return (<BreadcrumbFlowbite.Item key={to}><NavLink to={to}>{capitalize(value)}</NavLink></BreadcrumbFlowbite.Item>);
          })}
          <li className="ml-auto absolute left-[auto] right-0">
            {actionButton}
          </li>
        </BreadcrumbFlowbite>
    </>
  );
};

export default Breadcrumb;
