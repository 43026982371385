export interface Role {
    id: number | null;
    name: string;
    permissions: Array<number>;
}

export const defaultRole : Role= {
    id: null,
    name: '',
    permissions: []
};

export interface Permissions {
    id: number;
    name: string;
    beautifulName: string;
    description: string|null;
    category: string|null;
}

export interface PermissionInRole {
    id: number;
}

export interface RoleOptions {
    value: number;
    label: string;
}