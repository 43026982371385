import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD } from './graphql/mutations';
import { Button } from 'flowbite-react';
import Input from '../../components/Input';

const ChangePassword: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [changePassword, { loading, error }] = useMutation(CHANGE_PASSWORD);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("New passwords don't match");
      return;
    }
    try {
      const { data } = await changePassword({
        variables: { currentPassword, newPassword },
      });
      if (data.changePassword.success) {
        alert('Password changed successfully');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        alert(data.changePassword.message);
      }
    } catch (err) {
      console.error('Change password error:', err);
    }
  };

  return (
    <div className="mt-8">
      <h2 className="text-xl font-bold mb-4">Change Password</h2>
      <form onSubmit={handleSubmit} className="max-w-md">
        <Input
          id="currentPassword"
          label="Current Password"
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          required
        />
        <Input
          id="newPassword"
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <Input
          id="confirmPassword"
          label="Confirm New Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <Button type="submit" disabled={loading}>
          {loading ? 'Changing...' : 'Change Password'}
        </Button>
      </form>
      {error && <p className="text-red-500 mt-2">{error.message}</p>}
    </div>
  );
};

export default ChangePassword;