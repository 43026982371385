import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Table from './Table';
import { Button, Tooltip } from "flowbite-react";
import 'react-loading-skeleton/dist/skeleton.css';
import renderSkeleton from '../../components/SkeletonLoader';
import { Role } from '../../interfaces/Role';
import { RoleService } from '../../services/RoleService';
import DeleteModal from '../../components/DeleteModal';

const Index: React.FC = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [roleToDelete, setRoleToDelete] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const roleService = new RoleService();
  const navigate = useNavigate();

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    setLoading(true);
    const data = await roleService.getRoles();
    setRoles(data);
    setLoading(false);
  };

  const handleEdit = (id: number) => {
    navigate('/hr/roles/form/' + id);
  };

  const handleDelete = (id: number) => {
    setRoleToDelete(id);
    setShowModalDelete(true);
  };
  
  const confirmDelete = async () => {
    if (roleToDelete !== null) {
      await roleService.deleteRole(roleToDelete);
      setShowModalDelete(false);
      setRoles(roles.filter(role => role.id !== roleToDelete));
      setRoleToDelete(null);
    }
  };

  return (
    <div className="ag-theme-quartz px-4">
      <div className="flex justify-between items-center py-2">
      <div className="text-lg font-bold">
        Roluri
      </div>
    
      <Tooltip content="Adaugă utilizator">
        <NavLink to="/hr/roles/form">
          <Button size="sm">Adaugă rol</Button>
        </NavLink>
      </Tooltip>

    </div>
      {loading ? ( renderSkeleton() ) : (
        <>
          <Table roles={roles} onEdit={handleEdit} onDelete={handleDelete} />
          <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={() => {confirmDelete()}} deleteText="Sunteți sigur că doriți să ștergeți acest rol?" />
        </>
      )}
    </div>
  );
};

export default Index;
