
import Input from "../../../components/Input";
import { handleInputChange } from "../../../functions/selectHelper";
import { Client } from "../../../interfaces/Client";
import { useState } from "react";
import { Button } from 'flowbite-react';
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from "flowbite-react";
import { Contact, defaultContact } from '../../../interfaces/Contact';
import { formatDate, getDateFormat } from '../../../functions/dateHelper';
import Actions from '../../../components/Actions';
import "react-datepicker/dist/react-datepicker.css";
import { errorMessage } from '../../../functions/generalHelper';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ro } from 'date-fns/locale';
import CustomDatePicker from "../../../components/CustomDatePicker";

interface TabProps {
  client: any;
  setClient: React.Dispatch<React.SetStateAction<any>>;
}

registerLocale('ro', ro);
setDefaultLocale('ro');

const Contacts: React.FC<TabProps> = ({ client, setClient }) => {
    const [newContact, setNewContact] = useState<Contact>(defaultContact);
    const [isEdit, setIsEdit] = useState<number>(10000);

    const handleAddOrEditContact = () => {
        if(newContact.name?.trim() === ''){
            errorMessage('Numele este obligatoriu!');
            return;
        }
        if(isEdit !== 10000){
            setClient((prevClient : Client) => {
                const updatedContacts = [...prevClient.contacts];
                updatedContacts[isEdit] = newContact;
                return { ...prevClient, contacts: updatedContacts };
              });
            setIsEdit(10000);
        }else{
            setClient({ ...client, contacts: [...client.contacts, newContact] }); 
        }
        setNewContact(defaultContact);
    }

    const handleResetContact = () => {
        setIsEdit(10000);
        setNewContact(defaultContact);
    }

    const handleEditContact = (index : number) => {
        setIsEdit(index);
        setNewContact(client.contacts[index]);
    }

    const handleDeleteContact = (index : number) => {
        if(index === isEdit) {
            setIsEdit(10000);
            setNewContact(defaultContact);
        }
        setClient({ ...client, contacts: client.contacts.filter((contact : Contact, curentIndex : number) => { return curentIndex !== index}) }); 
    }

    return (
        <div>
            <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
                <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Contacte</h4>
                <div className="overflow-x-auto">
                    <Table striped>
                        <TableHead>
                            <TableHeadCell>Nume</TableHeadCell>
                            <TableHeadCell>Telefon</TableHeadCell>
                            <TableHeadCell>Email</TableHeadCell>
                            <TableHeadCell>Titlu</TableHeadCell>
                            <TableHeadCell>Funcție</TableHeadCell>
                            <TableHeadCell>Departament</TableHeadCell>
                            <TableHeadCell>Locație</TableHeadCell>
                            <TableHeadCell>Dată naștere</TableHeadCell>
                            <TableHeadCell>Acțiuni</TableHeadCell>
                        </TableHead>
                        <TableBody className="divide-y">
                            {client.contacts.map((contact : Contact, index : number) => (
                                <TableRow key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <TableCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        {contact.name}
                                    </TableCell>
                                    <TableCell><a className="link" href={`tel:${contact.name}`}>{contact.phone}</a></TableCell>
                                    <TableCell><a className="link" href={`mailTo:${contact.email}`}>{contact.email}</a></TableCell>
                                    <TableCell>{contact.title}</TableCell>
                                    <TableCell>{contact.position}</TableCell>
                                    <TableCell>{contact.department}</TableCell>
                                    <TableCell>{contact.location}</TableCell>
                                    <TableCell>{formatDate(contact.dateOfBirth)}</TableCell>
                                    <TableCell>
                                        <Actions id={index} onEdit={() => handleEditContact(index)} onDelete={() => handleDeleteContact(index)} moduleName="contact"/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
                <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">{isEdit !== 10000 ? 'Editează contact' : 'Adaugă contact'}</h4>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
                    <Input 
                        id="name" 
                        label="Nume"
                        value={newContact.name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContact, setNewContact, 'name', e.target.value, defaultContact)}
                    />
                    <Input 
                        id="phone" 
                        label="Telefon"
                        value={newContact.phone}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContact, setNewContact, 'phone', e.target.value, defaultContact)}
                    />
                    <Input 
                        id="email" 
                        label="Email"
                        value={newContact.email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContact, setNewContact, 'email', e.target.value, defaultContact)}
                    />
                    <Input 
                        id="title" 
                        label="Titlu"
                        value={newContact.title}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContact, setNewContact, 'title', e.target.value, defaultContact)}
                    />
                    <Input 
                        id="position" 
                        label="Funcție"
                        value={newContact.position}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContact, setNewContact, 'position', e.target.value, defaultContact)}
                    />
                    <Input 
                        id="department" 
                        label="Departament"
                        value={newContact.department}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContact, setNewContact, 'department', e.target.value, defaultContact)}
                    />
                    <Input 
                        id="location" 
                        label="Locație"
                        value={newContact.location}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContact, setNewContact, 'location', e.target.value, defaultContact)}
                    />
                    <div>
                        <CustomDatePicker
                            selected={newContact.dateOfBirth}
                            onChange={(date) => setNewContact({...newContact, dateOfBirth:date})} 
                            dateFormat={getDateFormat()}
                            locale="ro"
                            isClearable
                            placeholderText="Data nașterii"
                            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                </div>
                <div className="flex justify-end space-x-4 mt-3">
                    <Button size="sm" color="gray" onClick={handleResetContact}>{isEdit !== 10000 ? 'Renunță la editare' : 'Resetează câmpuri'}</Button>
                    <Button size="sm" onClick={handleAddOrEditContact}>{isEdit !== 10000 ? 'Editează contact' : 'Adaugă contact'}</Button>
                </div>
            </div>
        </div>
    );
}; 

export default Contacts;