import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { MovementFile } from '../../../interfaces/Movement';
import { MovementFileService } from '../../../services/MovementFileService';
import renderSkeleton from '../../../components/SkeletonLoader';
import Tooltip from '../../../components/Tooltip';
import { Button } from 'flowbite-react';
import DeleteModal from '../../../components/DeleteModal';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_RO } from '../../../functions/langHelper';
import { ColDef } from 'ag-grid-community';
import Actions from '../../../components/Actions';
import ModalFile from './ModalFile';

const Index: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [fileToEdit, setFileToEdit] = useState<number>(0);
    const [movementFiles, setMovementFiles] = useState<MovementFile[]>([]);
    const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const movementFileService = new MovementFileService();
    
    useEffect(() => {
        fetchMovementFiles();
    }, []);

    const fetchMovementFiles = useCallback(
        async () => {
            setLoading(true);
            const data = await movementFileService.getMovementFiles();
            setMovementFiles(data);
            setLoading(false);
        },
      [],
    )

    const onCreate = useCallback(
        () => {
            setFileToEdit(0);
            setShowModalCreate(true);
        },
      [fileToEdit, showModalCreate],
    )

    const onEdit = useCallback(
        (id: number) => {
            setFileToEdit(id);
            setShowModalCreate(true);
        },
      [fileToEdit, showModalCreate],
    )

    const onDelete = useCallback(
        (id: number) => {
            setFileToEdit(id);
            setShowModalDelete(true);
        },
      [fileToEdit, showModalDelete],
    )

    const confirmDelete = useCallback(
        () => {
            // delete din service fileToEdit
            setFileToEdit(0);
            setShowModalDelete(false);
            // successMessage
        },
      [fileToEdit],
    )

    const columns: ColDef<MovementFile>[] = useMemo(() => [
        { headerName: 'Id', field: 'id', sortable: true, filter: 'agNumberColumnFilter', floatingFilter: true, width: 110, maxWidth: 110},
        { headerName: 'Denumire', field: 'name', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true,
            cellRenderer: (params: any) => (
                <NavLink to={`/bookings/movementsConfiguration/view/${params.data.id}`} className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline">{params.value}</NavLink>
            )
         },
        { headerName: 'Detalii', field: 'description', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true },
        { headerName: 'Tip', field: 'type', sortable: true, filter: 'agSetColumnFilter', floatingFilter: true },
        { headerName: 'Coloana titluri', field: 'headerRow', sortable: true, filter: 'agNumberColumnFilter', floatingFilter: true, width: 110, maxWidth: 110},
        {
            headerName: 'Acțiuni',
            width: 150, 
            maxWidth: 150,
            field: 'id',
            cellRenderer: (params: any) => (
              <div>
                <Actions 
                  id={params.data.id} 
                  onEdit={() => onEdit(params.data.id)} 
                  onDelete={() => onDelete(params.data.id)} 
                  moduleName="fișier"
                />
              </div>
            )
          }
    ], []);

    return (
        <div className="ag-theme-quartz px-4">
            <div className="flex justify-between items-center py-2">
                <div className="text-lg font-bold">
                    Fișiere mișcări
                </div>
                
                <Tooltip content="Adaugă fișier">
                    <Button size="sm" onClick={onCreate}>Adaugă fișier</Button>
                </Tooltip>

            </div>
            {loading ? ( renderSkeleton() ) : (
                <>
                    <AgGridReact 
                        localeText={AG_GRID_LOCALE_RO}
                        columnDefs={columns} 
                        rowData={movementFiles}
                        defaultColDef={{ flex: 1, minWidth: 100 }}
                        domLayout='autoHeight'
                        pagination={false}
                        gridOptions={{rowHeight: 35}}
                    />
                    <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={confirmDelete} deleteText='Sunteți sigur că doriți să eliminați acest fisier?' />
                    <ModalFile showModal={showModalCreate} setShowModal={setShowModalCreate} id={fileToEdit} />
                </>
            )}
        </div>
    );
};

export default Index;