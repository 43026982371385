import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Index from './Index';

const Activities: React.FC = () => {
  return (
      <Index />
  );
};

export default Activities;
