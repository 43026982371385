import { format, toZonedTime } from 'date-fns-tz';
import { ro } from 'date-fns/locale';
import { Client } from '../interfaces/Client';
import { Contact } from '../interfaces/Contact';

const defaultFormat = 'dd.MM.yyyy';
const defaultTimeFormat = 'HH:mm';
const romaniaTimeZone = 'Europe/Bucharest';
const defaultDateTimeFormat = 'dd.MM.yyyy HH:mm';

export const formatDate = (date: Date | null, formatStr: string = defaultFormat, locale: (boolean | null) = null): string | null => {
    if(!date){
        return null;
    }

    try {
        const romaniaDate = toZonedTime(date, romaniaTimeZone);
        if (locale) {
            return format(romaniaDate, formatStr, { locale: ro, timeZone: romaniaTimeZone });
        }
        return format(romaniaDate, formatStr, { timeZone: romaniaTimeZone });
    } catch (error) {
        return '';
    }
};

export const formatTime = (date: Date | null, formatStr: string = defaultTimeFormat, locale: (boolean | null) = null): string | null => {
    if(!date){
        return null;
    }

    try {
        const romaniaDate = toZonedTime(date, romaniaTimeZone);
        if (locale) {
            return format(romaniaDate, formatStr, { locale: ro, timeZone: romaniaTimeZone });
        }
        return format(romaniaDate, formatStr, { timeZone: romaniaTimeZone });
    } catch (error) {
        return '';
    }
};

export const formatDateTime = (
    date: Date | null,
    formatStr: string = defaultDateTimeFormat,
    locale: boolean | null = null,
    options: {
      dateOnly?: boolean;
      timeOnly?: boolean;
    } = {}
  ): string | null => {
    if (!date) {
      return null;
    }
  
    try {
      const romaniaDate = toZonedTime(date, romaniaTimeZone);
      let finalFormat = formatStr;
  
      if (options.dateOnly) {
        finalFormat = 'yyyy-MM-dd';
      } else if (options.timeOnly) {
        finalFormat = 'HH:mm';
      }
  
      if (locale) {
        return format(romaniaDate, finalFormat, { locale: ro, timeZone: romaniaTimeZone });
      }
      return format(romaniaDate, finalFormat, { timeZone: romaniaTimeZone });
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
};

export const getDateFormat = (): string => {
    return defaultFormat;
};

export const getTimeFormat = (): string => {
    return defaultTimeFormat;
};

export const formatDateForBackend = (date: Date | undefined | null) => {
  if (!date) {
    return null;
  }
  
  // Creăm o nouă dată ajustată pentru timezone
  const timezoneOffset = date.getTimezoneOffset() * 60000; // convertim în milisecunde
  const adjustedDate = new Date(date.getTime() - timezoneOffset);
  
  return adjustedDate.toISOString();
};

export const getDateColorClass = (date: Date | null, status : string = 'Închis'): string => {
    if (!date || status === 'Închis') {
      return '';
    }
  
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to start of day
  
    const inputDate = new Date(date);
    inputDate.setHours(0, 0, 0, 0); // Reset time to start of day
  
    if (inputDate < today) {
      return 'text-red-600';
    } else if (inputDate.getTime() === today.getTime()) {
      return 'text-blue-600';
    }
  
    return '';
};

export function createDateFromString(date : string|null) : Date|null
{
    if(date == null) return null;
    const isoDate = date.replace('t', 'T').replace('z', 'Z');
    return new Date(isoDate);
}

export const formatDateTimeStringInInput = (input: string): string => {
  const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters

  if (cleaned.length <= 2) {
    return cleaned; // Day
  } else if (cleaned.length <= 4) {
    return `${cleaned.slice(0, 2)}.${cleaned.slice(2, 4)}`; // Day.Month
  } else if (cleaned.length <= 8) {
    return `${cleaned.slice(0, 2)}.${cleaned.slice(2, 4)}.${cleaned.slice(4, 8)}`; // Day.Month.Year
  } else if (cleaned.length <= 10) {
    return `${cleaned.slice(0, 2)}.${cleaned.slice(2, 4)}.${cleaned.slice(4, 8)} ${cleaned.slice(8, 10)}`; // Day.Month.Year Hour
  } else {
    return `${cleaned.slice(0, 2)}.${cleaned.slice(2, 4)}.${cleaned.slice(4, 8)} ${cleaned.slice(8, 10)}:${cleaned.slice(10, 12)}`; // Day.Month.Year Hour:Minute
  }
};

export const stringToDate = (dateString: string, format: string = defaultDateTimeFormat): Date => {
  type DateFormatPart = 'yyyy' | 'yy' | 'MM' | 'dd' | 'HH' | 'mm' | 'ss';

  interface FormatMapEntry {
    index: number;
    length: number;
  }

  type FormatMap = {
    [key in DateFormatPart]: FormatMapEntry;
  };

  const formatParts: string[] = format.split(/[^a-zA-Z]/);
  const dateParts: string[] = dateString.split(/\D/);
  
  const formatMap: FormatMap = {
    yyyy: { index: 0, length: 4 },
    yy: { index: 0, length: 2 },
    MM: { index: 1, length: 2 },
    dd: { index: 2, length: 2 },
    HH: { index: 3, length: 2 },
    mm: { index: 4, length: 2 },
    ss: { index: 5, length: 2 }
  };

  const datePieces: [number, number, number, number, number, number] = [
    new Date().getFullYear(), // an curent implicit
    0, // lună (0-11)
    1, // zi
    0, // oră
    0, // minute
    0  // secunde
  ] as [number, number, number, number, number, number];

  formatParts.forEach((part: string, index: number) => {
    if (part in formatMap) {
      const { index: pieceIndex, length } = formatMap[part as DateFormatPart];
      let value = parseInt(dateParts[index], 10);

      if (isNaN(value)) {
        throw new Error(`Invalid date part for format ${part}`);
      }

      if (part === 'yy') {
        const currentYear = new Date().getFullYear();
        const century = Math.floor(currentYear / 100) * 100;
        value = century + value;
      }

      if (part === 'MM') {
        value -= 1; // Ajustăm luna pentru JavaScript (0-11)
      }

      datePieces[pieceIndex] = value;
    }
  });

  const resultDate = new Date(...datePieces);

  if (isNaN(resultDate.getTime())) {
    throw new Error('Invalid date');
  }

  return resultDate;
}