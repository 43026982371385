import { Booking } from "./Booking";
import { LabelValueInt, LabelValueString } from "./Recommandation";

export interface BuildRule {
    header: string;
    filter: 'contains' | 'notContains' | 'equals' | 'notEqual' | 'startsWith' | 'endsWith' | 'blank' | 'notBlank';
    text: string;
}

export interface MovementFile {
    id?: number;
    name: string;
    description?: string|null;
    type: string;
    terminal: string;
    headerRow: number;
    columns: {header: string; field: string;}[];
}

export interface MovementLogic {
    id?: number;
    name: string;
    description: string;
    movementFileId: number;
    build: BuildRule[];
}

export interface Movement {
    id?: number;
    movement: string;
    port?: string|null;
    terminal?: string|null;
    containerCode: string;
    client?: string|null;
    bookingNumber?: string|null;
    size: string|null;
    type: string|null;
    iso: string|null;
    note: string|null;
    dateSystem: Date|null;
    createdAtSystem?: Date|null;
    date: Date;
    container?: Container;
    action: boolean;
    bookingId?: number|null;
    booking?: Booking|null;
    vesselCode?: string|null;
    vesselName?: string|null;
    isReportedWithin12Hours?: boolean;
    isLastMovement?: boolean;
}

export interface MovementReport {
    id: number;
    filename: string;
    date: Date;
    sentDate?: Date | null;
}

interface Container {
    size?: number;
    type?: string;
    bookingContainers?: {booking: {consigneeName: string, shipperName: string, number: string, shippingType: string }}[];
}

export const defaultMovement : Movement = {
    movement: 'DF',
    terminal: 'ROCNDP01',
    containerCode: '',
    size: '',
    type: '',
    date: new Date(),
    action: false,
    dateSystem: null,
    iso: '',
    note: '',
};

export const defaultMovementFile : MovementFile = {
    name: '',
    description: '',
    terminal: '',
    type: 'Excel',
    headerRow: 0,
    columns: []
};

export const movementColumnOptions : LabelValueString[] = [
    {label: 'Serie container', value: 'number'}, {label: 'Data', value: 'date'}, {label: 'Navă', value: 'vessel'}, {label: 'Discharge Port', value: 'dischargePort'}, {label: 'Loading port', value: 'loadingPort'}
];

export const defaultMovementLogic = (id : number) : MovementLogic => ({
    movementFileId: id,
    name: '',
    description: '',
    build: [defaultBuildRule],
});

export const defaultBuildRule : BuildRule = {
    header: '',
    filter: 'contains',
    text: 'Niste text'
};

export const filterOptions = [
    { label: 'Contine', value: 'contains' },
    { label: 'Nu contine', value: 'notContains' },
    { label: 'Este egal cu', value: 'equals' },
    { label: 'Nu este egal cu', value: 'notEqual' },
    { label: 'Începe cu', value: 'startsWith' },
    { label: 'Se termină cu', value: 'endsWith' },
    { label: 'Este gol', value: 'blank' },
    { label: 'Nu este gol', value: 'notBlank' },
];

export const existaText = (filter: string) : boolean => {
    if(filter == 'blank' || filter == 'notBlank'){
        return false;
    }
    return true;
}

export const getContainerInfo = (isoCode: string | null): { size: string | null; type: string | null; } => {
    if (isoCode === null) {
      return { size: null, type: null };
    }
  
    // Ensure the ISO code is uppercase for consistency
    const code = isoCode.toUpperCase();
  
    // Define mappings for ISO codes to size and type
    const isoCodeMappings: { size: string; type: string; codes: string[]; }[] = [
      { size: "10", type: "DC", codes: ["10G1"] },
      { size: "20", type: "DC", codes: ["20G0", "20G1", "20G2", "20G3", "22G0", "22G1", "22G2", "22G3", "22G4", "22G8", "22G9"] },
      { size: "20", type: "HQ", codes: ["25G0", "26G0", "2EG0"] },
      { size: "40", type: "DC", codes: ["42G0", "42G1", "4CG0"] },
      { size: "40", type: "HQ", codes: ["45G0", "45G1"] },
      // Add more mappings here as needed, for example:
      { size: "45", type: "DC", codes: ["L0G1", "L2G1", "LLG1"] },
      { size: "45", type: "HQ", codes: ["L5G1", "LDG1", "LDG8", "LEG1", "LEG8", "LEG9", "LNG1"] },
      { size: "20", type: "RF", codes: ["20R0", "20R1", "20R2", "20R3", "20R8", "20R9", "22R0", "22R1", "22R7", "22R9", "25R1"] },
      { size: "40", type: "RF", codes: ["42R0", "42R1", "42R3", "42R9", "45R1", "45R9"] },
      { size: "45", type: "RF", codes: ["L5R0", "L5R1", "L5R2", "L5R3", "L5R4", "L5R8", "L5R9", "LNR1"] },
    ];
  
    // Find the matching mapping
    const mapping = isoCodeMappings.find(m => m.codes.includes(code));
  
    if (mapping) {
      return { size: mapping.size, type: mapping.type };
    } else {
      return { size: null, type: null };
    }
  }