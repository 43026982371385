export type Permission = string;

export interface User {
  permissions: Permission[];
}

export const hasPermission = (user: User|null, permission: Permission): boolean => {
    if(!user) return false;
    return user.permissions.includes(permission);
};

export const hasAnyPermission = (user: User|null, permissions: Permission[]): boolean => {
    if(!user) return false;
    return permissions.some(permission => hasPermission(user, permission));
};

export const hasAllPermissions = (user: User|null, permissions: Permission[]): boolean => {
    if(!user) return false;
    return permissions.every(permission => hasPermission(user, permission));
};