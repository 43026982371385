import { Badge } from "flowbite-react";
import React from "react";

interface OfferStausFormater {
    status: string;
}

const Status: React.FC<OfferStausFormater> = ({status}) =>
{   
    switch (status) {
        case 'Creată':
            return (<Badge color="blue">Creată</Badge>);
        break;
        case 'Trimisă':
            return (<Badge color="success">Trimisă</Badge>);
        break;
        case 'Modificată':
            return (<Badge color="warning">Modificată</Badge>);
        break;
        case 'Refuzată':
            return (<Badge color="red">Refuzată</Badge>);
        break;
        case 'Expirată':
            return (<Badge color="indigo">Expirată</Badge>);
        break;
    }
    return (<></>);
};
export default Status;