import React, { useCallback, useMemo, useState } from 'react';
import { Client } from '../../interfaces/Client';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { ColDef, ColGroupDef, ColumnState, GridReadyEvent, SideBarDef } from 'ag-grid-community';
import Actions from '../../components/Actions';
import { AG_GRID_LOCALE_RO } from '../../functions/langHelper';
import ActivityModal from '../activities/ActivityModal';
import Tooltip from '../../components/Tooltip';
import { FaRegAddressCard } from 'react-icons/fa6';
import { BiSolidOffer } from "react-icons/bi";
import { NavLink } from 'react-router-dom';
import "ag-grid-charts-enterprise";
import { useAuth } from '../../functions/useAuth';
import { useUser } from '../../components/UserContext';
import CheckPermission from '../../components/CheckPermission';
import { IoSend } from 'react-icons/io5';
import useGridState from '../../functions/useGridState';
import { Badge } from 'flowbite-react';

interface ClientProps {
  clients: Client[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onView: (id: number) => void;
  onEmailModal: (id: number) => void;
}

const Table: React.FC<ClientProps> = ({ clients, onEdit, onDelete, onView, onEmailModal }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [clientId, setClientId] = useState<(number | null)>(null);
  const { saveState, restoreState } = useGridState('clients-grid');
  const { user } = useUser();
  
  const onGridReady = useCallback((event: GridReadyEvent) => {
    const api = event.api;
    event.api.closeToolPanel();
    
    // Restaurăm starea salvată
    const savedState = localStorage.getItem(`agGrid-clients-grid`);
    if (savedState) {
      // Dacă există state salvat, îl restaurăm
      restoreState(api);
    } else {
      // Configurarea implicita a gridului
      event.api.setFilterModel(null);
      event.api.setColumnFilterModel('user.name', { values: [user?.name] });
      event.api.onFilterChanged();

      const columnState: ColumnState[] = [
          { colId: 'id', sort: 'desc', sortIndex: 0 }
      ];
      event.api.applyColumnState({ state: columnState, defaultState: { sort: null } });
    }
    // Adăugăm event listeners pentru salvarea stării
    const saveCurrentState = () => saveState(api);
    
    api.addEventListener('filterChanged', saveCurrentState);
    api.addEventListener('sortChanged', saveCurrentState);
    api.addEventListener('columnMoved', saveCurrentState);
    api.addEventListener('columnResized', saveCurrentState);
    
    // Optional: Cleanup function
    return () => {
      api.removeEventListener('filterChanged', saveCurrentState);
      api.removeEventListener('sortChanged', saveCurrentState);
      api.removeEventListener('columnMoved', saveCurrentState);
      api.removeEventListener('columnResized', saveCurrentState);
    };
  }, [user, saveState, restoreState]);

  const pagination = true;
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const gridOptions = {
    rowHeight: 35, 
    enableRangeSelection: true
  };

  const columns: ColDef<Client>[] = [
    { headerName: 'Id', field: 'id', sortable: true, filter: 'agNumberColumnFilter', floatingFilter: true, minWidth: 100, maxWidth: 110},
    { 
      headerName: 'Denumire', 
      floatingFilter: true,
      field: 'company', 
      sortable: true, 
      filter: 'agTextColumnFilter',
      minWidth: 130,
      cellRenderer: (params: any) => (<span onClick={() => onView(params.data.id)} className={`font-medium cursor-pointer ${params.data.actualizat ? 'text-blue-600' : 'text-red-600'} dark:text-blue-500 hover:underline`}>{ params.data.company }</span>)
    },
    { headerName: 'Email', field: 'email', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, minWidth: 150 },
    { headerName: 'Telefon', field: 'phone', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, minWidth: 150 },
    { headerName: 'CUI', field: 'gstNumber', sortable: true, width: 140, maxWidth: 140, filter: 'agTextColumnFilter', floatingFilter: true, minWidth: 140 },
    { headerName: 'TradeID', field: 'tradeId', sortable: true, width: 140, maxWidth: 140, filter: 'agTextColumnFilter', floatingFilter: true, minWidth: 140, hide: true },
    { headerName: 'Tip', field: 'transportDirection', valueFormatter: (params) => params.value ? (params.value as string[]).join(', ') : '', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, minWidth: 150 },
    { headerName: 'Echipament', field: 'equipmentUsed', valueFormatter: (params) => params.value ? (params.value as string[]).join(', ') : '', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, minWidth: 150, hide: true },
    { headerName: 'Țară înc.', field: 'loadingCountry', valueFormatter: (params : any) => params.value ? (params.value as string[]).join(', ') : '', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, minWidth: 150, hide: true },
    { headerName: 'Regiune înc.', field: 'loadingRegion', valueFormatter: (params : any) => params.value ? (params.value as string[]).join(', ') : '', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, minWidth: 150, hide: true },
    { headerName: 'Județ înc.', field: 'loadingState', valueFormatter: (params : any) => params.value ? (params.value as string[]).join(', ') : '', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, minWidth: 150, hide: true },
    { headerName: 'Port înc.', field: 'loadingPort', valueFormatter: (params : any) => params.value ? (params.value as string[]).join(', ') : '', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, minWidth: 150, hide: true },
    { headerName: 'Loc înc.', field: 'loadingLocation', valueFormatter: (params : any) => params.value ? (params.value as string[]).join(', ') : '', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, minWidth: 150, hide: true },
    { 
      headerName: 'Transporta', 
      field: 'isTransporting', 
      sortable: true, 
      filter: 'agSetColumnFilter', 
      floatingFilter: true, 
      minWidth: 90, 
      hide: true,
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      cellRenderer: (params: any) => (
          <Badge color={params.value ? "success" : "failure"}>
              {params.value ? 'Da' : 'Nu'}
          </Badge>
      ),
    },
    { headerName: 'Sales', field: 'user.name', sortable: true, filter: 'agSetColumnFilter', floatingFilter: true, minWidth: 150 },
    {
      headerName: 'Acțiuni', 
      minWidth: 130,
      maxWidth: 150,
      field: 'id',
      cellRenderer: (params: any) => (
        <div>
          <Actions 
            id={params.data.id} 
            onEdit={() => onEdit(params.data.id)} 
            onDelete={() => onDelete(params.data.id)} 
            moduleName="client"
            deletePermissions={['CLIENT_DELETE']}
            editPermissions={['CLIENT_UPDATE']}
          >
            <CheckPermission permissions={['ACTIVITY_CREATE']}>
              <Tooltip content="Adaugă activitate">
                <button type="button" className="text-green-500 hover:text-green-700 h-[20px] mt-1 flex items-center mx-1" aria-label="Info" onClick={() => {setClientId(params.data.id); setOpenModal(true)}}>
                  <FaRegAddressCard className="w-[17px] h-[17px] hover:scale-110 duration-75"/>
                </button>
              </Tooltip>
            </CheckPermission> 
            <CheckPermission permissions={['OFFER_CREATE']}>
              <Tooltip content="Adaugă ofertă">
                <NavLink to={`/crm/offers/form/client/${params.data.id}`}>
                  <button type="button" className="text-yellow-500 hover:text-yellow-700 h-[20px] mt-1 flex items-center mx-1" aria-label="Info">
                    <BiSolidOffer className="w-[17px] h-[17px] hover:scale-110 duration-75"/>
                  </button>
                </NavLink>
              </Tooltip>
            </CheckPermission>
          </Actions>
        </div>
      )
    }
  ];

  return (
    <>
      {
        user ? <>
          <AgGridReact 
            localeText={AG_GRID_LOCALE_RO}
            columnDefs={columns} 
            rowData={clients}
            defaultColDef={{ flex: 1, minWidth: 20 }}
            domLayout='autoHeight'
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            enableRangeSelection={true}
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            enableCharts={true}
            sideBar={true}
          />
        </> : <></>
      }
      {clientId ? 
        <ActivityModal
          clientId={clientId!}
          openModal={openModal}
          setOpenModal={setOpenModal}
        /> 
      : ''}
    </>
  );
};

export default Table;
