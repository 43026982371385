import React, { useState } from 'react';
import InputSug from "../../../components/InputSug";
import Input from "../../../components/Input";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { handleInputChange, handleSelectChange } from "../../../functions/selectHelper";
import { Booking, defaultRoute, Route } from "../../../interfaces/Booking";
import { RecommendationResp } from "../../../interfaces/Recommandation";
import { handleRecommendations, handleOptions, errorMessage } from '../../../functions/generalHelper';
import { Button, Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from 'flowbite-react';
import Actions from '../../../components/Actions';
import { formatDate, getDateFormat } from '../../../functions/dateHelper';
import DynamicPortSelect from '../../../components/DynamicPortSelect';

interface TabProps {
  booking: Booking;
  setBooking: React.Dispatch<React.SetStateAction<Booking>>;
  recommendations: RecommendationResp[];
}

const RouteTab: React.FC<TabProps> = ({ booking, setBooking, recommendations }) => {
  const fromRouteSuggestions = handleRecommendations(recommendations, 'booking-fromRoute');
  const toRouteSuggestions = handleRecommendations(recommendations, 'booking-fromRoute');
  // const vesselNameRouteSuggestions = handleRecommendations(recommendations, 'booking-vesselNameRoute');
  // const loopRouteOptions = handleOptions(recommendations, 'booking-loopRoute');

  const [newRoute, setNewRoute] = useState<Route>(defaultRoute);
  const [isEdit, setIsEdit] = useState<number>(10000);

  // add and edit functions:
  const handleAddOrEditRoute = () => {
    if(newRoute.vesselCode?.trim() == '') {
      errorMessage('Codul vasului este obligatoriu!');
      return;
    }

    if(isEdit != 10000){
      setBooking((prevBooking : Booking) => {
        const updatedRoute = [...prevBooking.routes];
        updatedRoute[isEdit] = newRoute;
        return { ...prevBooking, routes: updatedRoute };
      });
        setIsEdit(10000);
    }else{
      setBooking((prevBooking : Booking) => ({...prevBooking, routes: [...prevBooking.routes, newRoute] })); 
    }
    setNewRoute(defaultRoute);
  }

  const handleResetRoute = () => {
    setIsEdit(10000);
    setNewRoute(defaultRoute);
  }

  const handleEditRoute = (index : number) => {
    setIsEdit(index);
    setNewRoute(booking.routes[index]);
  }

  const handleDeleteRoute = (index : number) => {
    if(index == isEdit) {
        setIsEdit(10000);
        setNewRoute(defaultRoute);
    }
    setBooking((prevBooking : Booking) => ({ ...prevBooking, routes: prevBooking.routes.filter((routes : Route, curentIndex : number) => { return curentIndex != index}) })); 
  }

  return (
    <div>
        <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
            <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Rute</h4>
            <div className="overflow-x-auto">
                <Table striped>
                    <TableHead>
                        <TableHeadCell>Cod vas</TableHeadCell>
                        <TableHeadCell>De la</TableHeadCell>
                        <TableHeadCell>Până la</TableHeadCell>
                        <TableHeadCell>ETD</TableHeadCell>
                        <TableHeadCell>ATD</TableHeadCell>
                        <TableHeadCell>ETA</TableHeadCell>
                        <TableHeadCell>ATA</TableHeadCell>
                        <TableHeadCell>Acțiuni</TableHeadCell>
                    </TableHead>
                    <TableBody className="divide-y">
                        {booking.routes.map((route : Route, index : number) => (
                            <TableRow key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <TableCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                  {route.vesselCode}
                                </TableCell>
                                <TableCell>{ route.vesselName }</TableCell>
                                <TableCell>{ route.from }</TableCell>
                                <TableCell>{ route.to }</TableCell>
                                <TableCell>{formatDate(route.etd)}</TableCell>
                                <TableCell>{formatDate(route.atd)}</TableCell>
                                <TableCell>{formatDate(route.eta)}</TableCell>
                                <TableCell>{formatDate(route.ata)}</TableCell>
                                <TableCell>
                                    <Actions id={index} onEdit={() => handleEditRoute(index)} onDelete={() => handleDeleteRoute(index)} moduleName="Ruta"/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
        <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
            <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">{isEdit != 10000 ? 'Editează Rută' : 'Adaugă Rută'}</h4>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
              <Input 
                id="vesselName" 
                label="Nume vas"
                value={newRoute.vesselName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newRoute, setNewRoute, 'vesselName', e.target.value, defaultRoute)}
              />
              <Input 
                id="vesselCode" 
                label="Cod vas"
                value={newRoute.vesselCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newRoute, setNewRoute, 'vesselCode', e.target.value, defaultRoute)}
              />
              <DynamicPortSelect
                placeholder="De la"
                value={newRoute.from ? newRoute.from : ''}
                onChange={(newValue) => setNewRoute(prev => ({...prev, from: newValue.value}))}
              />
              <DynamicPortSelect
                placeholder="Până la"
                value={newRoute.to ? newRoute.to : ''}
                onChange={(newValue) => setNewRoute(prev => ({...prev, to: newValue.value}))}
              />
              <div>
                  <CustomDatePicker
                    selected={newRoute.etd}
                    onChange={(date) => setNewRoute({...newRoute, etd: date })} 
                    dateFormat={getDateFormat()}
                    locale="ro"
                    isClearable
                    placeholderText="ETD"
                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
              </div>
              <div>
                  <CustomDatePicker
                    selected={newRoute.atd}
                    onChange={(date) => setNewRoute({...newRoute, atd: date })} 
                    dateFormat={getDateFormat()}
                    locale="ro"
                    isClearable
                    placeholderText="ATD"
                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
              </div>
              <div>
                  <CustomDatePicker
                    selected={newRoute.eta}
                    onChange={(date) => setNewRoute({...newRoute, eta: date })} 
                    dateFormat={getDateFormat()}
                    locale="ro"
                    isClearable
                    placeholderText="ETA"
                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
              </div>
              <div>
                  <CustomDatePicker
                    selected={newRoute.ata}
                    onChange={(date) => setNewRoute({...newRoute, ata: date })} 
                    dateFormat={getDateFormat()}
                    locale="ro"
                    isClearable
                    placeholderText="ATA"
                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
              </div>
            </div>
            <div className="flex justify-end space-x-4 mt-3">
                <Button size="sm" color="gray" onClick={handleResetRoute}>{isEdit != 10000 ? 'Renunță la editare' : 'Resetează câmpuri'}</Button>
                <Button size="sm" onClick={handleAddOrEditRoute}>{isEdit != 10000 ? 'Editează rută' : 'Adaugă Rută'}</Button>
            </div>
        </div>
    </div>
  );
};

export default RouteTab;