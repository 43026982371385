import React, { useMemo, useState, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { ColDef, TextMatcherParams, GridReadyEvent, RowSelectionOptions, SelectionChangedEvent } from 'ag-grid-community';
import Actions from '../../../components/Actions';
import { AG_GRID_LOCALE_RO } from '../../../functions/langHelper';
import "ag-grid-charts-enterprise";
import { BillCheck, BookingBillTable } from '../../../interfaces/Booking';
import { createDateFromString, formatDate } from '../../../functions/dateHelper';
import { NavLink } from 'react-router-dom';
import { Badge, Dropdown } from 'flowbite-react';
import ArrivalNoticeModal from '../components/ArrivalNoticeModal';
import ViewBillModal from '../components/ViewBillModal';
import Tooltip from '../../../components/Tooltip';
import { FaRegAddressCard } from 'react-icons/fa6';
import { FaCheck } from "react-icons/fa";
import { MdDeleteOutline } from 'react-icons/md';
import DeleteModal from '../../../components/DeleteModal';
import { BookingService } from '../../../services/BookingService';
import { successMessage } from '../../../functions/generalHelper';

interface TableProps {
  bills: Partial<BookingBillTable>[];
  refresh: () => void;
}

const Table: React.FC<TableProps> = ({ bills, refresh }) => {
  const [billId, setBillId] = useState<number|undefined>(undefined);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [billCheckToDelete, setBillCheckToDelete] = useState<number>(0);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const pagination = true;
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const bookingService = new BookingService();

  const gridOptions = {
    rowHeight: 35,
    defaultColDef: {
      enableRowGroup: true,
    },
    alwaysMultiSort: true,
  };

    const onDelete = (id: number) => {
        setBillCheckToDelete(id);
        setShowModalDelete(true);
    };

    const handleDelete = async () => {
        if (billCheckToDelete !== 0) {
            await bookingService.deleteBillCheck(billCheckToDelete);
            setShowModalDelete(false);
            refresh();
            setBillCheckToDelete(0);
            successMessage('Verificare stearsa cu succes!');
        }
    };

    const updateStatus = async (id: number) => {
        await bookingService.updateBillCheck({status: 'ok', id: id});
        refresh();
        successMessage('Verificare editata cu succes!');
    };

  const columns = useMemo<ColDef<Partial<BillCheck>>[]>(() => [
    { 
        headerName: 'B/L', 
        field: 'billNumber', 
        sortable: true, 
        filter: 'agTextColumnFilter', 
        floatingFilter: true, 
        minWidth: 160,
        maxWidth: 160,
    },
    { 
        headerName: 'Navă', 
        field: 'vesselName', 
        sortable: true, 
        filter: 'agTextColumnFilter', 
        floatingFilter: true, 
        minWidth: 200,
        maxWidth: 200,
        cellRenderer: (params : any) => <>{params.data.vesselName || ''} - {params.data.vesselCode || ''}</>,
    },
    // { 
    //     headerName: 'Navă inițială', 
    //     field: 'initialVesselCode', 
    //     sortable: true, 
    //     filter: 'agTextColumnFilter', 
    //     floatingFilter: true, 
    //     minWidth: 160,
    // },
    { 
        headerName: 'Status', 
        field: 'status', 
        sortable: true, 
        filter: 'agTextColumnFilter', 
        floatingFilter: true, 
        minWidth: 120,
        maxWidth: 120,
        cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
        cellRenderer: (params : any) => (
            <Badge color={params.value == 'ok' ? 'green' : (params.value == 'initial' ? 'blue' : 'red')}>
                {`${params.value.charAt(0).toUpperCase()}${params.value.slice(1)}`}
            </Badge>
        )
    },
    { 
        headerName: 'Actiuni', 
        field: 'id', 
        sortable: true, 
        filter: 'agTextColumnFilter', 
        floatingFilter: true, 
        minWidth: 100,
        maxWidth: 100,
        cellRenderer: (params: any) => (
            <div>
                {
                    params.data.status == 'modificat' ? <>
                        <Tooltip content="Marcheaza ok">
                            <button type="button" className="text-green-500 hover:text-green-700 h-[20px] mt-1 flex items-center mx-1" aria-label="Info" onClick={() => {updateStatus(params.data.id)}}>
                                <FaCheck className="w-[17px] h-[17px] hover:scale-110 duration-75"/>
                            </button>
                        </Tooltip>
                    </> : <></>
                }
                <Tooltip content={`Șterge verificare`}>
                    <button type="button" onClick={() => onDelete(params.data.id!)} className="text-red-500 hover:text-red-700 h-[20px] mt-1 flex items-center mx-1" aria-label={`Șterge verificare`}>
                        <MdDeleteOutline className='w-[20px] h-[20px] hover:scale-110 duration-75'/>
                    </button>
                </Tooltip>
            </div>
        )
    },
    { 
        headerName: 'Modificări', 
        field: 'info', 
        sortable: true, 
        filter: 'agTextColumnFilter', 
        floatingFilter: true, 
        minWidth: 300,
        cellRenderer: (params : any) => <span className={params.data.status == 'modificat' ? 'text-red-700' : ''}>{params.value}</span>,
    },
  ], []);

  const onGridReady = useCallback((event: GridReadyEvent) => {
    event.api.closeToolPanel();
  }, []);

  return (
    <>
        <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={handleDelete} deleteText={'Sunteți sigur că doriți să ștergeți această verificare?'} />
        <AgGridReact 
            className="tabel-agGrid"
            localeText={AG_GRID_LOCALE_RO}
            columnDefs={columns} 
            rowData={bills}
            defaultColDef={{ flex: 1, minWidth: 20 }}
            domLayout='autoHeight'
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            gridOptions={gridOptions}
            enableCharts={true}
            enableRangeSelection={true}
            sideBar={true}
            onGridReady={onGridReady}
        />
    </>
  );
};

export default Table; 