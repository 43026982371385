import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'flowbite-react';
import { errorMessage, isNullOrEmpty, successMessage } from '../../../functions/generalHelper';
import Input from '../../../components/Input';
import { ArrivalNotice, defaultArrivalNotice } from '../../../interfaces/Booking';
import { handleInputChange } from '../../../functions/selectHelper';
import { BookingService } from '../../../services/BookingService';
import { getDateFormat } from '../../../functions/dateHelper';
import CustomDatePicker from '../../../components/CustomDatePicker';
import SendEmailsModal from './SendEmailsModal';
import Loader from '../../../components/CustomLoader';

interface ArrivalNoticeModalProps {
    type: string;
    ids?: number[];
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    documentType: string; // arrivalNotice, deliveryOrder, arrivalUpdate
    setDocumentType: React.Dispatch<React.SetStateAction<string>>; // arrivalNotice, deliveryOrder, arrivalUpdate
}

const ArrivalNoticeModal: React.FC<ArrivalNoticeModalProps> = ({ type, ids, showModal, setShowModal, documentType, setDocumentType }) => {
    const [arrivalNotice, setArrivalNotice] = useState<ArrivalNotice>(defaultArrivalNotice(type, documentType));
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [arrivalNoticeResponse, setArrivalNoticeResponse] = useState<{ errors: string[]; emails: { id: number; email: string | null; name: string | null; booking: string | null; }[]; }|null>(null);
    const bookingService = new BookingService();

    useEffect(() => {
        if (!showModal) {
            setArrivalNotice(defaultArrivalNotice(type, documentType));
        }else{
            setArrivalNotice(prev => ({...prev, type, documentType}));
        }
    }, [showModal, type, documentType, ids]);

    useEffect(() => {
        if (!showModal) {
            setDocumentType('');
        }
    }, [showModal]);

    useEffect(() => {
        if(documentType == 'deliveryOrder' && (type == 'booking' || type == 'bill') && ids && ids.length > 0){
            handleSubmit();
        }
    }, [arrivalNotice]);


    const handleSubmit = async (e?: React.FormEvent) => {
        if(e){
            e.preventDefault();
        }
        try {
            setLoading(true);
            if (type == 'vessel' && isNullOrEmpty(arrivalNotice.vesselCode)) {
                errorMessage('Codul vasului este obligatoriu!');
                return;
            }
            const response : { errors: string[]; emails: { id: number; email: string | null; name: string | null; booking: string | null; }[]; } = await bookingService.sendArrivalNotice({...arrivalNotice, ids});
            setShowModal(false);
            successMessage('Notificări create cu succes!');
            setArrivalNoticeResponse(response);
            setShowEmailModal(true);
            setArrivalNotice(defaultArrivalNotice(type, documentType));
        } catch (error) {
            errorMessage('A aparut o eroare. Va rugam incercati din nou!');
        }
        setLoading(false);
    };

    const saveSendMails = () => {
       setArrivalNoticeResponse(null);
    }

    return (   
        <>
            <Loader isLoading={loading} />
            { arrivalNoticeResponse ? <SendEmailsModal title={documentType == 'arrivalNotice' ? 'Arrival Notice' : (documentType == 'deliveryOrder' ? 'Delivery Order' : 'Avizare')} response={arrivalNoticeResponse} showModal={showEmailModal} setShowModal={setShowEmailModal} onSave={saveSendMails} setLoading={setLoading} /> : <></> }
            <Modal show={showModal} size="3xl" popup onClose={() => setShowModal(false)}>
                <Modal.Header>{ documentType == 'arrivalNotice' ? 'Arrival Notice' : (documentType == 'deliveryOrder' ? 'Delivery Order' : 'Avizare')}</Modal.Header>
                <Modal.Body className="overflow-visible">
                    <div className="space-y-2 pt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="gap-4 grid grid-cols-2 mb-2">
                                {type == 'vessel' ? <>
                                    <Input 
                                        id="vesselCode"
                                        label="Cod vas"
                                        value={arrivalNotice.vesselCode}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(arrivalNotice, setArrivalNotice, 'vesselCode', e.target.value, defaultArrivalNotice(type, documentType))}
                                    />
                                </> : <></>}
                                {documentType != 'deliveryOrder' ? <>
                                    <CustomDatePicker
                                        selected={arrivalNotice.ata}
                                        onChange={(date) => setArrivalNotice(prev => ({ ...prev, ata: date! }))}
                                        dateFormat= {getDateFormat()}
                                        locale="ro"
                                        placeholderText="ATA"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />    
                                </> : <></>}
                            </div>
                            <div className="flex justify-end">
                                <Button size="sm" type="submit">Salvează</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ArrivalNoticeModal;
