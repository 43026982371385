import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LOGIN_MUTATION } from './graphql/mutations';
import { useAuth } from '../../functions/useAuth';
import { errorMessage, successMessage } from '../../functions/generalHelper';
import { Button } from 'flowbite-react';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const navigate = useNavigate();
  const { login, isAuthenticated } = useAuth();

  const [loginMutation, { loading }] = useMutation(LOGIN_MUTATION);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/crm/clients/index');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const rememberMe = localStorage.getItem('rememberMe') === 'true';
    setRemember(rememberMe);
    if (rememberMe) {
      const savedEmail = localStorage.getItem('savedEmail');
      if (savedEmail) setEmail(savedEmail);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { data } = await loginMutation({ variables: { email, password, remember } });
      login(data.login.access_token, remember);
      if (remember) {
        localStorage.setItem('savedEmail', email);
      } else {
        localStorage.removeItem('savedEmail');
      }
      successMessage('Conectare realizată cu succes!');
      navigate('/crm/clients/index');
    } catch (err) {
      errorMessage('Emailul sau parola sunt greșite!');
    }
  };

  return (
    <div className="bg-[url('https://flowbite.s3.amazonaws.com/blocks/marketing-ui/authentication/background.jpg')] bg-no-repeat bg-cover bg-center bg-gray-700 bg-blend-multiply bg-opacity-60">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen pt:mt-0">
          <a className="flex items-center mb-6 text-2xl font-semibold text-white">
              <img className="w-12 h-12 mr-2" src="icon.png" alt="logo" />
              Team Logistic Specialists
          </a>
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
              <div className="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl dark:text-white">
                      Autentificare
                  </h1>
                  <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                      <div>
                          <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adresa de email</label>
                          <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="nume@tls-romania.com" required />
                      </div>
                      <div>
                          <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Parola</label>
                          <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" id="confirm-password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" required />
                      </div>
                      <div className="flex items-center justify-between">
                          <div className="flex items-start">
                              <div className="flex items-center h-5">
                                <input checked={remember} onChange={(e) => setRemember(e.target.checked)} id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 text-primary rounded bg-gray-50 focus:ring-3 focus:ring-softprimary dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary dark:ring-offset-gray-800" />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Ține-mă minte</label>
                              </div>
                          </div>
                          <a className="text-sm font-medium text-primary hover:underline dark:text-primary">Ai uitat parola?</a>
                      </div>
                      <Button className="w-full" disabled={loading} type="submit">{loading ? 'Se încarcă...' : 'Login'}</Button>
                      <p className="text-sm font-light text-center text-gray-500 dark:text-gray-300">
                          <a className="font-medium text-primary hover:underline dark:text-primary">Ai nevoie de ajutor?</a>
                      </p>
                  </form>
              </div>
          </div>
      </div>
    </div>
  );
};

export default Login;