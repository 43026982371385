import React, { useState, useEffect } from 'react';
import { MovementFileService } from '../../../services/MovementFileService';
import { defaultMovementFile, movementColumnOptions, MovementFile } from '../../../interfaces/Movement';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import { Button, Modal } from 'flowbite-react';
import Input from '../../../components/Input';
import { handleInputChange, handleSelectChange } from '../../../functions/selectHelper';
import CustomSelect from '../../../components/CustomSelect';
import { useNavigate } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';

interface ModalFileProps {
    id: number;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalFile: React.FC<ModalFileProps> = ({ id, showModal, setShowModal }) => {
    const [movementFile, setMovementFile] = useState<MovementFile>(defaultMovementFile);
    const movementFileService = new MovementFileService();
    const navigate = useNavigate();

    useEffect(() => {
        setMovementFile(defaultMovementFile);
    }, [showModal])

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if(id != 0){
                // const ser = await movementFileService.update(activity);
                // setOpenModal(false);
                // successMessage('Activitate actualizată cu success!');
                // onEdit!(act);
            }else{
                const newId = await movementFileService.create(movementFile);
                setShowModal(false);
                successMessage('Fisier adaugat cu success!');
                navigate(`/bookings/movementsConfiguration/view/${newId}`);
            }
        } catch (error) {
            errorMessage('Denumirea este obligatorie!');
        }
    };

    const handleIndexChange = (index : number, value : string|number, field : string) => {
        setMovementFile(prev => {
            const columns = prev.columns;
            columns[index] = {...columns[index], [field]: value};
            return { ...prev, columns: columns };
        });
    }

    return (
        <>
            <Modal show={showModal} size="2xl" popup onClose={() => setShowModal(false)}>
                <Modal.Header>
                    {id ? 'Editează date fisier' : 'Adaugă date fisier'}
                </Modal.Header>
                <Modal.Body className="overflow-visible">
                    <div className="space-y-2 pt-5">
                        <form onSubmit={handleSubmit} className="gap-4 grid">            
                            <div className="mb-1">
                                <Input 
                                    id="name" 
                                    label="Denumire"
                                    value={movementFile.name}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movementFile, setMovementFile, 'name', e.target.value, defaultMovementFile)}
                                />
                            </div>
                            <div className="mb-1">
                                <Input 
                                    id="description" 
                                    label="Detalii"
                                    value={movementFile.description}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movementFile, setMovementFile, 'description', e.target.value, defaultMovementFile)}
                                />
                            </div>
                            <div className="mb-1">
                                <Input 
                                    id="headerRow" 
                                    label="Rand pentru titluri"
                                    type="number"
                                    value={movementFile.headerRow}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movementFile, setMovementFile, 'headerRow', e.target.value, defaultMovementFile)}
                                />
                            </div>
                            <div className="mb-1">
                                <Input 
                                    id="terminal" 
                                    label="Denumire terminal"
                                    value={movementFile.terminal}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movementFile, setMovementFile, 'terminal', e.target.value, defaultMovementFile)}
                                />
                            </div>
                            <div className="mb-1">
                                <CustomSelect
                                    value={[{label: 'Excel', value: 'Excel'}, {label: 'CSV', value: 'CSV'}].find(option => option.value === movementFile.type)}
                                    onChange={(data) => handleSelectChange(movementFile, setMovementFile, 'type', data)}
                                    options={[{label: 'Excel', value: 'Excel'}, {label: 'CSV', value: 'CSV'}]}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Tip fisier"
                                />
                            </div>

                            {movementFile.columns.map((field, index) => (
                                <>
                                    <div className="grid grid-cols-2 gap-2" key={`mainDiv-${index}`}>
                                        <div key={`firstDiv-${index}`}>
                                            <CustomSelect
                                                key={`columnValue-${index}`}
                                                value={movementColumnOptions.find(option => option.value === field.field)}
                                                onChange={(data) => handleIndexChange(index, data.value, 'field')}
                                                options={movementColumnOptions.filter(option => movementFile.columns.every(obj => obj.field !== option.value))}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Camp"
                                            />
                                        </div>
                                        <div key={`secondDiv-${index}`}>
                                            <Input 
                                                key={`header-${index}`} 
                                                id={`header-${index}`} 
                                                label="Titlu Coloană"
                                                value={field.header}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleIndexChange(index, e.target.value.trim(), 'header')}
                                            />
                                        </div>
                                    </div>
                                </>
                            ))}
                            <div>
                                <Button size="sm" onClick={() => {setMovementFile(prev => ({...prev, columns: [...prev.columns, {field: '', header: ''}]}))}} ><IoMdAdd /></Button>
                            </div>
                            <div className="flex justify-end">
                                <Button size="sm" type="submit">Salvează</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalFile;