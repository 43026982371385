
import Input from "../../../components/Input";
import { handleInputChange, handleSelectChange } from "../../../functions/selectHelper";
import { Client} from "../../../interfaces/Client";
import { useEffect, useState } from "react";
import { Button } from 'flowbite-react';
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from "flowbite-react";
import { Financial, defaultFinancial } from '../../../interfaces/Financial';
import Actions from '../../../components/Actions';
import { Currency } from '../../../interfaces/Currency';
import { CurrencyService } from '../../../services/CurrencyService';
import { errorMessage } from '../../../functions/generalHelper';
import CustomSelect from '../../../components/CustomSelect';
import { RecommendationResp } from "../../../interfaces/Recommandation";

interface TabProps {
  client: any;
  setClient: React.Dispatch<React.SetStateAction<any>>;
}

const Financials: React.FC<TabProps> = ({ client, setClient }) => {
    const [newFinancial, setNewFinancial] = useState<Financial>(defaultFinancial);
    const [isEdit, setIsEdit] = useState<number>(10000);
    const currencyService = new CurrencyService();
    const [currencies, setCurrencies] = useState<Currency[]>([]);

    useEffect(() => {
        currencyService.getCurrencies().then((data) => setCurrencies(data));
    }, []);
    const currencyOptions = currencies.map(currency => ({ value: currency.id, label: currency.name }));

    const handleAddOrEditFinancial = () => {
        if(newFinancial.bankName?.trim() == '' || newFinancial.iban?.trim() == ''){
            errorMessage('Numele băncii și codul IBAN suntobligatorii obligatoriu!');
            return;
        }
        if(isEdit != 10000){
            setClient((prevClient : Client) => {
                const updatedFinancials = [...prevClient.financials];
                updatedFinancials[isEdit] = newFinancial;
                return { ...prevClient, financials: updatedFinancials };
              });
            setIsEdit(10000);
        }else{
            setClient({ ...client, financials: [...client.financials, newFinancial] }); 
        }
        setNewFinancial(defaultFinancial);
    }

    const handleResetFinancial = () => {
        setIsEdit(10000);
        setNewFinancial(defaultFinancial);
    }

    const handleEditFinancial = (index : number) => {
        setIsEdit(index);
        setNewFinancial(client.financials[index]);
    }

    const handleDeleteFinancial = (index : number) => {
        if(index == isEdit) {
            setIsEdit(10000);
            setNewFinancial(defaultFinancial);
        }
        setClient({ ...client, financials: client.financials.filter((financials : Financial, curentIndex : number) => { return curentIndex != index}) }); 
    }

    return (
        <div>
            <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
                <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Financiar</h4>
                <div className="overflow-x-auto">
                    <Table striped>
                        <TableHead>
                            <TableHeadCell>Nume bancă</TableHeadCell>
                            <TableHeadCell>Cont IBAN</TableHeadCell>
                            <TableHeadCell>Monedă</TableHeadCell>
                            <TableHeadCell>Sucursală</TableHeadCell>
                            <TableHeadCell>Adresa bancă</TableHeadCell>
                            <TableHeadCell>Acțiuni</TableHeadCell>
                        </TableHead>
                        <TableBody className="divide-y">
                            {client.financials.map((financial : Financial, index : number) => (
                                <TableRow key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <TableCell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        {financial.bankName}
                                    </TableCell>
                                    <TableCell>{financial.iban}</TableCell>
                                    <TableCell>{currencies.find((item: Currency) => item.id === financial.currencyId)?.name}</TableCell>
                                    <TableCell>{financial.branch}</TableCell>
                                    <TableCell>{financial.bankAddress}</TableCell>
                                    <TableCell>
                                        <Actions id={index} onEdit={() => handleEditFinancial(index)} onDelete={() => handleDeleteFinancial(index)} moduleName="Financiar"/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <div className="rounded-lg border border-gray-200 bg-white p-5 relative my-6">
                <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">{isEdit != 10000 ? 'Editează Financiar' : 'Adaugă Financiar'}</h4>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
                    <Input 
                        id="bankName" 
                        label="Nume bancă"
                        value={newFinancial.bankName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newFinancial, setNewFinancial, 'bankName', e.target.value, defaultFinancial)}
                    />
                    <Input 
                        id="iban" 
                        label="Cont IBAN"
                        value={newFinancial.iban}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newFinancial, setNewFinancial, 'iban', e.target.value, defaultFinancial)}
                    />
                    <CustomSelect
                        value={currencyOptions.find(user => user.value == newFinancial.currencyId) ? currencyOptions.find(user => user.value == newFinancial.currencyId) : null}
                        onChange={(data) => { handleSelectChange(newFinancial, setNewFinancial, 'currencyId', data) }}
                        options={currencyOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Monedă"
                    />
                    <Input 
                        id="branch" 
                        label="Sucursală"
                        value={newFinancial.branch}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newFinancial, setNewFinancial, 'branch', e.target.value, defaultFinancial)}
                    />
                    <Input 
                        id="bankAddress" 
                        label="Adresa bancă"
                        value={newFinancial.bankAddress}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newFinancial, setNewFinancial, 'bankAddress', e.target.value, defaultFinancial)}
                    />
                </div>
                <div className="flex justify-end space-x-4 mt-3">
                    <Button size="sm" color="gray" onClick={handleResetFinancial}>{isEdit != 10000 ? 'Renunță la editare' : 'Resetează câmpuri'}</Button>
                    <Button size="sm" onClick={handleAddOrEditFinancial}>{isEdit != 10000 ? 'Editează Financiar' : 'Adaugă Financiar'}</Button>
                </div>
            </div>
        </div>
    );
};

export default Financials;