import React, { useState, useRef, useEffect } from 'react';
import Input from './Input';

interface InputSugProps {
  id: string;
  label: string;
  suggestions?: string[];
  borderColor?: string;
  errorColor?: string;
  placeholder?: string;
  type?: string;
  errorText?: string;
  value: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  [x: string]: any;
}

const InputSug: React.FC<InputSugProps> = ({
  id,
  label,
  suggestions = [],
  borderColor = 'primary',
  errorColor = 'text-red-600 dark:text-red-400',
  placeholder = ' ',
  errorText = '',
  type='text',
  value,
  onChange,
  ...props
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [position, setPosition] = useState<'above' | 'below'>('below');
  const inputRef = useRef<HTMLDivElement>(null);
  const suggestionsRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (inputRef.current && suggestionsRef.current) {
        const inputRect = inputRef.current.getBoundingClientRect();
        const suggestionsRect = suggestionsRef.current.getBoundingClientRect();
        const spaceAbove = inputRect.top;
        const spaceBelow = window.innerHeight - inputRect.bottom;

        if (spaceBelow < suggestionsRect.height && spaceAbove > suggestionsRect.height) {
          setPosition('above');
        } else {
          setPosition('below');
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [filteredSuggestions]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    const userInput = e.target.value;
    filterSuggestions(userInput);
  };

  const handleSuggestionClick = (suggestion: string) => {
    onChange({ target: { value: suggestion } } as React.ChangeEvent<HTMLInputElement>);
    setShowSuggestions(false);
  };

  const handleFocus = () => {
    filterSuggestions(value);
    setShowSuggestions(true);
  };

  const handleBlur = () => {
    setTimeout(() => setShowSuggestions(false), 100); // Delay to allow click event
  };

  const filterSuggestions = (inputValue: string | null | undefined) => {
    if (inputValue == null) {
      setFilteredSuggestions(suggestions);
      return;
    }
    const filtered = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredSuggestions(filtered);
  };

  return (
    <div ref={inputRef} className="relative">
      <Input
        id={id}
        label={label}
        borderColor={borderColor}
        errorColor={errorColor}
        placeholder={placeholder}
        errorText={errorText}
        type={type}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      />
      {showSuggestions && filteredSuggestions.length > 0 && (
        <ul
          ref={suggestionsRef}
          className={`absolute w-full bg-white border border-gray-300 dark:bg-gray-800 dark:border-gray-600 z-20 rounded-md overflow-auto max-h-[300px] ${
            position === 'above' ? 'bottom-full mb-1' : 'top-full mt-1'
          }`}
        >
          {filteredSuggestions.map((suggestion, index) => (
            <li
              key={index}
              className="ps-3 pe-2 py-1 cursor-pointer hover:bg-softprimary dark:hover:bg-softprimary border-b"
              onMouseDown={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default InputSug;
