import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import { SingleValue, ActionMeta } from 'react-select';
import AsyncSelect from 'react-select/async';
import { LabelValueString } from '../interfaces/Recommandation';
import AsyncCreatableSelect from 'react-select/async-creatable';

interface FloatingLabelSelectProps{
  placeholder: string;
  onChange: (newValue: LabelValueString) => void;
  value?: string;
  creatable?: boolean;
  props?: [];
}

const DynamicPortSelect: React.FC<FloatingLabelSelectProps> = memo(({ placeholder, onChange, value, creatable=false, ...props }) => {
  const [isFilled, setIsFilled] = useState(false);
  const [isFocused, setIsFocused] = useState(false); 
  const [defaultOptions, setDefaultOptions] = useState<LabelValueString[]>([]); 
  const cache = useMemo(() => new Map<string, LabelValueString[]>(), []);
  const [selectedOptions, setSelectedOptions] = useState<LabelValueString[]>([]); 

  useEffect(() => {
    fetchOptions('').then((data) => {
      setDefaultOptions(data.map((port : {name: string; code: string;}) => ({label: port.name, value: port.code})));
    });
  }, []);

  useEffect(() => {
    if(value && value.length > 2){
      setIsFilled(true);
      const option = selectedOptions.find((option) => option.value === value);
      if(!option){
        fetchOptions(value).then((data) => {
          const found = data.find((port : {name: string; code: string;}) => port.code === value);
          if(found){
            setSelectedOptions(prev => [...prev, {label: found.name, value: found.code}]);
          }else{
            setSelectedOptions(prev => [...prev, {label: value, value: value}]);
          }
        });
      }
    }else{
      setIsFilled(false);
    }
  }, [value]);

  const loadOptions = (inputValue: string) =>
    new Promise<LabelValueString[]>(async (resolve) => {
      const ports = await fetchOptions(inputValue);
      const portsToPush : LabelValueString[] = [];
      if(ports && ports.length !== 0){
        selectedOptions.forEach((option) => {
          if(!ports.find((item : LabelValueString) => item.value == option.value)){
            portsToPush.push(option);
          }
        });
        if(portsToPush.length > 0){
          resolve([...ports.map((port : {name: string; code: string;}) => ({label: port.name, value: port.code})), ...portsToPush]);
        }
        resolve(ports.map((port : {name: string; code: string;}) => ({label: port.name, value: port.code})));
      }
      selectedOptions.forEach((option) => {
        portsToPush.push(option);
      });
      resolve(portsToPush);
  });

  const fetchOptions = useCallback(async (term: string) => {
    if (cache.has(term)) {
      return cache.get(term) || [];
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/ports/search?term=${term}`);
      const data = await response.json();
      cache.set(term, data);
      return data;
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }, [cache]);

  const handleChange = useCallback(
    (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
      const newValueOption = newValue as SingleValue<LabelValueString>;
      if(newValueOption){
        setSelectedOptions(prev => [...prev, newValueOption]);
        onChange(newValue as LabelValueString);
      }
    },
    [onChange]
  );

  const handleFocus = useCallback(() => setIsFocused(true), []);
  const handleBlur = useCallback(() => setIsFocused(false), []);

  return (
    <div className="floating-label-select floating-label-async-select w-full">
      { creatable ? <>
        <AsyncCreatableSelect
          {...props}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          value={selectedOptions.find((option) => option.value === value)}
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          cacheOptions
        />
      </> : <>
        <AsyncSelect
          {...props}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          value={selectedOptions.find((option) => option.value === value)}
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          cacheOptions
        />
      </>}
      <label className={`floating-label ${isFilled ? 'filled' : ''} ${isFocused ? 'focused' : ''}`}>
        {placeholder}
      </label>
    </div>
  );
});

DynamicPortSelect.displayName = 'DynamicPortSelect';

export default DynamicPortSelect;