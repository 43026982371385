import React, { useMemo, useEffect } from 'react';
import { hasAllPermissions, hasAnyPermission, Permission } from '../interfaces/Permissions';
import Spinner from './Spinner';
import { useUser } from './UserContext';

interface CheckPermissionProps {
    permissions: Permission[];
    logic?: string,
    children: React.ReactNode;
    loadingComponent?: React.ReactNode;
}

const CheckPermission: React.FC<CheckPermissionProps> = ({ 
  permissions = [], 
  logic = 'OR', 
  children,
  loadingComponent = <Spinner />
}) => {
    const { user, logout } = useUser();

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (user === null) {
            timeoutId = setTimeout(() => {
                console.log('User not loaded after 10 seconds, logging out...');
                logout();
            }, 10000);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [user, logout]);

    const hasPermission = useMemo(() => {
        if(permissions.length === 0) return true;
        if (!user) return false;
        if (permissions.length === 1) {
            return hasAnyPermission(user, permissions);
        }
        return logic === 'AND' 
            ? hasAllPermissions(user, permissions) 
            : hasAnyPermission(user, permissions);
    }, [user, permissions, logic]);

    if (user == null) {
        return <>{loadingComponent}</>;
    }

    return hasPermission ? <>{children}</> : null;
};

export default CheckPermission;