import React from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { ColDef } from 'ag-grid-community';
import { AG_GRID_LOCALE_RO } from '../../functions/langHelper';
import "ag-grid-charts-enterprise";
import { RecommendationCategory } from '../../interfaces/Recommandation';

interface RecommandationTableProps {
  recommandations: RecommendationCategory[];
  onEdit: (slug: string) => void;
}

const Table: React.FC<RecommandationTableProps> = ({ recommandations, onEdit }) => {
  const pagination = true;
  const paginationPageSize = 100;
  const paginationPageSizeSelector = [50, 100, 250, 1000];
  const gridOptions = {rowHeight: 35};
  const columns: ColDef<RecommendationCategory>[] = [
    { 
        sortable: true, 
        filter: true,
        headerName: 'Id',
        valueGetter: 'node.rowIndex + 1',
        minWidth: 110, 
        maxWidth: 110,
    },
    { 
      headerName: 'Categorie', 
      field: 'categoryName', 
      sortable: true, 
      filter: true,
      cellRenderer: (params : any) => <><span onClick={() => onEdit(params.data.categorySlug)} className={`font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline`}>{ params.value }</span></>
    },
  ];

  return (
    <>
      <AgGridReact 
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={recommandations}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
        gridOptions={gridOptions}
        enableRangeSelection={true}
      />
    </>
  );
};

export default Table;
