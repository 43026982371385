import React, {useState} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Booking, BookingBill } from '../../../../interfaces/Booking';
import { CellRenderer } from '../../../../functions/agGridHelper';
import { AG_GRID_LOCALE_RO } from '../../../../functions/langHelper';
import Actions from '../../../../components/Actions';
import ViewBillModal from '../../components/ViewBillModal';

interface BillsTableProps {
  booking: Booking;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

const BillsTable: React.FC<BillsTableProps> = ({ booking, onEdit, onDelete }) => {
  const [viewId, setViewId] = useState<number>(0);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);

  const columns: ColDef<BookingBill>[] = [
    { 
      headerName: 'Număr', 
      field: 'code',
      cellRenderer: (params: any) => (
        <a onClick={() => {setViewId(params.data.id); setShowViewModal(true); }} className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline">{params.value}</a>
      )
    },
    { 
      headerName: 'Shipper', 
      field: 'shipperName', 
      cellRenderer: CellRenderer, 
    },
    { 
      headerName: 'Consignee', 
      field: 'consigneeName', 
      cellRenderer: CellRenderer,
    },
    { 
      headerName: 'Notify', 
      field: 'notifyName', 
      cellRenderer: CellRenderer,
    },
    { 
      headerName: 'Marfă', 
      field: 'shortCommodity',
      cellRenderer: CellRenderer,
    },
    { 
      headerName: 'Terminal', 
      field: 'terminal',
      cellRenderer: CellRenderer,
    },
    {
      headerName: 'Acțiuni',
      width: 150, 
      maxWidth: 150,
      field: 'id',
      cellRenderer: (params: any) => (
        <div>
          <Actions 
            id={params.data.id} 
            onEdit={() => onEdit(params.data.id)} 
            onDelete={() => onDelete(params.data.id)} 
            moduleName="B/L"
          />
        </div>
      )
    }
  ];

  return (
    <div className="ag-theme-quartz">
      <ViewBillModal id={viewId} showModal={showViewModal} setShowModal={setShowViewModal} />
      <AgGridReact 
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={booking.bookingBills}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={false}
        gridOptions={{rowHeight: 35}}
        enableRangeSelection={true}
      />
    </div>
  );
};

export default BillsTable;