import React from 'react';
import { bookingStatuses } from '../../../../interfaces/Booking';

interface BookingProgressProps {
  currentStatus: string;
}

const BookingProgress: React.FC<BookingProgressProps> = ({ currentStatus }) => {
  const currentStatusIndex = bookingStatuses.findIndex(status => status.name === currentStatus);

  return (
    <div style={{ width: '100%', padding: '0' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {bookingStatuses.map((status, index) => {
          const isCompleted = index < currentStatusIndex;
          const isCurrent = index === currentStatusIndex;
          
          return (
            <div key={status.name} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', position: 'relative' }}>
              <div style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                backgroundColor: isCompleted ? '#4CAF50' : isCurrent ? '#2196F3' : '#E0E0E0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '20px',
                position: 'relative',
                zIndex: 1
              }}>
                <status.Icon size={15} />
              </div>
              {index < bookingStatuses.length - 1 && (
                <div style={{
                  position: 'absolute',
                  top: '20px',
                  left: '50%',
                  width: '100%',
                  height: '2px',
                  backgroundColor: isCompleted ? '#4CAF50' : '#E0E0E0',
                  zIndex: 0
                }} />
              )}
              <span style={{
                marginTop: '5px',
                fontSize: '12px',
                fontWeight: isCompleted || isCurrent ? 'bold' : 'normal',
                color: isCompleted || isCurrent ? '#000' : '#757575',
                textAlign: 'center'
              }}>
                {status.name}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BookingProgress;