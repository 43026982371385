export const AG_GRID_LOCALE_RO = {
    // Set Filter
    selectAll: '(Selectează tot)',
    selectAllSearchResults: '(Selectează toate rezultatele căutării)',
    addCurrentSelectionToFilter: 'Adaugă selecția curentă la filtru',
    searchOoo: 'Căutare...',
    blanks: '(Goluri)',
    noMatches: 'Fără potriviri',

    // Number Filter & Text Filter
    filterOoo: 'Filtrează...',
    equals: 'Egal',
    notEqual: 'Nu este egal',
    blank: 'Gol',
    notBlank: 'Nu este gol',
    empty: 'Alege una',

    // Number Filter
    lessThan: 'Mai puțin decât',
    greaterThan: 'Mai mult decât',
    lessThanOrEqual: 'Mai puțin sau egal cu',
    greaterThanOrEqual: 'Mai mult sau egal cu',
    inRange: 'Între',
    inRangeStart: 'De la',
    inRangeEnd: 'Până la',

    // Text Filter
    contains: 'Conține',
    notContains: 'Nu conține',
    startsWith: 'Începe cu',
    endsWith: 'Se termină cu',

    // Date Filter
    dateFormatOoo: 'dd.MM.yyyy',
    before: 'Înainte',
    after: 'După',

    // Filter Conditions
    andCondition: 'ȘI',
    orCondition: 'SAU',

    // Filter Buttons
    applyFilter: 'Aplică',
    resetFilter: 'Resetează',
    clearFilter: 'Curăță',
    cancelFilter: 'Anulează',

    // Filter Titles
    textFilter: 'Filtru text',
    numberFilter: 'Filtru număr',
    dateFilter: 'Filtru dată',
    setFilter: 'Filtru set',

    // Group Column Filter
    groupFilterSelect: 'Selectează câmp:',

    // Advanced Filter
    advancedFilterContains: 'conține',
    advancedFilterNotContains: 'nu conține',
    advancedFilterTextEquals: 'egal',
    advancedFilterTextNotEqual: 'nu este egal',
    advancedFilterStartsWith: 'începe cu',
    advancedFilterEndsWith: 'se termină cu',
    advancedFilterBlank: 'este gol',
    advancedFilterNotBlank: 'nu este gol',
    advancedFilterEquals: '=',
    advancedFilterNotEqual: '!=',
    advancedFilterGreaterThan: '>',
    advancedFilterGreaterThanOrEqual: '>=',
    advancedFilterLessThan: '<',
    advancedFilterLessThanOrEqual: '<=',
    advancedFilterTrue: 'este adevărat',
    advancedFilterFalse: 'este fals',
    advancedFilterAnd: 'ȘI',
    advancedFilterOr: 'SAU',
    advancedFilterApply: 'Aplică',
    advancedFilterBuilder: 'Constructor',
    advancedFilterValidationMissingColumn: 'Lipsă coloană',
    advancedFilterValidationMissingOption: 'Lipsă opțiune',
    advancedFilterValidationMissingValue: 'Lipsă valoare',
    advancedFilterValidationInvalidColumn: 'Coloană negăsită',
    advancedFilterValidationInvalidOption: 'Opțiune negăsită',
    advancedFilterValidationMissingQuote: 'Valoarea nu are o ghilimele de încheiere',
    advancedFilterValidationNotANumber: 'Valoarea nu este un număr',
    advancedFilterValidationInvalidDate: 'Valoarea nu este o dată validă',
    advancedFilterValidationMissingCondition: 'Lipsă condiție',
    advancedFilterValidationJoinOperatorMismatch: 'Operatorii de unire din cadrul unei condiții trebuie să fie aceeași',
    advancedFilterValidationInvalidJoinOperator: 'Operator de unire negăsit',
    advancedFilterValidationMissingEndBracket: 'Lipsă paranteză de încheiere',
    advancedFilterValidationExtraEndBracket: 'Prea multe paranteze de încheiere',
    advancedFilterValidationMessage: 'Expresia are o eroare. ${variable} - ${variable}.',
    advancedFilterValidationMessageAtEnd: 'Expresia are o eroare. ${variable} la sfârșitul expresiei.',
    advancedFilterBuilderTitle: 'Filtru Avansat',
    advancedFilterBuilderApply: 'Aplică',
    advancedFilterBuilderCancel: 'Anulează',
    advancedFilterBuilderAddButtonTooltip: 'Adaugă Filtru sau Grup',
    advancedFilterBuilderRemoveButtonTooltip: 'Elimină',
    advancedFilterBuilderMoveUpButtonTooltip: 'Mută În Sus',
    advancedFilterBuilderMoveDownButtonTooltip: 'Mută În Jos',
    advancedFilterBuilderAddJoin: 'Adaugă Grup',
    advancedFilterBuilderAddCondition: 'Adaugă Filtru',
    advancedFilterBuilderSelectColumn: 'Selectează o coloană',
    advancedFilterBuilderSelectOption: 'Selectează o opțiune',
    advancedFilterBuilderEnterValue: 'Introdu o valoare...',
    advancedFilterBuilderValidationAlreadyApplied: 'Filtrul curent a fost deja aplicat.',
    advancedFilterBuilderValidationIncomplete: 'Nu toate condițiile sunt complete.',
    advancedFilterBuilderValidationSelectColumn: 'Trebuie să selectați o coloană.',
    advancedFilterBuilderValidationSelectOption: 'Trebuie să selectați o opțiune.',
    advancedFilterBuilderValidationEnterValue: 'Trebuie să introduceți o valoare.',

    // Side Bar
    columns: 'Coloane',
    filters: 'Filtre',

    // columns tool panel
    pivotMode: 'Mod Pivot',
    groups: 'Grupuri de Rânduri',
    rowGroupColumnsEmptyMessage: 'Trageți aici pentru a seta grupuri de rânduri',
    values: 'Valori',
    valueColumnsEmptyMessage: 'Trageți aici pentru a agrega',
    pivots: 'Etichete de Coloane',
    pivotColumnsEmptyMessage: 'Trageți aici pentru a seta etichete de coloane',

    // Header of the Default Group Column
    group: 'Grup',

    // Row Drag
    rowDragRow: 'rând',
    rowDragRows: 'rânduri',

    // Other
    loadingOoo: 'Se încarcă...',
    loadingError: 'EROARE',
    noRowsToShow: 'Nu au fost găsite date!',
    enabled: 'Activat',

    // Menu
    pinColumn: 'Fixează Coloana',
    pinLeft: 'Fixează la Stânga',
    pinRight: 'Fixează la Dreapta',
    noPin: 'Nu Fixa',
    valueAggregation: 'Agregare Valori',
    noAggregation: 'Niciuna',
    autosizeThisColumn: 'Auto redimensiona această coloană',
    autosizeAllColumns: 'Auto redimensiona toate coloanele',
    groupBy: 'Grupare după',
    ungroupBy: 'Degrupa după',
    ungroupAll: 'Degrupa toate',
    addToValues: 'Adaugă ${variable} la valori',
    removeFromValues: 'Elimină ${variable} din valori',
    addToLabels: 'Adaugă ${variable} la etichete',
    removeFromLabels: 'Elimină ${variable} din etichete',
    resetColumns: 'Resetează coloanele',
    expandAll: 'Extinde toate grupurile de rânduri',
    collapseAll: 'Închide toate grupurile de rânduri',
    copy: 'Copiază',
    ctrlC: 'Ctrl+C',
    ctrlX: 'Ctrl+X',
    copyWithHeaders: 'Copiază cu Antet',
    copyWithGroupHeaders: 'Copiază cu Antet de Grup',
    cut: 'Taie',
    paste: 'Lipește',
    ctrlV: 'Ctrl+V',
    export: 'Exportă',
    csvExport: 'Exportă CSV',
    excelExport: 'Exportă Excel',
    columnFilter: 'Filtru de Coloană',
    columnChooser: 'Alege Coloane',
    sortAscending: 'Sortare Ascendentă',
    sortDescending: 'Sortare Descendentă',
    sortUnSort: 'Șterge Sortarea',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sumă',
    first: 'Primul',
    last: 'Ultimul',
    min: 'Minim',
    max: 'Maxim',
    none: 'Niciunul',
    count: 'Numărătoare',
    avg: 'Medie',
    filteredRows: 'Filtrate',
    selectedRows: 'Selectate',
    totalRows: 'Total Rânduri',
    totalAndFilteredRows: 'Rânduri',
    more: 'Mai mult',
    to: 'la',
    of: 'din',
    page: 'Pagina',
    pageLastRowUnknown: '?',
    nextPage: 'Pagina Următoare',
    lastPage: 'Ultima Pagină',
    firstPage: 'Prima Pagină',
    previousPage: 'Pagina Anterioară',
    pageSizeSelectorLabel: 'Mărime Pagină:',
    footerTotal: 'Total',

    // Pivoting
    pivotColumnGroupTotals: 'Total',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart și Modul Pivot',
    pivotChart: 'Pivot Chart',
    chartRange: 'Interval Diagramă',
    columnChart: 'Coloană',
    groupedColumn: 'Grupat',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',
    barChart: 'Bară',
    groupedBar: 'Grupat',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',
    pieChart: 'Plăcintă',
    pie: 'Plăcintă',
    donut: 'Gogoașă',
    line: 'Linie',
    xyChart: 'X Y (Puncte dispersate)',
    scatter: 'Puncte dispersate',
    bubble: 'Bule',
    areaChart: 'Zonă',
    area: 'Zonă',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',
    histogramChart: 'Histogramă',
    polarChart: 'Polar',
    radarLine: 'Linie Radar',
    radarArea: 'Zonă Radar',
    nightingale: 'Nightingale',
    radialColumn: 'Coloană Radială',
    radialBar: 'Bară Radială',
    statisticalChart: 'Statistic',
    boxPlot: 'Grafic Box',
    rangeBar: 'Bară Interval',
    rangeArea: 'Zonă Interval',
    hierarchicalChart: 'Ierarhic',
    treemap: 'Hartă Arbore',
    sunburst: 'Explozie Solară',
    specializedChart: 'Specializat',
    waterfall: 'Cascadă',
    heatmap: 'Hartă Termică',
    combinationChart: 'Combinată',
    columnLineCombo: 'Coloană și Linie',
    AreaColumnCombo: 'Zonă și Coloană',

    // Charts
    pivotChartTitle: 'Grafic Pivot',
    rangeChartTitle: 'Grafic Gama',
    settings: 'Grafic',
    data: 'Setări',
    format: 'Personalizare',
    categories: 'Categorii',
    defaultCategory: '(Nici unul)',
    series: 'Serii',
    switchCategorySeries: 'Schimbare Categorie / Serii',
    categoryValues: 'Valori Categorie',
    seriesLabels: 'Etichete Serii',
    aggregate: 'Agregat',
    xyValues: 'Valori X Y',
    paired: 'Mod Pereche',
    axis: 'Axă',
    xAxis: 'Axă Orizontală',
    yAxis: 'Axă Verticală',
    polarAxis: 'Axă Polară',
    radiusAxis: 'Axă Radius',
    navigator: 'Navigator',
    zoom: 'Zoom',
    animation: 'Animație',
    crosshair: 'Cercetare',
    color: 'Culoare',
    thickness: 'Grosime',
    preferredLength: 'Lungime preferată',
    xType: 'Tip X',
    axisType: 'Tip Axă',
    automatic: 'Automat',
    category: 'Categorie',
    number: 'Număr',
    time: 'Timp',
    timeFormat: 'Format Timp',
    autoRotate: 'Rotație automată',
    labelRotation: 'Rotație',
    circle: 'Cerc',
    polygon: 'Poligon',
    square: 'Pătrat',
    cross: 'Cruce',
    diamond: 'Diamant',
    plus: 'Plus',
    triangle: 'Triunghi',
    heart: 'Inimă',
    orientation: 'Orientare',
    fixed: 'Fix',
    parallel: 'Paralel',
    perpendicular: 'Perpendicular',
    radiusAxisPosition: 'Poziție',
    ticks: 'Marcaje',
    gridLines: 'Linii Grilă',
    width: 'Lățime',
    height: 'Înălțime',
    length: 'Lungime',
    padding: 'Padding',
    spacing: 'Spațiere',
    chartStyle: 'Stil Grafic',
    title: 'Titlu',
    chartTitles: 'Titluri',
    chartTitle: 'Titlu Grafic',
    chartSubtitle: 'Subtitlu',
    horizontalAxisTitle: 'Titlu Axă Orizontală',
    verticalAxisTitle: 'Titlu Axă Verticală',
    polarAxisTitle: 'Titlu Axă Polară',
    titlePlaceholder: 'Titlu Grafic',
    background: 'Fundal',
    font: 'Font',
    weight: 'Greutate',
    top: 'Deasupra',
    right: 'Dreapta',
    bottom: 'Dedesubt',
    left: 'Stânga',
    labels: 'Etichete',
    calloutLabels: 'Etichete Apel',
    sectorLabels: 'Etichete Sector',
    positionRatio: 'Raport Poziție',
    size: 'Mărime',
    shape: 'Formă',
    minSize: 'Mărime Minimă',
    maxSize: 'Mărime Maximă',
    legend: 'Legendă',
    position: 'Poziție',
    markerSize: 'Mărime Marcaj',
    markerStroke: 'Tușe Marcaj',
    markerPadding: 'Padding Marcaj',
    itemSpacing: 'Spațiere Elemente',
    itemPaddingX: 'Padding Element X',
    itemPaddingY: 'Padding Element Y',
    layoutHorizontalSpacing: 'Spațiere Orizontală',
    layoutVerticalSpacing: 'Spațiere Verticală',
    strokeWidth: 'Grosime Linie',
    offset: 'Offset',
    offsets: 'Offset-uri',
    tooltips: 'Tooltip-uri',
    callout: 'Apel',
    markers: 'Marcaje',
    shadow: 'Umbră',
    blur: 'Estompare',
    xOffset: 'Offset X',
    yOffset: 'Offset Y',
    lineWidth: 'Grosime Linie',
    lineDash: 'Linie Punctată',
    lineDashOffset: 'Offset Linie Punctată',
    scrollingZoom: 'Defilare',
    scrollingStep: 'Pas Defilare',
    selectingZoom: 'Selectare',
    durationMillis: 'Durată (ms)',
    crosshairLabel: 'Etichetă',
    crosshairSnap: 'Fixare pe Nod',
    normal: 'Normal',
    bold: 'Aldin',
    italic: 'Italic',
    boldItalic: 'Aldin Italic',
    predefined: 'Predefinit',
    fillOpacity: 'Opacitate Umplere',
    strokeColor: 'Culoare Linie',
    strokeOpacity: 'Opacitate Linie',
    miniChart: 'Mini-Grafic',
    histogramBinCount: 'Număr Benzi',
    connectorLine: 'Linie Conectare',
    seriesItems: 'Elemente Serii',
    seriesItemType: 'Tip Element',
    seriesItemPositive: 'Pozitiv',
    seriesItemNegative: 'Negativ',
    seriesItemLabels: 'Etichete Elemente',
    columnGroup: 'Coloană',
    barGroup: 'Bară',
    pieGroup: 'Plăcintă',
    lineGroup: 'Linie',
    scatterGroup: 'X Y (Dispersie)',
    areaGroup: 'Zonă',
    polarGroup: 'Polar',
    statisticalGroup: 'Statistic',
    hierarchicalGroup: 'Ierarhic',
    specializedGroup: 'Specializat',
    combinationGroup: 'Combinație',
    groupedColumnTooltip: 'Grupat',
    stackedColumnTooltip: 'Stivuit',
    normalizedColumnTooltip: '100% Stivuit',
    groupedBarTooltip: 'Grupat',
    stackedBarTooltip: 'Stivuit',
    normalizedBarTooltip: '100% Stivuit',
    pieTooltip: 'Plăcintă',
    donutTooltip: 'Gogoasă',
    lineTooltip: 'Linie',
    groupedAreaTooltip: 'Ariat',
    stackedAreaTooltip: 'Stivuit',
    normalizedAreaTooltip: '100% Stivuit',
    scatterTooltip: 'Dispersie',
    bubbleTooltip: 'Bulă',
    histogramTooltip: 'Histogramă',
    radialColumnTooltip: 'Coloană Radială',
    radialBarTooltip: 'Bară Radială',
    radarLineTooltip: 'Linie Radar',
    radarAreaTooltip: 'Zonă Radar',
    nightingaleTooltip: 'Nightingale',
    rangeBarTooltip: 'Bară Gama',
    rangeAreaTooltip: 'Zonă Gama',
    boxPlotTooltip: 'Box Plot',
    treemapTooltip: 'Hartă Arbori',
    sunburstTooltip: 'Sunburst',
    waterfallTooltip: 'Cascadă',
    heatmapTooltip: 'Hartă Căldură',
    columnLineComboTooltip: 'Coloană & Linie',
    areaColumnComboTooltip: 'Zonă & Coloană',
    customComboTooltip: 'Combinație Personalizată',
    innerRadius: 'Raza Interioară',
    startAngle: 'Unghi de Start',
    endAngle: 'Unghi de Sfârșit',
    reverseDirection: 'Direcție Inversă',
    groupPadding: 'Padding Grup',
    seriesPadding: 'Padding Serii',
    tile: 'Placă',
    whisker: 'Codiță',
    cap: 'Capac',
    capLengthRatio: 'Raport Lungime Capac',
    labelPlacement: 'Plasament Etichetă',
    inside: 'În Interior',
    outside: 'În Exterior',
    noDataToChart: 'Nu sunt date disponibile pentru grafic.',
    pivotChartRequiresPivotMode: 'Graficul Pivot necesită activarea Modului Pivot.',
    chartSettingsToolbarTooltip: 'Meniu',
    chartLinkToolbarTooltip: 'Legat la Grila',
    chartUnlinkToolbarTooltip: 'Nelegat de Grilă',
    chartDownloadToolbarTooltip: 'Descărcare Grafic',
    chartMenuToolbarTooltip: 'Meniu',
    chartEdit: 'Editare Grafic',
    chartAdvancedSettings: 'Setări Avansate',
    chartLink: 'Legare la Grilă',
    chartUnlink: 'Dezlegare de Grilă',
    chartDownload: 'Descărcare Grafic',
    histogramFrequency: 'Frecvență',
    seriesChartType: 'Tip Grafic Serii',
    seriesType: 'Tip Serii',
    secondaryAxis: 'Axă Secundară',
    seriesAdd: 'Adăugați o serie',
    categoryAdd: 'Adăugați o categorie',
    bar: 'Bară',
    column: 'Coloană',
    histogram: 'Histogramă',
    advancedSettings: 'Setări Avansate',
    direction: 'Direcție',
    horizontal: 'Orizontal',
    vertical: 'Vertical',
    seriesGroupType: 'Tip Grup',
    groupedSeriesGroupType: 'Grupat',
    stackedSeriesGroupType: 'Stivuit',
    normalizedSeriesGroupType: '100% Stivuit',
    legendEnabled: 'Activat',
    invalidColor: 'Valoarea culorii este invalidă',
    groupedColumnFull: 'Coloană Grupată',
    stackedColumnFull: 'Coloană Stivuită',
    normalizedColumnFull: 'Coloană 100% Stivuită',
    groupedBarFull: 'Bară Grupată',
    stackedBarFull: 'Bară Stivuită',
    normalizedBarFull: 'Bară 100% Stivuită',
    stackedAreaFull: 'Zonă Stivuită',
    normalizedAreaFull: 'Zonă 100% Stivuită',
    customCombo: 'Combinație Personalizată',

    // ARIA
    ariaAdvancedFilterBuilderItem: '${variable}. Nivel ${variable}. Apăsați ENTER pentru a edita.',
    ariaAdvancedFilterBuilderItemValidation:
        '${variable}. Nivel ${variable}. ${variable} Apăsați ENTER pentru a edita.',
    ariaAdvancedFilterBuilderList: 'Lista Constructorului de Filtre Avansate',
    ariaAdvancedFilterBuilderFilterItem: 'Condiție de Filtrare',
    ariaAdvancedFilterBuilderGroupItem: 'Grup de Filtre',
    ariaAdvancedFilterBuilderColumn: 'Coloană',
    ariaAdvancedFilterBuilderOption: 'Opțiune',
    ariaAdvancedFilterBuilderValueP: 'Valoare',
    ariaAdvancedFilterBuilderJoinOperator: 'Operator de Îmbinare',
    ariaAdvancedFilterInput: 'Intrare Filtru Avansat',
    ariaChecked: 'bifat',
    ariaColumn: 'Coloană',
    ariaColumnGroup: 'Grup de Coloane',
    ariaColumnFiltered: 'Coloană Filtrată',
    ariaColumnSelectAll: 'Comută Selectarea Tuturor Coloanelor',
    ariaDateFilterInput: 'Intrare Filtru de Dată',
    ariaDefaultListName: 'Listă',
    ariaFilterColumnsInput: 'Intrare Filtrare Coloane',
    ariaFilterFromValue: 'Filtrează de la valoare',
    ariaFilterInput: 'Intrare Filtru',
    ariaFilterList: 'Listă de Filtre',
    ariaFilterToValue: 'Filtrează până la valoare',
    ariaFilterValue: 'Valoare Filtrată',
    ariaFilterMenuOpen: 'Deschide Meniul Filtrului',
    ariaFilteringOperator: 'Operator de Filtrare',
    ariaHidden: 'ascuns',
    ariaIndeterminate: 'nedeterminat',
    ariaInputEditor: 'Editor Intrare',
    ariaMenuColumn: 'Apăsați ALT în jos pentru a deschide meniul coloanei',
    ariaFilterColumn: 'Apăsați CTRL ENTER pentru a deschide filtrul',
    ariaRowDeselect: 'Apăsați SPACE pentru a deselecta acest rând',
    ariaRowSelectAll: 'Apăsați Space pentru a comuta selecția tuturor rândurilor',
    ariaRowToggleSelection: 'Apăsați Space pentru a comuta selecția rândului',
    ariaRowSelect: 'Apăsați SPACE pentru a selecta acest rând',
    ariaRowSelectionDisabled: 'Selecția rândului este dezactivată pentru acest rând',
    ariaSearch: 'Căutare',
    ariaSortableColumn: 'Apăsați ENTER pentru a sorta',
    ariaToggleVisibility: 'Apăsați SPACE pentru a comuta vizibilitatea',
    ariaToggleCellValue: 'Apăsați SPACE pentru a comuta valoarea celulei',
    ariaUnchecked: 'nebifat',
    ariaVisible: 'vizibil',
    ariaSearchFilterValues: 'Caută valori ale filtrului',
    ariaPageSizeSelectorLabel: 'Dimensiunea Paginii',
    ariaChartMenuClose: 'Închide meniul de editare a graficului',
    ariaChartSelected: 'Selectat',
    ariaSkeletonCellLoadingFailed: 'Încărcarea rândului a eșuat',
    ariaSkeletonCellLoading: 'Datele rândului se încarcă',

    // ARIA Labels for Drop Zones
    ariaRowGroupDropZonePanelLabel: 'Grupuri de rânduri',
    ariaValuesDropZonePanelLabel: 'Valori',
    ariaPivotDropZonePanelLabel: 'Etichete de coloană',
    ariaDropZoneColumnComponentDescription: 'Apăsați DELETE pentru a elimina',
    ariaDropZoneColumnValueItemDescription: 'Apăsați ENTER pentru a schimba tipul de agregare',
    ariaDropZoneColumnGroupItemDescription: 'Apăsați ENTER pentru a sorta',

    // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
    ariaDropZoneColumnComponentAggFuncSeparator: ' din ',
    ariaDropZoneColumnComponentSortAscending: 'crescător',
    ariaDropZoneColumnComponentSortDescending: 'descrescător',
    ariaLabelDialog: 'Dialog',
    ariaLabelColumnMenu: 'Meniu Coloană',
    ariaLabelColumnFilter: 'Filtru Coloană',
    ariaLabelCellEditor: 'Editor de Celule',
    ariaLabelSelectField: 'Selectează Câmp',

    // aria labels for rich select
    ariaLabelRichSelectField: 'Câmp Selectare Avansată',
    ariaLabelRichSelectToggleSelection: 'Apăsați SPACE pentru a comuta selecția',
    ariaLabelRichSelectDeselectAllItems: 'Apăsați DELETE pentru a deselecta toate elementele',
    ariaLabelRichSelectDeleteSelection: 'Apăsați DELETE pentru a deselecta elementul',
    ariaLabelTooltip: 'Sugestie',
    ariaLabelContextMenu: 'Meniu Contextual',
    ariaLabelSubMenu: 'Submeniu',
    ariaLabelAggregationFunction: 'Funcția de Agregare',
    ariaLabelAdvancedFilterAutocomplete: 'Autocompletare Filtru Avansat',
    ariaLabelAdvancedFilterBuilderAddField: 'Constructor Filtru Avansat - Adăugare Câmp',
    ariaLabelAdvancedFilterBuilderColumnSelectField: 'Constructor Filtru Avansat - Selectare Coloană Câmp',
    ariaLabelAdvancedFilterBuilderOptionSelectField: 'Constructor Filtru Avansat - Selectare Opțiune Câmp',
    ariaLabelAdvancedFilterBuilderJoinSelectField: 'Constructor Filtru Avansat - Selectare Operator De Unire Câmp',

    // ARIA Labels for the Side Bar
    ariaColumnPanelList: 'Listă de coloane',
    ariaFilterPanelList: 'Listă de filtre',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: ',',
    decimalSeparator: '.',

    // Data types
    true: 'Adevărat',
    false: 'Fals',
    invalidDate: 'Dată invalidă',
    invalidNumber: 'Număr invalid',
    january: 'Ianuarie',
    february: 'Februarie',
    march: 'Martie',
    april: 'Aprilie',
    may: 'Mai',
    june: 'Iunie',
    july: 'Iulie',
    august: 'August',
    september: 'Septembrie',
    october: 'Octombrie',
    november: 'Noiembrie',
    december: 'Decembrie',

    // Time formats
    timeFormatSlashesDDMMYYYY: 'DD/MM/YYYY',
    timeFormatSlashesMMDDYYYY: 'MM/DD/YYYY',
    timeFormatSlashesDDMMYY: 'DD/MM/YY',
    timeFormatSlashesMMDDYY: 'MM/DD/YY',
    timeFormatDotsDDMYY: 'DD.M.YY',
    timeFormatDotsMDDYY: 'M.DD.YY',
    timeFormatDashesYYYYMMDD: 'YYYY-MM-DD',
    timeFormatSpacesDDMMMMYYYY: 'DD MMMM YYYY',
    timeFormatHHMMSS: 'HH:MM:SS',
    timeFormatHHMMSSAmPm: 'HH:MM:SS AM/PM',
};