import React from 'react';

interface ListProps {
    text: string;
    title: string;
    type: string;
}
const ListItem: React.FC<ListProps> = ({text, title, type}) => {
    return (
      <li className="flex text-base text-body-color dark:text-dark-6">
        { type=="success" ? (
            <>
                <span className="mr-2.5 mt-0.5 text-green-500 items-top pt-1">
                    <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_980_24852)">
                        <path
                            d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                            fill="currentColor"
                        />
                        <path
                            d="M12.6875 7.09375L8.96875 10.7188L7.28125 9.0625C7 8.78125 6.5625 8.8125 6.28125 9.0625C6 9.34375 6.03125 9.78125 6.28125 10.0625L8.28125 12C8.46875 12.1875 8.71875 12.2813 8.96875 12.2813C9.21875 12.2813 9.46875 12.1875 9.65625 12L13.6875 8.125C13.9688 7.84375 13.9688 7.40625 13.6875 7.125C13.4063 6.84375 12.9688 6.84375 12.6875 7.09375Z"
                            fill="currentColor"
                        />
                        </g>
                        <defs>
                        <clipPath id="clip0_980_24852">
                            <rect width={20} height={20} fill="white" />
                        </clipPath>
                        </defs>
                    </svg>
                </span>
            </>
        ) : (
            <>
                <span className="mr-2.5 flex items-top text-base text-red-500 pt-1">
                    <svg
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <g clipPath="url(#clip0_980_24913)">
                        <path
                        d="M10 0.5625C4.78125 0.5625 0.5625 4.78125 0.5625 10C0.5625 15.2188 4.78125 19.4688 10 19.4688C15.2188 19.4688 19.4688 15.2188 19.4688 10C19.4688 4.78125 15.2188 0.5625 10 0.5625ZM10 18.0625C5.5625 18.0625 1.96875 14.4375 1.96875 10C1.96875 5.5625 5.5625 1.96875 10 1.96875C14.4375 1.96875 18.0625 5.59375 18.0625 10.0312C18.0625 14.4375 14.4375 18.0625 10 18.0625Z"
                        fill="currentColor"
                        />
                        <path
                        d="M12.875 7.09375C12.5938 6.8125 12.1563 6.8125 11.875 7.09375L10 9L8.09375 7.09375C7.8125 6.8125 7.375 6.8125 7.09375 7.09375C6.8125 7.375 6.8125 7.8125 7.09375 8.09375L9 10L7.09375 11.9062C6.8125 12.1875 6.8125 12.625 7.09375 12.9062C7.21875 13.0312 7.40625 13.125 7.59375 13.125C7.78125 13.125 7.96875 13.0625 8.09375 12.9062L10 11L11.9062 12.9062C12.0312 13.0312 12.2188 13.125 12.4063 13.125C12.5938 13.125 12.7812 13.0625 12.9062 12.9062C13.1875 12.625 13.1875 12.1875 12.9062 11.9062L11 10L12.9062 8.09375C13.1562 7.8125 13.1563 7.375 12.875 7.09375Z"
                        fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_980_24913">
                        <rect width={20} height={20} fill="white" />
                        </clipPath>
                    </defs>
                    </svg>
                </span>
            </>
        )}
        <div>
            <span className="font-semibold text-sm">{title}</span><br />
            <span className="font-semibold text-gray-400 text-sm leading-normal space-y-0">{text}</span>
        </div>
      </li>
    );
  };

  export default ListItem;