import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useUser } from '../../components/UserContext';
import Breadcrumb from '../../components/BreadCrumb';
import BookingsNavBar from '../../components/BookingsNavBar';
import { hasAnyPermission, hasPermission } from '../../interfaces/Permissions';
import Index from './Index';
import Reports from './Reports';
import Form from './Form';
import View from './View';
import CheckPermission from '../../components/CheckPermission';

const Bookings: React.FC = () => {
    const { user } = useUser();

    return (
      <>
        <BookingsNavBar />
        <Breadcrumb />
        <CheckPermission permissions={['BOOKINGS_VIEW_ALL']}>
          <Routes>
            {
              hasAnyPermission(user, ['CLIENT_VIEW']) ?
                <Route path="index" element={<Index />} /> 
              : <></>
            }
            <Route path="view/:id" element={<View />} />
            {
              hasPermission(user, 'CLIENT_CREATE') ?
                <Route path="form" element={<Form key={new Date().toISOString()} />} />
              : <></>
            }
            {
              hasPermission(user, 'CLIENT_UPDATE') ?
                <Route path="form/:id" element={<Form key={new Date().toISOString()} />} />
              : <></>
            }
            <Route path="/" element={<Navigate to="/bookings/index" replace />} />
          </Routes>
        </CheckPermission>
      </>
    );
};

export default Bookings;