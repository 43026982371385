import React from 'react';
import { Modal, Badge } from 'flowbite-react';
import { Activity } from '../../interfaces/Activity';
import { formatDate } from '../../functions/dateHelper';

interface ViewActivityModalProps {
  activity: Activity | null;
  show: boolean;
  onClose: () => void;
}

const ViewActivityModal = ({ activity, show, onClose }: ViewActivityModalProps) => {
  if (!activity) return null;

  const getPriorityColor = (priority: string | null) => {
    switch (priority) {
      case 'Scăzut':
        return 'success';
      case 'Mediu':
        return 'warning';
      case 'Ridicat':
        return 'failure';
      default:
        return 'gray';
    }
  };

  const getStatusColor = (status: string | null) => {
    return status === 'Deschis' ? 'success' : 'failure';
  };

  const getContactTypeColor = (type: string | null) => {
    switch (type) {
      case 'Email':
        return 'purple';
      case 'Telefon':
        return 'success';
      default:
        return 'pink';
    }
  };

  return (
    <Modal show={show} onClose={onClose} size="xl">
      <Modal.Header>
        <div className="flex items-center gap-2">
          <span>Activitate #{activity.id}</span>
          <Badge color={getStatusColor(activity.status)}>{activity.status}</Badge>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-500">Client</p>
              <p className="font-medium">{activity.client?.company || '-'}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Responsabil</p>
              <p className="font-medium">{activity.user.name}</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
                <p className="text-sm text-gray-500">Prioritate</p>
                <div className="flex font-bold">
                    <Badge color={getPriorityColor(activity.priority)}>{activity.priority}</Badge>
                </div>
            </div>
            <div>
                <p className="text-sm text-gray-500">Tip Contact</p>
                <div className="flex font-bold">
                    <Badge color={getContactTypeColor(activity.scheduledContact)}>{activity.scheduledContact}</Badge>
                </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
                <p className="text-sm text-gray-500">Data Programată</p>
                <p className="font-medium">{activity.scheduledDate ? formatDate(new Date(activity.scheduledDate)) : '-'}</p>
            </div>
            <div>
                <p className="text-sm text-gray-500">Contact</p>
                <p className="font-medium">{activity.contact?.name || '-'}</p>
            </div>
          </div>

          <div>
            <p className="text-sm text-gray-500">Descriere</p>
            <p className="font-medium">{activity.description || '-'}</p>
          </div>

          {activity.status === 'Închis' && (
            <div>
              <p className="text-sm text-gray-500">Motiv închidere</p>
              <p className="font-medium">{activity.closureReason || '-'}</p>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewActivityModal;