import React, { useState, useEffect, useMemo } from 'react';
import { defaultBuildRule, defaultMovement, defaultMovementLogic, existaText, filterOptions, getContainerInfo, Movement, MovementLogic } from '../../interfaces/Movement';
import { errorMessage, handleOptions, handleRecommendations, isNullOrEmpty, successMessage } from '../../functions/generalHelper';
import { Button, Modal } from 'flowbite-react';
import Input from '../../components/Input';
import { handleInputChange, handleSelectChange } from '../../functions/selectHelper';
import CustomSelect from '../../components/CustomSelect';
import { MovementService } from '../../services/MovementService';
import { LabelValueInt, RecommendationResp } from '../../interfaces/Recommandation';
import CustomDatePicker from '../../components/CustomDatePicker';
import { getDateFormat } from '../../functions/dateHelper';
import CustomTimePicker from '../../components/CustomTimePicker';

interface ModalCreateProps {
    id: number;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: () => void;
    recommendations: RecommendationResp[];
}

const ModalCreate: React.FC<ModalCreateProps> = ({ id, showModal, setShowModal, onSave, recommendations }) => {
    const [movement, setMovement] = useState<Movement>(defaultMovement);
    const [bookingOptions, setBookingOptions] = useState<LabelValueInt[]>([]);
    const movementService = new MovementService();
    const movementsOptions = handleOptions(recommendations, 'booking-movements');
    const terminalOptions = handleOptions(recommendations, 'booking-terminals');
    const containerSizeOptions = handleOptions(recommendations, 'container-size');
    const containerTypeOptions = handleOptions(recommendations, 'container-type');
    const loadingMovements = useMemo(() => ['DF', 'OF', 'OE', 'DE'], []);

    // ia movementul daca exista, altfel seteaza defaultMovement
    useEffect(() => {
      if(!showModal){
        setMovement(defaultMovement);
      }
      movementService.getBookings().then(data => setBookingOptions(data.map((d) => ({ label: d.number!, value: d.id! }))));
    }, [showModal])

    // daca exista containerul selecteaza datele acestuia
    useEffect(() => {
        if(movement.containerCode.length == 11){
            // daca a patra litera este R, adauga in movement.type=RF 
            if(movement.containerCode[3] === 'R'){
                setMovement(prev => ({ ...prev, type: 'RF' }));
            }
            movementService.getContainerInfo(movement.containerCode).then(response => {
            if(response){
                    setMovement(prev => ({
                        ...prev,
                        iso: isNullOrEmpty(response.iso) ? prev.iso : response.iso!,
                        type: isNullOrEmpty(response.type) ? prev.type : response.type!,
                        size: isNullOrEmpty(response.size) ? prev.size : response.size!,
                        bookingId: response.bookingId ? response.bookingId! : prev.bookingId,
                        movement: isNullOrEmpty(response.movement) ? prev.movement : response.movement!,
                        terminal: isNullOrEmpty(response.terminal) ? prev.terminal : response.terminal!,
                    }))
                }
            });
        }
    }, [movement.containerCode]);
    
    // daca se schimba iso-ul, seteaza size si type
    useEffect(() => {
        const typeSize = getContainerInfo(movement.iso);
        if(typeSize.size && typeSize.type){
            setMovement(prev => ({
                ...prev,
                size: typeSize.size,
                type: typeSize.type,
            }));
        }
    }, [movement.iso]);    

    // daca se schimba movementul, seteaza portul si vesselCode si vesselName
    useEffect(() => {
        if(!loadingMovements.includes(movement.movement)){
            setMovement(prev => ({ ...prev, port: defaultMovement.port, vesselCode: defaultMovement.vesselCode, vesselName: defaultMovement.vesselName }));
        }
    }, [movement.movement]);

    // functia care trimite datele catre server
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if(id != 0){
                // const ser = await movementFileService.update(activity);
                // setOpenModal(false);
                // successMessage('Activitate actualizată cu success!');
                // onEdit!(act);
            }else{
                const newMovement = await movementService.createMovement(movement);
                if(newMovement.status == 'success'){
                    setShowModal(false);
                    successMessage(newMovement.message);
                    setMovement(defaultMovement);
                }else{
                    errorMessage(newMovement.message);
                }
                onSave();
            }
        } catch (error) {
            errorMessage('Denumirea este obligatorie!');
        }
    };
    
    // functia care modifica data catre YML daca nu exista si daca se modifica data miscarii
    useEffect(() => {
        if(movement.date && !movement.dateSystem){
            setMovement(prev => ({ ...prev, dateSystem: movement.date }));
        }
    }, [movement.date]);

    return (
        <>
            <Modal show={showModal} size="3xl" popup onClose={() => setShowModal(false)}>
                <Modal.Header>
                    {id ? 'Editează mișcare' : 'Adaugă mișcare'}
                </Modal.Header>
                <Modal.Body className="overflow-visible">
                    <div className="space-y-2 pt-5">
                        <form onSubmit={handleSubmit}>     
                            <div className="gap-4 grid grid-cols-2 mb-2">  
                                <div className="mb-1">
                                    <Input 
                                        id="containerCode" 
                                        label="Container"
                                        value={movement.containerCode}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movement, setMovement, 'containerCode', e.target.value, defaultMovement)}
                                    />
                                </div>   
                                <div className="mb-1">
                                    <CustomSelect
                                        value={movementsOptions.find(option => option.value === movement.movement)}
                                        onChange={(data) => handleSelectChange(movement, setMovement, 'movement', data)}
                                        options={movementsOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Mișcare"
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomSelect
                                        value={terminalOptions.find(option => option.value === movement.terminal)}
                                        onChange={(data) => handleSelectChange(movement, setMovement, 'terminal', data)}
                                        options={terminalOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Terminal"
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomSelect
                                        value={bookingOptions.find(option => option.value === movement.bookingId)}
                                        onChange={(data) => handleSelectChange(movement, setMovement, 'bookingId', data)}
                                        options={bookingOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Booking"
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomDatePicker
                                        selected={movement.date}
                                        onChange={(date) => setMovement({ ...movement, date: date ? date : new Date() })}
                                        dateFormat= {getDateFormat()}
                                        locale="ro"
                                        isClearable
                                        placeholderText="Dată"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomTimePicker 
                                        selected={movement.date}
                                        onChange={(date) => setMovement({ ...movement, date: date ? date : new Date() })}
                                        placeholderText="Oră"
                                    />
                                </div>
                                <div className="mb-1">
                                    <Input 
                                        id="iso" 
                                        label="ISO"
                                        value={movement.iso}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movement, setMovement, 'iso', e.target.value, defaultMovement)}
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomSelect
                                        value={containerSizeOptions.find(option => option.value === movement.size)}
                                        onChange={(data) => handleSelectChange(movement, setMovement, 'size', data)}
                                        options={containerSizeOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Dimensiune"
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomSelect
                                        value={containerTypeOptions.find(option => option.value === movement.type)}
                                        onChange={(data) => handleSelectChange(movement, setMovement, 'type', data)}
                                        options={containerTypeOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Tip"
                                    />
                                </div>
                                <div className="mb-1">
                                    <Input 
                                        id="note" 
                                        label="Notă"
                                        value={movement.note}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movement, setMovement, 'note', e.target.value, defaultMovement)}
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomDatePicker
                                        selected={movement.dateSystem}
                                        onChange={(date) => setMovement({ ...movement, dateSystem: date })}
                                        dateFormat= {getDateFormat()}
                                        locale="ro"
                                        isClearable
                                        placeholderText="Dată YML"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomTimePicker 
                                        selected={movement.dateSystem}
                                        onChange={(date) => setMovement({ ...movement, dateSystem: date })}
                                        placeholderText="Oră YML"
                                    />
                                </div>
                                {loadingMovements.includes(movement.movement) && (
                                    <>
                                        <div className="mb-1">
                                            <Input 
                                                id="vesselCode" 
                                                label="Cod Vas"
                                                value={movement.vesselCode}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movement, setMovement, 'vesselCode', e.target.value, defaultMovement)}
                                            />
                                        </div>
                                        <div className="mb-1">
                                            <Input 
                                                id="vesselName" 
                                                label="Nume Vas"
                                                value={movement.vesselName}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movement, setMovement, 'vesselName', e.target.value, defaultMovement)}
                                            />
                                        </div>
                                        <div className="mb-1">
                                            <Input
                                                id="port"
                                                label="Port străin"
                                                value={movement.port}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(movement, setMovement, 'port', e.target.value, defaultMovement)}
                                            />
                                        </div>
                                    </>  
                                )}
                            </div>
                            <div className="flex justify-end">
                                <Button size="sm" type="submit">Salvează</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalCreate;