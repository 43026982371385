import React, { useState } from 'react';
import { Role } from '../../interfaces/Role';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { ColDef } from 'ag-grid-community';
import Actions from '../../components/Actions';
import { AG_GRID_LOCALE_RO } from '../../functions/langHelper';
import ActivityModal from '../activities/ActivityModal';
import ViewModal from './ViewModal';
import "ag-grid-charts-enterprise";

interface RoleProps {
  roles: Role[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

const Table: React.FC<RoleProps> = ({ roles, onEdit, onDelete }) => {
  const [showModalView, setShowModalView] = useState<boolean>(false);
  const [viewId, setViewId] = useState<number>(0);
  const pagination = true;
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const gridOptions = {rowHeight: 35};
  const columns: ColDef<Role>[] = [
    { headerName: 'Id', field: 'id', sortable: true, filter: true },
    { 
      headerName: 'Nume', 
      field: 'name', 
      sortable: true, 
      filter: true ,
      cellRenderer: (params : any) => <><span onClick={() => {handleView(params.data.id)}} className={`font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline`}>{ params.value }</span></>
    },
    {
      headerName: 'Actions',
      field: 'id',
      cellRenderer: (params: any) => (
        <div>
          <Actions id={params.data.id} onEdit={() => onEdit(params.data.id)} onDelete={() => onDelete(params.data.id)} moduleName="rol" />
        </div>
      )
    }
  ];

  const handleView = (id : number) => {
    setShowModalView(true);
    setViewId(id);
  }

  return (
    <>
      <AgGridReact 
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={roles}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
        gridOptions={gridOptions}
        enableCharts={true}
        enableRangeSelection={true}
      />
      <ViewModal id={viewId!} setShowModal={setShowModalView} showModal={showModalView} />
    </>
  );
};

export default Table;
