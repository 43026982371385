import { Button } from 'flowbite-react';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import renderSkeleton from '../../../components/SkeletonLoader';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_RO } from '../../../functions/langHelper';
import { MovementFileService } from '../../../services/MovementFileService';
import { ColDef, ColumnState, GridReadyEvent, TextMatcherParams } from 'ag-grid-community';
import { createDateFromString, formatDate } from '../../../functions/dateHelper';

const MovementErrors: React.FC = () => {
//   const { id } = useParams<{ id?: string }>();
//   const [component, setComponent] = useState<Component>(defaultComponent);
//   const componentService = new ComponentService();
  const [loading, setLoading] = useState<boolean>(true);
  const [movementErrors, setMovementErrors] = useState<{ id: number; filename: string; date: Date; }[]>([]);
  const movementFileService = new MovementFileService();

  useEffect(() => {
    movementFileService.getMovementErrors().then(data => {setMovementErrors(data); setLoading(false)});
  }, []);

  const columns: ColDef<{ id: number; filename: string; date: Date; }>[] = useMemo(() => [
    { headerName: 'Id', field: 'id', sortable: true, filter: 'agNumberColumnFilter', floatingFilter: true, width: 110, maxWidth: 110},
    { headerName: 'Fisier', field: 'filename', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true,
      cellRenderer : (params: any) => {
        return <>
          <a href={`${process.env.REACT_APP_BACKEND_URI}/file/movementErrors/${params.value}`} target="_blank" rel="noopener noreferrer" className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline">
            {params.value}
          </a>
        </>
      }
     },
    {
        headerName: 'Dată',
        field: 'date',
        sort: 'asc',
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
            filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
            textMatcher: (params: TextMatcherParams) => {
                if(params.filterText === null || params.filterText.trim() === ''){
                    return false;
                }
                const dateString = formatDate(createDateFromString(params.value));
                if(dateString==null){
                    return false;
                }
                switch (params.filterOption) {
                    case 'contains':
                        return dateString.indexOf(params.filterText) >= 0;
                    case 'notContains':
                        return dateString.indexOf(params.filterText) < 0;
                    case 'equals':
                        return dateString === params.filterText;
                    case 'notEqual':
                        return dateString != params.filterText;
                    case 'startsWith':
                        return dateString.indexOf(params.filterText) === 0;
                    case 'endsWith':
                        const index = dateString.lastIndexOf(params.filterText);
                        return index >= 0 && index === (dateString.length - params.filterText.length);
                    default:
                        return false;
                }
            }
        },
        minWidth: 170,
        cellRenderer: (params : any) => <>{formatDate(createDateFromString(params.value))}</>,
        comparator: (valueA, valueB) => {
            const dateA = createDateFromString(valueA)?.getTime();
            const dateB = createDateFromString(valueB)?.getTime();
            return dateA && dateB ? dateA - dateB : 0;
        },
        
    },
  ], []);

  const onGridReady = useCallback((event: GridReadyEvent) => {
      const columnState: ColumnState[] = [
          { colId: 'id', sort: 'desc', sortIndex: 0 }
      ];
      event.api.applyColumnState({ state: columnState, defaultState: { sort: null } });
  },[]);

  return (
      <div className="ag-theme-quartz px-4">
        <div className="flex justify-between items-center py-2">
            <div className="text-lg font-bold">
                Erori de procesare
            </div>
            <div className="flex gap-2">
                <NavLink to="/bookings/movements/index">
                    <Button size="sm">Înapoi la Mișcări</Button>
                </NavLink>
            </div>
        </div>
        {loading ? ( renderSkeleton() ) : (
            <>
                <AgGridReact
                    localeText={AG_GRID_LOCALE_RO}
                    columnDefs={columns} 
                    rowData={movementErrors}
                    defaultColDef={{ flex: 1, minWidth: 100 }}
                    domLayout='autoHeight'
                    pagination={true}
                    paginationPageSize={100}
                    paginationPageSizeSelector={[50, 100, 1000, 10000]}
                      onGridReady={onGridReady}
                      gridOptions={{rowHeight: 35}}
                />
            </>
        )}
    </div>
  );
};

export default MovementErrors;