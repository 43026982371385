import React from 'react';
import { NavLink } from 'react-router-dom';
import { TbUsers } from "react-icons/tb";

const SettingsNavBar: React.FC = () => {
  return (
    <div className="hidden bg-softprimary md:flex items-center px-5 text-black text-[0.95rem] font-medium h-9 ps-[155px]">
      <NavLink to="/settings/recommandations" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
        <TbUsers className='h-4 w-4'/>
        <span className="mr-2">Recomandări</span>
      </NavLink>
    </div>
  );
};

export default SettingsNavBar;