import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useUser } from '../../../components/UserContext';
import Breadcrumb from '../../../components/BreadCrumb';
import BookingsNavBar from '../../../components/BookingsNavBar';
import { hasAnyPermission, hasPermission } from '../../../interfaces/Permissions';
import Index from './Index';
import CheckPermission from '../../../components/CheckPermission';

const Bills: React.FC = () => {
    const { user } = useUser();

    return (
      <>
        <BookingsNavBar />
        <Breadcrumb />
        <CheckPermission permissions={['BOOKINGS_VIEW_ALL']}>
          <Routes>
              <Route path="index" element={<Index />} /> 
              <Route path="/" element={<Navigate to="/bookings/bills/index" replace />} />
          </Routes>
        </CheckPermission>
      </>
    );
};

export default Bills;