import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ClientService } from '../../services/ClientService';
import { Button, Modal, Tabs } from 'flowbite-react';
import { FullClient } from '../../interfaces/Client';
import { HiClipboardList, HiUserCircle } from 'react-icons/hi';
import { MdDashboard } from 'react-icons/md';
import General from './viewTabs/General';
import Offers from './viewTabs/Offers';
import Index from '../activities/Index';
import CheckPermission from '../../components/CheckPermission';

interface ModalViewProps {
  id: number;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalView: React.FC<ModalViewProps> = ({ id, showModal, setShowModal }) => {
  const [client, setClient] = useState<FullClient | null>(null);
  const clientSerice = new ClientService();
  
  useEffect(() => {
    if(showModal){
      clientSerice.getFullClient(id).then((data) => setClient(data));
    }
  }, [showModal]);

  return (
    <>
      <Modal show={showModal} position={`center`} size={`7xl`} onClose={() => setShowModal(false)} className="clientModalView">
        <Modal.Header>{ client?.company }</Modal.Header>  
        <Modal.Body className="modal_body">
          {
            client ? (
              <>
                <Tabs aria-label="Tabs with icons" className="pt-2">
                  <Tabs.Item title="Generale" icon={MdDashboard}>
                    <General onSave={() => {}} client={client} />
                  </Tabs.Item>
                  {/* <CheckPermission permissions={['ACTIVITY_VIEW_ALL', 'ACTIVITY_VIEW']}> */}
                    <Tabs.Item active title="Activitate" icon={HiUserCircle}>
                      <Index clientId={client.id}/>
                    </Tabs.Item>
                  {/* </CheckPermission> */}
                  {/* <Tabs.Item title="Bookinguri" icon={HiClipboardList}>
                  </Tabs.Item> */}
                  {/* <CheckPermission permissions={['OFFERS_VIEW_ALL', 'OFFERS_VIEW']}> */}
                    <Tabs.Item title="Oferte" icon={HiClipboardList}>
                      <Offers client={client} />
                    </Tabs.Item>
                  {/* </CheckPermission> */}
                  {/* <Tabs.Item title="Date client" icon={HiClipboardList}>
                  </Tabs.Item>
                  <Tabs.Item title="Istoric" icon={HiClipboardList}>
                  </Tabs.Item> */}
                </Tabs>
              </>
            ) : (<></>)
          }
        </Modal.Body>
        <Modal.Footer className="modal_footer">
            <Button color="gray" onClick={() => setShowModal(false)}>
              Închide
            </Button>
          </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalView;