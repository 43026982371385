import React from 'react';
import { MdDeleteOutline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Tooltip from './Tooltip';
import CheckPermission from './CheckPermission';

interface ClientActionsProps {
  id: string | number;
  moduleName: string;
  onEdit: (id: string | number) => void;
  onDelete: (id: string | number) => void;
  children?: React.ReactNode;
  deletePermissions?: string[];
  editPermissions?: string[];
}

const Actions: React.FC<ClientActionsProps> = ({ id, onEdit, onDelete, moduleName, children, deletePermissions = [], editPermissions = [] }) => {
  return (
    <div className="flex items-center">
        <CheckPermission permissions={editPermissions}>
          <Tooltip content={`Editează ${moduleName}`}>
              <button type="button" onClick={() => onEdit(id)} className="text-blue-500 hover:text-blue-700 h-[20px] mt-1 flex items-center mx-1" aria-label={`Editează ${moduleName}`}>
                  <FaEdit className='w-[17px] h-[17px] hover:scale-110 duration-75'/>
              </button>
          </Tooltip>
        </CheckPermission>
        <CheckPermission permissions={deletePermissions}>
          <Tooltip content={`Șterge ${moduleName}`}>
              <button type="button" onClick={() => onDelete(id)} className="text-red-500 hover:text-red-700 h-[20px] mt-1 flex items-center mx-1" aria-label={`Șterge ${moduleName}`}>
                  <MdDeleteOutline className='w-[20px] h-[20px] hover:scale-110 duration-75'/>
              </button>
          </Tooltip>
        </CheckPermission>
        {children}
    </div>
  );
};

export default Actions;
