import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Table from './Table';
import { Button, Tooltip } from "flowbite-react";
import 'react-loading-skeleton/dist/skeleton.css';
import renderSkeleton from '../../components/SkeletonLoader';
import { User } from '../../interfaces/User';
import { UserService } from '../../services/UserService';
import Form from './Form';
import DeleteModal from '../../components/DeleteModal';
import ModalRoles from './ModalRoles';
import CheckPermission from '../../components/CheckPermission';

const Index: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [showModalRole, setShowModalRole] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<number | null>(null);
  const [userRole, setUserRole] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const userService = new UserService();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    const data = await userService.getUsers();
    setUsers(data);
    setLoading(false);
  };

  const handleAdd = () => {
    setUserToDelete(null);
    setShowModalEdit(true);
  };

  const handleEdit = (id: number) => {
    setUserToDelete(id);
    setShowModalEdit(true);
  };

  const handleDelete = (id: number) => {
    setUserToDelete(id);
    setShowModalDelete(true);
  };

  const handleRole = (user: User) => {
    setUserRole(user);
    setShowModalRole(true);
  };

  const confirmDelete = async () => {
    if (userToDelete !== null) {
      await userService.deleteUser(userToDelete);
      setShowModalDelete(false);
      setUsers(users.filter(user => user.id !== userToDelete));
      setUserToDelete(null);
    }
  };
  
  const handleCreate = (user: User, type: string) => {
    if(type == "create"){
      setUsers((prev) => [...prev, user]);
    }else{
      setUsers(prev => prev.map((u) => (u.id === user.id ? { ...u, ...user } : u)));
    }
  }

  return (
    <div className="ag-theme-quartz px-4">
      <div className="flex justify-between items-center py-2">
      <div className="text-lg font-bold">
        Utilizatori
      </div>
    
      <Tooltip content="Adaugă utilizator">
        <Button size="sm" onClick={handleAdd}>Adaugă utilizator</Button>
      </Tooltip>
    </div>
      {loading ? ( renderSkeleton() ) : (
        <>
          <Table users={users} onEdit={handleEdit} onDelete={handleDelete} onRole={handleRole} />
          <CheckPermission permissions={['USER_CREATE', 'USER_UPDATE']}>
            <Form onSave={handleCreate} setShowModal={setShowModalEdit} showModal={showModalEdit} userId={userToDelete} />
          </CheckPermission>
          <CheckPermission permissions={['USER_DELETE']}>
            <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={() => {confirmDelete()}} deleteText="Sunteți sigur că doriți să ștergeți acest utilizator?" />
          </CheckPermission>
          <CheckPermission permissions={['USER_UPDATE', 'ROLE_VIEW_ALL']} logic="AND">
            <ModalRoles user={userRole} showModal={showModalRole} setShowModal={setShowModalRole} onSave={handleCreate} />
          </CheckPermission>
        </>
      )}
    </div>
  );
};

export default Index;
