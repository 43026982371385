import React, { useState, useEffect } from 'react';
import { Modal, Button, ToggleSwitch } from 'flowbite-react';
import { errorMessage, handleOptions, isNullOrEmpty, successMessage } from '../../../functions/generalHelper';
import Input from '../../../components/Input';
import CustomSelect from '../../../components/CustomSelect';
import { Booking, BookingBill, Container, defaultContainer } from '../../../interfaces/Booking';
import { handleInputChange, handleSelectChange } from '../../../functions/selectHelper';
import { BookingService } from '../../../services/BookingService';
import { RecommendationResp } from '../../../interfaces/Recommandation';
import { RecommandationService } from '../../../services/RecommandationService';
import { getContainerInfo } from '../../../interfaces/Movement';

interface ContainerModalProps {
    id: number;
    booking: Booking;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: (container: Container, create: boolean) => void;
}

const ContainerModal: React.FC<ContainerModalProps> = ({ id, booking, showModal, setShowModal, onSave }) => {
    const [container, setContainer] = useState<Container>(defaultContainer);
    const bookingService = new BookingService();
    const [recommendations, setRecommendations] = useState<RecommendationResp[]>([]);
    const recommendationsService = new RecommandationService();
    const typeOptions = handleOptions(recommendations, 'container-type');
    const sizeOptions = handleOptions(recommendations, 'container-size');

    useEffect(() => {
      recommendationsService.getRecommendations().then(data => setRecommendations(data));
    }, []);

    useEffect(() => {
        if (!showModal) {
            setContainer({ ...defaultContainer, bookingId: booking.id! });
        }else{
            if(id != 0){
                setContainer({
                    ...defaultContainer, 
                    ...booking.containers.find(container => container.id === id) || defaultContainer, 
                });
            }else{
                setContainer(prev => ({
                    ...prev, 
                    bookingBillId: booking.bookingBills.length == 1 ? booking.bookingBills[0].id : defaultContainer.bookingBillId
                }));
            }
        }
    }, [showModal, booking.id, id]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (!container.type || !container.size) {
                errorMessage('Tipul si dimensiunea containerului sunt obligatorii!');
                return;
            }
            const newContainer = await bookingService.createBookingContainer(container);
            setShowModal(false);
            successMessage('Container adaugat cu succes!');
            onSave(newContainer, id == 0 ? true : false);
        } catch (error) {
            errorMessage('A aparut o eroare la adaugarea containerului!');
        }
    };

    return (
        <Modal show={showModal} size="3xl" popup onClose={() => setShowModal(false)}>
            <Modal.Header>Adaugă container</Modal.Header>
            <Modal.Body className="overflow-visible">
                <div className="space-y-2 pt-5">
                    <form onSubmit={handleSubmit}>
                        <div className="gap-4 grid grid-cols-2 mb-2">
                            <Input 
                                id="containerNumber" 
                                label="Număr container"
                                value={container.containerNumber || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(container, setContainer, 'containerNumber', e.target.value, defaultContainer)}
                            />
                            <Input 
                                id="sealNumber" 
                                label="Număr sigiliu"
                                value={container.sealNumber || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(container, setContainer, 'sealNumber', e.target.value, defaultContainer)}
                            />
                            <CustomSelect
                                value={typeOptions.find(option => option.value === container.type)}
                                onChange={(data) => handleSelectChange(container, setContainer, 'type', data)}
                                options={typeOptions} // Add your container type options here
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Tip"
                            />
                            <CustomSelect
                                value={sizeOptions.find(option => option.value === container.size)}
                                onChange={(data) => handleSelectChange(container, setContainer, 'size', data)}
                                options={sizeOptions} // Add your container size options here
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Dimensiune"
                            />
                            <CustomSelect
                                value={booking.bookingBills?.filter(bill => bill.id === container.bookingBillId).map(bill => ({ value: bill.id, label: bill.code }))[0]}
                                onChange={(data) => handleSelectChange(container, setContainer, 'bookingBillId', data)}
                                options={booking.bookingBills?.map(bill => ({ value: bill.id, label: bill.code })) || []}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Booking Bill"
                            />
                            <Input 
                                id="tare" 
                                label="Tara"
                                type="number"
                                value={container.tare}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(container, setContainer, 'tare', parseFloat(e.target.value), defaultContainer)}
                            />
                            <Input 
                                id="freeDays" 
                                label="Zile libere"
                                type="number"
                                value={container.freeDays}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(container, setContainer, 'freeDays', parseFloat(e.target.value), defaultContainer)}
                            />
                            <Input 
                                id="weight" 
                                label="Greutate"
                                type="number"
                                value={container.weight}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(container, setContainer, 'weight', parseFloat(e.target.value), defaultContainer)}
                            />
                            <Input 
                                id="packageNumber" 
                                label="Număr pachete"
                                type="number"
                                value={container.packageNumber}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(container, setContainer, 'packageNumber', parseInt(e.target.value), defaultContainer)}
                            />
                            <Input 
                                id="volume"
                                label="Volum"
                                type="number"
                                value={container.volume}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(container, setContainer, 'volume', parseFloat(e.target.value), defaultContainer)}
                            />
                            <Input 
                                id="iso" 
                                label="ISO"
                                value={container.iso}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange(container, setContainer, 'iso', e.target.value, defaultContainer);
                                    const sizeType = getContainerInfo(e.target.value);
                                    if(sizeType.size && sizeType.type){
                                        setContainer(prev => ({...prev, size: sizeType.size, type: sizeType.type}));
                                    }
                                }}
                            />
                            <div className="flex items-center">
                                <ToggleSwitch
                                    color="primary"
                                    checked={container.dangerCargo!}
                                    label="Marfă periculoasă"
                                    onChange={() => setContainer(prev => ({ ...prev, dangerCargo: !prev.dangerCargo }))}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <Button size="sm" type="submit">Salvează</Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ContainerModal;
