import React from 'react';
import { NavLink } from 'react-router-dom';
import { TbUsers } from "react-icons/tb";
import { IoMdAddCircleOutline } from "react-icons/io";
import { HiTemplate } from "react-icons/hi";
import CheckPermission from './CheckPermission';
import { LuTable } from "react-icons/lu";

const OffersNavBar: React.FC = () => {
  return (
    <div className="hidden bg-softprimary md:flex items-center px-5 text-black text-[0.95rem] font-medium h-9 ps-[155px]">
        <CheckPermission permissions={['OFFER_VIEW', 'OFFER_VIEW_ALL']}>
          <NavLink to="/crm/offers/index" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
            <LuTable className='h-4 w-4'/>
            <span className="mr-2">Oferte</span>
          </NavLink>
        </CheckPermission>
        <CheckPermission permissions={['OFFER_CREATE']}>
          <NavLink to="/crm/offers/form" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
            <IoMdAddCircleOutline className='h-5 w-5'/>
            <span className="mr-2">Adaugă ofertă</span>
          </NavLink>
        </CheckPermission>
        <CheckPermission permissions={['OFFER_VIEW', 'OFFER_VIEW_ALL']}>
          <NavLink to="/crm/offers/templates" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
            <HiTemplate className='h-4 w-4'/>
            <span className="mr-2">Șabloane</span>
          </NavLink>
        </CheckPermission>
    </div>
  );
};

export default OffersNavBar;