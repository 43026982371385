import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useUser } from '../../../components/UserContext';
import Breadcrumb from '../../../components/BreadCrumb';
import BookingsNavBar from '../../../components/BookingsNavBar';
import { hasAnyPermission, hasPermission } from '../../../interfaces/Permissions';
import Index from './Index';
import View from './View';
import CheckPermission from '../../../components/CheckPermission';

const MovementsFile: React.FC = () => {
    const { user } = useUser();

    return (
      <>
        <BookingsNavBar />
        <Breadcrumb />
        <CheckPermission permissions={['MOVEMENT_FILES']}>
          <Routes>
              <Route path="view/:id" element={<View />} />
              <Route path="*" element={<Index />} />
          </Routes>
        </CheckPermission>
      </>
    );
};

export default MovementsFile;